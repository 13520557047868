import { soluc } from "./produtos"

export function Solucoes(props:any){

    const size = props.mobile ? 25 : 70

    return(
        <div>
            <div style={{marginTop:'10vh', padding:'0 10vw', transform:props.mobile ? 'translateY(-3vh)' :'translateY(-50px)', width:'80vw'}}>
                    {soluc.map((sol:any, ind:any)=>{
                        return(
                            <div key={ind} style={{display:'flex',textAlign:'left', backgroundImage:'url('+sol.foto+')', height:size+'vh', width:(sol.width/sol.height)*size+'vh', backgroundSize:'cover', position:'relative', borderRadius:'15px', float:ind%2&&!props.mobile ? 'right' : 'left', marginTop: props.mobile ? '5vh':'10vh'}}>
                                <div style={{backgroundColor:'#000', height:'100%', width:(sol.width/sol.height)*size+'vh', position:'absolute', opacity:'0.5', borderRadius:'15px'}}/>
                                <div style={{zIndex:'100', display:'inline-block', position:'absolute', bottom:'30px', left:'30px'}}>
                                    <div style={{fontFamily:'lemon', color:'#fff', fontSize: props.mobile ? '14px' : '25px'}}>
                                        {sol.nome}
                                    </div>
                                    <div style={{fontFamily:'lemon', color:'#fff', fontSize: props.mobile ? '9px' : '14px'}}>
                                        {sol.desc}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                <div style={{clear:'both'}}/>
            </div>
        </div>
    )
}