export const municipios =
            [
        {
            "Id": 1,
            "Codigo": 1100015,
            "Nome": "Alta Floresta D'Oeste",
            "Uf": "RO"
        },
        {
            "Id": 2,
            "Codigo": 1100023,
            "Nome": "Ariquemes",
            "Uf": "RO"
        },
        {
            "Id": 3,
            "Codigo": 1100031,
            "Nome": "Cabixi",
            "Uf": "RO"
        },
        {
            "Id": 4,
            "Codigo": 1100049,
            "Nome": "Cacoal",
            "Uf": "RO"
        },
        {
            "Id": 5,
            "Codigo": 1100056,
            "Nome": "Cerejeiras",
            "Uf": "RO"
        },
        {
            "Id": 6,
            "Codigo": 1100064,
            "Nome": "Colorado do Oeste",
            "Uf": "RO"
        },
        {
            "Id": 7,
            "Codigo": 1100072,
            "Nome": "Corumbiara",
            "Uf": "RO"
        },
        {
            "Id": 8,
            "Codigo": 1100080,
            "Nome": "Costa Marques",
            "Uf": "RO"
        },
        {
            "Id": 9,
            "Codigo": 1100098,
            "Nome": "Espigão D'Oeste",
            "Uf": "RO"
        },
        {
            "Id": 10,
            "Codigo": 1100106,
            "Nome": "Guajará-Mirim",
            "Uf": "RO"
        },
        {
            "Id": 11,
            "Codigo": 1100114,
            "Nome": "Jaru",
            "Uf": "RO"
        },
        {
            "Id": 12,
            "Codigo": 1100122,
            "Nome": "Ji-Paraná",
            "Uf": "RO"
        },
        {
            "Id": 13,
            "Codigo": 1100130,
            "Nome": "Machadinho D'Oeste",
            "Uf": "RO"
        },
        {
            "Id": 14,
            "Codigo": 1100148,
            "Nome": "Nova Brasilândia D'Oeste",
            "Uf": "RO"
        },
        {
            "Id": 15,
            "Codigo": 1100155,
            "Nome": "Ouro Preto do Oeste",
            "Uf": "RO"
        },
        {
            "Id": 16,
            "Codigo": 1100189,
            "Nome": "Pimenta Bueno",
            "Uf": "RO"
        },
        {
            "Id": 17,
            "Codigo": 1100205,
            "Nome": "Porto Velho",
            "Uf": "RO"
        },
        {
            "Id": 18,
            "Codigo": 1100254,
            "Nome": "Presidente Médici",
            "Uf": "RO"
        },
        {
            "Id": 19,
            "Codigo": 1100262,
            "Nome": "Rio Crespo",
            "Uf": "RO"
        },
        {
            "Id": 20,
            "Codigo": 1100288,
            "Nome": "Rolim de Moura",
            "Uf": "RO"
        },
        {
            "Id": 21,
            "Codigo": 1100296,
            "Nome": "Santa Luzia D'Oeste",
            "Uf": "RO"
        },
        {
            "Id": 22,
            "Codigo": 1100304,
            "Nome": "Vilhena",
            "Uf": "RO"
        },
        {
            "Id": 23,
            "Codigo": 1100320,
            "Nome": "São Miguel do Guaporé",
            "Uf": "RO"
        },
        {
            "Id": 24,
            "Codigo": 1100338,
            "Nome": "Nova Mamoré",
            "Uf": "RO"
        },
        {
            "Id": 25,
            "Codigo": 1100346,
            "Nome": "Alvorada D'Oeste",
            "Uf": "RO"
        },
        {
            "Id": 26,
            "Codigo": 1100379,
            "Nome": "Alto Alegre dos Parecis",
            "Uf": "RO"
        },
        {
            "Id": 27,
            "Codigo": 1100403,
            "Nome": "Alto Paraíso",
            "Uf": "RO"
        },
        {
            "Id": 28,
            "Codigo": 1100452,
            "Nome": "Buritis",
            "Uf": "RO"
        },
        {
            "Id": 29,
            "Codigo": 1100502,
            "Nome": "Novo Horizonte do Oeste",
            "Uf": "RO"
        },
        {
            "Id": 30,
            "Codigo": 1100601,
            "Nome": "Cacaulândia",
            "Uf": "RO"
        },
        {
            "Id": 31,
            "Codigo": 1100700,
            "Nome": "Campo Novo de Rondônia",
            "Uf": "RO"
        },
        {
            "Id": 32,
            "Codigo": 1100809,
            "Nome": "Candeias do Jamari",
            "Uf": "RO"
        },
        {
            "Id": 33,
            "Codigo": 1100908,
            "Nome": "Castanheiras",
            "Uf": "RO"
        },
        {
            "Id": 34,
            "Codigo": 1100924,
            "Nome": "Chupinguaia",
            "Uf": "RO"
        },
        {
            "Id": 35,
            "Codigo": 1100940,
            "Nome": "Cujubim",
            "Uf": "RO"
        },
        {
            "Id": 36,
            "Codigo": 1101005,
            "Nome": "Governador Jorge Teixeira",
            "Uf": "RO"
        },
        {
            "Id": 37,
            "Codigo": 1101104,
            "Nome": "Itapuã do Oeste",
            "Uf": "RO"
        },
        {
            "Id": 38,
            "Codigo": 1101203,
            "Nome": "Ministro Andreazza",
            "Uf": "RO"
        },
        {
            "Id": 39,
            "Codigo": 1101302,
            "Nome": "Mirante da Serra",
            "Uf": "RO"
        },
        {
            "Id": 40,
            "Codigo": 1101401,
            "Nome": "Monte Negro",
            "Uf": "RO"
        },
        {
            "Id": 41,
            "Codigo": 1101435,
            "Nome": "Nova União",
            "Uf": "RO"
        },
        {
            "Id": 42,
            "Codigo": 1101450,
            "Nome": "Parecis",
            "Uf": "RO"
        },
        {
            "Id": 43,
            "Codigo": 1101468,
            "Nome": "Pimenteiras do Oeste",
            "Uf": "RO"
        },
        {
            "Id": 44,
            "Codigo": 1101476,
            "Nome": "Primavera de Rondônia",
            "Uf": "RO"
        },
        {
            "Id": 45,
            "Codigo": 1101484,
            "Nome": "São Felipe D'Oeste",
            "Uf": "RO"
        },
        {
            "Id": 46,
            "Codigo": 1101492,
            "Nome": "São Francisco do Guaporé",
            "Uf": "RO"
        },
        {
            "Id": 47,
            "Codigo": 1101500,
            "Nome": "Seringueiras",
            "Uf": "RO"
        },
        {
            "Id": 48,
            "Codigo": 1101559,
            "Nome": "Teixeirópolis",
            "Uf": "RO"
        },
        {
            "Id": 49,
            "Codigo": 1101609,
            "Nome": "Theobroma",
            "Uf": "RO"
        },
        {
            "Id": 50,
            "Codigo": 1101708,
            "Nome": "Urupá",
            "Uf": "RO"
        },
        {
            "Id": 51,
            "Codigo": 1101757,
            "Nome": "Vale do Anari",
            "Uf": "RO"
        },
        {
            "Id": 52,
            "Codigo": 1101807,
            "Nome": "Vale do Paraíso",
            "Uf": "RO"
        },
        {
            "Id": 53,
            "Codigo": 1200013,
            "Nome": "Acrelândia",
            "Uf": "AC"
        },
        {
            "Id": 54,
            "Codigo": 1200054,
            "Nome": "Assis Brasil",
            "Uf": "AC"
        },
        {
            "Id": 55,
            "Codigo": 1200104,
            "Nome": "Brasiléia",
            "Uf": "AC"
        },
        {
            "Id": 56,
            "Codigo": 1200138,
            "Nome": "Bujari",
            "Uf": "AC"
        },
        {
            "Id": 57,
            "Codigo": 1200179,
            "Nome": "Capixaba",
            "Uf": "AC"
        },
        {
            "Id": 58,
            "Codigo": 1200203,
            "Nome": "Cruzeiro do Sul",
            "Uf": "AC"
        },
        {
            "Id": 59,
            "Codigo": 1200252,
            "Nome": "Epitaciolândia",
            "Uf": "AC"
        },
        {
            "Id": 60,
            "Codigo": 1200302,
            "Nome": "Feijó",
            "Uf": "AC"
        },
        {
            "Id": 61,
            "Codigo": 1200328,
            "Nome": "Jordão",
            "Uf": "AC"
        },
        {
            "Id": 62,
            "Codigo": 1200336,
            "Nome": "Mâncio Lima",
            "Uf": "AC"
        },
        {
            "Id": 63,
            "Codigo": 1200344,
            "Nome": "Manoel Urbano",
            "Uf": "AC"
        },
        {
            "Id": 64,
            "Codigo": 1200351,
            "Nome": "Marechal Thaumaturgo",
            "Uf": "AC"
        },
        {
            "Id": 65,
            "Codigo": 1200385,
            "Nome": "Plácido de Castro",
            "Uf": "AC"
        },
        {
            "Id": 66,
            "Codigo": 1200393,
            "Nome": "Porto Walter",
            "Uf": "AC"
        },
        {
            "Id": 67,
            "Codigo": 1200401,
            "Nome": "Rio Branco",
            "Uf": "AC"
        },
        {
            "Id": 68,
            "Codigo": 1200427,
            "Nome": "Rodrigues Alves",
            "Uf": "AC"
        },
        {
            "Id": 69,
            "Codigo": 1200435,
            "Nome": "Santa Rosa do Purus",
            "Uf": "AC"
        },
        {
            "Id": 70,
            "Codigo": 1200450,
            "Nome": "Senador Guiomard",
            "Uf": "AC"
        },
        {
            "Id": 71,
            "Codigo": 1200500,
            "Nome": "Sena Madureira",
            "Uf": "AC"
        },
        {
            "Id": 72,
            "Codigo": 1200609,
            "Nome": "Tarauacá",
            "Uf": "AC"
        },
        {
            "Id": 73,
            "Codigo": 1200708,
            "Nome": "Xapuri",
            "Uf": "AC"
        },
        {
            "Id": 74,
            "Codigo": 1200807,
            "Nome": "Porto Acre",
            "Uf": "AC"
        },
        {
            "Id": 75,
            "Codigo": 1300029,
            "Nome": "Alvarães",
            "Uf": "AM"
        },
        {
            "Id": 76,
            "Codigo": 1300060,
            "Nome": "Amaturá",
            "Uf": "AM"
        },
        {
            "Id": 77,
            "Codigo": 1300086,
            "Nome": "Anamã",
            "Uf": "AM"
        },
        {
            "Id": 78,
            "Codigo": 1300102,
            "Nome": "Anori",
            "Uf": "AM"
        },
        {
            "Id": 79,
            "Codigo": 1300144,
            "Nome": "Apuí",
            "Uf": "AM"
        },
        {
            "Id": 80,
            "Codigo": 1300201,
            "Nome": "Atalaia do Norte",
            "Uf": "AM"
        },
        {
            "Id": 81,
            "Codigo": 1300300,
            "Nome": "Autazes",
            "Uf": "AM"
        },
        {
            "Id": 82,
            "Codigo": 1300409,
            "Nome": "Barcelos",
            "Uf": "AM"
        },
        {
            "Id": 83,
            "Codigo": 1300508,
            "Nome": "Barreirinha",
            "Uf": "AM"
        },
        {
            "Id": 84,
            "Codigo": 1300607,
            "Nome": "Benjamin Constant",
            "Uf": "AM"
        },
        {
            "Id": 85,
            "Codigo": 1300631,
            "Nome": "Beruri",
            "Uf": "AM"
        },
        {
            "Id": 86,
            "Codigo": 1300680,
            "Nome": "Boa Vista do Ramos",
            "Uf": "AM"
        },
        {
            "Id": 87,
            "Codigo": 1300706,
            "Nome": "Boca do Acre",
            "Uf": "AM"
        },
        {
            "Id": 88,
            "Codigo": 1300805,
            "Nome": "Borba",
            "Uf": "AM"
        },
        {
            "Id": 89,
            "Codigo": 1300839,
            "Nome": "Caapiranga",
            "Uf": "AM"
        },
        {
            "Id": 90,
            "Codigo": 1300904,
            "Nome": "Canutama",
            "Uf": "AM"
        },
        {
            "Id": 91,
            "Codigo": 1301001,
            "Nome": "Carauari",
            "Uf": "AM"
        },
        {
            "Id": 92,
            "Codigo": 1301100,
            "Nome": "Careiro",
            "Uf": "AM"
        },
        {
            "Id": 93,
            "Codigo": 1301159,
            "Nome": "Careiro da Várzea",
            "Uf": "AM"
        },
        {
            "Id": 94,
            "Codigo": 1301209,
            "Nome": "Coari",
            "Uf": "AM"
        },
        {
            "Id": 95,
            "Codigo": 1301308,
            "Nome": "Codajás",
            "Uf": "AM"
        },
        {
            "Id": 96,
            "Codigo": 1301407,
            "Nome": "Eirunepé",
            "Uf": "AM"
        },
        {
            "Id": 97,
            "Codigo": 1301506,
            "Nome": "Envira",
            "Uf": "AM"
        },
        {
            "Id": 98,
            "Codigo": 1301605,
            "Nome": "Fonte Boa",
            "Uf": "AM"
        },
        {
            "Id": 99,
            "Codigo": 1301654,
            "Nome": "Guajará",
            "Uf": "AM"
        },
        {
            "Id": 100,
            "Codigo": 1301704,
            "Nome": "Humaitá",
            "Uf": "AM"
        },
        {
            "Id": 101,
            "Codigo": 1301803,
            "Nome": "Ipixuna",
            "Uf": "AM"
        },
        {
            "Id": 102,
            "Codigo": 1301852,
            "Nome": "Iranduba",
            "Uf": "AM"
        },
        {
            "Id": 103,
            "Codigo": 1301902,
            "Nome": "Itacoatiara",
            "Uf": "AM"
        },
        {
            "Id": 104,
            "Codigo": 1301951,
            "Nome": "Itamarati",
            "Uf": "AM"
        },
        {
            "Id": 105,
            "Codigo": 1302009,
            "Nome": "Itapiranga",
            "Uf": "AM"
        },
        {
            "Id": 106,
            "Codigo": 1302108,
            "Nome": "Japurá",
            "Uf": "AM"
        },
        {
            "Id": 107,
            "Codigo": 1302207,
            "Nome": "Juruá",
            "Uf": "AM"
        },
        {
            "Id": 108,
            "Codigo": 1302306,
            "Nome": "Jutaí",
            "Uf": "AM"
        },
        {
            "Id": 109,
            "Codigo": 1302405,
            "Nome": "Lábrea",
            "Uf": "AM"
        },
        {
            "Id": 110,
            "Codigo": 1302504,
            "Nome": "Manacapuru",
            "Uf": "AM"
        },
        {
            "Id": 111,
            "Codigo": 1302553,
            "Nome": "Manaquiri",
            "Uf": "AM"
        },
        {
            "Id": 112,
            "Codigo": 1302603,
            "Nome": "Manaus",
            "Uf": "AM"
        },
        {
            "Id": 113,
            "Codigo": 1302702,
            "Nome": "Manicoré",
            "Uf": "AM"
        },
        {
            "Id": 114,
            "Codigo": 1302801,
            "Nome": "Maraã",
            "Uf": "AM"
        },
        {
            "Id": 115,
            "Codigo": 1302900,
            "Nome": "Maués",
            "Uf": "AM"
        },
        {
            "Id": 116,
            "Codigo": 1303007,
            "Nome": "Nhamundá",
            "Uf": "AM"
        },
        {
            "Id": 117,
            "Codigo": 1303106,
            "Nome": "Nova Olinda do Norte",
            "Uf": "AM"
        },
        {
            "Id": 118,
            "Codigo": 1303205,
            "Nome": "Novo Airão",
            "Uf": "AM"
        },
        {
            "Id": 119,
            "Codigo": 1303304,
            "Nome": "Novo Aripuanã",
            "Uf": "AM"
        },
        {
            "Id": 120,
            "Codigo": 1303403,
            "Nome": "Parintins",
            "Uf": "AM"
        },
        {
            "Id": 121,
            "Codigo": 1303502,
            "Nome": "Pauini",
            "Uf": "AM"
        },
        {
            "Id": 122,
            "Codigo": 1303536,
            "Nome": "Presidente Figueiredo",
            "Uf": "AM"
        },
        {
            "Id": 123,
            "Codigo": 1303569,
            "Nome": "Rio Preto da Eva",
            "Uf": "AM"
        },
        {
            "Id": 124,
            "Codigo": 1303601,
            "Nome": "Santa Isabel do Rio Negro",
            "Uf": "AM"
        },
        {
            "Id": 125,
            "Codigo": 1303700,
            "Nome": "Santo Antônio do Içá",
            "Uf": "AM"
        },
        {
            "Id": 126,
            "Codigo": 1303809,
            "Nome": "São Gabriel da Cachoeira",
            "Uf": "AM"
        },
        {
            "Id": 127,
            "Codigo": 1303908,
            "Nome": "São Paulo de Olivença",
            "Uf": "AM"
        },
        {
            "Id": 128,
            "Codigo": 1303957,
            "Nome": "São Sebastião do Uatumã",
            "Uf": "AM"
        },
        {
            "Id": 129,
            "Codigo": 1304005,
            "Nome": "Silves",
            "Uf": "AM"
        },
        {
            "Id": 130,
            "Codigo": 1304062,
            "Nome": "Tabatinga",
            "Uf": "AM"
        },
        {
            "Id": 131,
            "Codigo": 1304104,
            "Nome": "Tapauá",
            "Uf": "AM"
        },
        {
            "Id": 132,
            "Codigo": 1304203,
            "Nome": "Tefé",
            "Uf": "AM"
        },
        {
            "Id": 133,
            "Codigo": 1304237,
            "Nome": "Tonantins",
            "Uf": "AM"
        },
        {
            "Id": 134,
            "Codigo": 1304260,
            "Nome": "Uarini",
            "Uf": "AM"
        },
        {
            "Id": 135,
            "Codigo": 1304302,
            "Nome": "Urucará",
            "Uf": "AM"
        },
        {
            "Id": 136,
            "Codigo": 1304401,
            "Nome": "Urucurituba",
            "Uf": "AM"
        },
        {
            "Id": 137,
            "Codigo": 1400027,
            "Nome": "Amajari",
            "Uf": "RR"
        },
        {
            "Id": 138,
            "Codigo": 1400050,
            "Nome": "Alto Alegre",
            "Uf": "RR"
        },
        {
            "Id": 139,
            "Codigo": 1400100,
            "Nome": "Boa Vista",
            "Uf": "RR"
        },
        {
            "Id": 140,
            "Codigo": 1400159,
            "Nome": "Bonfim",
            "Uf": "RR"
        },
        {
            "Id": 141,
            "Codigo": 1400175,
            "Nome": "Cantá",
            "Uf": "RR"
        },
        {
            "Id": 142,
            "Codigo": 1400209,
            "Nome": "Caracaraí",
            "Uf": "RR"
        },
        {
            "Id": 143,
            "Codigo": 1400233,
            "Nome": "Caroebe",
            "Uf": "RR"
        },
        {
            "Id": 144,
            "Codigo": 1400282,
            "Nome": "Iracema",
            "Uf": "RR"
        },
        {
            "Id": 145,
            "Codigo": 1400308,
            "Nome": "Mucajaí",
            "Uf": "RR"
        },
        {
            "Id": 146,
            "Codigo": 1400407,
            "Nome": "Normandia",
            "Uf": "RR"
        },
        {
            "Id": 147,
            "Codigo": 1400456,
            "Nome": "Pacaraima",
            "Uf": "RR"
        },
        {
            "Id": 148,
            "Codigo": 1400472,
            "Nome": "Rorainópolis",
            "Uf": "RR"
        },
        {
            "Id": 149,
            "Codigo": 1400506,
            "Nome": "São João da Baliza",
            "Uf": "RR"
        },
        {
            "Id": 150,
            "Codigo": 1400605,
            "Nome": "São Luiz",
            "Uf": "RR"
        },
        {
            "Id": 151,
            "Codigo": 1400704,
            "Nome": "Uiramutã",
            "Uf": "RR"
        },
        {
            "Id": 152,
            "Codigo": 1500107,
            "Nome": "Abaetetuba",
            "Uf": "PA"
        },
        {
            "Id": 153,
            "Codigo": 1500131,
            "Nome": "Abel Figueiredo",
            "Uf": "PA"
        },
        {
            "Id": 154,
            "Codigo": 1500206,
            "Nome": "Acará",
            "Uf": "PA"
        },
        {
            "Id": 155,
            "Codigo": 1500305,
            "Nome": "Afuá",
            "Uf": "PA"
        },
        {
            "Id": 156,
            "Codigo": 1500347,
            "Nome": "Água Azul do Norte",
            "Uf": "PA"
        },
        {
            "Id": 157,
            "Codigo": 1500404,
            "Nome": "Alenquer",
            "Uf": "PA"
        },
        {
            "Id": 158,
            "Codigo": 1500503,
            "Nome": "Almeirim",
            "Uf": "PA"
        },
        {
            "Id": 159,
            "Codigo": 1500602,
            "Nome": "Altamira",
            "Uf": "PA"
        },
        {
            "Id": 160,
            "Codigo": 1500701,
            "Nome": "Anajás",
            "Uf": "PA"
        },
        {
            "Id": 161,
            "Codigo": 1500800,
            "Nome": "Ananindeua",
            "Uf": "PA"
        },
        {
            "Id": 162,
            "Codigo": 1500859,
            "Nome": "Anapu",
            "Uf": "PA"
        },
        {
            "Id": 163,
            "Codigo": 1500909,
            "Nome": "Augusto Corrêa",
            "Uf": "PA"
        },
        {
            "Id": 164,
            "Codigo": 1500958,
            "Nome": "Aurora do Pará",
            "Uf": "PA"
        },
        {
            "Id": 165,
            "Codigo": 1501006,
            "Nome": "Aveiro",
            "Uf": "PA"
        },
        {
            "Id": 166,
            "Codigo": 1501105,
            "Nome": "Bagre",
            "Uf": "PA"
        },
        {
            "Id": 167,
            "Codigo": 1501204,
            "Nome": "Baião",
            "Uf": "PA"
        },
        {
            "Id": 168,
            "Codigo": 1501253,
            "Nome": "Bannach",
            "Uf": "PA"
        },
        {
            "Id": 169,
            "Codigo": 1501303,
            "Nome": "Barcarena",
            "Uf": "PA"
        },
        {
            "Id": 170,
            "Codigo": 1501402,
            "Nome": "Belém",
            "Uf": "PA"
        },
        {
            "Id": 171,
            "Codigo": 1501451,
            "Nome": "Belterra",
            "Uf": "PA"
        },
        {
            "Id": 172,
            "Codigo": 1501501,
            "Nome": "Benevides",
            "Uf": "PA"
        },
        {
            "Id": 173,
            "Codigo": 1501576,
            "Nome": "Bom Jesus do Tocantins",
            "Uf": "PA"
        },
        {
            "Id": 174,
            "Codigo": 1501600,
            "Nome": "Bonito",
            "Uf": "PA"
        },
        {
            "Id": 175,
            "Codigo": 1501709,
            "Nome": "Bragança",
            "Uf": "PA"
        },
        {
            "Id": 176,
            "Codigo": 1501725,
            "Nome": "Brasil Novo",
            "Uf": "PA"
        },
        {
            "Id": 177,
            "Codigo": 1501758,
            "Nome": "Brejo Grande do Araguaia",
            "Uf": "PA"
        },
        {
            "Id": 178,
            "Codigo": 1501782,
            "Nome": "Breu Branco",
            "Uf": "PA"
        },
        {
            "Id": 179,
            "Codigo": 1501808,
            "Nome": "Breves",
            "Uf": "PA"
        },
        {
            "Id": 180,
            "Codigo": 1501907,
            "Nome": "Bujaru",
            "Uf": "PA"
        },
        {
            "Id": 181,
            "Codigo": 1501956,
            "Nome": "Cachoeira do Piriá",
            "Uf": "PA"
        },
        {
            "Id": 182,
            "Codigo": 1502004,
            "Nome": "Cachoeira do Arari",
            "Uf": "PA"
        },
        {
            "Id": 183,
            "Codigo": 1502103,
            "Nome": "Cametá",
            "Uf": "PA"
        },
        {
            "Id": 184,
            "Codigo": 1502152,
            "Nome": "Canaã dos Carajás",
            "Uf": "PA"
        },
        {
            "Id": 185,
            "Codigo": 1502202,
            "Nome": "Capanema",
            "Uf": "PA"
        },
        {
            "Id": 186,
            "Codigo": 1502301,
            "Nome": "Capitão Poço",
            "Uf": "PA"
        },
        {
            "Id": 187,
            "Codigo": 1502400,
            "Nome": "Castanhal",
            "Uf": "PA"
        },
        {
            "Id": 188,
            "Codigo": 1502509,
            "Nome": "Chaves",
            "Uf": "PA"
        },
        {
            "Id": 189,
            "Codigo": 1502608,
            "Nome": "Colares",
            "Uf": "PA"
        },
        {
            "Id": 190,
            "Codigo": 1502707,
            "Nome": "Conceição do Araguaia",
            "Uf": "PA"
        },
        {
            "Id": 191,
            "Codigo": 1502756,
            "Nome": "Concórdia do Pará",
            "Uf": "PA"
        },
        {
            "Id": 192,
            "Codigo": 1502764,
            "Nome": "Cumaru do Norte",
            "Uf": "PA"
        },
        {
            "Id": 193,
            "Codigo": 1502772,
            "Nome": "Curionópolis",
            "Uf": "PA"
        },
        {
            "Id": 194,
            "Codigo": 1502806,
            "Nome": "Curralinho",
            "Uf": "PA"
        },
        {
            "Id": 195,
            "Codigo": 1502855,
            "Nome": "Curuá",
            "Uf": "PA"
        },
        {
            "Id": 196,
            "Codigo": 1502905,
            "Nome": "Curuçá",
            "Uf": "PA"
        },
        {
            "Id": 197,
            "Codigo": 1502939,
            "Nome": "Dom Eliseu",
            "Uf": "PA"
        },
        {
            "Id": 198,
            "Codigo": 1502954,
            "Nome": "Eldorado dos Carajás",
            "Uf": "PA"
        },
        {
            "Id": 199,
            "Codigo": 1503002,
            "Nome": "Faro",
            "Uf": "PA"
        },
        {
            "Id": 200,
            "Codigo": 1503044,
            "Nome": "Floresta do Araguaia",
            "Uf": "PA"
        },
        {
            "Id": 201,
            "Codigo": 1503077,
            "Nome": "Garrafão do Norte",
            "Uf": "PA"
        },
        {
            "Id": 202,
            "Codigo": 1503093,
            "Nome": "Goianésia do Pará",
            "Uf": "PA"
        },
        {
            "Id": 203,
            "Codigo": 1503101,
            "Nome": "Gurupá",
            "Uf": "PA"
        },
        {
            "Id": 204,
            "Codigo": 1503200,
            "Nome": "Igarapé-Açu",
            "Uf": "PA"
        },
        {
            "Id": 205,
            "Codigo": 1503309,
            "Nome": "Igarapé-Miri",
            "Uf": "PA"
        },
        {
            "Id": 206,
            "Codigo": 1503408,
            "Nome": "Inhangapi",
            "Uf": "PA"
        },
        {
            "Id": 207,
            "Codigo": 1503457,
            "Nome": "Ipixuna do Pará",
            "Uf": "PA"
        },
        {
            "Id": 208,
            "Codigo": 1503507,
            "Nome": "Irituia",
            "Uf": "PA"
        },
        {
            "Id": 209,
            "Codigo": 1503606,
            "Nome": "Itaituba",
            "Uf": "PA"
        },
        {
            "Id": 210,
            "Codigo": 1503705,
            "Nome": "Itupiranga",
            "Uf": "PA"
        },
        {
            "Id": 211,
            "Codigo": 1503754,
            "Nome": "Jacareacanga",
            "Uf": "PA"
        },
        {
            "Id": 212,
            "Codigo": 1503804,
            "Nome": "Jacundá",
            "Uf": "PA"
        },
        {
            "Id": 213,
            "Codigo": 1503903,
            "Nome": "Juruti",
            "Uf": "PA"
        },
        {
            "Id": 214,
            "Codigo": 1504000,
            "Nome": "Limoeiro do Ajuru",
            "Uf": "PA"
        },
        {
            "Id": 215,
            "Codigo": 1504059,
            "Nome": "Mãe do Rio",
            "Uf": "PA"
        },
        {
            "Id": 216,
            "Codigo": 1504109,
            "Nome": "Magalhães Barata",
            "Uf": "PA"
        },
        {
            "Id": 217,
            "Codigo": 1504208,
            "Nome": "Marabá",
            "Uf": "PA"
        },
        {
            "Id": 218,
            "Codigo": 1504307,
            "Nome": "Maracanã",
            "Uf": "PA"
        },
        {
            "Id": 219,
            "Codigo": 1504406,
            "Nome": "Marapanim",
            "Uf": "PA"
        },
        {
            "Id": 220,
            "Codigo": 1504422,
            "Nome": "Marituba",
            "Uf": "PA"
        },
        {
            "Id": 221,
            "Codigo": 1504455,
            "Nome": "Medicilândia",
            "Uf": "PA"
        },
        {
            "Id": 222,
            "Codigo": 1504505,
            "Nome": "Melgaço",
            "Uf": "PA"
        },
        {
            "Id": 223,
            "Codigo": 1504604,
            "Nome": "Mocajuba",
            "Uf": "PA"
        },
        {
            "Id": 224,
            "Codigo": 1504703,
            "Nome": "Moju",
            "Uf": "PA"
        },
        {
            "Id": 225,
            "Codigo": 1504752,
            "Nome": "Mojuí dos Campos",
            "Uf": "PA"
        },
        {
            "Id": 226,
            "Codigo": 1504802,
            "Nome": "Monte Alegre",
            "Uf": "PA"
        },
        {
            "Id": 227,
            "Codigo": 1504901,
            "Nome": "Muaná",
            "Uf": "PA"
        },
        {
            "Id": 228,
            "Codigo": 1504950,
            "Nome": "Nova Esperança do Piriá",
            "Uf": "PA"
        },
        {
            "Id": 229,
            "Codigo": 1504976,
            "Nome": "Nova Ipixuna",
            "Uf": "PA"
        },
        {
            "Id": 230,
            "Codigo": 1505007,
            "Nome": "Nova Timboteua",
            "Uf": "PA"
        },
        {
            "Id": 231,
            "Codigo": 1505031,
            "Nome": "Novo Progresso",
            "Uf": "PA"
        },
        {
            "Id": 232,
            "Codigo": 1505064,
            "Nome": "Novo Repartimento",
            "Uf": "PA"
        },
        {
            "Id": 233,
            "Codigo": 1505106,
            "Nome": "Óbidos",
            "Uf": "PA"
        },
        {
            "Id": 234,
            "Codigo": 1505205,
            "Nome": "Oeiras do Pará",
            "Uf": "PA"
        },
        {
            "Id": 235,
            "Codigo": 1505304,
            "Nome": "Oriximiná",
            "Uf": "PA"
        },
        {
            "Id": 236,
            "Codigo": 1505403,
            "Nome": "Ourém",
            "Uf": "PA"
        },
        {
            "Id": 237,
            "Codigo": 1505437,
            "Nome": "Ourilândia do Norte",
            "Uf": "PA"
        },
        {
            "Id": 238,
            "Codigo": 1505486,
            "Nome": "Pacajá",
            "Uf": "PA"
        },
        {
            "Id": 239,
            "Codigo": 1505494,
            "Nome": "Palestina do Pará",
            "Uf": "PA"
        },
        {
            "Id": 240,
            "Codigo": 1505502,
            "Nome": "Paragominas",
            "Uf": "PA"
        },
        {
            "Id": 241,
            "Codigo": 1505536,
            "Nome": "Parauapebas",
            "Uf": "PA"
        },
        {
            "Id": 242,
            "Codigo": 1505551,
            "Nome": "Pau D'Arco",
            "Uf": "PA"
        },
        {
            "Id": 243,
            "Codigo": 1505601,
            "Nome": "Peixe-Boi",
            "Uf": "PA"
        },
        {
            "Id": 244,
            "Codigo": 1505635,
            "Nome": "Piçarra",
            "Uf": "PA"
        },
        {
            "Id": 245,
            "Codigo": 1505650,
            "Nome": "Placas",
            "Uf": "PA"
        },
        {
            "Id": 246,
            "Codigo": 1505700,
            "Nome": "Ponta de Pedras",
            "Uf": "PA"
        },
        {
            "Id": 247,
            "Codigo": 1505809,
            "Nome": "Portel",
            "Uf": "PA"
        },
        {
            "Id": 248,
            "Codigo": 1505908,
            "Nome": "Porto de Moz",
            "Uf": "PA"
        },
        {
            "Id": 249,
            "Codigo": 1506005,
            "Nome": "Prainha",
            "Uf": "PA"
        },
        {
            "Id": 250,
            "Codigo": 1506104,
            "Nome": "Primavera",
            "Uf": "PA"
        },
        {
            "Id": 251,
            "Codigo": 1506112,
            "Nome": "Quatipuru",
            "Uf": "PA"
        },
        {
            "Id": 252,
            "Codigo": 1506138,
            "Nome": "Redenção",
            "Uf": "PA"
        },
        {
            "Id": 253,
            "Codigo": 1506161,
            "Nome": "Rio Maria",
            "Uf": "PA"
        },
        {
            "Id": 254,
            "Codigo": 1506187,
            "Nome": "Rondon do Pará",
            "Uf": "PA"
        },
        {
            "Id": 255,
            "Codigo": 1506195,
            "Nome": "Rurópolis",
            "Uf": "PA"
        },
        {
            "Id": 256,
            "Codigo": 1506203,
            "Nome": "Salinópolis",
            "Uf": "PA"
        },
        {
            "Id": 257,
            "Codigo": 1506302,
            "Nome": "Salvaterra",
            "Uf": "PA"
        },
        {
            "Id": 258,
            "Codigo": 1506351,
            "Nome": "Santa Bárbara do Pará",
            "Uf": "PA"
        },
        {
            "Id": 259,
            "Codigo": 1506401,
            "Nome": "Santa Cruz do Arari",
            "Uf": "PA"
        },
        {
            "Id": 260,
            "Codigo": 1506500,
            "Nome": "Santa Isabel do Pará",
            "Uf": "PA"
        },
        {
            "Id": 261,
            "Codigo": 1506559,
            "Nome": "Santa Luzia do Pará",
            "Uf": "PA"
        },
        {
            "Id": 262,
            "Codigo": 1506583,
            "Nome": "Santa Maria das Barreiras",
            "Uf": "PA"
        },
        {
            "Id": 263,
            "Codigo": 1506609,
            "Nome": "Santa Maria do Pará",
            "Uf": "PA"
        },
        {
            "Id": 264,
            "Codigo": 1506708,
            "Nome": "Santana do Araguaia",
            "Uf": "PA"
        },
        {
            "Id": 265,
            "Codigo": 1506807,
            "Nome": "Santarém",
            "Uf": "PA"
        },
        {
            "Id": 266,
            "Codigo": 1506906,
            "Nome": "Santarém Novo",
            "Uf": "PA"
        },
        {
            "Id": 267,
            "Codigo": 1507003,
            "Nome": "Santo Antônio do Tauá",
            "Uf": "PA"
        },
        {
            "Id": 268,
            "Codigo": 1507102,
            "Nome": "São Caetano de Odivelas",
            "Uf": "PA"
        },
        {
            "Id": 269,
            "Codigo": 1507151,
            "Nome": "São Domingos do Araguaia",
            "Uf": "PA"
        },
        {
            "Id": 270,
            "Codigo": 1507201,
            "Nome": "São Domingos do Capim",
            "Uf": "PA"
        },
        {
            "Id": 271,
            "Codigo": 1507300,
            "Nome": "São Félix do Xingu",
            "Uf": "PA"
        },
        {
            "Id": 272,
            "Codigo": 1507409,
            "Nome": "São Francisco do Pará",
            "Uf": "PA"
        },
        {
            "Id": 273,
            "Codigo": 1507458,
            "Nome": "São Geraldo do Araguaia",
            "Uf": "PA"
        },
        {
            "Id": 274,
            "Codigo": 1507466,
            "Nome": "São João da Ponta",
            "Uf": "PA"
        },
        {
            "Id": 275,
            "Codigo": 1507474,
            "Nome": "São João de Pirabas",
            "Uf": "PA"
        },
        {
            "Id": 276,
            "Codigo": 1507508,
            "Nome": "São João do Araguaia",
            "Uf": "PA"
        },
        {
            "Id": 277,
            "Codigo": 1507607,
            "Nome": "São Miguel do Guamá",
            "Uf": "PA"
        },
        {
            "Id": 278,
            "Codigo": 1507706,
            "Nome": "São Sebastião da Boa Vista",
            "Uf": "PA"
        },
        {
            "Id": 279,
            "Codigo": 1507755,
            "Nome": "Sapucaia",
            "Uf": "PA"
        },
        {
            "Id": 280,
            "Codigo": 1507805,
            "Nome": "Senador José Porfírio",
            "Uf": "PA"
        },
        {
            "Id": 281,
            "Codigo": 1507904,
            "Nome": "Soure",
            "Uf": "PA"
        },
        {
            "Id": 282,
            "Codigo": 1507953,
            "Nome": "Tailândia",
            "Uf": "PA"
        },
        {
            "Id": 283,
            "Codigo": 1507961,
            "Nome": "Terra Alta",
            "Uf": "PA"
        },
        {
            "Id": 284,
            "Codigo": 1507979,
            "Nome": "Terra Santa",
            "Uf": "PA"
        },
        {
            "Id": 285,
            "Codigo": 1508001,
            "Nome": "Tomé-Açu",
            "Uf": "PA"
        },
        {
            "Id": 286,
            "Codigo": 1508035,
            "Nome": "Tracuateua",
            "Uf": "PA"
        },
        {
            "Id": 287,
            "Codigo": 1508050,
            "Nome": "Trairão",
            "Uf": "PA"
        },
        {
            "Id": 288,
            "Codigo": 1508084,
            "Nome": "Tucumã",
            "Uf": "PA"
        },
        {
            "Id": 289,
            "Codigo": 1508100,
            "Nome": "Tucuruí",
            "Uf": "PA"
        },
        {
            "Id": 290,
            "Codigo": 1508126,
            "Nome": "Ulianópolis",
            "Uf": "PA"
        },
        {
            "Id": 291,
            "Codigo": 1508159,
            "Nome": "Uruará",
            "Uf": "PA"
        },
        {
            "Id": 292,
            "Codigo": 1508209,
            "Nome": "Vigia",
            "Uf": "PA"
        },
        {
            "Id": 293,
            "Codigo": 1508308,
            "Nome": "Viseu",
            "Uf": "PA"
        },
        {
            "Id": 294,
            "Codigo": 1508357,
            "Nome": "Vitória do Xingu",
            "Uf": "PA"
        },
        {
            "Id": 295,
            "Codigo": 1508407,
            "Nome": "Xinguara",
            "Uf": "PA"
        },
        {
            "Id": 296,
            "Codigo": 1600055,
            "Nome": "Serra do Navio",
            "Uf": "AP"
        },
        {
            "Id": 297,
            "Codigo": 1600105,
            "Nome": "Amapá",
            "Uf": "AP"
        },
        {
            "Id": 298,
            "Codigo": 1600154,
            "Nome": "Pedra Branca do Amapari",
            "Uf": "AP"
        },
        {
            "Id": 299,
            "Codigo": 1600204,
            "Nome": "Calçoene",
            "Uf": "AP"
        },
        {
            "Id": 300,
            "Codigo": 1600212,
            "Nome": "Cutias",
            "Uf": "AP"
        },
        {
            "Id": 301,
            "Codigo": 1600238,
            "Nome": "Ferreira Gomes",
            "Uf": "AP"
        },
        {
            "Id": 302,
            "Codigo": 1600253,
            "Nome": "Itaubal",
            "Uf": "AP"
        },
        {
            "Id": 303,
            "Codigo": 1600279,
            "Nome": "Laranjal do Jari",
            "Uf": "AP"
        },
        {
            "Id": 304,
            "Codigo": 1600303,
            "Nome": "Macapá",
            "Uf": "AP"
        },
        {
            "Id": 305,
            "Codigo": 1600402,
            "Nome": "Mazagão",
            "Uf": "AP"
        },
        {
            "Id": 306,
            "Codigo": 1600501,
            "Nome": "Oiapoque",
            "Uf": "AP"
        },
        {
            "Id": 307,
            "Codigo": 1600535,
            "Nome": "Porto Grande",
            "Uf": "AP"
        },
        {
            "Id": 308,
            "Codigo": 1600550,
            "Nome": "Pracuúba",
            "Uf": "AP"
        },
        {
            "Id": 309,
            "Codigo": 1600600,
            "Nome": "Santana",
            "Uf": "AP"
        },
        {
            "Id": 310,
            "Codigo": 1600709,
            "Nome": "Tartarugalzinho",
            "Uf": "AP"
        },
        {
            "Id": 311,
            "Codigo": 1600808,
            "Nome": "Vitória do Jari",
            "Uf": "AP"
        },
        {
            "Id": 312,
            "Codigo": 1700251,
            "Nome": "Abreulândia",
            "Uf": "TO"
        },
        {
            "Id": 313,
            "Codigo": 1700301,
            "Nome": "Aguiarnópolis",
            "Uf": "TO"
        },
        {
            "Id": 314,
            "Codigo": 1700350,
            "Nome": "Aliança do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 315,
            "Codigo": 1700400,
            "Nome": "Almas",
            "Uf": "TO"
        },
        {
            "Id": 316,
            "Codigo": 1700707,
            "Nome": "Alvorada",
            "Uf": "TO"
        },
        {
            "Id": 317,
            "Codigo": 1701002,
            "Nome": "Ananás",
            "Uf": "TO"
        },
        {
            "Id": 318,
            "Codigo": 1701051,
            "Nome": "Angico",
            "Uf": "TO"
        },
        {
            "Id": 319,
            "Codigo": 1701101,
            "Nome": "Aparecida do Rio Negro",
            "Uf": "TO"
        },
        {
            "Id": 320,
            "Codigo": 1701309,
            "Nome": "Aragominas",
            "Uf": "TO"
        },
        {
            "Id": 321,
            "Codigo": 1701903,
            "Nome": "Araguacema",
            "Uf": "TO"
        },
        {
            "Id": 322,
            "Codigo": 1702000,
            "Nome": "Araguaçu",
            "Uf": "TO"
        },
        {
            "Id": 323,
            "Codigo": 1702109,
            "Nome": "Araguaína",
            "Uf": "TO"
        },
        {
            "Id": 324,
            "Codigo": 1702158,
            "Nome": "Araguanã",
            "Uf": "TO"
        },
        {
            "Id": 325,
            "Codigo": 1702208,
            "Nome": "Araguatins",
            "Uf": "TO"
        },
        {
            "Id": 326,
            "Codigo": 1702307,
            "Nome": "Arapoema",
            "Uf": "TO"
        },
        {
            "Id": 327,
            "Codigo": 1702406,
            "Nome": "Arraias",
            "Uf": "TO"
        },
        {
            "Id": 328,
            "Codigo": 1702554,
            "Nome": "Augustinópolis",
            "Uf": "TO"
        },
        {
            "Id": 329,
            "Codigo": 1702703,
            "Nome": "Aurora do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 330,
            "Codigo": 1702901,
            "Nome": "Axixá do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 331,
            "Codigo": 1703008,
            "Nome": "Babaçulândia",
            "Uf": "TO"
        },
        {
            "Id": 332,
            "Codigo": 1703057,
            "Nome": "Bandeirantes do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 333,
            "Codigo": 1703073,
            "Nome": "Barra do Ouro",
            "Uf": "TO"
        },
        {
            "Id": 334,
            "Codigo": 1703107,
            "Nome": "Barrolândia",
            "Uf": "TO"
        },
        {
            "Id": 335,
            "Codigo": 1703206,
            "Nome": "Bernardo Sayão",
            "Uf": "TO"
        },
        {
            "Id": 336,
            "Codigo": 1703305,
            "Nome": "Bom Jesus do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 337,
            "Codigo": 1703602,
            "Nome": "Brasilândia do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 338,
            "Codigo": 1703701,
            "Nome": "Brejinho de Nazaré",
            "Uf": "TO"
        },
        {
            "Id": 339,
            "Codigo": 1703800,
            "Nome": "Buriti do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 340,
            "Codigo": 1703826,
            "Nome": "Cachoeirinha",
            "Uf": "TO"
        },
        {
            "Id": 341,
            "Codigo": 1703842,
            "Nome": "Campos Lindos",
            "Uf": "TO"
        },
        {
            "Id": 342,
            "Codigo": 1703867,
            "Nome": "Cariri do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 343,
            "Codigo": 1703883,
            "Nome": "Carmolândia",
            "Uf": "TO"
        },
        {
            "Id": 344,
            "Codigo": 1703891,
            "Nome": "Carrasco Bonito",
            "Uf": "TO"
        },
        {
            "Id": 345,
            "Codigo": 1703909,
            "Nome": "Caseara",
            "Uf": "TO"
        },
        {
            "Id": 346,
            "Codigo": 1704105,
            "Nome": "Centenário",
            "Uf": "TO"
        },
        {
            "Id": 347,
            "Codigo": 1704600,
            "Nome": "Chapada de Areia",
            "Uf": "TO"
        },
        {
            "Id": 348,
            "Codigo": 1705102,
            "Nome": "Chapada da Natividade",
            "Uf": "TO"
        },
        {
            "Id": 349,
            "Codigo": 1705508,
            "Nome": "Colinas do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 350,
            "Codigo": 1705557,
            "Nome": "Combinado",
            "Uf": "TO"
        },
        {
            "Id": 351,
            "Codigo": 1705607,
            "Nome": "Conceição do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 352,
            "Codigo": 1706001,
            "Nome": "Couto Magalhães",
            "Uf": "TO"
        },
        {
            "Id": 353,
            "Codigo": 1706100,
            "Nome": "Cristalândia",
            "Uf": "TO"
        },
        {
            "Id": 354,
            "Codigo": 1706258,
            "Nome": "Crixás do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 355,
            "Codigo": 1706506,
            "Nome": "Darcinópolis",
            "Uf": "TO"
        },
        {
            "Id": 356,
            "Codigo": 1707009,
            "Nome": "Dianópolis",
            "Uf": "TO"
        },
        {
            "Id": 357,
            "Codigo": 1707108,
            "Nome": "Divinópolis do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 358,
            "Codigo": 1707207,
            "Nome": "Dois Irmãos do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 359,
            "Codigo": 1707306,
            "Nome": "Dueré",
            "Uf": "TO"
        },
        {
            "Id": 360,
            "Codigo": 1707405,
            "Nome": "Esperantina",
            "Uf": "TO"
        },
        {
            "Id": 361,
            "Codigo": 1707553,
            "Nome": "Fátima",
            "Uf": "TO"
        },
        {
            "Id": 362,
            "Codigo": 1707652,
            "Nome": "Figueirópolis",
            "Uf": "TO"
        },
        {
            "Id": 363,
            "Codigo": 1707702,
            "Nome": "Filadélfia",
            "Uf": "TO"
        },
        {
            "Id": 364,
            "Codigo": 1708205,
            "Nome": "Formoso do Araguaia",
            "Uf": "TO"
        },
        {
            "Id": 365,
            "Codigo": 1708254,
            "Nome": "Fortaleza do Tabocão",
            "Uf": "TO"
        },
        {
            "Id": 366,
            "Codigo": 1708304,
            "Nome": "Goianorte",
            "Uf": "TO"
        },
        {
            "Id": 367,
            "Codigo": 1709005,
            "Nome": "Goiatins",
            "Uf": "TO"
        },
        {
            "Id": 368,
            "Codigo": 1709302,
            "Nome": "Guaraí",
            "Uf": "TO"
        },
        {
            "Id": 369,
            "Codigo": 1709500,
            "Nome": "Gurupi",
            "Uf": "TO"
        },
        {
            "Id": 370,
            "Codigo": 1709807,
            "Nome": "Ipueiras",
            "Uf": "TO"
        },
        {
            "Id": 371,
            "Codigo": 1710508,
            "Nome": "Itacajá",
            "Uf": "TO"
        },
        {
            "Id": 372,
            "Codigo": 1710706,
            "Nome": "Itaguatins",
            "Uf": "TO"
        },
        {
            "Id": 373,
            "Codigo": 1710904,
            "Nome": "Itapiratins",
            "Uf": "TO"
        },
        {
            "Id": 374,
            "Codigo": 1711100,
            "Nome": "Itaporã do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 375,
            "Codigo": 1711506,
            "Nome": "Jaú do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 376,
            "Codigo": 1711803,
            "Nome": "Juarina",
            "Uf": "TO"
        },
        {
            "Id": 377,
            "Codigo": 1711902,
            "Nome": "Lagoa da Confusão",
            "Uf": "TO"
        },
        {
            "Id": 378,
            "Codigo": 1711951,
            "Nome": "Lagoa do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 379,
            "Codigo": 1712009,
            "Nome": "Lajeado",
            "Uf": "TO"
        },
        {
            "Id": 380,
            "Codigo": 1712157,
            "Nome": "Lavandeira",
            "Uf": "TO"
        },
        {
            "Id": 381,
            "Codigo": 1712405,
            "Nome": "Lizarda",
            "Uf": "TO"
        },
        {
            "Id": 382,
            "Codigo": 1712454,
            "Nome": "Luzinópolis",
            "Uf": "TO"
        },
        {
            "Id": 383,
            "Codigo": 1712504,
            "Nome": "Marianópolis do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 384,
            "Codigo": 1712702,
            "Nome": "Mateiros",
            "Uf": "TO"
        },
        {
            "Id": 385,
            "Codigo": 1712801,
            "Nome": "Maurilândia do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 386,
            "Codigo": 1713205,
            "Nome": "Miracema do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 387,
            "Codigo": 1713304,
            "Nome": "Miranorte",
            "Uf": "TO"
        },
        {
            "Id": 388,
            "Codigo": 1713601,
            "Nome": "Monte do Carmo",
            "Uf": "TO"
        },
        {
            "Id": 389,
            "Codigo": 1713700,
            "Nome": "Monte Santo do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 390,
            "Codigo": 1713809,
            "Nome": "Palmeiras do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 391,
            "Codigo": 1713957,
            "Nome": "Muricilândia",
            "Uf": "TO"
        },
        {
            "Id": 392,
            "Codigo": 1714203,
            "Nome": "Natividade",
            "Uf": "TO"
        },
        {
            "Id": 393,
            "Codigo": 1714302,
            "Nome": "Nazaré",
            "Uf": "TO"
        },
        {
            "Id": 394,
            "Codigo": 1714880,
            "Nome": "Nova Olinda",
            "Uf": "TO"
        },
        {
            "Id": 395,
            "Codigo": 1715002,
            "Nome": "Nova Rosalândia",
            "Uf": "TO"
        },
        {
            "Id": 396,
            "Codigo": 1715101,
            "Nome": "Novo Acordo",
            "Uf": "TO"
        },
        {
            "Id": 397,
            "Codigo": 1715150,
            "Nome": "Novo Alegre",
            "Uf": "TO"
        },
        {
            "Id": 398,
            "Codigo": 1715259,
            "Nome": "Novo Jardim",
            "Uf": "TO"
        },
        {
            "Id": 399,
            "Codigo": 1715507,
            "Nome": "Oliveira de Fátima",
            "Uf": "TO"
        },
        {
            "Id": 400,
            "Codigo": 1715705,
            "Nome": "Palmeirante",
            "Uf": "TO"
        },
        {
            "Id": 401,
            "Codigo": 1715754,
            "Nome": "Palmeirópolis",
            "Uf": "TO"
        },
        {
            "Id": 402,
            "Codigo": 1716109,
            "Nome": "Paraíso do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 403,
            "Codigo": 1716208,
            "Nome": "Paranã",
            "Uf": "TO"
        },
        {
            "Id": 404,
            "Codigo": 1716307,
            "Nome": "Pau D'Arco",
            "Uf": "TO"
        },
        {
            "Id": 405,
            "Codigo": 1716505,
            "Nome": "Pedro Afonso",
            "Uf": "TO"
        },
        {
            "Id": 406,
            "Codigo": 1716604,
            "Nome": "Peixe",
            "Uf": "TO"
        },
        {
            "Id": 407,
            "Codigo": 1716653,
            "Nome": "Pequizeiro",
            "Uf": "TO"
        },
        {
            "Id": 408,
            "Codigo": 1716703,
            "Nome": "Colméia",
            "Uf": "TO"
        },
        {
            "Id": 409,
            "Codigo": 1717008,
            "Nome": "Pindorama do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 410,
            "Codigo": 1717206,
            "Nome": "Piraquê",
            "Uf": "TO"
        },
        {
            "Id": 411,
            "Codigo": 1717503,
            "Nome": "Pium",
            "Uf": "TO"
        },
        {
            "Id": 412,
            "Codigo": 1717800,
            "Nome": "Ponte Alta do Bom Jesus",
            "Uf": "TO"
        },
        {
            "Id": 413,
            "Codigo": 1717909,
            "Nome": "Ponte Alta do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 414,
            "Codigo": 1718006,
            "Nome": "Porto Alegre do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 415,
            "Codigo": 1718204,
            "Nome": "Porto Nacional",
            "Uf": "TO"
        },
        {
            "Id": 416,
            "Codigo": 1718303,
            "Nome": "Praia Norte",
            "Uf": "TO"
        },
        {
            "Id": 417,
            "Codigo": 1718402,
            "Nome": "Presidente Kennedy",
            "Uf": "TO"
        },
        {
            "Id": 418,
            "Codigo": 1718451,
            "Nome": "Pugmil",
            "Uf": "TO"
        },
        {
            "Id": 419,
            "Codigo": 1718501,
            "Nome": "Recursolândia",
            "Uf": "TO"
        },
        {
            "Id": 420,
            "Codigo": 1718550,
            "Nome": "Riachinho",
            "Uf": "TO"
        },
        {
            "Id": 421,
            "Codigo": 1718659,
            "Nome": "Rio da Conceição",
            "Uf": "TO"
        },
        {
            "Id": 422,
            "Codigo": 1718709,
            "Nome": "Rio dos Bois",
            "Uf": "TO"
        },
        {
            "Id": 423,
            "Codigo": 1718758,
            "Nome": "Rio Sono",
            "Uf": "TO"
        },
        {
            "Id": 424,
            "Codigo": 1718808,
            "Nome": "Sampaio",
            "Uf": "TO"
        },
        {
            "Id": 425,
            "Codigo": 1718840,
            "Nome": "Sandolândia",
            "Uf": "TO"
        },
        {
            "Id": 426,
            "Codigo": 1718865,
            "Nome": "Santa Fé do Araguaia",
            "Uf": "TO"
        },
        {
            "Id": 427,
            "Codigo": 1718881,
            "Nome": "Santa Maria do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 428,
            "Codigo": 1718899,
            "Nome": "Santa Rita do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 429,
            "Codigo": 1718907,
            "Nome": "Santa Rosa do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 430,
            "Codigo": 1719004,
            "Nome": "Santa Tereza do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 431,
            "Codigo": 1720002,
            "Nome": "Santa Terezinha do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 432,
            "Codigo": 1720101,
            "Nome": "São Bento do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 433,
            "Codigo": 1720150,
            "Nome": "São Félix do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 434,
            "Codigo": 1720200,
            "Nome": "São Miguel do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 435,
            "Codigo": 1720259,
            "Nome": "São Salvador do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 436,
            "Codigo": 1720309,
            "Nome": "São Sebastião do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 437,
            "Codigo": 1720499,
            "Nome": "São Valério",
            "Uf": "TO"
        },
        {
            "Id": 438,
            "Codigo": 1720655,
            "Nome": "Silvanópolis",
            "Uf": "TO"
        },
        {
            "Id": 439,
            "Codigo": 1720804,
            "Nome": "Sítio Novo do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 440,
            "Codigo": 1720853,
            "Nome": "Sucupira",
            "Uf": "TO"
        },
        {
            "Id": 441,
            "Codigo": 1720903,
            "Nome": "Taguatinga",
            "Uf": "TO"
        },
        {
            "Id": 442,
            "Codigo": 1720937,
            "Nome": "Taipas do Tocantins",
            "Uf": "TO"
        },
        {
            "Id": 443,
            "Codigo": 1720978,
            "Nome": "Talismã",
            "Uf": "TO"
        },
        {
            "Id": 444,
            "Codigo": 1721000,
            "Nome": "Palmas",
            "Uf": "TO"
        },
        {
            "Id": 445,
            "Codigo": 1721109,
            "Nome": "Tocantínia",
            "Uf": "TO"
        },
        {
            "Id": 446,
            "Codigo": 1721208,
            "Nome": "Tocantinópolis",
            "Uf": "TO"
        },
        {
            "Id": 447,
            "Codigo": 1721257,
            "Nome": "Tupirama",
            "Uf": "TO"
        },
        {
            "Id": 448,
            "Codigo": 1721307,
            "Nome": "Tupiratins",
            "Uf": "TO"
        },
        {
            "Id": 449,
            "Codigo": 1722081,
            "Nome": "Wanderlândia",
            "Uf": "TO"
        },
        {
            "Id": 450,
            "Codigo": 1722107,
            "Nome": "Xambioá",
            "Uf": "TO"
        },
        {
            "Id": 451,
            "Codigo": 2100055,
            "Nome": "Açailândia",
            "Uf": "MA"
        },
        {
            "Id": 452,
            "Codigo": 2100105,
            "Nome": "Afonso Cunha",
            "Uf": "MA"
        },
        {
            "Id": 453,
            "Codigo": 2100154,
            "Nome": "Água Doce do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 454,
            "Codigo": 2100204,
            "Nome": "Alcântara",
            "Uf": "MA"
        },
        {
            "Id": 455,
            "Codigo": 2100303,
            "Nome": "Aldeias Altas",
            "Uf": "MA"
        },
        {
            "Id": 456,
            "Codigo": 2100402,
            "Nome": "Altamira do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 457,
            "Codigo": 2100436,
            "Nome": "Alto Alegre do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 458,
            "Codigo": 2100477,
            "Nome": "Alto Alegre do Pindaré",
            "Uf": "MA"
        },
        {
            "Id": 459,
            "Codigo": 2100501,
            "Nome": "Alto Parnaíba",
            "Uf": "MA"
        },
        {
            "Id": 460,
            "Codigo": 2100550,
            "Nome": "Amapá do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 461,
            "Codigo": 2100600,
            "Nome": "Amarante do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 462,
            "Codigo": 2100709,
            "Nome": "Anajatuba",
            "Uf": "MA"
        },
        {
            "Id": 463,
            "Codigo": 2100808,
            "Nome": "Anapurus",
            "Uf": "MA"
        },
        {
            "Id": 464,
            "Codigo": 2100832,
            "Nome": "Apicum-Açu",
            "Uf": "MA"
        },
        {
            "Id": 465,
            "Codigo": 2100873,
            "Nome": "Araguanã",
            "Uf": "MA"
        },
        {
            "Id": 466,
            "Codigo": 2100907,
            "Nome": "Araioses",
            "Uf": "MA"
        },
        {
            "Id": 467,
            "Codigo": 2100956,
            "Nome": "Arame",
            "Uf": "MA"
        },
        {
            "Id": 468,
            "Codigo": 2101004,
            "Nome": "Arari",
            "Uf": "MA"
        },
        {
            "Id": 469,
            "Codigo": 2101103,
            "Nome": "Axixá",
            "Uf": "MA"
        },
        {
            "Id": 470,
            "Codigo": 2101202,
            "Nome": "Bacabal",
            "Uf": "MA"
        },
        {
            "Id": 471,
            "Codigo": 2101251,
            "Nome": "Bacabeira",
            "Uf": "MA"
        },
        {
            "Id": 472,
            "Codigo": 2101301,
            "Nome": "Bacuri",
            "Uf": "MA"
        },
        {
            "Id": 473,
            "Codigo": 2101350,
            "Nome": "Bacurituba",
            "Uf": "MA"
        },
        {
            "Id": 474,
            "Codigo": 2101400,
            "Nome": "Balsas",
            "Uf": "MA"
        },
        {
            "Id": 475,
            "Codigo": 2101509,
            "Nome": "Barão de Grajaú",
            "Uf": "MA"
        },
        {
            "Id": 476,
            "Codigo": 2101608,
            "Nome": "Barra do Corda",
            "Uf": "MA"
        },
        {
            "Id": 477,
            "Codigo": 2101707,
            "Nome": "Barreirinhas",
            "Uf": "MA"
        },
        {
            "Id": 478,
            "Codigo": 2101731,
            "Nome": "Belágua",
            "Uf": "MA"
        },
        {
            "Id": 479,
            "Codigo": 2101772,
            "Nome": "Bela Vista do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 480,
            "Codigo": 2101806,
            "Nome": "Benedito Leite",
            "Uf": "MA"
        },
        {
            "Id": 481,
            "Codigo": 2101905,
            "Nome": "Bequimão",
            "Uf": "MA"
        },
        {
            "Id": 482,
            "Codigo": 2101939,
            "Nome": "Bernardo do Mearim",
            "Uf": "MA"
        },
        {
            "Id": 483,
            "Codigo": 2101970,
            "Nome": "Boa Vista do Gurupi",
            "Uf": "MA"
        },
        {
            "Id": 484,
            "Codigo": 2102002,
            "Nome": "Bom Jardim",
            "Uf": "MA"
        },
        {
            "Id": 485,
            "Codigo": 2102036,
            "Nome": "Bom Jesus das Selvas",
            "Uf": "MA"
        },
        {
            "Id": 486,
            "Codigo": 2102077,
            "Nome": "Bom Lugar",
            "Uf": "MA"
        },
        {
            "Id": 487,
            "Codigo": 2102101,
            "Nome": "Brejo",
            "Uf": "MA"
        },
        {
            "Id": 488,
            "Codigo": 2102150,
            "Nome": "Brejo de Areia",
            "Uf": "MA"
        },
        {
            "Id": 489,
            "Codigo": 2102200,
            "Nome": "Buriti",
            "Uf": "MA"
        },
        {
            "Id": 490,
            "Codigo": 2102309,
            "Nome": "Buriti Bravo",
            "Uf": "MA"
        },
        {
            "Id": 491,
            "Codigo": 2102325,
            "Nome": "Buriticupu",
            "Uf": "MA"
        },
        {
            "Id": 492,
            "Codigo": 2102358,
            "Nome": "Buritirana",
            "Uf": "MA"
        },
        {
            "Id": 493,
            "Codigo": 2102374,
            "Nome": "Cachoeira Grande",
            "Uf": "MA"
        },
        {
            "Id": 494,
            "Codigo": 2102408,
            "Nome": "Cajapió",
            "Uf": "MA"
        },
        {
            "Id": 495,
            "Codigo": 2102507,
            "Nome": "Cajari",
            "Uf": "MA"
        },
        {
            "Id": 496,
            "Codigo": 2102556,
            "Nome": "Campestre do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 497,
            "Codigo": 2102606,
            "Nome": "Cândido Mendes",
            "Uf": "MA"
        },
        {
            "Id": 498,
            "Codigo": 2102705,
            "Nome": "Cantanhede",
            "Uf": "MA"
        },
        {
            "Id": 499,
            "Codigo": 2102754,
            "Nome": "Capinzal do Norte",
            "Uf": "MA"
        },
        {
            "Id": 500,
            "Codigo": 2102804,
            "Nome": "Carolina",
            "Uf": "MA"
        },
        {
            "Id": 501,
            "Codigo": 2102903,
            "Nome": "Carutapera",
            "Uf": "MA"
        },
        {
            "Id": 502,
            "Codigo": 2103000,
            "Nome": "Caxias",
            "Uf": "MA"
        },
        {
            "Id": 503,
            "Codigo": 2103109,
            "Nome": "Cedral",
            "Uf": "MA"
        },
        {
            "Id": 504,
            "Codigo": 2103125,
            "Nome": "Central do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 505,
            "Codigo": 2103158,
            "Nome": "Centro do Guilherme",
            "Uf": "MA"
        },
        {
            "Id": 506,
            "Codigo": 2103174,
            "Nome": "Centro Novo do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 507,
            "Codigo": 2103208,
            "Nome": "Chapadinha",
            "Uf": "MA"
        },
        {
            "Id": 508,
            "Codigo": 2103257,
            "Nome": "Cidelândia",
            "Uf": "MA"
        },
        {
            "Id": 509,
            "Codigo": 2103307,
            "Nome": "Codó",
            "Uf": "MA"
        },
        {
            "Id": 510,
            "Codigo": 2103406,
            "Nome": "Coelho Neto",
            "Uf": "MA"
        },
        {
            "Id": 511,
            "Codigo": 2103505,
            "Nome": "Colinas",
            "Uf": "MA"
        },
        {
            "Id": 512,
            "Codigo": 2103554,
            "Nome": "Conceição do Lago-Açu",
            "Uf": "MA"
        },
        {
            "Id": 513,
            "Codigo": 2103604,
            "Nome": "Coroatá",
            "Uf": "MA"
        },
        {
            "Id": 514,
            "Codigo": 2103703,
            "Nome": "Cururupu",
            "Uf": "MA"
        },
        {
            "Id": 515,
            "Codigo": 2103752,
            "Nome": "Davinópolis",
            "Uf": "MA"
        },
        {
            "Id": 516,
            "Codigo": 2103802,
            "Nome": "Dom Pedro",
            "Uf": "MA"
        },
        {
            "Id": 517,
            "Codigo": 2103901,
            "Nome": "Duque Bacelar",
            "Uf": "MA"
        },
        {
            "Id": 518,
            "Codigo": 2104008,
            "Nome": "Esperantinópolis",
            "Uf": "MA"
        },
        {
            "Id": 519,
            "Codigo": 2104057,
            "Nome": "Estreito",
            "Uf": "MA"
        },
        {
            "Id": 520,
            "Codigo": 2104073,
            "Nome": "Feira Nova do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 521,
            "Codigo": 2104081,
            "Nome": "Fernando Falcão",
            "Uf": "MA"
        },
        {
            "Id": 522,
            "Codigo": 2104099,
            "Nome": "Formosa da Serra Negra",
            "Uf": "MA"
        },
        {
            "Id": 523,
            "Codigo": 2104107,
            "Nome": "Fortaleza dos Nogueiras",
            "Uf": "MA"
        },
        {
            "Id": 524,
            "Codigo": 2104206,
            "Nome": "Fortuna",
            "Uf": "MA"
        },
        {
            "Id": 525,
            "Codigo": 2104305,
            "Nome": "Godofredo Viana",
            "Uf": "MA"
        },
        {
            "Id": 526,
            "Codigo": 2104404,
            "Nome": "Gonçalves Dias",
            "Uf": "MA"
        },
        {
            "Id": 527,
            "Codigo": 2104503,
            "Nome": "Governador Archer",
            "Uf": "MA"
        },
        {
            "Id": 528,
            "Codigo": 2104552,
            "Nome": "Governador Edison Lobão",
            "Uf": "MA"
        },
        {
            "Id": 529,
            "Codigo": 2104602,
            "Nome": "Governador Eugênio Barros",
            "Uf": "MA"
        },
        {
            "Id": 530,
            "Codigo": 2104628,
            "Nome": "Governador Luiz Rocha",
            "Uf": "MA"
        },
        {
            "Id": 531,
            "Codigo": 2104651,
            "Nome": "Governador Newton Bello",
            "Uf": "MA"
        },
        {
            "Id": 532,
            "Codigo": 2104677,
            "Nome": "Governador Nunes Freire",
            "Uf": "MA"
        },
        {
            "Id": 533,
            "Codigo": 2104701,
            "Nome": "Graça Aranha",
            "Uf": "MA"
        },
        {
            "Id": 534,
            "Codigo": 2104800,
            "Nome": "Grajaú",
            "Uf": "MA"
        },
        {
            "Id": 535,
            "Codigo": 2104909,
            "Nome": "Guimarães",
            "Uf": "MA"
        },
        {
            "Id": 536,
            "Codigo": 2105005,
            "Nome": "Humberto de Campos",
            "Uf": "MA"
        },
        {
            "Id": 537,
            "Codigo": 2105104,
            "Nome": "Icatu",
            "Uf": "MA"
        },
        {
            "Id": 538,
            "Codigo": 2105153,
            "Nome": "Igarapé do Meio",
            "Uf": "MA"
        },
        {
            "Id": 539,
            "Codigo": 2105203,
            "Nome": "Igarapé Grande",
            "Uf": "MA"
        },
        {
            "Id": 540,
            "Codigo": 2105302,
            "Nome": "Imperatriz",
            "Uf": "MA"
        },
        {
            "Id": 541,
            "Codigo": 2105351,
            "Nome": "Itaipava do Grajaú",
            "Uf": "MA"
        },
        {
            "Id": 542,
            "Codigo": 2105401,
            "Nome": "Itapecuru Mirim",
            "Uf": "MA"
        },
        {
            "Id": 543,
            "Codigo": 2105427,
            "Nome": "Itinga do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 544,
            "Codigo": 2105450,
            "Nome": "Jatobá",
            "Uf": "MA"
        },
        {
            "Id": 545,
            "Codigo": 2105476,
            "Nome": "Jenipapo dos Vieiras",
            "Uf": "MA"
        },
        {
            "Id": 546,
            "Codigo": 2105500,
            "Nome": "João Lisboa",
            "Uf": "MA"
        },
        {
            "Id": 547,
            "Codigo": 2105609,
            "Nome": "Joselândia",
            "Uf": "MA"
        },
        {
            "Id": 548,
            "Codigo": 2105658,
            "Nome": "Junco do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 549,
            "Codigo": 2105708,
            "Nome": "Lago da Pedra",
            "Uf": "MA"
        },
        {
            "Id": 550,
            "Codigo": 2105807,
            "Nome": "Lago do Junco",
            "Uf": "MA"
        },
        {
            "Id": 551,
            "Codigo": 2105906,
            "Nome": "Lago Verde",
            "Uf": "MA"
        },
        {
            "Id": 552,
            "Codigo": 2105922,
            "Nome": "Lagoa do Mato",
            "Uf": "MA"
        },
        {
            "Id": 553,
            "Codigo": 2105948,
            "Nome": "Lago dos Rodrigues",
            "Uf": "MA"
        },
        {
            "Id": 554,
            "Codigo": 2105963,
            "Nome": "Lagoa Grande do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 555,
            "Codigo": 2105989,
            "Nome": "Lajeado Novo",
            "Uf": "MA"
        },
        {
            "Id": 556,
            "Codigo": 2106003,
            "Nome": "Lima Campos",
            "Uf": "MA"
        },
        {
            "Id": 557,
            "Codigo": 2106102,
            "Nome": "Loreto",
            "Uf": "MA"
        },
        {
            "Id": 558,
            "Codigo": 2106201,
            "Nome": "Luís Domingues",
            "Uf": "MA"
        },
        {
            "Id": 559,
            "Codigo": 2106300,
            "Nome": "Magalhães de Almeida",
            "Uf": "MA"
        },
        {
            "Id": 560,
            "Codigo": 2106326,
            "Nome": "Maracaçumé",
            "Uf": "MA"
        },
        {
            "Id": 561,
            "Codigo": 2106359,
            "Nome": "Marajá do Sena",
            "Uf": "MA"
        },
        {
            "Id": 562,
            "Codigo": 2106375,
            "Nome": "Maranhãozinho",
            "Uf": "MA"
        },
        {
            "Id": 563,
            "Codigo": 2106409,
            "Nome": "Mata Roma",
            "Uf": "MA"
        },
        {
            "Id": 564,
            "Codigo": 2106508,
            "Nome": "Matinha",
            "Uf": "MA"
        },
        {
            "Id": 565,
            "Codigo": 2106607,
            "Nome": "Matões",
            "Uf": "MA"
        },
        {
            "Id": 566,
            "Codigo": 2106631,
            "Nome": "Matões do Norte",
            "Uf": "MA"
        },
        {
            "Id": 567,
            "Codigo": 2106672,
            "Nome": "Milagres do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 568,
            "Codigo": 2106706,
            "Nome": "Mirador",
            "Uf": "MA"
        },
        {
            "Id": 569,
            "Codigo": 2106755,
            "Nome": "Miranda do Norte",
            "Uf": "MA"
        },
        {
            "Id": 570,
            "Codigo": 2106805,
            "Nome": "Mirinzal",
            "Uf": "MA"
        },
        {
            "Id": 571,
            "Codigo": 2106904,
            "Nome": "Monção",
            "Uf": "MA"
        },
        {
            "Id": 572,
            "Codigo": 2107001,
            "Nome": "Montes Altos",
            "Uf": "MA"
        },
        {
            "Id": 573,
            "Codigo": 2107100,
            "Nome": "Morros",
            "Uf": "MA"
        },
        {
            "Id": 574,
            "Codigo": 2107209,
            "Nome": "Nina Rodrigues",
            "Uf": "MA"
        },
        {
            "Id": 575,
            "Codigo": 2107258,
            "Nome": "Nova Colinas",
            "Uf": "MA"
        },
        {
            "Id": 576,
            "Codigo": 2107308,
            "Nome": "Nova Iorque",
            "Uf": "MA"
        },
        {
            "Id": 577,
            "Codigo": 2107357,
            "Nome": "Nova Olinda do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 578,
            "Codigo": 2107407,
            "Nome": "Olho D'Água das Cunhãs",
            "Uf": "MA"
        },
        {
            "Id": 579,
            "Codigo": 2107456,
            "Nome": "Olinda Nova do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 580,
            "Codigo": 2107506,
            "Nome": "Paço do Lumiar",
            "Uf": "MA"
        },
        {
            "Id": 581,
            "Codigo": 2107605,
            "Nome": "Palmeirândia",
            "Uf": "MA"
        },
        {
            "Id": 582,
            "Codigo": 2107704,
            "Nome": "Paraibano",
            "Uf": "MA"
        },
        {
            "Id": 583,
            "Codigo": 2107803,
            "Nome": "Parnarama",
            "Uf": "MA"
        },
        {
            "Id": 584,
            "Codigo": 2107902,
            "Nome": "Passagem Franca",
            "Uf": "MA"
        },
        {
            "Id": 585,
            "Codigo": 2108009,
            "Nome": "Pastos Bons",
            "Uf": "MA"
        },
        {
            "Id": 586,
            "Codigo": 2108058,
            "Nome": "Paulino Neves",
            "Uf": "MA"
        },
        {
            "Id": 587,
            "Codigo": 2108108,
            "Nome": "Paulo Ramos",
            "Uf": "MA"
        },
        {
            "Id": 588,
            "Codigo": 2108207,
            "Nome": "Pedreiras",
            "Uf": "MA"
        },
        {
            "Id": 589,
            "Codigo": 2108256,
            "Nome": "Pedro do Rosário",
            "Uf": "MA"
        },
        {
            "Id": 590,
            "Codigo": 2108306,
            "Nome": "Penalva",
            "Uf": "MA"
        },
        {
            "Id": 591,
            "Codigo": 2108405,
            "Nome": "Peri Mirim",
            "Uf": "MA"
        },
        {
            "Id": 592,
            "Codigo": 2108454,
            "Nome": "Peritoró",
            "Uf": "MA"
        },
        {
            "Id": 593,
            "Codigo": 2108504,
            "Nome": "Pindaré-Mirim",
            "Uf": "MA"
        },
        {
            "Id": 594,
            "Codigo": 2108603,
            "Nome": "Pinheiro",
            "Uf": "MA"
        },
        {
            "Id": 595,
            "Codigo": 2108702,
            "Nome": "Pio XII",
            "Uf": "MA"
        },
        {
            "Id": 596,
            "Codigo": 2108801,
            "Nome": "Pirapemas",
            "Uf": "MA"
        },
        {
            "Id": 597,
            "Codigo": 2108900,
            "Nome": "Poção de Pedras",
            "Uf": "MA"
        },
        {
            "Id": 598,
            "Codigo": 2109007,
            "Nome": "Porto Franco",
            "Uf": "MA"
        },
        {
            "Id": 599,
            "Codigo": 2109056,
            "Nome": "Porto Rico do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 600,
            "Codigo": 2109106,
            "Nome": "Presidente Dutra",
            "Uf": "MA"
        },
        {
            "Id": 601,
            "Codigo": 2109205,
            "Nome": "Presidente Juscelino",
            "Uf": "MA"
        },
        {
            "Id": 602,
            "Codigo": 2109239,
            "Nome": "Presidente Médici",
            "Uf": "MA"
        },
        {
            "Id": 603,
            "Codigo": 2109270,
            "Nome": "Presidente Sarney",
            "Uf": "MA"
        },
        {
            "Id": 604,
            "Codigo": 2109304,
            "Nome": "Presidente Vargas",
            "Uf": "MA"
        },
        {
            "Id": 605,
            "Codigo": 2109403,
            "Nome": "Primeira Cruz",
            "Uf": "MA"
        },
        {
            "Id": 606,
            "Codigo": 2109452,
            "Nome": "Raposa",
            "Uf": "MA"
        },
        {
            "Id": 607,
            "Codigo": 2109502,
            "Nome": "Riachão",
            "Uf": "MA"
        },
        {
            "Id": 608,
            "Codigo": 2109551,
            "Nome": "Ribamar Fiquene",
            "Uf": "MA"
        },
        {
            "Id": 609,
            "Codigo": 2109601,
            "Nome": "Rosário",
            "Uf": "MA"
        },
        {
            "Id": 610,
            "Codigo": 2109700,
            "Nome": "Sambaíba",
            "Uf": "MA"
        },
        {
            "Id": 611,
            "Codigo": 2109759,
            "Nome": "Santa Filomena do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 612,
            "Codigo": 2109809,
            "Nome": "Santa Helena",
            "Uf": "MA"
        },
        {
            "Id": 613,
            "Codigo": 2109908,
            "Nome": "Santa Inês",
            "Uf": "MA"
        },
        {
            "Id": 614,
            "Codigo": 2110005,
            "Nome": "Santa Luzia",
            "Uf": "MA"
        },
        {
            "Id": 615,
            "Codigo": 2110039,
            "Nome": "Santa Luzia do Paruá",
            "Uf": "MA"
        },
        {
            "Id": 616,
            "Codigo": 2110104,
            "Nome": "Santa Quitéria do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 617,
            "Codigo": 2110203,
            "Nome": "Santa Rita",
            "Uf": "MA"
        },
        {
            "Id": 618,
            "Codigo": 2110237,
            "Nome": "Santana do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 619,
            "Codigo": 2110278,
            "Nome": "Santo Amaro do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 620,
            "Codigo": 2110302,
            "Nome": "Santo Antônio dos Lopes",
            "Uf": "MA"
        },
        {
            "Id": 621,
            "Codigo": 2110401,
            "Nome": "São Benedito do Rio Preto",
            "Uf": "MA"
        },
        {
            "Id": 622,
            "Codigo": 2110500,
            "Nome": "São Bento",
            "Uf": "MA"
        },
        {
            "Id": 623,
            "Codigo": 2110609,
            "Nome": "São Bernardo",
            "Uf": "MA"
        },
        {
            "Id": 624,
            "Codigo": 2110658,
            "Nome": "São Domingos do Azeitão",
            "Uf": "MA"
        },
        {
            "Id": 625,
            "Codigo": 2110708,
            "Nome": "São Domingos do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 626,
            "Codigo": 2110807,
            "Nome": "São Félix de Balsas",
            "Uf": "MA"
        },
        {
            "Id": 627,
            "Codigo": 2110856,
            "Nome": "São Francisco do Brejão",
            "Uf": "MA"
        },
        {
            "Id": 628,
            "Codigo": 2110906,
            "Nome": "São Francisco do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 629,
            "Codigo": 2111003,
            "Nome": "São João Batista",
            "Uf": "MA"
        },
        {
            "Id": 630,
            "Codigo": 2111029,
            "Nome": "São João do Carú",
            "Uf": "MA"
        },
        {
            "Id": 631,
            "Codigo": 2111052,
            "Nome": "São João do Paraíso",
            "Uf": "MA"
        },
        {
            "Id": 632,
            "Codigo": 2111078,
            "Nome": "São João do Soter",
            "Uf": "MA"
        },
        {
            "Id": 633,
            "Codigo": 2111102,
            "Nome": "São João dos Patos",
            "Uf": "MA"
        },
        {
            "Id": 634,
            "Codigo": 2111201,
            "Nome": "São José de Ribamar",
            "Uf": "MA"
        },
        {
            "Id": 635,
            "Codigo": 2111250,
            "Nome": "São José dos Basílios",
            "Uf": "MA"
        },
        {
            "Id": 636,
            "Codigo": 2111300,
            "Nome": "São Luís",
            "Uf": "MA"
        },
        {
            "Id": 637,
            "Codigo": 2111409,
            "Nome": "São Luís Gonzaga do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 638,
            "Codigo": 2111508,
            "Nome": "São Mateus do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 639,
            "Codigo": 2111532,
            "Nome": "São Pedro da Água Branca",
            "Uf": "MA"
        },
        {
            "Id": 640,
            "Codigo": 2111573,
            "Nome": "São Pedro dos Crentes",
            "Uf": "MA"
        },
        {
            "Id": 641,
            "Codigo": 2111607,
            "Nome": "São Raimundo das Mangabeiras",
            "Uf": "MA"
        },
        {
            "Id": 642,
            "Codigo": 2111631,
            "Nome": "São Raimundo do Doca Bezerra",
            "Uf": "MA"
        },
        {
            "Id": 643,
            "Codigo": 2111672,
            "Nome": "São Roberto",
            "Uf": "MA"
        },
        {
            "Id": 644,
            "Codigo": 2111706,
            "Nome": "São Vicente Ferrer",
            "Uf": "MA"
        },
        {
            "Id": 645,
            "Codigo": 2111722,
            "Nome": "Satubinha",
            "Uf": "MA"
        },
        {
            "Id": 646,
            "Codigo": 2111748,
            "Nome": "Senador Alexandre Costa",
            "Uf": "MA"
        },
        {
            "Id": 647,
            "Codigo": 2111763,
            "Nome": "Senador La Rocque",
            "Uf": "MA"
        },
        {
            "Id": 648,
            "Codigo": 2111789,
            "Nome": "Serrano do Maranhão",
            "Uf": "MA"
        },
        {
            "Id": 649,
            "Codigo": 2111805,
            "Nome": "Sítio Novo",
            "Uf": "MA"
        },
        {
            "Id": 650,
            "Codigo": 2111904,
            "Nome": "Sucupira do Norte",
            "Uf": "MA"
        },
        {
            "Id": 651,
            "Codigo": 2111953,
            "Nome": "Sucupira do Riachão",
            "Uf": "MA"
        },
        {
            "Id": 652,
            "Codigo": 2112001,
            "Nome": "Tasso Fragoso",
            "Uf": "MA"
        },
        {
            "Id": 653,
            "Codigo": 2112100,
            "Nome": "Timbiras",
            "Uf": "MA"
        },
        {
            "Id": 654,
            "Codigo": 2112209,
            "Nome": "Timon",
            "Uf": "MA"
        },
        {
            "Id": 655,
            "Codigo": 2112233,
            "Nome": "Trizidela do Vale",
            "Uf": "MA"
        },
        {
            "Id": 656,
            "Codigo": 2112274,
            "Nome": "Tufilândia",
            "Uf": "MA"
        },
        {
            "Id": 657,
            "Codigo": 2112308,
            "Nome": "Tuntum",
            "Uf": "MA"
        },
        {
            "Id": 658,
            "Codigo": 2112407,
            "Nome": "Turiaçu",
            "Uf": "MA"
        },
        {
            "Id": 659,
            "Codigo": 2112456,
            "Nome": "Turilândia",
            "Uf": "MA"
        },
        {
            "Id": 660,
            "Codigo": 2112506,
            "Nome": "Tutóia",
            "Uf": "MA"
        },
        {
            "Id": 661,
            "Codigo": 2112605,
            "Nome": "Urbano Santos",
            "Uf": "MA"
        },
        {
            "Id": 662,
            "Codigo": 2112704,
            "Nome": "Vargem Grande",
            "Uf": "MA"
        },
        {
            "Id": 663,
            "Codigo": 2112803,
            "Nome": "Viana",
            "Uf": "MA"
        },
        {
            "Id": 664,
            "Codigo": 2112852,
            "Nome": "Vila Nova dos Martírios",
            "Uf": "MA"
        },
        {
            "Id": 665,
            "Codigo": 2112902,
            "Nome": "Vitória do Mearim",
            "Uf": "MA"
        },
        {
            "Id": 666,
            "Codigo": 2113009,
            "Nome": "Vitorino Freire",
            "Uf": "MA"
        },
        {
            "Id": 667,
            "Codigo": 2114007,
            "Nome": "Zé Doca",
            "Uf": "MA"
        },
        {
            "Id": 668,
            "Codigo": 2200053,
            "Nome": "Acauã",
            "Uf": "PI"
        },
        {
            "Id": 669,
            "Codigo": 2200103,
            "Nome": "Agricolândia",
            "Uf": "PI"
        },
        {
            "Id": 670,
            "Codigo": 2200202,
            "Nome": "Água Branca",
            "Uf": "PI"
        },
        {
            "Id": 671,
            "Codigo": 2200251,
            "Nome": "Alagoinha do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 672,
            "Codigo": 2200277,
            "Nome": "Alegrete do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 673,
            "Codigo": 2200301,
            "Nome": "Alto Longá",
            "Uf": "PI"
        },
        {
            "Id": 674,
            "Codigo": 2200400,
            "Nome": "Altos",
            "Uf": "PI"
        },
        {
            "Id": 675,
            "Codigo": 2200459,
            "Nome": "Alvorada do Gurguéia",
            "Uf": "PI"
        },
        {
            "Id": 676,
            "Codigo": 2200509,
            "Nome": "Amarante",
            "Uf": "PI"
        },
        {
            "Id": 677,
            "Codigo": 2200608,
            "Nome": "Angical do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 678,
            "Codigo": 2200707,
            "Nome": "Anísio de Abreu",
            "Uf": "PI"
        },
        {
            "Id": 679,
            "Codigo": 2200806,
            "Nome": "Antônio Almeida",
            "Uf": "PI"
        },
        {
            "Id": 680,
            "Codigo": 2200905,
            "Nome": "Aroazes",
            "Uf": "PI"
        },
        {
            "Id": 681,
            "Codigo": 2200954,
            "Nome": "Aroeiras do Itaim",
            "Uf": "PI"
        },
        {
            "Id": 682,
            "Codigo": 2201002,
            "Nome": "Arraial",
            "Uf": "PI"
        },
        {
            "Id": 683,
            "Codigo": 2201051,
            "Nome": "Assunção do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 684,
            "Codigo": 2201101,
            "Nome": "Avelino Lopes",
            "Uf": "PI"
        },
        {
            "Id": 685,
            "Codigo": 2201150,
            "Nome": "Baixa Grande do Ribeiro",
            "Uf": "PI"
        },
        {
            "Id": 686,
            "Codigo": 2201176,
            "Nome": "Barra D'Alcântara",
            "Uf": "PI"
        },
        {
            "Id": 687,
            "Codigo": 2201200,
            "Nome": "Barras",
            "Uf": "PI"
        },
        {
            "Id": 688,
            "Codigo": 2201309,
            "Nome": "Barreiras do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 689,
            "Codigo": 2201408,
            "Nome": "Barro Duro",
            "Uf": "PI"
        },
        {
            "Id": 690,
            "Codigo": 2201507,
            "Nome": "Batalha",
            "Uf": "PI"
        },
        {
            "Id": 691,
            "Codigo": 2201556,
            "Nome": "Bela Vista do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 692,
            "Codigo": 2201572,
            "Nome": "Belém do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 693,
            "Codigo": 2201606,
            "Nome": "Beneditinos",
            "Uf": "PI"
        },
        {
            "Id": 694,
            "Codigo": 2201705,
            "Nome": "Bertolínia",
            "Uf": "PI"
        },
        {
            "Id": 695,
            "Codigo": 2201739,
            "Nome": "Betânia do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 696,
            "Codigo": 2201770,
            "Nome": "Boa Hora",
            "Uf": "PI"
        },
        {
            "Id": 697,
            "Codigo": 2201804,
            "Nome": "Bocaina",
            "Uf": "PI"
        },
        {
            "Id": 698,
            "Codigo": 2201903,
            "Nome": "Bom Jesus",
            "Uf": "PI"
        },
        {
            "Id": 699,
            "Codigo": 2201919,
            "Nome": "Bom Princípio do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 700,
            "Codigo": 2201929,
            "Nome": "Bonfim do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 701,
            "Codigo": 2201945,
            "Nome": "Boqueirão do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 702,
            "Codigo": 2201960,
            "Nome": "Brasileira",
            "Uf": "PI"
        },
        {
            "Id": 703,
            "Codigo": 2201988,
            "Nome": "Brejo do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 704,
            "Codigo": 2202000,
            "Nome": "Buriti dos Lopes",
            "Uf": "PI"
        },
        {
            "Id": 705,
            "Codigo": 2202026,
            "Nome": "Buriti dos Montes",
            "Uf": "PI"
        },
        {
            "Id": 706,
            "Codigo": 2202059,
            "Nome": "Cabeceiras do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 707,
            "Codigo": 2202075,
            "Nome": "Cajazeiras do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 708,
            "Codigo": 2202083,
            "Nome": "Cajueiro da Praia",
            "Uf": "PI"
        },
        {
            "Id": 709,
            "Codigo": 2202091,
            "Nome": "Caldeirão Grande do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 710,
            "Codigo": 2202109,
            "Nome": "Campinas do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 711,
            "Codigo": 2202117,
            "Nome": "Campo Alegre do Fidalgo",
            "Uf": "PI"
        },
        {
            "Id": 712,
            "Codigo": 2202133,
            "Nome": "Campo Grande do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 713,
            "Codigo": 2202174,
            "Nome": "Campo Largo do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 714,
            "Codigo": 2202208,
            "Nome": "Campo Maior",
            "Uf": "PI"
        },
        {
            "Id": 715,
            "Codigo": 2202251,
            "Nome": "Canavieira",
            "Uf": "PI"
        },
        {
            "Id": 716,
            "Codigo": 2202307,
            "Nome": "Canto do Buriti",
            "Uf": "PI"
        },
        {
            "Id": 717,
            "Codigo": 2202406,
            "Nome": "Capitão de Campos",
            "Uf": "PI"
        },
        {
            "Id": 718,
            "Codigo": 2202455,
            "Nome": "Capitão Gervásio Oliveira",
            "Uf": "PI"
        },
        {
            "Id": 719,
            "Codigo": 2202505,
            "Nome": "Caracol",
            "Uf": "PI"
        },
        {
            "Id": 720,
            "Codigo": 2202539,
            "Nome": "Caraúbas do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 721,
            "Codigo": 2202554,
            "Nome": "Caridade do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 722,
            "Codigo": 2202604,
            "Nome": "Castelo do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 723,
            "Codigo": 2202653,
            "Nome": "Caxingó",
            "Uf": "PI"
        },
        {
            "Id": 724,
            "Codigo": 2202703,
            "Nome": "Cocal",
            "Uf": "PI"
        },
        {
            "Id": 725,
            "Codigo": 2202711,
            "Nome": "Cocal de Telha",
            "Uf": "PI"
        },
        {
            "Id": 726,
            "Codigo": 2202729,
            "Nome": "Cocal dos Alves",
            "Uf": "PI"
        },
        {
            "Id": 727,
            "Codigo": 2202737,
            "Nome": "Coivaras",
            "Uf": "PI"
        },
        {
            "Id": 728,
            "Codigo": 2202752,
            "Nome": "Colônia do Gurguéia",
            "Uf": "PI"
        },
        {
            "Id": 729,
            "Codigo": 2202778,
            "Nome": "Colônia do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 730,
            "Codigo": 2202802,
            "Nome": "Conceição do Canindé",
            "Uf": "PI"
        },
        {
            "Id": 731,
            "Codigo": 2202851,
            "Nome": "Coronel José Dias",
            "Uf": "PI"
        },
        {
            "Id": 732,
            "Codigo": 2202901,
            "Nome": "Corrente",
            "Uf": "PI"
        },
        {
            "Id": 733,
            "Codigo": 2203008,
            "Nome": "Cristalândia do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 734,
            "Codigo": 2203107,
            "Nome": "Cristino Castro",
            "Uf": "PI"
        },
        {
            "Id": 735,
            "Codigo": 2203206,
            "Nome": "Curimatá",
            "Uf": "PI"
        },
        {
            "Id": 736,
            "Codigo": 2203230,
            "Nome": "Currais",
            "Uf": "PI"
        },
        {
            "Id": 737,
            "Codigo": 2203255,
            "Nome": "Curralinhos",
            "Uf": "PI"
        },
        {
            "Id": 738,
            "Codigo": 2203271,
            "Nome": "Curral Novo do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 739,
            "Codigo": 2203305,
            "Nome": "Demerval Lobão",
            "Uf": "PI"
        },
        {
            "Id": 740,
            "Codigo": 2203354,
            "Nome": "Dirceu Arcoverde",
            "Uf": "PI"
        },
        {
            "Id": 741,
            "Codigo": 2203404,
            "Nome": "Dom Expedito Lopes",
            "Uf": "PI"
        },
        {
            "Id": 742,
            "Codigo": 2203420,
            "Nome": "Domingos Mourão",
            "Uf": "PI"
        },
        {
            "Id": 743,
            "Codigo": 2203453,
            "Nome": "Dom Inocêncio",
            "Uf": "PI"
        },
        {
            "Id": 744,
            "Codigo": 2203503,
            "Nome": "Elesbão Veloso",
            "Uf": "PI"
        },
        {
            "Id": 745,
            "Codigo": 2203602,
            "Nome": "Eliseu Martins",
            "Uf": "PI"
        },
        {
            "Id": 746,
            "Codigo": 2203701,
            "Nome": "Esperantina",
            "Uf": "PI"
        },
        {
            "Id": 747,
            "Codigo": 2203750,
            "Nome": "Fartura do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 748,
            "Codigo": 2203800,
            "Nome": "Flores do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 749,
            "Codigo": 2203859,
            "Nome": "Floresta do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 750,
            "Codigo": 2203909,
            "Nome": "Floriano",
            "Uf": "PI"
        },
        {
            "Id": 751,
            "Codigo": 2204006,
            "Nome": "Francinópolis",
            "Uf": "PI"
        },
        {
            "Id": 752,
            "Codigo": 2204105,
            "Nome": "Francisco Ayres",
            "Uf": "PI"
        },
        {
            "Id": 753,
            "Codigo": 2204154,
            "Nome": "Francisco Macedo",
            "Uf": "PI"
        },
        {
            "Id": 754,
            "Codigo": 2204204,
            "Nome": "Francisco Santos",
            "Uf": "PI"
        },
        {
            "Id": 755,
            "Codigo": 2204303,
            "Nome": "Fronteiras",
            "Uf": "PI"
        },
        {
            "Id": 756,
            "Codigo": 2204352,
            "Nome": "Geminiano",
            "Uf": "PI"
        },
        {
            "Id": 757,
            "Codigo": 2204402,
            "Nome": "Gilbués",
            "Uf": "PI"
        },
        {
            "Id": 758,
            "Codigo": 2204501,
            "Nome": "Guadalupe",
            "Uf": "PI"
        },
        {
            "Id": 759,
            "Codigo": 2204550,
            "Nome": "Guaribas",
            "Uf": "PI"
        },
        {
            "Id": 760,
            "Codigo": 2204600,
            "Nome": "Hugo Napoleão",
            "Uf": "PI"
        },
        {
            "Id": 761,
            "Codigo": 2204659,
            "Nome": "Ilha Grande",
            "Uf": "PI"
        },
        {
            "Id": 762,
            "Codigo": 2204709,
            "Nome": "Inhuma",
            "Uf": "PI"
        },
        {
            "Id": 763,
            "Codigo": 2204808,
            "Nome": "Ipiranga do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 764,
            "Codigo": 2204907,
            "Nome": "Isaías Coelho",
            "Uf": "PI"
        },
        {
            "Id": 765,
            "Codigo": 2205003,
            "Nome": "Itainópolis",
            "Uf": "PI"
        },
        {
            "Id": 766,
            "Codigo": 2205102,
            "Nome": "Itaueira",
            "Uf": "PI"
        },
        {
            "Id": 767,
            "Codigo": 2205151,
            "Nome": "Jacobina do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 768,
            "Codigo": 2205201,
            "Nome": "Jaicós",
            "Uf": "PI"
        },
        {
            "Id": 769,
            "Codigo": 2205250,
            "Nome": "Jardim do Mulato",
            "Uf": "PI"
        },
        {
            "Id": 770,
            "Codigo": 2205276,
            "Nome": "Jatobá do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 771,
            "Codigo": 2205300,
            "Nome": "Jerumenha",
            "Uf": "PI"
        },
        {
            "Id": 772,
            "Codigo": 2205359,
            "Nome": "João Costa",
            "Uf": "PI"
        },
        {
            "Id": 773,
            "Codigo": 2205409,
            "Nome": "Joaquim Pires",
            "Uf": "PI"
        },
        {
            "Id": 774,
            "Codigo": 2205458,
            "Nome": "Joca Marques",
            "Uf": "PI"
        },
        {
            "Id": 775,
            "Codigo": 2205508,
            "Nome": "José de Freitas",
            "Uf": "PI"
        },
        {
            "Id": 776,
            "Codigo": 2205516,
            "Nome": "Juazeiro do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 777,
            "Codigo": 2205524,
            "Nome": "Júlio Borges",
            "Uf": "PI"
        },
        {
            "Id": 778,
            "Codigo": 2205532,
            "Nome": "Jurema",
            "Uf": "PI"
        },
        {
            "Id": 779,
            "Codigo": 2205540,
            "Nome": "Lagoinha do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 780,
            "Codigo": 2205557,
            "Nome": "Lagoa Alegre",
            "Uf": "PI"
        },
        {
            "Id": 781,
            "Codigo": 2205565,
            "Nome": "Lagoa do Barro do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 782,
            "Codigo": 2205573,
            "Nome": "Lagoa de São Francisco",
            "Uf": "PI"
        },
        {
            "Id": 783,
            "Codigo": 2205581,
            "Nome": "Lagoa do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 784,
            "Codigo": 2205599,
            "Nome": "Lagoa do Sítio",
            "Uf": "PI"
        },
        {
            "Id": 785,
            "Codigo": 2205607,
            "Nome": "Landri Sales",
            "Uf": "PI"
        },
        {
            "Id": 786,
            "Codigo": 2205706,
            "Nome": "Luís Correia",
            "Uf": "PI"
        },
        {
            "Id": 787,
            "Codigo": 2205805,
            "Nome": "Luzilândia",
            "Uf": "PI"
        },
        {
            "Id": 788,
            "Codigo": 2205854,
            "Nome": "Madeiro",
            "Uf": "PI"
        },
        {
            "Id": 789,
            "Codigo": 2205904,
            "Nome": "Manoel Emídio",
            "Uf": "PI"
        },
        {
            "Id": 790,
            "Codigo": 2205953,
            "Nome": "Marcolândia",
            "Uf": "PI"
        },
        {
            "Id": 791,
            "Codigo": 2206001,
            "Nome": "Marcos Parente",
            "Uf": "PI"
        },
        {
            "Id": 792,
            "Codigo": 2206050,
            "Nome": "Massapê do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 793,
            "Codigo": 2206100,
            "Nome": "Matias Olímpio",
            "Uf": "PI"
        },
        {
            "Id": 794,
            "Codigo": 2206209,
            "Nome": "Miguel Alves",
            "Uf": "PI"
        },
        {
            "Id": 795,
            "Codigo": 2206308,
            "Nome": "Miguel Leão",
            "Uf": "PI"
        },
        {
            "Id": 796,
            "Codigo": 2206357,
            "Nome": "Milton Brandão",
            "Uf": "PI"
        },
        {
            "Id": 797,
            "Codigo": 2206407,
            "Nome": "Monsenhor Gil",
            "Uf": "PI"
        },
        {
            "Id": 798,
            "Codigo": 2206506,
            "Nome": "Monsenhor Hipólito",
            "Uf": "PI"
        },
        {
            "Id": 799,
            "Codigo": 2206605,
            "Nome": "Monte Alegre do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 800,
            "Codigo": 2206654,
            "Nome": "Morro Cabeça no Tempo",
            "Uf": "PI"
        },
        {
            "Id": 801,
            "Codigo": 2206670,
            "Nome": "Morro do Chapéu do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 802,
            "Codigo": 2206696,
            "Nome": "Murici dos Portelas",
            "Uf": "PI"
        },
        {
            "Id": 803,
            "Codigo": 2206704,
            "Nome": "Nazaré do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 804,
            "Codigo": 2206720,
            "Nome": "Nazária",
            "Uf": "PI"
        },
        {
            "Id": 805,
            "Codigo": 2206753,
            "Nome": "Nossa Senhora de Nazaré",
            "Uf": "PI"
        },
        {
            "Id": 806,
            "Codigo": 2206803,
            "Nome": "Nossa Senhora dos Remédios",
            "Uf": "PI"
        },
        {
            "Id": 807,
            "Codigo": 2206902,
            "Nome": "Novo Oriente do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 808,
            "Codigo": 2206951,
            "Nome": "Novo Santo Antônio",
            "Uf": "PI"
        },
        {
            "Id": 809,
            "Codigo": 2207009,
            "Nome": "Oeiras",
            "Uf": "PI"
        },
        {
            "Id": 810,
            "Codigo": 2207108,
            "Nome": "Olho D'Água do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 811,
            "Codigo": 2207207,
            "Nome": "Padre Marcos",
            "Uf": "PI"
        },
        {
            "Id": 812,
            "Codigo": 2207306,
            "Nome": "Paes Landim",
            "Uf": "PI"
        },
        {
            "Id": 813,
            "Codigo": 2207355,
            "Nome": "Pajeú do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 814,
            "Codigo": 2207405,
            "Nome": "Palmeira do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 815,
            "Codigo": 2207504,
            "Nome": "Palmeirais",
            "Uf": "PI"
        },
        {
            "Id": 816,
            "Codigo": 2207553,
            "Nome": "Paquetá",
            "Uf": "PI"
        },
        {
            "Id": 817,
            "Codigo": 2207603,
            "Nome": "Parnaguá",
            "Uf": "PI"
        },
        {
            "Id": 818,
            "Codigo": 2207702,
            "Nome": "Parnaíba",
            "Uf": "PI"
        },
        {
            "Id": 819,
            "Codigo": 2207751,
            "Nome": "Passagem Franca do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 820,
            "Codigo": 2207777,
            "Nome": "Patos do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 821,
            "Codigo": 2207793,
            "Nome": "Pau D'Arco do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 822,
            "Codigo": 2207801,
            "Nome": "Paulistana",
            "Uf": "PI"
        },
        {
            "Id": 823,
            "Codigo": 2207850,
            "Nome": "Pavussu",
            "Uf": "PI"
        },
        {
            "Id": 824,
            "Codigo": 2207900,
            "Nome": "Pedro II",
            "Uf": "PI"
        },
        {
            "Id": 825,
            "Codigo": 2207934,
            "Nome": "Pedro Laurentino",
            "Uf": "PI"
        },
        {
            "Id": 826,
            "Codigo": 2207959,
            "Nome": "Nova Santa Rita",
            "Uf": "PI"
        },
        {
            "Id": 827,
            "Codigo": 2208007,
            "Nome": "Picos",
            "Uf": "PI"
        },
        {
            "Id": 828,
            "Codigo": 2208106,
            "Nome": "Pimenteiras",
            "Uf": "PI"
        },
        {
            "Id": 829,
            "Codigo": 2208205,
            "Nome": "Pio IX",
            "Uf": "PI"
        },
        {
            "Id": 830,
            "Codigo": 2208304,
            "Nome": "Piracuruca",
            "Uf": "PI"
        },
        {
            "Id": 831,
            "Codigo": 2208403,
            "Nome": "Piripiri",
            "Uf": "PI"
        },
        {
            "Id": 832,
            "Codigo": 2208502,
            "Nome": "Porto",
            "Uf": "PI"
        },
        {
            "Id": 833,
            "Codigo": 2208551,
            "Nome": "Porto Alegre do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 834,
            "Codigo": 2208601,
            "Nome": "Prata do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 835,
            "Codigo": 2208650,
            "Nome": "Queimada Nova",
            "Uf": "PI"
        },
        {
            "Id": 836,
            "Codigo": 2208700,
            "Nome": "Redenção do Gurguéia",
            "Uf": "PI"
        },
        {
            "Id": 837,
            "Codigo": 2208809,
            "Nome": "Regeneração",
            "Uf": "PI"
        },
        {
            "Id": 838,
            "Codigo": 2208858,
            "Nome": "Riacho Frio",
            "Uf": "PI"
        },
        {
            "Id": 839,
            "Codigo": 2208874,
            "Nome": "Ribeira do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 840,
            "Codigo": 2208908,
            "Nome": "Ribeiro Gonçalves",
            "Uf": "PI"
        },
        {
            "Id": 841,
            "Codigo": 2209005,
            "Nome": "Rio Grande do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 842,
            "Codigo": 2209104,
            "Nome": "Santa Cruz do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 843,
            "Codigo": 2209153,
            "Nome": "Santa Cruz dos Milagres",
            "Uf": "PI"
        },
        {
            "Id": 844,
            "Codigo": 2209203,
            "Nome": "Santa Filomena",
            "Uf": "PI"
        },
        {
            "Id": 845,
            "Codigo": 2209302,
            "Nome": "Santa Luz",
            "Uf": "PI"
        },
        {
            "Id": 846,
            "Codigo": 2209351,
            "Nome": "Santana do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 847,
            "Codigo": 2209377,
            "Nome": "Santa Rosa do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 848,
            "Codigo": 2209401,
            "Nome": "Santo Antônio de Lisboa",
            "Uf": "PI"
        },
        {
            "Id": 849,
            "Codigo": 2209450,
            "Nome": "Santo Antônio dos Milagres",
            "Uf": "PI"
        },
        {
            "Id": 850,
            "Codigo": 2209500,
            "Nome": "Santo Inácio do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 851,
            "Codigo": 2209559,
            "Nome": "São Braz do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 852,
            "Codigo": 2209609,
            "Nome": "São Félix do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 853,
            "Codigo": 2209658,
            "Nome": "São Francisco de Assis do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 854,
            "Codigo": 2209708,
            "Nome": "São Francisco do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 855,
            "Codigo": 2209757,
            "Nome": "São Gonçalo do Gurguéia",
            "Uf": "PI"
        },
        {
            "Id": 856,
            "Codigo": 2209807,
            "Nome": "São Gonçalo do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 857,
            "Codigo": 2209856,
            "Nome": "São João da Canabrava",
            "Uf": "PI"
        },
        {
            "Id": 858,
            "Codigo": 2209872,
            "Nome": "São João da Fronteira",
            "Uf": "PI"
        },
        {
            "Id": 859,
            "Codigo": 2209906,
            "Nome": "São João da Serra",
            "Uf": "PI"
        },
        {
            "Id": 860,
            "Codigo": 2209955,
            "Nome": "São João da Varjota",
            "Uf": "PI"
        },
        {
            "Id": 861,
            "Codigo": 2209971,
            "Nome": "São João do Arraial",
            "Uf": "PI"
        },
        {
            "Id": 862,
            "Codigo": 2210003,
            "Nome": "São João do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 863,
            "Codigo": 2210052,
            "Nome": "São José do Divino",
            "Uf": "PI"
        },
        {
            "Id": 864,
            "Codigo": 2210102,
            "Nome": "São José do Peixe",
            "Uf": "PI"
        },
        {
            "Id": 865,
            "Codigo": 2210201,
            "Nome": "São José do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 866,
            "Codigo": 2210300,
            "Nome": "São Julião",
            "Uf": "PI"
        },
        {
            "Id": 867,
            "Codigo": 2210359,
            "Nome": "São Lourenço do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 868,
            "Codigo": 2210375,
            "Nome": "São Luis do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 869,
            "Codigo": 2210383,
            "Nome": "São Miguel da Baixa Grande",
            "Uf": "PI"
        },
        {
            "Id": 870,
            "Codigo": 2210391,
            "Nome": "São Miguel do Fidalgo",
            "Uf": "PI"
        },
        {
            "Id": 871,
            "Codigo": 2210409,
            "Nome": "São Miguel do Tapuio",
            "Uf": "PI"
        },
        {
            "Id": 872,
            "Codigo": 2210508,
            "Nome": "São Pedro do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 873,
            "Codigo": 2210607,
            "Nome": "São Raimundo Nonato",
            "Uf": "PI"
        },
        {
            "Id": 874,
            "Codigo": 2210623,
            "Nome": "Sebastião Barros",
            "Uf": "PI"
        },
        {
            "Id": 875,
            "Codigo": 2210631,
            "Nome": "Sebastião Leal",
            "Uf": "PI"
        },
        {
            "Id": 876,
            "Codigo": 2210656,
            "Nome": "Sigefredo Pacheco",
            "Uf": "PI"
        },
        {
            "Id": 877,
            "Codigo": 2210706,
            "Nome": "Simões",
            "Uf": "PI"
        },
        {
            "Id": 878,
            "Codigo": 2210805,
            "Nome": "Simplício Mendes",
            "Uf": "PI"
        },
        {
            "Id": 879,
            "Codigo": 2210904,
            "Nome": "Socorro do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 880,
            "Codigo": 2210938,
            "Nome": "Sussuapara",
            "Uf": "PI"
        },
        {
            "Id": 881,
            "Codigo": 2210953,
            "Nome": "Tamboril do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 882,
            "Codigo": 2210979,
            "Nome": "Tanque do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 883,
            "Codigo": 2211001,
            "Nome": "Teresina",
            "Uf": "PI"
        },
        {
            "Id": 884,
            "Codigo": 2211100,
            "Nome": "União",
            "Uf": "PI"
        },
        {
            "Id": 885,
            "Codigo": 2211209,
            "Nome": "Uruçuí",
            "Uf": "PI"
        },
        {
            "Id": 886,
            "Codigo": 2211308,
            "Nome": "Valença do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 887,
            "Codigo": 2211357,
            "Nome": "Várzea Branca",
            "Uf": "PI"
        },
        {
            "Id": 888,
            "Codigo": 2211407,
            "Nome": "Várzea Grande",
            "Uf": "PI"
        },
        {
            "Id": 889,
            "Codigo": 2211506,
            "Nome": "Vera Mendes",
            "Uf": "PI"
        },
        {
            "Id": 890,
            "Codigo": 2211605,
            "Nome": "Vila Nova do Piauí",
            "Uf": "PI"
        },
        {
            "Id": 891,
            "Codigo": 2211704,
            "Nome": "Wall Ferraz",
            "Uf": "PI"
        },
        {
            "Id": 892,
            "Codigo": 2300101,
            "Nome": "Abaiara",
            "Uf": "CE"
        },
        {
            "Id": 893,
            "Codigo": 2300150,
            "Nome": "Acarape",
            "Uf": "CE"
        },
        {
            "Id": 894,
            "Codigo": 2300200,
            "Nome": "Acaraú",
            "Uf": "CE"
        },
        {
            "Id": 895,
            "Codigo": 2300309,
            "Nome": "Acopiara",
            "Uf": "CE"
        },
        {
            "Id": 896,
            "Codigo": 2300408,
            "Nome": "Aiuaba",
            "Uf": "CE"
        },
        {
            "Id": 897,
            "Codigo": 2300507,
            "Nome": "Alcântaras",
            "Uf": "CE"
        },
        {
            "Id": 898,
            "Codigo": 2300606,
            "Nome": "Altaneira",
            "Uf": "CE"
        },
        {
            "Id": 899,
            "Codigo": 2300705,
            "Nome": "Alto Santo",
            "Uf": "CE"
        },
        {
            "Id": 900,
            "Codigo": 2300754,
            "Nome": "Amontada",
            "Uf": "CE"
        },
        {
            "Id": 901,
            "Codigo": 2300804,
            "Nome": "Antonina do Norte",
            "Uf": "CE"
        },
        {
            "Id": 902,
            "Codigo": 2300903,
            "Nome": "Apuiarés",
            "Uf": "CE"
        },
        {
            "Id": 903,
            "Codigo": 2301000,
            "Nome": "Aquiraz",
            "Uf": "CE"
        },
        {
            "Id": 904,
            "Codigo": 2301109,
            "Nome": "Aracati",
            "Uf": "CE"
        },
        {
            "Id": 905,
            "Codigo": 2301208,
            "Nome": "Aracoiaba",
            "Uf": "CE"
        },
        {
            "Id": 906,
            "Codigo": 2301257,
            "Nome": "Ararendá",
            "Uf": "CE"
        },
        {
            "Id": 907,
            "Codigo": 2301307,
            "Nome": "Araripe",
            "Uf": "CE"
        },
        {
            "Id": 908,
            "Codigo": 2301406,
            "Nome": "Aratuba",
            "Uf": "CE"
        },
        {
            "Id": 909,
            "Codigo": 2301505,
            "Nome": "Arneiroz",
            "Uf": "CE"
        },
        {
            "Id": 910,
            "Codigo": 2301604,
            "Nome": "Assaré",
            "Uf": "CE"
        },
        {
            "Id": 911,
            "Codigo": 2301703,
            "Nome": "Aurora",
            "Uf": "CE"
        },
        {
            "Id": 912,
            "Codigo": 2301802,
            "Nome": "Baixio",
            "Uf": "CE"
        },
        {
            "Id": 913,
            "Codigo": 2301851,
            "Nome": "Banabuiú",
            "Uf": "CE"
        },
        {
            "Id": 914,
            "Codigo": 2301901,
            "Nome": "Barbalha",
            "Uf": "CE"
        },
        {
            "Id": 915,
            "Codigo": 2301950,
            "Nome": "Barreira",
            "Uf": "CE"
        },
        {
            "Id": 916,
            "Codigo": 2302008,
            "Nome": "Barro",
            "Uf": "CE"
        },
        {
            "Id": 917,
            "Codigo": 2302057,
            "Nome": "Barroquinha",
            "Uf": "CE"
        },
        {
            "Id": 918,
            "Codigo": 2302107,
            "Nome": "Baturité",
            "Uf": "CE"
        },
        {
            "Id": 919,
            "Codigo": 2302206,
            "Nome": "Beberibe",
            "Uf": "CE"
        },
        {
            "Id": 920,
            "Codigo": 2302305,
            "Nome": "Bela Cruz",
            "Uf": "CE"
        },
        {
            "Id": 921,
            "Codigo": 2302404,
            "Nome": "Boa Viagem",
            "Uf": "CE"
        },
        {
            "Id": 922,
            "Codigo": 2302503,
            "Nome": "Brejo Santo",
            "Uf": "CE"
        },
        {
            "Id": 923,
            "Codigo": 2302602,
            "Nome": "Camocim",
            "Uf": "CE"
        },
        {
            "Id": 924,
            "Codigo": 2302701,
            "Nome": "Campos Sales",
            "Uf": "CE"
        },
        {
            "Id": 925,
            "Codigo": 2302800,
            "Nome": "Canindé",
            "Uf": "CE"
        },
        {
            "Id": 926,
            "Codigo": 2302909,
            "Nome": "Capistrano",
            "Uf": "CE"
        },
        {
            "Id": 927,
            "Codigo": 2303006,
            "Nome": "Caridade",
            "Uf": "CE"
        },
        {
            "Id": 928,
            "Codigo": 2303105,
            "Nome": "Cariré",
            "Uf": "CE"
        },
        {
            "Id": 929,
            "Codigo": 2303204,
            "Nome": "Caririaçu",
            "Uf": "CE"
        },
        {
            "Id": 930,
            "Codigo": 2303303,
            "Nome": "Cariús",
            "Uf": "CE"
        },
        {
            "Id": 931,
            "Codigo": 2303402,
            "Nome": "Carnaubal",
            "Uf": "CE"
        },
        {
            "Id": 932,
            "Codigo": 2303501,
            "Nome": "Cascavel",
            "Uf": "CE"
        },
        {
            "Id": 933,
            "Codigo": 2303600,
            "Nome": "Catarina",
            "Uf": "CE"
        },
        {
            "Id": 934,
            "Codigo": 2303659,
            "Nome": "Catunda",
            "Uf": "CE"
        },
        {
            "Id": 935,
            "Codigo": 2303709,
            "Nome": "Caucaia",
            "Uf": "CE"
        },
        {
            "Id": 936,
            "Codigo": 2303808,
            "Nome": "Cedro",
            "Uf": "CE"
        },
        {
            "Id": 937,
            "Codigo": 2303907,
            "Nome": "Chaval",
            "Uf": "CE"
        },
        {
            "Id": 938,
            "Codigo": 2303931,
            "Nome": "Choró",
            "Uf": "CE"
        },
        {
            "Id": 939,
            "Codigo": 2303956,
            "Nome": "Chorozinho",
            "Uf": "CE"
        },
        {
            "Id": 940,
            "Codigo": 2304004,
            "Nome": "Coreaú",
            "Uf": "CE"
        },
        {
            "Id": 941,
            "Codigo": 2304103,
            "Nome": "Crateús",
            "Uf": "CE"
        },
        {
            "Id": 942,
            "Codigo": 2304202,
            "Nome": "Crato",
            "Uf": "CE"
        },
        {
            "Id": 943,
            "Codigo": 2304236,
            "Nome": "Croatá",
            "Uf": "CE"
        },
        {
            "Id": 944,
            "Codigo": 2304251,
            "Nome": "Cruz",
            "Uf": "CE"
        },
        {
            "Id": 945,
            "Codigo": 2304269,
            "Nome": "Deputado Irapuan Pinheiro",
            "Uf": "CE"
        },
        {
            "Id": 946,
            "Codigo": 2304277,
            "Nome": "Ererê",
            "Uf": "CE"
        },
        {
            "Id": 947,
            "Codigo": 2304285,
            "Nome": "Eusébio",
            "Uf": "CE"
        },
        {
            "Id": 948,
            "Codigo": 2304301,
            "Nome": "Farias Brito",
            "Uf": "CE"
        },
        {
            "Id": 949,
            "Codigo": 2304350,
            "Nome": "Forquilha",
            "Uf": "CE"
        },
        {
            "Id": 950,
            "Codigo": 2304400,
            "Nome": "Fortaleza",
            "Uf": "CE"
        },
        {
            "Id": 951,
            "Codigo": 2304459,
            "Nome": "Fortim",
            "Uf": "CE"
        },
        {
            "Id": 952,
            "Codigo": 2304509,
            "Nome": "Frecheirinha",
            "Uf": "CE"
        },
        {
            "Id": 953,
            "Codigo": 2304608,
            "Nome": "General Sampaio",
            "Uf": "CE"
        },
        {
            "Id": 954,
            "Codigo": 2304657,
            "Nome": "Graça",
            "Uf": "CE"
        },
        {
            "Id": 955,
            "Codigo": 2304707,
            "Nome": "Granja",
            "Uf": "CE"
        },
        {
            "Id": 956,
            "Codigo": 2304806,
            "Nome": "Granjeiro",
            "Uf": "CE"
        },
        {
            "Id": 957,
            "Codigo": 2304905,
            "Nome": "Groaíras",
            "Uf": "CE"
        },
        {
            "Id": 958,
            "Codigo": 2304954,
            "Nome": "Guaiúba",
            "Uf": "CE"
        },
        {
            "Id": 959,
            "Codigo": 2305001,
            "Nome": "Guaraciaba do Norte",
            "Uf": "CE"
        },
        {
            "Id": 960,
            "Codigo": 2305100,
            "Nome": "Guaramiranga",
            "Uf": "CE"
        },
        {
            "Id": 961,
            "Codigo": 2305209,
            "Nome": "Hidrolândia",
            "Uf": "CE"
        },
        {
            "Id": 962,
            "Codigo": 2305233,
            "Nome": "Horizonte",
            "Uf": "CE"
        },
        {
            "Id": 963,
            "Codigo": 2305266,
            "Nome": "Ibaretama",
            "Uf": "CE"
        },
        {
            "Id": 964,
            "Codigo": 2305308,
            "Nome": "Ibiapina",
            "Uf": "CE"
        },
        {
            "Id": 965,
            "Codigo": 2305332,
            "Nome": "Ibicuitinga",
            "Uf": "CE"
        },
        {
            "Id": 966,
            "Codigo": 2305357,
            "Nome": "Icapuí",
            "Uf": "CE"
        },
        {
            "Id": 967,
            "Codigo": 2305407,
            "Nome": "Icó",
            "Uf": "CE"
        },
        {
            "Id": 968,
            "Codigo": 2305506,
            "Nome": "Iguatu",
            "Uf": "CE"
        },
        {
            "Id": 969,
            "Codigo": 2305605,
            "Nome": "Independência",
            "Uf": "CE"
        },
        {
            "Id": 970,
            "Codigo": 2305654,
            "Nome": "Ipaporanga",
            "Uf": "CE"
        },
        {
            "Id": 971,
            "Codigo": 2305704,
            "Nome": "Ipaumirim",
            "Uf": "CE"
        },
        {
            "Id": 972,
            "Codigo": 2305803,
            "Nome": "Ipu",
            "Uf": "CE"
        },
        {
            "Id": 973,
            "Codigo": 2305902,
            "Nome": "Ipueiras",
            "Uf": "CE"
        },
        {
            "Id": 974,
            "Codigo": 2306009,
            "Nome": "Iracema",
            "Uf": "CE"
        },
        {
            "Id": 975,
            "Codigo": 2306108,
            "Nome": "Irauçuba",
            "Uf": "CE"
        },
        {
            "Id": 976,
            "Codigo": 2306207,
            "Nome": "Itaiçaba",
            "Uf": "CE"
        },
        {
            "Id": 977,
            "Codigo": 2306256,
            "Nome": "Itaitinga",
            "Uf": "CE"
        },
        {
            "Id": 978,
            "Codigo": 2306306,
            "Nome": "Itapagé",
            "Uf": "CE"
        },
        {
            "Id": 979,
            "Codigo": 2306405,
            "Nome": "Itapipoca",
            "Uf": "CE"
        },
        {
            "Id": 980,
            "Codigo": 2306504,
            "Nome": "Itapiúna",
            "Uf": "CE"
        },
        {
            "Id": 981,
            "Codigo": 2306553,
            "Nome": "Itarema",
            "Uf": "CE"
        },
        {
            "Id": 982,
            "Codigo": 2306603,
            "Nome": "Itatira",
            "Uf": "CE"
        },
        {
            "Id": 983,
            "Codigo": 2306702,
            "Nome": "Jaguaretama",
            "Uf": "CE"
        },
        {
            "Id": 984,
            "Codigo": 2306801,
            "Nome": "Jaguaribara",
            "Uf": "CE"
        },
        {
            "Id": 985,
            "Codigo": 2306900,
            "Nome": "Jaguaribe",
            "Uf": "CE"
        },
        {
            "Id": 986,
            "Codigo": 2307007,
            "Nome": "Jaguaruana",
            "Uf": "CE"
        },
        {
            "Id": 987,
            "Codigo": 2307106,
            "Nome": "Jardim",
            "Uf": "CE"
        },
        {
            "Id": 988,
            "Codigo": 2307205,
            "Nome": "Jati",
            "Uf": "CE"
        },
        {
            "Id": 989,
            "Codigo": 2307254,
            "Nome": "Jijoca de Jericoacoara",
            "Uf": "CE"
        },
        {
            "Id": 990,
            "Codigo": 2307304,
            "Nome": "Juazeiro do Norte",
            "Uf": "CE"
        },
        {
            "Id": 991,
            "Codigo": 2307403,
            "Nome": "Jucás",
            "Uf": "CE"
        },
        {
            "Id": 992,
            "Codigo": 2307502,
            "Nome": "Lavras da Mangabeira",
            "Uf": "CE"
        },
        {
            "Id": 993,
            "Codigo": 2307601,
            "Nome": "Limoeiro do Norte",
            "Uf": "CE"
        },
        {
            "Id": 994,
            "Codigo": 2307635,
            "Nome": "Madalena",
            "Uf": "CE"
        },
        {
            "Id": 995,
            "Codigo": 2307650,
            "Nome": "Maracanaú",
            "Uf": "CE"
        },
        {
            "Id": 996,
            "Codigo": 2307700,
            "Nome": "Maranguape",
            "Uf": "CE"
        },
        {
            "Id": 997,
            "Codigo": 2307809,
            "Nome": "Marco",
            "Uf": "CE"
        },
        {
            "Id": 998,
            "Codigo": 2307908,
            "Nome": "Martinópole",
            "Uf": "CE"
        },
        {
            "Id": 999,
            "Codigo": 2308005,
            "Nome": "Massapê",
            "Uf": "CE"
        },
        {
            "Id": 1000,
            "Codigo": 2308104,
            "Nome": "Mauriti",
            "Uf": "CE"
        },
        {
            "Id": 1001,
            "Codigo": 2308203,
            "Nome": "Meruoca",
            "Uf": "CE"
        },
        {
            "Id": 1002,
            "Codigo": 2308302,
            "Nome": "Milagres",
            "Uf": "CE"
        },
        {
            "Id": 1003,
            "Codigo": 2308351,
            "Nome": "Milhã",
            "Uf": "CE"
        },
        {
            "Id": 1004,
            "Codigo": 2308377,
            "Nome": "Miraíma",
            "Uf": "CE"
        },
        {
            "Id": 1005,
            "Codigo": 2308401,
            "Nome": "Missão Velha",
            "Uf": "CE"
        },
        {
            "Id": 1006,
            "Codigo": 2308500,
            "Nome": "Mombaça",
            "Uf": "CE"
        },
        {
            "Id": 1007,
            "Codigo": 2308609,
            "Nome": "Monsenhor Tabosa",
            "Uf": "CE"
        },
        {
            "Id": 1008,
            "Codigo": 2308708,
            "Nome": "Morada Nova",
            "Uf": "CE"
        },
        {
            "Id": 1009,
            "Codigo": 2308807,
            "Nome": "Moraújo",
            "Uf": "CE"
        },
        {
            "Id": 1010,
            "Codigo": 2308906,
            "Nome": "Morrinhos",
            "Uf": "CE"
        },
        {
            "Id": 1011,
            "Codigo": 2309003,
            "Nome": "Mucambo",
            "Uf": "CE"
        },
        {
            "Id": 1012,
            "Codigo": 2309102,
            "Nome": "Mulungu",
            "Uf": "CE"
        },
        {
            "Id": 1013,
            "Codigo": 2309201,
            "Nome": "Nova Olinda",
            "Uf": "CE"
        },
        {
            "Id": 1014,
            "Codigo": 2309300,
            "Nome": "Nova Russas",
            "Uf": "CE"
        },
        {
            "Id": 1015,
            "Codigo": 2309409,
            "Nome": "Novo Oriente",
            "Uf": "CE"
        },
        {
            "Id": 1016,
            "Codigo": 2309458,
            "Nome": "Ocara",
            "Uf": "CE"
        },
        {
            "Id": 1017,
            "Codigo": 2309508,
            "Nome": "Orós",
            "Uf": "CE"
        },
        {
            "Id": 1018,
            "Codigo": 2309607,
            "Nome": "Pacajus",
            "Uf": "CE"
        },
        {
            "Id": 1019,
            "Codigo": 2309706,
            "Nome": "Pacatuba",
            "Uf": "CE"
        },
        {
            "Id": 1020,
            "Codigo": 2309805,
            "Nome": "Pacoti",
            "Uf": "CE"
        },
        {
            "Id": 1021,
            "Codigo": 2309904,
            "Nome": "Pacujá",
            "Uf": "CE"
        },
        {
            "Id": 1022,
            "Codigo": 2310001,
            "Nome": "Palhano",
            "Uf": "CE"
        },
        {
            "Id": 1023,
            "Codigo": 2310100,
            "Nome": "Palmácia",
            "Uf": "CE"
        },
        {
            "Id": 1024,
            "Codigo": 2310209,
            "Nome": "Paracuru",
            "Uf": "CE"
        },
        {
            "Id": 1025,
            "Codigo": 2310258,
            "Nome": "Paraipaba",
            "Uf": "CE"
        },
        {
            "Id": 1026,
            "Codigo": 2310308,
            "Nome": "Parambu",
            "Uf": "CE"
        },
        {
            "Id": 1027,
            "Codigo": 2310407,
            "Nome": "Paramoti",
            "Uf": "CE"
        },
        {
            "Id": 1028,
            "Codigo": 2310506,
            "Nome": "Pedra Branca",
            "Uf": "CE"
        },
        {
            "Id": 1029,
            "Codigo": 2310605,
            "Nome": "Penaforte",
            "Uf": "CE"
        },
        {
            "Id": 1030,
            "Codigo": 2310704,
            "Nome": "Pentecoste",
            "Uf": "CE"
        },
        {
            "Id": 1031,
            "Codigo": 2310803,
            "Nome": "Pereiro",
            "Uf": "CE"
        },
        {
            "Id": 1032,
            "Codigo": 2310852,
            "Nome": "Pindoretama",
            "Uf": "CE"
        },
        {
            "Id": 1033,
            "Codigo": 2310902,
            "Nome": "Piquet Carneiro",
            "Uf": "CE"
        },
        {
            "Id": 1034,
            "Codigo": 2310951,
            "Nome": "Pires Ferreira",
            "Uf": "CE"
        },
        {
            "Id": 1035,
            "Codigo": 2311009,
            "Nome": "Poranga",
            "Uf": "CE"
        },
        {
            "Id": 1036,
            "Codigo": 2311108,
            "Nome": "Porteiras",
            "Uf": "CE"
        },
        {
            "Id": 1037,
            "Codigo": 2311207,
            "Nome": "Potengi",
            "Uf": "CE"
        },
        {
            "Id": 1038,
            "Codigo": 2311231,
            "Nome": "Potiretama",
            "Uf": "CE"
        },
        {
            "Id": 1039,
            "Codigo": 2311264,
            "Nome": "Quiterianópolis",
            "Uf": "CE"
        },
        {
            "Id": 1040,
            "Codigo": 2311306,
            "Nome": "Quixadá",
            "Uf": "CE"
        },
        {
            "Id": 1041,
            "Codigo": 2311355,
            "Nome": "Quixelô",
            "Uf": "CE"
        },
        {
            "Id": 1042,
            "Codigo": 2311405,
            "Nome": "Quixeramobim",
            "Uf": "CE"
        },
        {
            "Id": 1043,
            "Codigo": 2311504,
            "Nome": "Quixeré",
            "Uf": "CE"
        },
        {
            "Id": 1044,
            "Codigo": 2311603,
            "Nome": "Redenção",
            "Uf": "CE"
        },
        {
            "Id": 1045,
            "Codigo": 2311702,
            "Nome": "Reriutaba",
            "Uf": "CE"
        },
        {
            "Id": 1046,
            "Codigo": 2311801,
            "Nome": "Russas",
            "Uf": "CE"
        },
        {
            "Id": 1047,
            "Codigo": 2311900,
            "Nome": "Saboeiro",
            "Uf": "CE"
        },
        {
            "Id": 1048,
            "Codigo": 2311959,
            "Nome": "Salitre",
            "Uf": "CE"
        },
        {
            "Id": 1049,
            "Codigo": 2312007,
            "Nome": "Santana do Acaraú",
            "Uf": "CE"
        },
        {
            "Id": 1050,
            "Codigo": 2312106,
            "Nome": "Santana do Cariri",
            "Uf": "CE"
        },
        {
            "Id": 1051,
            "Codigo": 2312205,
            "Nome": "Santa Quitéria",
            "Uf": "CE"
        },
        {
            "Id": 1052,
            "Codigo": 2312304,
            "Nome": "São Benedito",
            "Uf": "CE"
        },
        {
            "Id": 1053,
            "Codigo": 2312403,
            "Nome": "São Gonçalo do Amarante",
            "Uf": "CE"
        },
        {
            "Id": 1054,
            "Codigo": 2312502,
            "Nome": "São João do Jaguaribe",
            "Uf": "CE"
        },
        {
            "Id": 1055,
            "Codigo": 2312601,
            "Nome": "São Luís do Curu",
            "Uf": "CE"
        },
        {
            "Id": 1056,
            "Codigo": 2312700,
            "Nome": "Senador Pompeu",
            "Uf": "CE"
        },
        {
            "Id": 1057,
            "Codigo": 2312809,
            "Nome": "Senador Sá",
            "Uf": "CE"
        },
        {
            "Id": 1058,
            "Codigo": 2312908,
            "Nome": "Sobral",
            "Uf": "CE"
        },
        {
            "Id": 1059,
            "Codigo": 2313005,
            "Nome": "Solonópole",
            "Uf": "CE"
        },
        {
            "Id": 1060,
            "Codigo": 2313104,
            "Nome": "Tabuleiro do Norte",
            "Uf": "CE"
        },
        {
            "Id": 1061,
            "Codigo": 2313203,
            "Nome": "Tamboril",
            "Uf": "CE"
        },
        {
            "Id": 1062,
            "Codigo": 2313252,
            "Nome": "Tarrafas",
            "Uf": "CE"
        },
        {
            "Id": 1063,
            "Codigo": 2313302,
            "Nome": "Tauá",
            "Uf": "CE"
        },
        {
            "Id": 1064,
            "Codigo": 2313351,
            "Nome": "Tejuçuoca",
            "Uf": "CE"
        },
        {
            "Id": 1065,
            "Codigo": 2313401,
            "Nome": "Tianguá",
            "Uf": "CE"
        },
        {
            "Id": 1066,
            "Codigo": 2313500,
            "Nome": "Trairi",
            "Uf": "CE"
        },
        {
            "Id": 1067,
            "Codigo": 2313559,
            "Nome": "Tururu",
            "Uf": "CE"
        },
        {
            "Id": 1068,
            "Codigo": 2313609,
            "Nome": "Ubajara",
            "Uf": "CE"
        },
        {
            "Id": 1069,
            "Codigo": 2313708,
            "Nome": "Umari",
            "Uf": "CE"
        },
        {
            "Id": 1070,
            "Codigo": 2313757,
            "Nome": "Umirim",
            "Uf": "CE"
        },
        {
            "Id": 1071,
            "Codigo": 2313807,
            "Nome": "Uruburetama",
            "Uf": "CE"
        },
        {
            "Id": 1072,
            "Codigo": 2313906,
            "Nome": "Uruoca",
            "Uf": "CE"
        },
        {
            "Id": 1073,
            "Codigo": 2313955,
            "Nome": "Varjota",
            "Uf": "CE"
        },
        {
            "Id": 1074,
            "Codigo": 2314003,
            "Nome": "Várzea Alegre",
            "Uf": "CE"
        },
        {
            "Id": 1075,
            "Codigo": 2314102,
            "Nome": "Viçosa do Ceará",
            "Uf": "CE"
        },
        {
            "Id": 1076,
            "Codigo": 2400109,
            "Nome": "Acari",
            "Uf": "RN"
        },
        {
            "Id": 1077,
            "Codigo": 2400208,
            "Nome": "Açu",
            "Uf": "RN"
        },
        {
            "Id": 1078,
            "Codigo": 2400307,
            "Nome": "Afonso Bezerra",
            "Uf": "RN"
        },
        {
            "Id": 1079,
            "Codigo": 2400406,
            "Nome": "Água Nova",
            "Uf": "RN"
        },
        {
            "Id": 1080,
            "Codigo": 2400505,
            "Nome": "Alexandria",
            "Uf": "RN"
        },
        {
            "Id": 1081,
            "Codigo": 2400604,
            "Nome": "Almino Afonso",
            "Uf": "RN"
        },
        {
            "Id": 1082,
            "Codigo": 2400703,
            "Nome": "Alto do Rodrigues",
            "Uf": "RN"
        },
        {
            "Id": 1083,
            "Codigo": 2400802,
            "Nome": "Angicos",
            "Uf": "RN"
        },
        {
            "Id": 1084,
            "Codigo": 2400901,
            "Nome": "Antônio Martins",
            "Uf": "RN"
        },
        {
            "Id": 1085,
            "Codigo": 2401008,
            "Nome": "Apodi",
            "Uf": "RN"
        },
        {
            "Id": 1086,
            "Codigo": 2401107,
            "Nome": "Areia Branca",
            "Uf": "RN"
        },
        {
            "Id": 1087,
            "Codigo": 2401206,
            "Nome": "Arês",
            "Uf": "RN"
        },
        {
            "Id": 1088,
            "Codigo": 2401305,
            "Nome": "Augusto Severo",
            "Uf": "RN"
        },
        {
            "Id": 1089,
            "Codigo": 2401404,
            "Nome": "Baía Formosa",
            "Uf": "RN"
        },
        {
            "Id": 1090,
            "Codigo": 2401453,
            "Nome": "Baraúna",
            "Uf": "RN"
        },
        {
            "Id": 1091,
            "Codigo": 2401503,
            "Nome": "Barcelona",
            "Uf": "RN"
        },
        {
            "Id": 1092,
            "Codigo": 2401602,
            "Nome": "Bento Fernandes",
            "Uf": "RN"
        },
        {
            "Id": 1093,
            "Codigo": 2401651,
            "Nome": "Bodó",
            "Uf": "RN"
        },
        {
            "Id": 1094,
            "Codigo": 2401701,
            "Nome": "Bom Jesus",
            "Uf": "RN"
        },
        {
            "Id": 1095,
            "Codigo": 2401800,
            "Nome": "Brejinho",
            "Uf": "RN"
        },
        {
            "Id": 1096,
            "Codigo": 2401859,
            "Nome": "Caiçara do Norte",
            "Uf": "RN"
        },
        {
            "Id": 1097,
            "Codigo": 2401909,
            "Nome": "Caiçara do Rio do Vento",
            "Uf": "RN"
        },
        {
            "Id": 1098,
            "Codigo": 2402006,
            "Nome": "Caicó",
            "Uf": "RN"
        },
        {
            "Id": 1099,
            "Codigo": 2402105,
            "Nome": "Campo Redondo",
            "Uf": "RN"
        },
        {
            "Id": 1100,
            "Codigo": 2402204,
            "Nome": "Canguaretama",
            "Uf": "RN"
        },
        {
            "Id": 1101,
            "Codigo": 2402303,
            "Nome": "Caraúbas",
            "Uf": "RN"
        },
        {
            "Id": 1102,
            "Codigo": 2402402,
            "Nome": "Carnaúba dos Dantas",
            "Uf": "RN"
        },
        {
            "Id": 1103,
            "Codigo": 2402501,
            "Nome": "Carnaubais",
            "Uf": "RN"
        },
        {
            "Id": 1104,
            "Codigo": 2402600,
            "Nome": "Ceará-Mirim",
            "Uf": "RN"
        },
        {
            "Id": 1105,
            "Codigo": 2402709,
            "Nome": "Cerro Corá",
            "Uf": "RN"
        },
        {
            "Id": 1106,
            "Codigo": 2402808,
            "Nome": "Coronel Ezequiel",
            "Uf": "RN"
        },
        {
            "Id": 1107,
            "Codigo": 2402907,
            "Nome": "Coronel João Pessoa",
            "Uf": "RN"
        },
        {
            "Id": 1108,
            "Codigo": 2403004,
            "Nome": "Cruzeta",
            "Uf": "RN"
        },
        {
            "Id": 1109,
            "Codigo": 2403103,
            "Nome": "Currais Novos",
            "Uf": "RN"
        },
        {
            "Id": 1110,
            "Codigo": 2403202,
            "Nome": "Doutor Severiano",
            "Uf": "RN"
        },
        {
            "Id": 1111,
            "Codigo": 2403251,
            "Nome": "Parnamirim",
            "Uf": "RN"
        },
        {
            "Id": 1112,
            "Codigo": 2403301,
            "Nome": "Encanto",
            "Uf": "RN"
        },
        {
            "Id": 1113,
            "Codigo": 2403400,
            "Nome": "Equador",
            "Uf": "RN"
        },
        {
            "Id": 1114,
            "Codigo": 2403509,
            "Nome": "Espírito Santo",
            "Uf": "RN"
        },
        {
            "Id": 1115,
            "Codigo": 2403608,
            "Nome": "Extremoz",
            "Uf": "RN"
        },
        {
            "Id": 1116,
            "Codigo": 2403707,
            "Nome": "Felipe Guerra",
            "Uf": "RN"
        },
        {
            "Id": 1117,
            "Codigo": 2403756,
            "Nome": "Fernando Pedroza",
            "Uf": "RN"
        },
        {
            "Id": 1118,
            "Codigo": 2403806,
            "Nome": "Florânia",
            "Uf": "RN"
        },
        {
            "Id": 1119,
            "Codigo": 2403905,
            "Nome": "Francisco Dantas",
            "Uf": "RN"
        },
        {
            "Id": 1120,
            "Codigo": 2404002,
            "Nome": "Frutuoso Gomes",
            "Uf": "RN"
        },
        {
            "Id": 1121,
            "Codigo": 2404101,
            "Nome": "Galinhos",
            "Uf": "RN"
        },
        {
            "Id": 1122,
            "Codigo": 2404200,
            "Nome": "Goianinha",
            "Uf": "RN"
        },
        {
            "Id": 1123,
            "Codigo": 2404309,
            "Nome": "Governador Dix-Sept Rosado",
            "Uf": "RN"
        },
        {
            "Id": 1124,
            "Codigo": 2404408,
            "Nome": "Grossos",
            "Uf": "RN"
        },
        {
            "Id": 1125,
            "Codigo": 2404507,
            "Nome": "Guamaré",
            "Uf": "RN"
        },
        {
            "Id": 1126,
            "Codigo": 2404606,
            "Nome": "Ielmo Marinho",
            "Uf": "RN"
        },
        {
            "Id": 1127,
            "Codigo": 2404705,
            "Nome": "Ipanguaçu",
            "Uf": "RN"
        },
        {
            "Id": 1128,
            "Codigo": 2404804,
            "Nome": "Ipueira",
            "Uf": "RN"
        },
        {
            "Id": 1129,
            "Codigo": 2404853,
            "Nome": "Itajá",
            "Uf": "RN"
        },
        {
            "Id": 1130,
            "Codigo": 2404903,
            "Nome": "Itaú",
            "Uf": "RN"
        },
        {
            "Id": 1131,
            "Codigo": 2405009,
            "Nome": "Jaçanã",
            "Uf": "RN"
        },
        {
            "Id": 1132,
            "Codigo": 2405108,
            "Nome": "Jandaíra",
            "Uf": "RN"
        },
        {
            "Id": 1133,
            "Codigo": 2405207,
            "Nome": "Janduís",
            "Uf": "RN"
        },
        {
            "Id": 1134,
            "Codigo": 2405306,
            "Nome": "Januário Cicco",
            "Uf": "RN"
        },
        {
            "Id": 1135,
            "Codigo": 2405405,
            "Nome": "Japi",
            "Uf": "RN"
        },
        {
            "Id": 1136,
            "Codigo": 2405504,
            "Nome": "Jardim de Angicos",
            "Uf": "RN"
        },
        {
            "Id": 1137,
            "Codigo": 2405603,
            "Nome": "Jardim de Piranhas",
            "Uf": "RN"
        },
        {
            "Id": 1138,
            "Codigo": 2405702,
            "Nome": "Jardim do Seridó",
            "Uf": "RN"
        },
        {
            "Id": 1139,
            "Codigo": 2405801,
            "Nome": "João Câmara",
            "Uf": "RN"
        },
        {
            "Id": 1140,
            "Codigo": 2405900,
            "Nome": "João Dias",
            "Uf": "RN"
        },
        {
            "Id": 1141,
            "Codigo": 2406007,
            "Nome": "José da Penha",
            "Uf": "RN"
        },
        {
            "Id": 1142,
            "Codigo": 2406106,
            "Nome": "Jucurutu",
            "Uf": "RN"
        },
        {
            "Id": 1143,
            "Codigo": 2406155,
            "Nome": "Jundiá",
            "Uf": "RN"
        },
        {
            "Id": 1144,
            "Codigo": 2406205,
            "Nome": "Lagoa D'Anta",
            "Uf": "RN"
        },
        {
            "Id": 1145,
            "Codigo": 2406304,
            "Nome": "Lagoa de Pedras",
            "Uf": "RN"
        },
        {
            "Id": 1146,
            "Codigo": 2406403,
            "Nome": "Lagoa de Velhos",
            "Uf": "RN"
        },
        {
            "Id": 1147,
            "Codigo": 2406502,
            "Nome": "Lagoa Nova",
            "Uf": "RN"
        },
        {
            "Id": 1148,
            "Codigo": 2406601,
            "Nome": "Lagoa Salgada",
            "Uf": "RN"
        },
        {
            "Id": 1149,
            "Codigo": 2406700,
            "Nome": "Lajes",
            "Uf": "RN"
        },
        {
            "Id": 1150,
            "Codigo": 2406809,
            "Nome": "Lajes Pintadas",
            "Uf": "RN"
        },
        {
            "Id": 1151,
            "Codigo": 2406908,
            "Nome": "Lucrécia",
            "Uf": "RN"
        },
        {
            "Id": 1152,
            "Codigo": 2407005,
            "Nome": "Luís Gomes",
            "Uf": "RN"
        },
        {
            "Id": 1153,
            "Codigo": 2407104,
            "Nome": "Macaíba",
            "Uf": "RN"
        },
        {
            "Id": 1154,
            "Codigo": 2407203,
            "Nome": "Macau",
            "Uf": "RN"
        },
        {
            "Id": 1155,
            "Codigo": 2407252,
            "Nome": "Major Sales",
            "Uf": "RN"
        },
        {
            "Id": 1156,
            "Codigo": 2407302,
            "Nome": "Marcelino Vieira",
            "Uf": "RN"
        },
        {
            "Id": 1157,
            "Codigo": 2407401,
            "Nome": "Martins",
            "Uf": "RN"
        },
        {
            "Id": 1158,
            "Codigo": 2407500,
            "Nome": "Maxaranguape",
            "Uf": "RN"
        },
        {
            "Id": 1159,
            "Codigo": 2407609,
            "Nome": "Messias Targino",
            "Uf": "RN"
        },
        {
            "Id": 1160,
            "Codigo": 2407708,
            "Nome": "Montanhas",
            "Uf": "RN"
        },
        {
            "Id": 1161,
            "Codigo": 2407807,
            "Nome": "Monte Alegre",
            "Uf": "RN"
        },
        {
            "Id": 1162,
            "Codigo": 2407906,
            "Nome": "Monte das Gameleiras",
            "Uf": "RN"
        },
        {
            "Id": 1163,
            "Codigo": 2408003,
            "Nome": "Mossoró",
            "Uf": "RN"
        },
        {
            "Id": 1164,
            "Codigo": 2408102,
            "Nome": "Natal",
            "Uf": "RN"
        },
        {
            "Id": 1165,
            "Codigo": 2408201,
            "Nome": "Nísia Floresta",
            "Uf": "RN"
        },
        {
            "Id": 1166,
            "Codigo": 2408300,
            "Nome": "Nova Cruz",
            "Uf": "RN"
        },
        {
            "Id": 1167,
            "Codigo": 2408409,
            "Nome": "Olho-D'Água do Borges",
            "Uf": "RN"
        },
        {
            "Id": 1168,
            "Codigo": 2408508,
            "Nome": "Ouro Branco",
            "Uf": "RN"
        },
        {
            "Id": 1169,
            "Codigo": 2408607,
            "Nome": "Paraná",
            "Uf": "RN"
        },
        {
            "Id": 1170,
            "Codigo": 2408706,
            "Nome": "Paraú",
            "Uf": "RN"
        },
        {
            "Id": 1171,
            "Codigo": 2408805,
            "Nome": "Parazinho",
            "Uf": "RN"
        },
        {
            "Id": 1172,
            "Codigo": 2408904,
            "Nome": "Parelhas",
            "Uf": "RN"
        },
        {
            "Id": 1173,
            "Codigo": 2408953,
            "Nome": "Rio do Fogo",
            "Uf": "RN"
        },
        {
            "Id": 1174,
            "Codigo": 2409100,
            "Nome": "Passa e Fica",
            "Uf": "RN"
        },
        {
            "Id": 1175,
            "Codigo": 2409209,
            "Nome": "Passagem",
            "Uf": "RN"
        },
        {
            "Id": 1176,
            "Codigo": 2409308,
            "Nome": "Patu",
            "Uf": "RN"
        },
        {
            "Id": 1177,
            "Codigo": 2409332,
            "Nome": "Santa Maria",
            "Uf": "RN"
        },
        {
            "Id": 1178,
            "Codigo": 2409407,
            "Nome": "Pau dos Ferros",
            "Uf": "RN"
        },
        {
            "Id": 1179,
            "Codigo": 2409506,
            "Nome": "Pedra Grande",
            "Uf": "RN"
        },
        {
            "Id": 1180,
            "Codigo": 2409605,
            "Nome": "Pedra Preta",
            "Uf": "RN"
        },
        {
            "Id": 1181,
            "Codigo": 2409704,
            "Nome": "Pedro Avelino",
            "Uf": "RN"
        },
        {
            "Id": 1182,
            "Codigo": 2409803,
            "Nome": "Pedro Velho",
            "Uf": "RN"
        },
        {
            "Id": 1183,
            "Codigo": 2409902,
            "Nome": "Pendências",
            "Uf": "RN"
        },
        {
            "Id": 1184,
            "Codigo": 2410009,
            "Nome": "Pilões",
            "Uf": "RN"
        },
        {
            "Id": 1185,
            "Codigo": 2410108,
            "Nome": "Poço Branco",
            "Uf": "RN"
        },
        {
            "Id": 1186,
            "Codigo": 2410207,
            "Nome": "Portalegre",
            "Uf": "RN"
        },
        {
            "Id": 1187,
            "Codigo": 2410256,
            "Nome": "Porto do Mangue",
            "Uf": "RN"
        },
        {
            "Id": 1188,
            "Codigo": 2410306,
            "Nome": "Presidente Juscelino",
            "Uf": "RN"
        },
        {
            "Id": 1189,
            "Codigo": 2410405,
            "Nome": "Pureza",
            "Uf": "RN"
        },
        {
            "Id": 1190,
            "Codigo": 2410504,
            "Nome": "Rafael Fernandes",
            "Uf": "RN"
        },
        {
            "Id": 1191,
            "Codigo": 2410603,
            "Nome": "Rafael Godeiro",
            "Uf": "RN"
        },
        {
            "Id": 1192,
            "Codigo": 2410702,
            "Nome": "Riacho da Cruz",
            "Uf": "RN"
        },
        {
            "Id": 1193,
            "Codigo": 2410801,
            "Nome": "Riacho de Santana",
            "Uf": "RN"
        },
        {
            "Id": 1194,
            "Codigo": 2410900,
            "Nome": "Riachuelo",
            "Uf": "RN"
        },
        {
            "Id": 1195,
            "Codigo": 2411007,
            "Nome": "Rodolfo Fernandes",
            "Uf": "RN"
        },
        {
            "Id": 1196,
            "Codigo": 2411056,
            "Nome": "Tibau",
            "Uf": "RN"
        },
        {
            "Id": 1197,
            "Codigo": 2411106,
            "Nome": "Ruy Barbosa",
            "Uf": "RN"
        },
        {
            "Id": 1198,
            "Codigo": 2411205,
            "Nome": "Santa Cruz",
            "Uf": "RN"
        },
        {
            "Id": 1199,
            "Codigo": 2411403,
            "Nome": "Santana do Matos",
            "Uf": "RN"
        },
        {
            "Id": 1200,
            "Codigo": 2411429,
            "Nome": "Santana do Seridó",
            "Uf": "RN"
        },
        {
            "Id": 1201,
            "Codigo": 2411502,
            "Nome": "Santo Antônio",
            "Uf": "RN"
        },
        {
            "Id": 1202,
            "Codigo": 2411601,
            "Nome": "São Bento do Norte",
            "Uf": "RN"
        },
        {
            "Id": 1203,
            "Codigo": 2411700,
            "Nome": "São Bento do Trairí",
            "Uf": "RN"
        },
        {
            "Id": 1204,
            "Codigo": 2411809,
            "Nome": "São Fernando",
            "Uf": "RN"
        },
        {
            "Id": 1205,
            "Codigo": 2411908,
            "Nome": "São Francisco do Oeste",
            "Uf": "RN"
        },
        {
            "Id": 1206,
            "Codigo": 2412005,
            "Nome": "São Gonçalo do Amarante",
            "Uf": "RN"
        },
        {
            "Id": 1207,
            "Codigo": 2412104,
            "Nome": "São João do Sabugi",
            "Uf": "RN"
        },
        {
            "Id": 1208,
            "Codigo": 2412203,
            "Nome": "São José de Mipibu",
            "Uf": "RN"
        },
        {
            "Id": 1209,
            "Codigo": 2412302,
            "Nome": "São José do Campestre",
            "Uf": "RN"
        },
        {
            "Id": 1210,
            "Codigo": 2412401,
            "Nome": "São José do Seridó",
            "Uf": "RN"
        },
        {
            "Id": 1211,
            "Codigo": 2412500,
            "Nome": "São Miguel",
            "Uf": "RN"
        },
        {
            "Id": 1212,
            "Codigo": 2412559,
            "Nome": "São Miguel do Gostoso",
            "Uf": "RN"
        },
        {
            "Id": 1213,
            "Codigo": 2412609,
            "Nome": "São Paulo do Potengi",
            "Uf": "RN"
        },
        {
            "Id": 1214,
            "Codigo": 2412708,
            "Nome": "São Pedro",
            "Uf": "RN"
        },
        {
            "Id": 1215,
            "Codigo": 2412807,
            "Nome": "São Rafael",
            "Uf": "RN"
        },
        {
            "Id": 1216,
            "Codigo": 2412906,
            "Nome": "São Tomé",
            "Uf": "RN"
        },
        {
            "Id": 1217,
            "Codigo": 2413003,
            "Nome": "São Vicente",
            "Uf": "RN"
        },
        {
            "Id": 1218,
            "Codigo": 2413102,
            "Nome": "Senador Elói de Souza",
            "Uf": "RN"
        },
        {
            "Id": 1219,
            "Codigo": 2413201,
            "Nome": "Senador Georgino Avelino",
            "Uf": "RN"
        },
        {
            "Id": 1220,
            "Codigo": 2413300,
            "Nome": "Serra de São Bento",
            "Uf": "RN"
        },
        {
            "Id": 1221,
            "Codigo": 2413359,
            "Nome": "Serra do Mel",
            "Uf": "RN"
        },
        {
            "Id": 1222,
            "Codigo": 2413409,
            "Nome": "Serra Negra do Norte",
            "Uf": "RN"
        },
        {
            "Id": 1223,
            "Codigo": 2413508,
            "Nome": "Serrinha",
            "Uf": "RN"
        },
        {
            "Id": 1224,
            "Codigo": 2413557,
            "Nome": "Serrinha dos Pintos",
            "Uf": "RN"
        },
        {
            "Id": 1225,
            "Codigo": 2413607,
            "Nome": "Severiano Melo",
            "Uf": "RN"
        },
        {
            "Id": 1226,
            "Codigo": 2413706,
            "Nome": "Sítio Novo",
            "Uf": "RN"
        },
        {
            "Id": 1227,
            "Codigo": 2413805,
            "Nome": "Taboleiro Grande",
            "Uf": "RN"
        },
        {
            "Id": 1228,
            "Codigo": 2413904,
            "Nome": "Taipu",
            "Uf": "RN"
        },
        {
            "Id": 1229,
            "Codigo": 2414001,
            "Nome": "Tangará",
            "Uf": "RN"
        },
        {
            "Id": 1230,
            "Codigo": 2414100,
            "Nome": "Tenente Ananias",
            "Uf": "RN"
        },
        {
            "Id": 1231,
            "Codigo": 2414159,
            "Nome": "Tenente Laurentino Cruz",
            "Uf": "RN"
        },
        {
            "Id": 1232,
            "Codigo": 2414209,
            "Nome": "Tibau do Sul",
            "Uf": "RN"
        },
        {
            "Id": 1233,
            "Codigo": 2414308,
            "Nome": "Timbaúba dos Batistas",
            "Uf": "RN"
        },
        {
            "Id": 1234,
            "Codigo": 2414407,
            "Nome": "Touros",
            "Uf": "RN"
        },
        {
            "Id": 1235,
            "Codigo": 2414456,
            "Nome": "Triunfo Potiguar",
            "Uf": "RN"
        },
        {
            "Id": 1236,
            "Codigo": 2414506,
            "Nome": "Umarizal",
            "Uf": "RN"
        },
        {
            "Id": 1237,
            "Codigo": 2414605,
            "Nome": "Upanema",
            "Uf": "RN"
        },
        {
            "Id": 1238,
            "Codigo": 2414704,
            "Nome": "Várzea",
            "Uf": "RN"
        },
        {
            "Id": 1239,
            "Codigo": 2414753,
            "Nome": "Venha-Ver",
            "Uf": "RN"
        },
        {
            "Id": 1240,
            "Codigo": 2414803,
            "Nome": "Vera Cruz",
            "Uf": "RN"
        },
        {
            "Id": 1241,
            "Codigo": 2414902,
            "Nome": "Viçosa",
            "Uf": "RN"
        },
        {
            "Id": 1242,
            "Codigo": 2415008,
            "Nome": "Vila Flor",
            "Uf": "RN"
        },
        {
            "Id": 1243,
            "Codigo": 2500106,
            "Nome": "Água Branca",
            "Uf": "PB"
        },
        {
            "Id": 1244,
            "Codigo": 2500205,
            "Nome": "Aguiar",
            "Uf": "PB"
        },
        {
            "Id": 1245,
            "Codigo": 2500304,
            "Nome": "Alagoa Grande",
            "Uf": "PB"
        },
        {
            "Id": 1246,
            "Codigo": 2500403,
            "Nome": "Alagoa Nova",
            "Uf": "PB"
        },
        {
            "Id": 1247,
            "Codigo": 2500502,
            "Nome": "Alagoinha",
            "Uf": "PB"
        },
        {
            "Id": 1248,
            "Codigo": 2500536,
            "Nome": "Alcantil",
            "Uf": "PB"
        },
        {
            "Id": 1249,
            "Codigo": 2500577,
            "Nome": "Algodão de Jandaíra",
            "Uf": "PB"
        },
        {
            "Id": 1250,
            "Codigo": 2500601,
            "Nome": "Alhandra",
            "Uf": "PB"
        },
        {
            "Id": 1251,
            "Codigo": 2500700,
            "Nome": "São João do Rio do Peixe",
            "Uf": "PB"
        },
        {
            "Id": 1252,
            "Codigo": 2500734,
            "Nome": "Amparo",
            "Uf": "PB"
        },
        {
            "Id": 1253,
            "Codigo": 2500775,
            "Nome": "Aparecida",
            "Uf": "PB"
        },
        {
            "Id": 1254,
            "Codigo": 2500809,
            "Nome": "Araçagi",
            "Uf": "PB"
        },
        {
            "Id": 1255,
            "Codigo": 2500908,
            "Nome": "Arara",
            "Uf": "PB"
        },
        {
            "Id": 1256,
            "Codigo": 2501005,
            "Nome": "Araruna",
            "Uf": "PB"
        },
        {
            "Id": 1257,
            "Codigo": 2501104,
            "Nome": "Areia",
            "Uf": "PB"
        },
        {
            "Id": 1258,
            "Codigo": 2501153,
            "Nome": "Areia de Baraúnas",
            "Uf": "PB"
        },
        {
            "Id": 1259,
            "Codigo": 2501203,
            "Nome": "Areial",
            "Uf": "PB"
        },
        {
            "Id": 1260,
            "Codigo": 2501302,
            "Nome": "Aroeiras",
            "Uf": "PB"
        },
        {
            "Id": 1261,
            "Codigo": 2501351,
            "Nome": "Assunção",
            "Uf": "PB"
        },
        {
            "Id": 1262,
            "Codigo": 2501401,
            "Nome": "Baía da Traição",
            "Uf": "PB"
        },
        {
            "Id": 1263,
            "Codigo": 2501500,
            "Nome": "Bananeiras",
            "Uf": "PB"
        },
        {
            "Id": 1264,
            "Codigo": 2501534,
            "Nome": "Baraúna",
            "Uf": "PB"
        },
        {
            "Id": 1265,
            "Codigo": 2501575,
            "Nome": "Barra de Santana",
            "Uf": "PB"
        },
        {
            "Id": 1266,
            "Codigo": 2501609,
            "Nome": "Barra de Santa Rosa",
            "Uf": "PB"
        },
        {
            "Id": 1267,
            "Codigo": 2501708,
            "Nome": "Barra de São Miguel",
            "Uf": "PB"
        },
        {
            "Id": 1268,
            "Codigo": 2501807,
            "Nome": "Bayeux",
            "Uf": "PB"
        },
        {
            "Id": 1269,
            "Codigo": 2501906,
            "Nome": "Belém",
            "Uf": "PB"
        },
        {
            "Id": 1270,
            "Codigo": 2502003,
            "Nome": "Belém do Brejo do Cruz",
            "Uf": "PB"
        },
        {
            "Id": 1271,
            "Codigo": 2502052,
            "Nome": "Bernardino Batista",
            "Uf": "PB"
        },
        {
            "Id": 1272,
            "Codigo": 2502102,
            "Nome": "Boa Ventura",
            "Uf": "PB"
        },
        {
            "Id": 1273,
            "Codigo": 2502151,
            "Nome": "Boa Vista",
            "Uf": "PB"
        },
        {
            "Id": 1274,
            "Codigo": 2502201,
            "Nome": "Bom Jesus",
            "Uf": "PB"
        },
        {
            "Id": 1275,
            "Codigo": 2502300,
            "Nome": "Bom Sucesso",
            "Uf": "PB"
        },
        {
            "Id": 1276,
            "Codigo": 2502409,
            "Nome": "Bonito de Santa Fé",
            "Uf": "PB"
        },
        {
            "Id": 1277,
            "Codigo": 2502508,
            "Nome": "Boqueirão",
            "Uf": "PB"
        },
        {
            "Id": 1278,
            "Codigo": 2502607,
            "Nome": "Igaracy",
            "Uf": "PB"
        },
        {
            "Id": 1279,
            "Codigo": 2502706,
            "Nome": "Borborema",
            "Uf": "PB"
        },
        {
            "Id": 1280,
            "Codigo": 2502805,
            "Nome": "Brejo do Cruz",
            "Uf": "PB"
        },
        {
            "Id": 1281,
            "Codigo": 2502904,
            "Nome": "Brejo dos Santos",
            "Uf": "PB"
        },
        {
            "Id": 1282,
            "Codigo": 2503001,
            "Nome": "Caaporã",
            "Uf": "PB"
        },
        {
            "Id": 1283,
            "Codigo": 2503100,
            "Nome": "Cabaceiras",
            "Uf": "PB"
        },
        {
            "Id": 1284,
            "Codigo": 2503209,
            "Nome": "Cabedelo",
            "Uf": "PB"
        },
        {
            "Id": 1285,
            "Codigo": 2503308,
            "Nome": "Cachoeira dos Índios",
            "Uf": "PB"
        },
        {
            "Id": 1286,
            "Codigo": 2503407,
            "Nome": "Cacimba de Areia",
            "Uf": "PB"
        },
        {
            "Id": 1287,
            "Codigo": 2503506,
            "Nome": "Cacimba de Dentro",
            "Uf": "PB"
        },
        {
            "Id": 1288,
            "Codigo": 2503555,
            "Nome": "Cacimbas",
            "Uf": "PB"
        },
        {
            "Id": 1289,
            "Codigo": 2503605,
            "Nome": "Caiçara",
            "Uf": "PB"
        },
        {
            "Id": 1290,
            "Codigo": 2503704,
            "Nome": "Cajazeiras",
            "Uf": "PB"
        },
        {
            "Id": 1291,
            "Codigo": 2503753,
            "Nome": "Cajazeirinhas",
            "Uf": "PB"
        },
        {
            "Id": 1292,
            "Codigo": 2503803,
            "Nome": "Caldas Brandão",
            "Uf": "PB"
        },
        {
            "Id": 1293,
            "Codigo": 2503902,
            "Nome": "Camalaú",
            "Uf": "PB"
        },
        {
            "Id": 1294,
            "Codigo": 2504009,
            "Nome": "Campina Grande",
            "Uf": "PB"
        },
        {
            "Id": 1295,
            "Codigo": 2504033,
            "Nome": "Capim",
            "Uf": "PB"
        },
        {
            "Id": 1296,
            "Codigo": 2504074,
            "Nome": "Caraúbas",
            "Uf": "PB"
        },
        {
            "Id": 1297,
            "Codigo": 2504108,
            "Nome": "Carrapateira",
            "Uf": "PB"
        },
        {
            "Id": 1298,
            "Codigo": 2504157,
            "Nome": "Casserengue",
            "Uf": "PB"
        },
        {
            "Id": 1299,
            "Codigo": 2504207,
            "Nome": "Catingueira",
            "Uf": "PB"
        },
        {
            "Id": 1300,
            "Codigo": 2504306,
            "Nome": "Catolé do Rocha",
            "Uf": "PB"
        },
        {
            "Id": 1301,
            "Codigo": 2504355,
            "Nome": "Caturité",
            "Uf": "PB"
        },
        {
            "Id": 1302,
            "Codigo": 2504405,
            "Nome": "Conceição",
            "Uf": "PB"
        },
        {
            "Id": 1303,
            "Codigo": 2504504,
            "Nome": "Condado",
            "Uf": "PB"
        },
        {
            "Id": 1304,
            "Codigo": 2504603,
            "Nome": "Conde",
            "Uf": "PB"
        },
        {
            "Id": 1305,
            "Codigo": 2504702,
            "Nome": "Congo",
            "Uf": "PB"
        },
        {
            "Id": 1306,
            "Codigo": 2504801,
            "Nome": "Coremas",
            "Uf": "PB"
        },
        {
            "Id": 1307,
            "Codigo": 2504850,
            "Nome": "Coxixola",
            "Uf": "PB"
        },
        {
            "Id": 1308,
            "Codigo": 2504900,
            "Nome": "Cruz do Espírito Santo",
            "Uf": "PB"
        },
        {
            "Id": 1309,
            "Codigo": 2505006,
            "Nome": "Cubati",
            "Uf": "PB"
        },
        {
            "Id": 1310,
            "Codigo": 2505105,
            "Nome": "Cuité",
            "Uf": "PB"
        },
        {
            "Id": 1311,
            "Codigo": 2505204,
            "Nome": "Cuitegi",
            "Uf": "PB"
        },
        {
            "Id": 1312,
            "Codigo": 2505238,
            "Nome": "Cuité de Mamanguape",
            "Uf": "PB"
        },
        {
            "Id": 1313,
            "Codigo": 2505279,
            "Nome": "Curral de Cima",
            "Uf": "PB"
        },
        {
            "Id": 1314,
            "Codigo": 2505303,
            "Nome": "Curral Velho",
            "Uf": "PB"
        },
        {
            "Id": 1315,
            "Codigo": 2505352,
            "Nome": "Damião",
            "Uf": "PB"
        },
        {
            "Id": 1316,
            "Codigo": 2505402,
            "Nome": "Desterro",
            "Uf": "PB"
        },
        {
            "Id": 1317,
            "Codigo": 2505501,
            "Nome": "Vista Serrana",
            "Uf": "PB"
        },
        {
            "Id": 1318,
            "Codigo": 2505600,
            "Nome": "Diamante",
            "Uf": "PB"
        },
        {
            "Id": 1319,
            "Codigo": 2505709,
            "Nome": "Dona Inês",
            "Uf": "PB"
        },
        {
            "Id": 1320,
            "Codigo": 2505808,
            "Nome": "Duas Estradas",
            "Uf": "PB"
        },
        {
            "Id": 1321,
            "Codigo": 2505907,
            "Nome": "Emas",
            "Uf": "PB"
        },
        {
            "Id": 1322,
            "Codigo": 2506004,
            "Nome": "Esperança",
            "Uf": "PB"
        },
        {
            "Id": 1323,
            "Codigo": 2506103,
            "Nome": "Fagundes",
            "Uf": "PB"
        },
        {
            "Id": 1324,
            "Codigo": 2506202,
            "Nome": "Frei Martinho",
            "Uf": "PB"
        },
        {
            "Id": 1325,
            "Codigo": 2506251,
            "Nome": "Gado Bravo",
            "Uf": "PB"
        },
        {
            "Id": 1326,
            "Codigo": 2506301,
            "Nome": "Guarabira",
            "Uf": "PB"
        },
        {
            "Id": 1327,
            "Codigo": 2506400,
            "Nome": "Gurinhém",
            "Uf": "PB"
        },
        {
            "Id": 1328,
            "Codigo": 2506509,
            "Nome": "Gurjão",
            "Uf": "PB"
        },
        {
            "Id": 1329,
            "Codigo": 2506608,
            "Nome": "Ibiara",
            "Uf": "PB"
        },
        {
            "Id": 1330,
            "Codigo": 2506707,
            "Nome": "Imaculada",
            "Uf": "PB"
        },
        {
            "Id": 1331,
            "Codigo": 2506806,
            "Nome": "Ingá",
            "Uf": "PB"
        },
        {
            "Id": 1332,
            "Codigo": 2506905,
            "Nome": "Itabaiana",
            "Uf": "PB"
        },
        {
            "Id": 1333,
            "Codigo": 2507002,
            "Nome": "Itaporanga",
            "Uf": "PB"
        },
        {
            "Id": 1334,
            "Codigo": 2507101,
            "Nome": "Itapororoca",
            "Uf": "PB"
        },
        {
            "Id": 1335,
            "Codigo": 2507200,
            "Nome": "Itatuba",
            "Uf": "PB"
        },
        {
            "Id": 1336,
            "Codigo": 2507309,
            "Nome": "Jacaraú",
            "Uf": "PB"
        },
        {
            "Id": 1337,
            "Codigo": 2507408,
            "Nome": "Jericó",
            "Uf": "PB"
        },
        {
            "Id": 1338,
            "Codigo": 2507507,
            "Nome": "João Pessoa",
            "Uf": "PB"
        },
        {
            "Id": 1339,
            "Codigo": 2507606,
            "Nome": "Juarez Távora",
            "Uf": "PB"
        },
        {
            "Id": 1340,
            "Codigo": 2507705,
            "Nome": "Juazeirinho",
            "Uf": "PB"
        },
        {
            "Id": 1341,
            "Codigo": 2507804,
            "Nome": "Junco do Seridó",
            "Uf": "PB"
        },
        {
            "Id": 1342,
            "Codigo": 2507903,
            "Nome": "Juripiranga",
            "Uf": "PB"
        },
        {
            "Id": 1343,
            "Codigo": 2508000,
            "Nome": "Juru",
            "Uf": "PB"
        },
        {
            "Id": 1344,
            "Codigo": 2508109,
            "Nome": "Lagoa",
            "Uf": "PB"
        },
        {
            "Id": 1345,
            "Codigo": 2508208,
            "Nome": "Lagoa de Dentro",
            "Uf": "PB"
        },
        {
            "Id": 1346,
            "Codigo": 2508307,
            "Nome": "Lagoa Seca",
            "Uf": "PB"
        },
        {
            "Id": 1347,
            "Codigo": 2508406,
            "Nome": "Lastro",
            "Uf": "PB"
        },
        {
            "Id": 1348,
            "Codigo": 2508505,
            "Nome": "Livramento",
            "Uf": "PB"
        },
        {
            "Id": 1349,
            "Codigo": 2508554,
            "Nome": "Logradouro",
            "Uf": "PB"
        },
        {
            "Id": 1350,
            "Codigo": 2508604,
            "Nome": "Lucena",
            "Uf": "PB"
        },
        {
            "Id": 1351,
            "Codigo": 2508703,
            "Nome": "Mãe D'Água",
            "Uf": "PB"
        },
        {
            "Id": 1352,
            "Codigo": 2508802,
            "Nome": "Malta",
            "Uf": "PB"
        },
        {
            "Id": 1353,
            "Codigo": 2508901,
            "Nome": "Mamanguape",
            "Uf": "PB"
        },
        {
            "Id": 1354,
            "Codigo": 2509008,
            "Nome": "Manaíra",
            "Uf": "PB"
        },
        {
            "Id": 1355,
            "Codigo": 2509057,
            "Nome": "Marcação",
            "Uf": "PB"
        },
        {
            "Id": 1356,
            "Codigo": 2509107,
            "Nome": "Mari",
            "Uf": "PB"
        },
        {
            "Id": 1357,
            "Codigo": 2509156,
            "Nome": "Marizópolis",
            "Uf": "PB"
        },
        {
            "Id": 1358,
            "Codigo": 2509206,
            "Nome": "Massaranduba",
            "Uf": "PB"
        },
        {
            "Id": 1359,
            "Codigo": 2509305,
            "Nome": "Mataraca",
            "Uf": "PB"
        },
        {
            "Id": 1360,
            "Codigo": 2509339,
            "Nome": "Matinhas",
            "Uf": "PB"
        },
        {
            "Id": 1361,
            "Codigo": 2509370,
            "Nome": "Mato Grosso",
            "Uf": "PB"
        },
        {
            "Id": 1362,
            "Codigo": 2509396,
            "Nome": "Maturéia",
            "Uf": "PB"
        },
        {
            "Id": 1363,
            "Codigo": 2509404,
            "Nome": "Mogeiro",
            "Uf": "PB"
        },
        {
            "Id": 1364,
            "Codigo": 2509503,
            "Nome": "Montadas",
            "Uf": "PB"
        },
        {
            "Id": 1365,
            "Codigo": 2509602,
            "Nome": "Monte Horebe",
            "Uf": "PB"
        },
        {
            "Id": 1366,
            "Codigo": 2509701,
            "Nome": "Monteiro",
            "Uf": "PB"
        },
        {
            "Id": 1367,
            "Codigo": 2509800,
            "Nome": "Mulungu",
            "Uf": "PB"
        },
        {
            "Id": 1368,
            "Codigo": 2509909,
            "Nome": "Natuba",
            "Uf": "PB"
        },
        {
            "Id": 1369,
            "Codigo": 2510006,
            "Nome": "Nazarezinho",
            "Uf": "PB"
        },
        {
            "Id": 1370,
            "Codigo": 2510105,
            "Nome": "Nova Floresta",
            "Uf": "PB"
        },
        {
            "Id": 1371,
            "Codigo": 2510204,
            "Nome": "Nova Olinda",
            "Uf": "PB"
        },
        {
            "Id": 1372,
            "Codigo": 2510303,
            "Nome": "Nova Palmeira",
            "Uf": "PB"
        },
        {
            "Id": 1373,
            "Codigo": 2510402,
            "Nome": "Olho D'Água",
            "Uf": "PB"
        },
        {
            "Id": 1374,
            "Codigo": 2510501,
            "Nome": "Olivedos",
            "Uf": "PB"
        },
        {
            "Id": 1375,
            "Codigo": 2510600,
            "Nome": "Ouro Velho",
            "Uf": "PB"
        },
        {
            "Id": 1376,
            "Codigo": 2510659,
            "Nome": "Parari",
            "Uf": "PB"
        },
        {
            "Id": 1377,
            "Codigo": 2510709,
            "Nome": "Passagem",
            "Uf": "PB"
        },
        {
            "Id": 1378,
            "Codigo": 2510808,
            "Nome": "Patos",
            "Uf": "PB"
        },
        {
            "Id": 1379,
            "Codigo": 2510907,
            "Nome": "Paulista",
            "Uf": "PB"
        },
        {
            "Id": 1380,
            "Codigo": 2511004,
            "Nome": "Pedra Branca",
            "Uf": "PB"
        },
        {
            "Id": 1381,
            "Codigo": 2511103,
            "Nome": "Pedra Lavrada",
            "Uf": "PB"
        },
        {
            "Id": 1382,
            "Codigo": 2511202,
            "Nome": "Pedras de Fogo",
            "Uf": "PB"
        },
        {
            "Id": 1383,
            "Codigo": 2511301,
            "Nome": "Piancó",
            "Uf": "PB"
        },
        {
            "Id": 1384,
            "Codigo": 2511400,
            "Nome": "Picuí",
            "Uf": "PB"
        },
        {
            "Id": 1385,
            "Codigo": 2511509,
            "Nome": "Pilar",
            "Uf": "PB"
        },
        {
            "Id": 1386,
            "Codigo": 2511608,
            "Nome": "Pilões",
            "Uf": "PB"
        },
        {
            "Id": 1387,
            "Codigo": 2511707,
            "Nome": "Pilõezinhos",
            "Uf": "PB"
        },
        {
            "Id": 1388,
            "Codigo": 2511806,
            "Nome": "Pirpirituba",
            "Uf": "PB"
        },
        {
            "Id": 1389,
            "Codigo": 2511905,
            "Nome": "Pitimbu",
            "Uf": "PB"
        },
        {
            "Id": 1390,
            "Codigo": 2512002,
            "Nome": "Pocinhos",
            "Uf": "PB"
        },
        {
            "Id": 1391,
            "Codigo": 2512036,
            "Nome": "Poço Dantas",
            "Uf": "PB"
        },
        {
            "Id": 1392,
            "Codigo": 2512077,
            "Nome": "Poço de José de Moura",
            "Uf": "PB"
        },
        {
            "Id": 1393,
            "Codigo": 2512101,
            "Nome": "Pombal",
            "Uf": "PB"
        },
        {
            "Id": 1394,
            "Codigo": 2512200,
            "Nome": "Prata",
            "Uf": "PB"
        },
        {
            "Id": 1395,
            "Codigo": 2512309,
            "Nome": "Princesa Isabel",
            "Uf": "PB"
        },
        {
            "Id": 1396,
            "Codigo": 2512408,
            "Nome": "Puxinanã",
            "Uf": "PB"
        },
        {
            "Id": 1397,
            "Codigo": 2512507,
            "Nome": "Queimadas",
            "Uf": "PB"
        },
        {
            "Id": 1398,
            "Codigo": 2512606,
            "Nome": "Quixabá",
            "Uf": "PB"
        },
        {
            "Id": 1399,
            "Codigo": 2512705,
            "Nome": "Remígio",
            "Uf": "PB"
        },
        {
            "Id": 1400,
            "Codigo": 2512721,
            "Nome": "Pedro Régis",
            "Uf": "PB"
        },
        {
            "Id": 1401,
            "Codigo": 2512747,
            "Nome": "Riachão",
            "Uf": "PB"
        },
        {
            "Id": 1402,
            "Codigo": 2512754,
            "Nome": "Riachão do Bacamarte",
            "Uf": "PB"
        },
        {
            "Id": 1403,
            "Codigo": 2512762,
            "Nome": "Riachão do Poço",
            "Uf": "PB"
        },
        {
            "Id": 1404,
            "Codigo": 2512788,
            "Nome": "Riacho de Santo Antônio",
            "Uf": "PB"
        },
        {
            "Id": 1405,
            "Codigo": 2512804,
            "Nome": "Riacho dos Cavalos",
            "Uf": "PB"
        },
        {
            "Id": 1406,
            "Codigo": 2512903,
            "Nome": "Rio Tinto",
            "Uf": "PB"
        },
        {
            "Id": 1407,
            "Codigo": 2513000,
            "Nome": "Salgadinho",
            "Uf": "PB"
        },
        {
            "Id": 1408,
            "Codigo": 2513109,
            "Nome": "Salgado de São Félix",
            "Uf": "PB"
        },
        {
            "Id": 1409,
            "Codigo": 2513158,
            "Nome": "Santa Cecília",
            "Uf": "PB"
        },
        {
            "Id": 1410,
            "Codigo": 2513208,
            "Nome": "Santa Cruz",
            "Uf": "PB"
        },
        {
            "Id": 1411,
            "Codigo": 2513307,
            "Nome": "Santa Helena",
            "Uf": "PB"
        },
        {
            "Id": 1412,
            "Codigo": 2513356,
            "Nome": "Santa Inês",
            "Uf": "PB"
        },
        {
            "Id": 1413,
            "Codigo": 2513406,
            "Nome": "Santa Luzia",
            "Uf": "PB"
        },
        {
            "Id": 1414,
            "Codigo": 2513505,
            "Nome": "Santana de Mangueira",
            "Uf": "PB"
        },
        {
            "Id": 1415,
            "Codigo": 2513604,
            "Nome": "Santana dos Garrotes",
            "Uf": "PB"
        },
        {
            "Id": 1416,
            "Codigo": 2513653,
            "Nome": "Joca Claudino",
            "Uf": "PB"
        },
        {
            "Id": 1417,
            "Codigo": 2513703,
            "Nome": "Santa Rita",
            "Uf": "PB"
        },
        {
            "Id": 1418,
            "Codigo": 2513802,
            "Nome": "Santa Teresinha",
            "Uf": "PB"
        },
        {
            "Id": 1419,
            "Codigo": 2513851,
            "Nome": "Santo André",
            "Uf": "PB"
        },
        {
            "Id": 1420,
            "Codigo": 2513901,
            "Nome": "São Bento",
            "Uf": "PB"
        },
        {
            "Id": 1421,
            "Codigo": 2513927,
            "Nome": "São Bentinho",
            "Uf": "PB"
        },
        {
            "Id": 1422,
            "Codigo": 2513943,
            "Nome": "São Domingos do Cariri",
            "Uf": "PB"
        },
        {
            "Id": 1423,
            "Codigo": 2513968,
            "Nome": "São Domingos",
            "Uf": "PB"
        },
        {
            "Id": 1424,
            "Codigo": 2513984,
            "Nome": "São Francisco",
            "Uf": "PB"
        },
        {
            "Id": 1425,
            "Codigo": 2514008,
            "Nome": "São João do Cariri",
            "Uf": "PB"
        },
        {
            "Id": 1426,
            "Codigo": 2514107,
            "Nome": "São João do Tigre",
            "Uf": "PB"
        },
        {
            "Id": 1427,
            "Codigo": 2514206,
            "Nome": "São José da Lagoa Tapada",
            "Uf": "PB"
        },
        {
            "Id": 1428,
            "Codigo": 2514305,
            "Nome": "São José de Caiana",
            "Uf": "PB"
        },
        {
            "Id": 1429,
            "Codigo": 2514404,
            "Nome": "São José de Espinharas",
            "Uf": "PB"
        },
        {
            "Id": 1430,
            "Codigo": 2514453,
            "Nome": "São José dos Ramos",
            "Uf": "PB"
        },
        {
            "Id": 1431,
            "Codigo": 2514503,
            "Nome": "São José de Piranhas",
            "Uf": "PB"
        },
        {
            "Id": 1432,
            "Codigo": 2514552,
            "Nome": "São José de Princesa",
            "Uf": "PB"
        },
        {
            "Id": 1433,
            "Codigo": 2514602,
            "Nome": "São José do Bonfim",
            "Uf": "PB"
        },
        {
            "Id": 1434,
            "Codigo": 2514651,
            "Nome": "São José do Brejo do Cruz",
            "Uf": "PB"
        },
        {
            "Id": 1435,
            "Codigo": 2514701,
            "Nome": "São José do Sabugi",
            "Uf": "PB"
        },
        {
            "Id": 1436,
            "Codigo": 2514800,
            "Nome": "São José dos Cordeiros",
            "Uf": "PB"
        },
        {
            "Id": 1437,
            "Codigo": 2514909,
            "Nome": "São Mamede",
            "Uf": "PB"
        },
        {
            "Id": 1438,
            "Codigo": 2515005,
            "Nome": "São Miguel de Taipu",
            "Uf": "PB"
        },
        {
            "Id": 1439,
            "Codigo": 2515104,
            "Nome": "São Sebastião de Lagoa de Roça",
            "Uf": "PB"
        },
        {
            "Id": 1440,
            "Codigo": 2515203,
            "Nome": "São Sebastião do Umbuzeiro",
            "Uf": "PB"
        },
        {
            "Id": 1441,
            "Codigo": 2515302,
            "Nome": "Sapé",
            "Uf": "PB"
        },
        {
            "Id": 1442,
            "Codigo": 2515401,
            "Nome": "São Vicente do Seridó",
            "Uf": "PB"
        },
        {
            "Id": 1443,
            "Codigo": 2515500,
            "Nome": "Serra Branca",
            "Uf": "PB"
        },
        {
            "Id": 1444,
            "Codigo": 2515609,
            "Nome": "Serra da Raiz",
            "Uf": "PB"
        },
        {
            "Id": 1445,
            "Codigo": 2515708,
            "Nome": "Serra Grande",
            "Uf": "PB"
        },
        {
            "Id": 1446,
            "Codigo": 2515807,
            "Nome": "Serra Redonda",
            "Uf": "PB"
        },
        {
            "Id": 1447,
            "Codigo": 2515906,
            "Nome": "Serraria",
            "Uf": "PB"
        },
        {
            "Id": 1448,
            "Codigo": 2515930,
            "Nome": "Sertãozinho",
            "Uf": "PB"
        },
        {
            "Id": 1449,
            "Codigo": 2515971,
            "Nome": "Sobrado",
            "Uf": "PB"
        },
        {
            "Id": 1450,
            "Codigo": 2516003,
            "Nome": "Solânea",
            "Uf": "PB"
        },
        {
            "Id": 1451,
            "Codigo": 2516102,
            "Nome": "Soledade",
            "Uf": "PB"
        },
        {
            "Id": 1452,
            "Codigo": 2516151,
            "Nome": "Sossêgo",
            "Uf": "PB"
        },
        {
            "Id": 1453,
            "Codigo": 2516201,
            "Nome": "Sousa",
            "Uf": "PB"
        },
        {
            "Id": 1454,
            "Codigo": 2516300,
            "Nome": "Sumé",
            "Uf": "PB"
        },
        {
            "Id": 1455,
            "Codigo": 2516409,
            "Nome": "Tacima",
            "Uf": "PB"
        },
        {
            "Id": 1456,
            "Codigo": 2516508,
            "Nome": "Taperoá",
            "Uf": "PB"
        },
        {
            "Id": 1457,
            "Codigo": 2516607,
            "Nome": "Tavares",
            "Uf": "PB"
        },
        {
            "Id": 1458,
            "Codigo": 2516706,
            "Nome": "Teixeira",
            "Uf": "PB"
        },
        {
            "Id": 1459,
            "Codigo": 2516755,
            "Nome": "Tenório",
            "Uf": "PB"
        },
        {
            "Id": 1460,
            "Codigo": 2516805,
            "Nome": "Triunfo",
            "Uf": "PB"
        },
        {
            "Id": 1461,
            "Codigo": 2516904,
            "Nome": "Uiraúna",
            "Uf": "PB"
        },
        {
            "Id": 1462,
            "Codigo": 2517001,
            "Nome": "Umbuzeiro",
            "Uf": "PB"
        },
        {
            "Id": 1463,
            "Codigo": 2517100,
            "Nome": "Várzea",
            "Uf": "PB"
        },
        {
            "Id": 1464,
            "Codigo": 2517209,
            "Nome": "Vieirópolis",
            "Uf": "PB"
        },
        {
            "Id": 1465,
            "Codigo": 2517407,
            "Nome": "Zabelê",
            "Uf": "PB"
        },
        {
            "Id": 1466,
            "Codigo": 2600054,
            "Nome": "Abreu e Lima",
            "Uf": "PE"
        },
        {
            "Id": 1467,
            "Codigo": 2600104,
            "Nome": "Afogados da Ingazeira",
            "Uf": "PE"
        },
        {
            "Id": 1468,
            "Codigo": 2600203,
            "Nome": "Afrânio",
            "Uf": "PE"
        },
        {
            "Id": 1469,
            "Codigo": 2600302,
            "Nome": "Agrestina",
            "Uf": "PE"
        },
        {
            "Id": 1470,
            "Codigo": 2600401,
            "Nome": "Água Preta",
            "Uf": "PE"
        },
        {
            "Id": 1471,
            "Codigo": 2600500,
            "Nome": "Águas Belas",
            "Uf": "PE"
        },
        {
            "Id": 1472,
            "Codigo": 2600609,
            "Nome": "Alagoinha",
            "Uf": "PE"
        },
        {
            "Id": 1473,
            "Codigo": 2600708,
            "Nome": "Aliança",
            "Uf": "PE"
        },
        {
            "Id": 1474,
            "Codigo": 2600807,
            "Nome": "Altinho",
            "Uf": "PE"
        },
        {
            "Id": 1475,
            "Codigo": 2600906,
            "Nome": "Amaraji",
            "Uf": "PE"
        },
        {
            "Id": 1476,
            "Codigo": 2601003,
            "Nome": "Angelim",
            "Uf": "PE"
        },
        {
            "Id": 1477,
            "Codigo": 2601052,
            "Nome": "Araçoiaba",
            "Uf": "PE"
        },
        {
            "Id": 1478,
            "Codigo": 2601102,
            "Nome": "Araripina",
            "Uf": "PE"
        },
        {
            "Id": 1479,
            "Codigo": 2601201,
            "Nome": "Arcoverde",
            "Uf": "PE"
        },
        {
            "Id": 1480,
            "Codigo": 2601300,
            "Nome": "Barra de Guabiraba",
            "Uf": "PE"
        },
        {
            "Id": 1481,
            "Codigo": 2601409,
            "Nome": "Barreiros",
            "Uf": "PE"
        },
        {
            "Id": 1482,
            "Codigo": 2601508,
            "Nome": "Belém de Maria",
            "Uf": "PE"
        },
        {
            "Id": 1483,
            "Codigo": 2601607,
            "Nome": "Belém do São Francisco",
            "Uf": "PE"
        },
        {
            "Id": 1484,
            "Codigo": 2601706,
            "Nome": "Belo Jardim",
            "Uf": "PE"
        },
        {
            "Id": 1485,
            "Codigo": 2601805,
            "Nome": "Betânia",
            "Uf": "PE"
        },
        {
            "Id": 1486,
            "Codigo": 2601904,
            "Nome": "Bezerros",
            "Uf": "PE"
        },
        {
            "Id": 1487,
            "Codigo": 2602001,
            "Nome": "Bodocó",
            "Uf": "PE"
        },
        {
            "Id": 1488,
            "Codigo": 2602100,
            "Nome": "Bom Conselho",
            "Uf": "PE"
        },
        {
            "Id": 1489,
            "Codigo": 2602209,
            "Nome": "Bom Jardim",
            "Uf": "PE"
        },
        {
            "Id": 1490,
            "Codigo": 2602308,
            "Nome": "Bonito",
            "Uf": "PE"
        },
        {
            "Id": 1491,
            "Codigo": 2602407,
            "Nome": "Brejão",
            "Uf": "PE"
        },
        {
            "Id": 1492,
            "Codigo": 2602506,
            "Nome": "Brejinho",
            "Uf": "PE"
        },
        {
            "Id": 1493,
            "Codigo": 2602605,
            "Nome": "Brejo da Madre de Deus",
            "Uf": "PE"
        },
        {
            "Id": 1494,
            "Codigo": 2602704,
            "Nome": "Buenos Aires",
            "Uf": "PE"
        },
        {
            "Id": 1495,
            "Codigo": 2602803,
            "Nome": "Buíque",
            "Uf": "PE"
        },
        {
            "Id": 1496,
            "Codigo": 2602902,
            "Nome": "Cabo de Santo Agostinho",
            "Uf": "PE"
        },
        {
            "Id": 1497,
            "Codigo": 2603009,
            "Nome": "Cabrobó",
            "Uf": "PE"
        },
        {
            "Id": 1498,
            "Codigo": 2603108,
            "Nome": "Cachoeirinha",
            "Uf": "PE"
        },
        {
            "Id": 1499,
            "Codigo": 2603207,
            "Nome": "Caetés",
            "Uf": "PE"
        },
        {
            "Id": 1500,
            "Codigo": 2603306,
            "Nome": "Calçado",
            "Uf": "PE"
        },
        {
            "Id": 1501,
            "Codigo": 2603405,
            "Nome": "Calumbi",
            "Uf": "PE"
        },
        {
            "Id": 1502,
            "Codigo": 2603454,
            "Nome": "Camaragibe",
            "Uf": "PE"
        },
        {
            "Id": 1503,
            "Codigo": 2603504,
            "Nome": "Camocim de São Félix",
            "Uf": "PE"
        },
        {
            "Id": 1504,
            "Codigo": 2603603,
            "Nome": "Camutanga",
            "Uf": "PE"
        },
        {
            "Id": 1505,
            "Codigo": 2603702,
            "Nome": "Canhotinho",
            "Uf": "PE"
        },
        {
            "Id": 1506,
            "Codigo": 2603801,
            "Nome": "Capoeiras",
            "Uf": "PE"
        },
        {
            "Id": 1507,
            "Codigo": 2603900,
            "Nome": "Carnaíba",
            "Uf": "PE"
        },
        {
            "Id": 1508,
            "Codigo": 2603926,
            "Nome": "Carnaubeira da Penha",
            "Uf": "PE"
        },
        {
            "Id": 1509,
            "Codigo": 2604007,
            "Nome": "Carpina",
            "Uf": "PE"
        },
        {
            "Id": 1510,
            "Codigo": 2604106,
            "Nome": "Caruaru",
            "Uf": "PE"
        },
        {
            "Id": 1511,
            "Codigo": 2604155,
            "Nome": "Casinhas",
            "Uf": "PE"
        },
        {
            "Id": 1512,
            "Codigo": 2604205,
            "Nome": "Catende",
            "Uf": "PE"
        },
        {
            "Id": 1513,
            "Codigo": 2604304,
            "Nome": "Cedro",
            "Uf": "PE"
        },
        {
            "Id": 1514,
            "Codigo": 2604403,
            "Nome": "Chã de Alegria",
            "Uf": "PE"
        },
        {
            "Id": 1515,
            "Codigo": 2604502,
            "Nome": "Chã Grande",
            "Uf": "PE"
        },
        {
            "Id": 1516,
            "Codigo": 2604601,
            "Nome": "Condado",
            "Uf": "PE"
        },
        {
            "Id": 1517,
            "Codigo": 2604700,
            "Nome": "Correntes",
            "Uf": "PE"
        },
        {
            "Id": 1518,
            "Codigo": 2604809,
            "Nome": "Cortês",
            "Uf": "PE"
        },
        {
            "Id": 1519,
            "Codigo": 2604908,
            "Nome": "Cumaru",
            "Uf": "PE"
        },
        {
            "Id": 1520,
            "Codigo": 2605004,
            "Nome": "Cupira",
            "Uf": "PE"
        },
        {
            "Id": 1521,
            "Codigo": 2605103,
            "Nome": "Custódia",
            "Uf": "PE"
        },
        {
            "Id": 1522,
            "Codigo": 2605152,
            "Nome": "Dormentes",
            "Uf": "PE"
        },
        {
            "Id": 1523,
            "Codigo": 2605202,
            "Nome": "Escada",
            "Uf": "PE"
        },
        {
            "Id": 1524,
            "Codigo": 2605301,
            "Nome": "Exu",
            "Uf": "PE"
        },
        {
            "Id": 1525,
            "Codigo": 2605400,
            "Nome": "Feira Nova",
            "Uf": "PE"
        },
        {
            "Id": 1526,
            "Codigo": 2605459,
            "Nome": "Fernando de Noronha",
            "Uf": "PE"
        },
        {
            "Id": 1527,
            "Codigo": 2605509,
            "Nome": "Ferreiros",
            "Uf": "PE"
        },
        {
            "Id": 1528,
            "Codigo": 2605608,
            "Nome": "Flores",
            "Uf": "PE"
        },
        {
            "Id": 1529,
            "Codigo": 2605707,
            "Nome": "Floresta",
            "Uf": "PE"
        },
        {
            "Id": 1530,
            "Codigo": 2605806,
            "Nome": "Frei Miguelinho",
            "Uf": "PE"
        },
        {
            "Id": 1531,
            "Codigo": 2605905,
            "Nome": "Gameleira",
            "Uf": "PE"
        },
        {
            "Id": 1532,
            "Codigo": 2606002,
            "Nome": "Garanhuns",
            "Uf": "PE"
        },
        {
            "Id": 1533,
            "Codigo": 2606101,
            "Nome": "Glória do Goitá",
            "Uf": "PE"
        },
        {
            "Id": 1534,
            "Codigo": 2606200,
            "Nome": "Goiana",
            "Uf": "PE"
        },
        {
            "Id": 1535,
            "Codigo": 2606309,
            "Nome": "Granito",
            "Uf": "PE"
        },
        {
            "Id": 1536,
            "Codigo": 2606408,
            "Nome": "Gravatá",
            "Uf": "PE"
        },
        {
            "Id": 1537,
            "Codigo": 2606507,
            "Nome": "Iati",
            "Uf": "PE"
        },
        {
            "Id": 1538,
            "Codigo": 2606606,
            "Nome": "Ibimirim",
            "Uf": "PE"
        },
        {
            "Id": 1539,
            "Codigo": 2606705,
            "Nome": "Ibirajuba",
            "Uf": "PE"
        },
        {
            "Id": 1540,
            "Codigo": 2606804,
            "Nome": "Igarassu",
            "Uf": "PE"
        },
        {
            "Id": 1541,
            "Codigo": 2606903,
            "Nome": "Iguaraci",
            "Uf": "PE"
        },
        {
            "Id": 1542,
            "Codigo": 2607000,
            "Nome": "Inajá",
            "Uf": "PE"
        },
        {
            "Id": 1543,
            "Codigo": 2607109,
            "Nome": "Ingazeira",
            "Uf": "PE"
        },
        {
            "Id": 1544,
            "Codigo": 2607208,
            "Nome": "Ipojuca",
            "Uf": "PE"
        },
        {
            "Id": 1545,
            "Codigo": 2607307,
            "Nome": "Ipubi",
            "Uf": "PE"
        },
        {
            "Id": 1546,
            "Codigo": 2607406,
            "Nome": "Itacuruba",
            "Uf": "PE"
        },
        {
            "Id": 1547,
            "Codigo": 2607505,
            "Nome": "Itaíba",
            "Uf": "PE"
        },
        {
            "Id": 1548,
            "Codigo": 2607604,
            "Nome": "Ilha de Itamaracá",
            "Uf": "PE"
        },
        {
            "Id": 1549,
            "Codigo": 2607653,
            "Nome": "Itambé",
            "Uf": "PE"
        },
        {
            "Id": 1550,
            "Codigo": 2607703,
            "Nome": "Itapetim",
            "Uf": "PE"
        },
        {
            "Id": 1551,
            "Codigo": 2607752,
            "Nome": "Itapissuma",
            "Uf": "PE"
        },
        {
            "Id": 1552,
            "Codigo": 2607802,
            "Nome": "Itaquitinga",
            "Uf": "PE"
        },
        {
            "Id": 1553,
            "Codigo": 2607901,
            "Nome": "Jaboatão dos Guararapes",
            "Uf": "PE"
        },
        {
            "Id": 1554,
            "Codigo": 2607950,
            "Nome": "Jaqueira",
            "Uf": "PE"
        },
        {
            "Id": 1555,
            "Codigo": 2608008,
            "Nome": "Jataúba",
            "Uf": "PE"
        },
        {
            "Id": 1556,
            "Codigo": 2608057,
            "Nome": "Jatobá",
            "Uf": "PE"
        },
        {
            "Id": 1557,
            "Codigo": 2608107,
            "Nome": "João Alfredo",
            "Uf": "PE"
        },
        {
            "Id": 1558,
            "Codigo": 2608206,
            "Nome": "Joaquim Nabuco",
            "Uf": "PE"
        },
        {
            "Id": 1559,
            "Codigo": 2608255,
            "Nome": "Jucati",
            "Uf": "PE"
        },
        {
            "Id": 1560,
            "Codigo": 2608305,
            "Nome": "Jupi",
            "Uf": "PE"
        },
        {
            "Id": 1561,
            "Codigo": 2608404,
            "Nome": "Jurema",
            "Uf": "PE"
        },
        {
            "Id": 1562,
            "Codigo": 2608453,
            "Nome": "Lagoa do Carro",
            "Uf": "PE"
        },
        {
            "Id": 1563,
            "Codigo": 2608503,
            "Nome": "Lagoa de Itaenga",
            "Uf": "PE"
        },
        {
            "Id": 1564,
            "Codigo": 2608602,
            "Nome": "Lagoa do Ouro",
            "Uf": "PE"
        },
        {
            "Id": 1565,
            "Codigo": 2608701,
            "Nome": "Lagoa dos Gatos",
            "Uf": "PE"
        },
        {
            "Id": 1566,
            "Codigo": 2608750,
            "Nome": "Lagoa Grande",
            "Uf": "PE"
        },
        {
            "Id": 1567,
            "Codigo": 2608800,
            "Nome": "Lajedo",
            "Uf": "PE"
        },
        {
            "Id": 1568,
            "Codigo": 2608909,
            "Nome": "Limoeiro",
            "Uf": "PE"
        },
        {
            "Id": 1569,
            "Codigo": 2609006,
            "Nome": "Macaparana",
            "Uf": "PE"
        },
        {
            "Id": 1570,
            "Codigo": 2609105,
            "Nome": "Machados",
            "Uf": "PE"
        },
        {
            "Id": 1571,
            "Codigo": 2609154,
            "Nome": "Manari",
            "Uf": "PE"
        },
        {
            "Id": 1572,
            "Codigo": 2609204,
            "Nome": "Maraial",
            "Uf": "PE"
        },
        {
            "Id": 1573,
            "Codigo": 2609303,
            "Nome": "Mirandiba",
            "Uf": "PE"
        },
        {
            "Id": 1574,
            "Codigo": 2609402,
            "Nome": "Moreno",
            "Uf": "PE"
        },
        {
            "Id": 1575,
            "Codigo": 2609501,
            "Nome": "Nazaré da Mata",
            "Uf": "PE"
        },
        {
            "Id": 1576,
            "Codigo": 2609600,
            "Nome": "Olinda",
            "Uf": "PE"
        },
        {
            "Id": 1577,
            "Codigo": 2609709,
            "Nome": "Orobó",
            "Uf": "PE"
        },
        {
            "Id": 1578,
            "Codigo": 2609808,
            "Nome": "Orocó",
            "Uf": "PE"
        },
        {
            "Id": 1579,
            "Codigo": 2609907,
            "Nome": "Ouricuri",
            "Uf": "PE"
        },
        {
            "Id": 1580,
            "Codigo": 2610004,
            "Nome": "Palmares",
            "Uf": "PE"
        },
        {
            "Id": 1581,
            "Codigo": 2610103,
            "Nome": "Palmeirina",
            "Uf": "PE"
        },
        {
            "Id": 1582,
            "Codigo": 2610202,
            "Nome": "Panelas",
            "Uf": "PE"
        },
        {
            "Id": 1583,
            "Codigo": 2610301,
            "Nome": "Paranatama",
            "Uf": "PE"
        },
        {
            "Id": 1584,
            "Codigo": 2610400,
            "Nome": "Parnamirim",
            "Uf": "PE"
        },
        {
            "Id": 1585,
            "Codigo": 2610509,
            "Nome": "Passira",
            "Uf": "PE"
        },
        {
            "Id": 1586,
            "Codigo": 2610608,
            "Nome": "Paudalho",
            "Uf": "PE"
        },
        {
            "Id": 1587,
            "Codigo": 2610707,
            "Nome": "Paulista",
            "Uf": "PE"
        },
        {
            "Id": 1588,
            "Codigo": 2610806,
            "Nome": "Pedra",
            "Uf": "PE"
        },
        {
            "Id": 1589,
            "Codigo": 2610905,
            "Nome": "Pesqueira",
            "Uf": "PE"
        },
        {
            "Id": 1590,
            "Codigo": 2611002,
            "Nome": "Petrolândia",
            "Uf": "PE"
        },
        {
            "Id": 1591,
            "Codigo": 2611101,
            "Nome": "Petrolina",
            "Uf": "PE"
        },
        {
            "Id": 1592,
            "Codigo": 2611200,
            "Nome": "Poção",
            "Uf": "PE"
        },
        {
            "Id": 1593,
            "Codigo": 2611309,
            "Nome": "Pombos",
            "Uf": "PE"
        },
        {
            "Id": 1594,
            "Codigo": 2611408,
            "Nome": "Primavera",
            "Uf": "PE"
        },
        {
            "Id": 1595,
            "Codigo": 2611507,
            "Nome": "Quipapá",
            "Uf": "PE"
        },
        {
            "Id": 1596,
            "Codigo": 2611533,
            "Nome": "Quixaba",
            "Uf": "PE"
        },
        {
            "Id": 1597,
            "Codigo": 2611606,
            "Nome": "Recife",
            "Uf": "PE"
        },
        {
            "Id": 1598,
            "Codigo": 2611705,
            "Nome": "Riacho das Almas",
            "Uf": "PE"
        },
        {
            "Id": 1599,
            "Codigo": 2611804,
            "Nome": "Ribeirão",
            "Uf": "PE"
        },
        {
            "Id": 1600,
            "Codigo": 2611903,
            "Nome": "Rio Formoso",
            "Uf": "PE"
        },
        {
            "Id": 1601,
            "Codigo": 2612000,
            "Nome": "Sairé",
            "Uf": "PE"
        },
        {
            "Id": 1602,
            "Codigo": 2612109,
            "Nome": "Salgadinho",
            "Uf": "PE"
        },
        {
            "Id": 1603,
            "Codigo": 2612208,
            "Nome": "Salgueiro",
            "Uf": "PE"
        },
        {
            "Id": 1604,
            "Codigo": 2612307,
            "Nome": "Saloá",
            "Uf": "PE"
        },
        {
            "Id": 1605,
            "Codigo": 2612406,
            "Nome": "Sanharó",
            "Uf": "PE"
        },
        {
            "Id": 1606,
            "Codigo": 2612455,
            "Nome": "Santa Cruz",
            "Uf": "PE"
        },
        {
            "Id": 1607,
            "Codigo": 2612471,
            "Nome": "Santa Cruz da Baixa Verde",
            "Uf": "PE"
        },
        {
            "Id": 1608,
            "Codigo": 2612505,
            "Nome": "Santa Cruz do Capibaribe",
            "Uf": "PE"
        },
        {
            "Id": 1609,
            "Codigo": 2612554,
            "Nome": "Santa Filomena",
            "Uf": "PE"
        },
        {
            "Id": 1610,
            "Codigo": 2612604,
            "Nome": "Santa Maria da Boa Vista",
            "Uf": "PE"
        },
        {
            "Id": 1611,
            "Codigo": 2612703,
            "Nome": "Santa Maria do Cambucá",
            "Uf": "PE"
        },
        {
            "Id": 1612,
            "Codigo": 2612802,
            "Nome": "Santa Terezinha",
            "Uf": "PE"
        },
        {
            "Id": 1613,
            "Codigo": 2612901,
            "Nome": "São Benedito do Sul",
            "Uf": "PE"
        },
        {
            "Id": 1614,
            "Codigo": 2613008,
            "Nome": "São Bento do Una",
            "Uf": "PE"
        },
        {
            "Id": 1615,
            "Codigo": 2613107,
            "Nome": "São Caitano",
            "Uf": "PE"
        },
        {
            "Id": 1616,
            "Codigo": 2613206,
            "Nome": "São João",
            "Uf": "PE"
        },
        {
            "Id": 1617,
            "Codigo": 2613305,
            "Nome": "São Joaquim do Monte",
            "Uf": "PE"
        },
        {
            "Id": 1618,
            "Codigo": 2613404,
            "Nome": "São José da Coroa Grande",
            "Uf": "PE"
        },
        {
            "Id": 1619,
            "Codigo": 2613503,
            "Nome": "São José do Belmonte",
            "Uf": "PE"
        },
        {
            "Id": 1620,
            "Codigo": 2613602,
            "Nome": "São José do Egito",
            "Uf": "PE"
        },
        {
            "Id": 1621,
            "Codigo": 2613701,
            "Nome": "São Lourenço da Mata",
            "Uf": "PE"
        },
        {
            "Id": 1622,
            "Codigo": 2613800,
            "Nome": "São Vicente Ferrer",
            "Uf": "PE"
        },
        {
            "Id": 1623,
            "Codigo": 2613909,
            "Nome": "Serra Talhada",
            "Uf": "PE"
        },
        {
            "Id": 1624,
            "Codigo": 2614006,
            "Nome": "Serrita",
            "Uf": "PE"
        },
        {
            "Id": 1625,
            "Codigo": 2614105,
            "Nome": "Sertânia",
            "Uf": "PE"
        },
        {
            "Id": 1626,
            "Codigo": 2614204,
            "Nome": "Sirinhaém",
            "Uf": "PE"
        },
        {
            "Id": 1627,
            "Codigo": 2614303,
            "Nome": "Moreilândia",
            "Uf": "PE"
        },
        {
            "Id": 1628,
            "Codigo": 2614402,
            "Nome": "Solidão",
            "Uf": "PE"
        },
        {
            "Id": 1629,
            "Codigo": 2614501,
            "Nome": "Surubim",
            "Uf": "PE"
        },
        {
            "Id": 1630,
            "Codigo": 2614600,
            "Nome": "Tabira",
            "Uf": "PE"
        },
        {
            "Id": 1631,
            "Codigo": 2614709,
            "Nome": "Tacaimbó",
            "Uf": "PE"
        },
        {
            "Id": 1632,
            "Codigo": 2614808,
            "Nome": "Tacaratu",
            "Uf": "PE"
        },
        {
            "Id": 1633,
            "Codigo": 2614857,
            "Nome": "Tamandaré",
            "Uf": "PE"
        },
        {
            "Id": 1634,
            "Codigo": 2615003,
            "Nome": "Taquaritinga do Norte",
            "Uf": "PE"
        },
        {
            "Id": 1635,
            "Codigo": 2615102,
            "Nome": "Terezinha",
            "Uf": "PE"
        },
        {
            "Id": 1636,
            "Codigo": 2615201,
            "Nome": "Terra Nova",
            "Uf": "PE"
        },
        {
            "Id": 1637,
            "Codigo": 2615300,
            "Nome": "Timbaúba",
            "Uf": "PE"
        },
        {
            "Id": 1638,
            "Codigo": 2615409,
            "Nome": "Toritama",
            "Uf": "PE"
        },
        {
            "Id": 1639,
            "Codigo": 2615508,
            "Nome": "Tracunhaém",
            "Uf": "PE"
        },
        {
            "Id": 1640,
            "Codigo": 2615607,
            "Nome": "Trindade",
            "Uf": "PE"
        },
        {
            "Id": 1641,
            "Codigo": 2615706,
            "Nome": "Triunfo",
            "Uf": "PE"
        },
        {
            "Id": 1642,
            "Codigo": 2615805,
            "Nome": "Tupanatinga",
            "Uf": "PE"
        },
        {
            "Id": 1643,
            "Codigo": 2615904,
            "Nome": "Tuparetama",
            "Uf": "PE"
        },
        {
            "Id": 1644,
            "Codigo": 2616001,
            "Nome": "Venturosa",
            "Uf": "PE"
        },
        {
            "Id": 1645,
            "Codigo": 2616100,
            "Nome": "Verdejante",
            "Uf": "PE"
        },
        {
            "Id": 1646,
            "Codigo": 2616183,
            "Nome": "Vertente do Lério",
            "Uf": "PE"
        },
        {
            "Id": 1647,
            "Codigo": 2616209,
            "Nome": "Vertentes",
            "Uf": "PE"
        },
        {
            "Id": 1648,
            "Codigo": 2616308,
            "Nome": "Vicência",
            "Uf": "PE"
        },
        {
            "Id": 1649,
            "Codigo": 2616407,
            "Nome": "Vitória de Santo Antão",
            "Uf": "PE"
        },
        {
            "Id": 1650,
            "Codigo": 2616506,
            "Nome": "Xexéu",
            "Uf": "PE"
        },
        {
            "Id": 1651,
            "Codigo": 2700102,
            "Nome": "Água Branca",
            "Uf": "AL"
        },
        {
            "Id": 1652,
            "Codigo": 2700201,
            "Nome": "Anadia",
            "Uf": "AL"
        },
        {
            "Id": 1653,
            "Codigo": 2700300,
            "Nome": "Arapiraca",
            "Uf": "AL"
        },
        {
            "Id": 1654,
            "Codigo": 2700409,
            "Nome": "Atalaia",
            "Uf": "AL"
        },
        {
            "Id": 1655,
            "Codigo": 2700508,
            "Nome": "Barra de Santo Antônio",
            "Uf": "AL"
        },
        {
            "Id": 1656,
            "Codigo": 2700607,
            "Nome": "Barra de São Miguel",
            "Uf": "AL"
        },
        {
            "Id": 1657,
            "Codigo": 2700706,
            "Nome": "Batalha",
            "Uf": "AL"
        },
        {
            "Id": 1658,
            "Codigo": 2700805,
            "Nome": "Belém",
            "Uf": "AL"
        },
        {
            "Id": 1659,
            "Codigo": 2700904,
            "Nome": "Belo Monte",
            "Uf": "AL"
        },
        {
            "Id": 1660,
            "Codigo": 2701001,
            "Nome": "Boca da Mata",
            "Uf": "AL"
        },
        {
            "Id": 1661,
            "Codigo": 2701100,
            "Nome": "Branquinha",
            "Uf": "AL"
        },
        {
            "Id": 1662,
            "Codigo": 2701209,
            "Nome": "Cacimbinhas",
            "Uf": "AL"
        },
        {
            "Id": 1663,
            "Codigo": 2701308,
            "Nome": "Cajueiro",
            "Uf": "AL"
        },
        {
            "Id": 1664,
            "Codigo": 2701357,
            "Nome": "Campestre",
            "Uf": "AL"
        },
        {
            "Id": 1665,
            "Codigo": 2701407,
            "Nome": "Campo Alegre",
            "Uf": "AL"
        },
        {
            "Id": 1666,
            "Codigo": 2701506,
            "Nome": "Campo Grande",
            "Uf": "AL"
        },
        {
            "Id": 1667,
            "Codigo": 2701605,
            "Nome": "Canapi",
            "Uf": "AL"
        },
        {
            "Id": 1668,
            "Codigo": 2701704,
            "Nome": "Capela",
            "Uf": "AL"
        },
        {
            "Id": 1669,
            "Codigo": 2701803,
            "Nome": "Carneiros",
            "Uf": "AL"
        },
        {
            "Id": 1670,
            "Codigo": 2701902,
            "Nome": "Chã Preta",
            "Uf": "AL"
        },
        {
            "Id": 1671,
            "Codigo": 2702009,
            "Nome": "Coité do Nóia",
            "Uf": "AL"
        },
        {
            "Id": 1672,
            "Codigo": 2702108,
            "Nome": "Colônia Leopoldina",
            "Uf": "AL"
        },
        {
            "Id": 1673,
            "Codigo": 2702207,
            "Nome": "Coqueiro Seco",
            "Uf": "AL"
        },
        {
            "Id": 1674,
            "Codigo": 2702306,
            "Nome": "Coruripe",
            "Uf": "AL"
        },
        {
            "Id": 1675,
            "Codigo": 2702355,
            "Nome": "Craíbas",
            "Uf": "AL"
        },
        {
            "Id": 1676,
            "Codigo": 2702405,
            "Nome": "Delmiro Gouveia",
            "Uf": "AL"
        },
        {
            "Id": 1677,
            "Codigo": 2702504,
            "Nome": "Dois Riachos",
            "Uf": "AL"
        },
        {
            "Id": 1678,
            "Codigo": 2702553,
            "Nome": "Estrela de Alagoas",
            "Uf": "AL"
        },
        {
            "Id": 1679,
            "Codigo": 2702603,
            "Nome": "Feira Grande",
            "Uf": "AL"
        },
        {
            "Id": 1680,
            "Codigo": 2702702,
            "Nome": "Feliz Deserto",
            "Uf": "AL"
        },
        {
            "Id": 1681,
            "Codigo": 2702801,
            "Nome": "Flexeiras",
            "Uf": "AL"
        },
        {
            "Id": 1682,
            "Codigo": 2702900,
            "Nome": "Girau do Ponciano",
            "Uf": "AL"
        },
        {
            "Id": 1683,
            "Codigo": 2703007,
            "Nome": "Ibateguara",
            "Uf": "AL"
        },
        {
            "Id": 1684,
            "Codigo": 2703106,
            "Nome": "Igaci",
            "Uf": "AL"
        },
        {
            "Id": 1685,
            "Codigo": 2703205,
            "Nome": "Igreja Nova",
            "Uf": "AL"
        },
        {
            "Id": 1686,
            "Codigo": 2703304,
            "Nome": "Inhapi",
            "Uf": "AL"
        },
        {
            "Id": 1687,
            "Codigo": 2703403,
            "Nome": "Jacaré dos Homens",
            "Uf": "AL"
        },
        {
            "Id": 1688,
            "Codigo": 2703502,
            "Nome": "Jacuípe",
            "Uf": "AL"
        },
        {
            "Id": 1689,
            "Codigo": 2703601,
            "Nome": "Japaratinga",
            "Uf": "AL"
        },
        {
            "Id": 1690,
            "Codigo": 2703700,
            "Nome": "Jaramataia",
            "Uf": "AL"
        },
        {
            "Id": 1691,
            "Codigo": 2703759,
            "Nome": "Jequiá da Praia",
            "Uf": "AL"
        },
        {
            "Id": 1692,
            "Codigo": 2703809,
            "Nome": "Joaquim Gomes",
            "Uf": "AL"
        },
        {
            "Id": 1693,
            "Codigo": 2703908,
            "Nome": "Jundiá",
            "Uf": "AL"
        },
        {
            "Id": 1694,
            "Codigo": 2704005,
            "Nome": "Junqueiro",
            "Uf": "AL"
        },
        {
            "Id": 1695,
            "Codigo": 2704104,
            "Nome": "Lagoa da Canoa",
            "Uf": "AL"
        },
        {
            "Id": 1696,
            "Codigo": 2704203,
            "Nome": "Limoeiro de Anadia",
            "Uf": "AL"
        },
        {
            "Id": 1697,
            "Codigo": 2704302,
            "Nome": "Maceió",
            "Uf": "AL"
        },
        {
            "Id": 1698,
            "Codigo": 2704401,
            "Nome": "Major Isidoro",
            "Uf": "AL"
        },
        {
            "Id": 1699,
            "Codigo": 2704500,
            "Nome": "Maragogi",
            "Uf": "AL"
        },
        {
            "Id": 1700,
            "Codigo": 2704609,
            "Nome": "Maravilha",
            "Uf": "AL"
        },
        {
            "Id": 1701,
            "Codigo": 2704708,
            "Nome": "Marechal Deodoro",
            "Uf": "AL"
        },
        {
            "Id": 1702,
            "Codigo": 2704807,
            "Nome": "Maribondo",
            "Uf": "AL"
        },
        {
            "Id": 1703,
            "Codigo": 2704906,
            "Nome": "Mar Vermelho",
            "Uf": "AL"
        },
        {
            "Id": 1704,
            "Codigo": 2705002,
            "Nome": "Mata Grande",
            "Uf": "AL"
        },
        {
            "Id": 1705,
            "Codigo": 2705101,
            "Nome": "Matriz de Camaragibe",
            "Uf": "AL"
        },
        {
            "Id": 1706,
            "Codigo": 2705200,
            "Nome": "Messias",
            "Uf": "AL"
        },
        {
            "Id": 1707,
            "Codigo": 2705309,
            "Nome": "Minador do Negrão",
            "Uf": "AL"
        },
        {
            "Id": 1708,
            "Codigo": 2705408,
            "Nome": "Monteirópolis",
            "Uf": "AL"
        },
        {
            "Id": 1709,
            "Codigo": 2705507,
            "Nome": "Murici",
            "Uf": "AL"
        },
        {
            "Id": 1710,
            "Codigo": 2705606,
            "Nome": "Novo Lino",
            "Uf": "AL"
        },
        {
            "Id": 1711,
            "Codigo": 2705705,
            "Nome": "Olho D'Água das Flores",
            "Uf": "AL"
        },
        {
            "Id": 1712,
            "Codigo": 2705804,
            "Nome": "Olho D'Água do Casado",
            "Uf": "AL"
        },
        {
            "Id": 1713,
            "Codigo": 2705903,
            "Nome": "Olho D'Água Grande",
            "Uf": "AL"
        },
        {
            "Id": 1714,
            "Codigo": 2706000,
            "Nome": "Olivença",
            "Uf": "AL"
        },
        {
            "Id": 1715,
            "Codigo": 2706109,
            "Nome": "Ouro Branco",
            "Uf": "AL"
        },
        {
            "Id": 1716,
            "Codigo": 2706208,
            "Nome": "Palestina",
            "Uf": "AL"
        },
        {
            "Id": 1717,
            "Codigo": 2706307,
            "Nome": "Palmeira dos Índios",
            "Uf": "AL"
        },
        {
            "Id": 1718,
            "Codigo": 2706406,
            "Nome": "Pão de Açúcar",
            "Uf": "AL"
        },
        {
            "Id": 1719,
            "Codigo": 2706422,
            "Nome": "Pariconha",
            "Uf": "AL"
        },
        {
            "Id": 1720,
            "Codigo": 2706448,
            "Nome": "Paripueira",
            "Uf": "AL"
        },
        {
            "Id": 1721,
            "Codigo": 2706505,
            "Nome": "Passo de Camaragibe",
            "Uf": "AL"
        },
        {
            "Id": 1722,
            "Codigo": 2706604,
            "Nome": "Paulo Jacinto",
            "Uf": "AL"
        },
        {
            "Id": 1723,
            "Codigo": 2706703,
            "Nome": "Penedo",
            "Uf": "AL"
        },
        {
            "Id": 1724,
            "Codigo": 2706802,
            "Nome": "Piaçabuçu",
            "Uf": "AL"
        },
        {
            "Id": 1725,
            "Codigo": 2706901,
            "Nome": "Pilar",
            "Uf": "AL"
        },
        {
            "Id": 1726,
            "Codigo": 2707008,
            "Nome": "Pindoba",
            "Uf": "AL"
        },
        {
            "Id": 1727,
            "Codigo": 2707107,
            "Nome": "Piranhas",
            "Uf": "AL"
        },
        {
            "Id": 1728,
            "Codigo": 2707206,
            "Nome": "Poço das Trincheiras",
            "Uf": "AL"
        },
        {
            "Id": 1729,
            "Codigo": 2707305,
            "Nome": "Porto Calvo",
            "Uf": "AL"
        },
        {
            "Id": 1730,
            "Codigo": 2707404,
            "Nome": "Porto de Pedras",
            "Uf": "AL"
        },
        {
            "Id": 1731,
            "Codigo": 2707503,
            "Nome": "Porto Real do Colégio",
            "Uf": "AL"
        },
        {
            "Id": 1732,
            "Codigo": 2707602,
            "Nome": "Quebrangulo",
            "Uf": "AL"
        },
        {
            "Id": 1733,
            "Codigo": 2707701,
            "Nome": "Rio Largo",
            "Uf": "AL"
        },
        {
            "Id": 1734,
            "Codigo": 2707800,
            "Nome": "Roteiro",
            "Uf": "AL"
        },
        {
            "Id": 1735,
            "Codigo": 2707909,
            "Nome": "Santa Luzia do Norte",
            "Uf": "AL"
        },
        {
            "Id": 1736,
            "Codigo": 2708006,
            "Nome": "Santana do Ipanema",
            "Uf": "AL"
        },
        {
            "Id": 1737,
            "Codigo": 2708105,
            "Nome": "Santana do Mundaú",
            "Uf": "AL"
        },
        {
            "Id": 1738,
            "Codigo": 2708204,
            "Nome": "São Brás",
            "Uf": "AL"
        },
        {
            "Id": 1739,
            "Codigo": 2708303,
            "Nome": "São José da Laje",
            "Uf": "AL"
        },
        {
            "Id": 1740,
            "Codigo": 2708402,
            "Nome": "São José da Tapera",
            "Uf": "AL"
        },
        {
            "Id": 1741,
            "Codigo": 2708501,
            "Nome": "São Luís do Quitunde",
            "Uf": "AL"
        },
        {
            "Id": 1742,
            "Codigo": 2708600,
            "Nome": "São Miguel dos Campos",
            "Uf": "AL"
        },
        {
            "Id": 1743,
            "Codigo": 2708709,
            "Nome": "São Miguel dos Milagres",
            "Uf": "AL"
        },
        {
            "Id": 1744,
            "Codigo": 2708808,
            "Nome": "São Sebastião",
            "Uf": "AL"
        },
        {
            "Id": 1745,
            "Codigo": 2708907,
            "Nome": "Satuba",
            "Uf": "AL"
        },
        {
            "Id": 1746,
            "Codigo": 2708956,
            "Nome": "Senador Rui Palmeira",
            "Uf": "AL"
        },
        {
            "Id": 1747,
            "Codigo": 2709004,
            "Nome": "Tanque D'Arca",
            "Uf": "AL"
        },
        {
            "Id": 1748,
            "Codigo": 2709103,
            "Nome": "Taquarana",
            "Uf": "AL"
        },
        {
            "Id": 1749,
            "Codigo": 2709152,
            "Nome": "Teotônio Vilela",
            "Uf": "AL"
        },
        {
            "Id": 1750,
            "Codigo": 2709202,
            "Nome": "Traipu",
            "Uf": "AL"
        },
        {
            "Id": 1751,
            "Codigo": 2709301,
            "Nome": "União dos Palmares",
            "Uf": "AL"
        },
        {
            "Id": 1752,
            "Codigo": 2709400,
            "Nome": "Viçosa",
            "Uf": "AL"
        },
        {
            "Id": 1753,
            "Codigo": 2800100,
            "Nome": "Amparo de São Francisco",
            "Uf": "SE"
        },
        {
            "Id": 1754,
            "Codigo": 2800209,
            "Nome": "Aquidabã",
            "Uf": "SE"
        },
        {
            "Id": 1755,
            "Codigo": 2800308,
            "Nome": "Aracaju",
            "Uf": "SE"
        },
        {
            "Id": 1756,
            "Codigo": 2800407,
            "Nome": "Arauá",
            "Uf": "SE"
        },
        {
            "Id": 1757,
            "Codigo": 2800506,
            "Nome": "Areia Branca",
            "Uf": "SE"
        },
        {
            "Id": 1758,
            "Codigo": 2800605,
            "Nome": "Barra dos Coqueiros",
            "Uf": "SE"
        },
        {
            "Id": 1759,
            "Codigo": 2800670,
            "Nome": "Boquim",
            "Uf": "SE"
        },
        {
            "Id": 1760,
            "Codigo": 2800704,
            "Nome": "Brejo Grande",
            "Uf": "SE"
        },
        {
            "Id": 1761,
            "Codigo": 2801009,
            "Nome": "Campo do Brito",
            "Uf": "SE"
        },
        {
            "Id": 1762,
            "Codigo": 2801108,
            "Nome": "Canhoba",
            "Uf": "SE"
        },
        {
            "Id": 1763,
            "Codigo": 2801207,
            "Nome": "Canindé de São Francisco",
            "Uf": "SE"
        },
        {
            "Id": 1764,
            "Codigo": 2801306,
            "Nome": "Capela",
            "Uf": "SE"
        },
        {
            "Id": 1765,
            "Codigo": 2801405,
            "Nome": "Carira",
            "Uf": "SE"
        },
        {
            "Id": 1766,
            "Codigo": 2801504,
            "Nome": "Carmópolis",
            "Uf": "SE"
        },
        {
            "Id": 1767,
            "Codigo": 2801603,
            "Nome": "Cedro de São João",
            "Uf": "SE"
        },
        {
            "Id": 1768,
            "Codigo": 2801702,
            "Nome": "Cristinápolis",
            "Uf": "SE"
        },
        {
            "Id": 1769,
            "Codigo": 2801900,
            "Nome": "Cumbe",
            "Uf": "SE"
        },
        {
            "Id": 1770,
            "Codigo": 2802007,
            "Nome": "Divina Pastora",
            "Uf": "SE"
        },
        {
            "Id": 1771,
            "Codigo": 2802106,
            "Nome": "Estância",
            "Uf": "SE"
        },
        {
            "Id": 1772,
            "Codigo": 2802205,
            "Nome": "Feira Nova",
            "Uf": "SE"
        },
        {
            "Id": 1773,
            "Codigo": 2802304,
            "Nome": "Frei Paulo",
            "Uf": "SE"
        },
        {
            "Id": 1774,
            "Codigo": 2802403,
            "Nome": "Gararu",
            "Uf": "SE"
        },
        {
            "Id": 1775,
            "Codigo": 2802502,
            "Nome": "General Maynard",
            "Uf": "SE"
        },
        {
            "Id": 1776,
            "Codigo": 2802601,
            "Nome": "Gracho Cardoso",
            "Uf": "SE"
        },
        {
            "Id": 1777,
            "Codigo": 2802700,
            "Nome": "Ilha das Flores",
            "Uf": "SE"
        },
        {
            "Id": 1778,
            "Codigo": 2802809,
            "Nome": "Indiaroba",
            "Uf": "SE"
        },
        {
            "Id": 1779,
            "Codigo": 2802908,
            "Nome": "Itabaiana",
            "Uf": "SE"
        },
        {
            "Id": 1780,
            "Codigo": 2803005,
            "Nome": "Itabaianinha",
            "Uf": "SE"
        },
        {
            "Id": 1781,
            "Codigo": 2803104,
            "Nome": "Itabi",
            "Uf": "SE"
        },
        {
            "Id": 1782,
            "Codigo": 2803203,
            "Nome": "Itaporanga D'Ajuda",
            "Uf": "SE"
        },
        {
            "Id": 1783,
            "Codigo": 2803302,
            "Nome": "Japaratuba",
            "Uf": "SE"
        },
        {
            "Id": 1784,
            "Codigo": 2803401,
            "Nome": "Japoatã",
            "Uf": "SE"
        },
        {
            "Id": 1785,
            "Codigo": 2803500,
            "Nome": "Lagarto",
            "Uf": "SE"
        },
        {
            "Id": 1786,
            "Codigo": 2803609,
            "Nome": "Laranjeiras",
            "Uf": "SE"
        },
        {
            "Id": 1787,
            "Codigo": 2803708,
            "Nome": "Macambira",
            "Uf": "SE"
        },
        {
            "Id": 1788,
            "Codigo": 2803807,
            "Nome": "Malhada dos Bois",
            "Uf": "SE"
        },
        {
            "Id": 1789,
            "Codigo": 2803906,
            "Nome": "Malhador",
            "Uf": "SE"
        },
        {
            "Id": 1790,
            "Codigo": 2804003,
            "Nome": "Maruim",
            "Uf": "SE"
        },
        {
            "Id": 1791,
            "Codigo": 2804102,
            "Nome": "Moita Bonita",
            "Uf": "SE"
        },
        {
            "Id": 1792,
            "Codigo": 2804201,
            "Nome": "Monte Alegre de Sergipe",
            "Uf": "SE"
        },
        {
            "Id": 1793,
            "Codigo": 2804300,
            "Nome": "Muribeca",
            "Uf": "SE"
        },
        {
            "Id": 1794,
            "Codigo": 2804409,
            "Nome": "Neópolis",
            "Uf": "SE"
        },
        {
            "Id": 1795,
            "Codigo": 2804458,
            "Nome": "Nossa Senhora Aparecida",
            "Uf": "SE"
        },
        {
            "Id": 1796,
            "Codigo": 2804508,
            "Nome": "Nossa Senhora da Glória",
            "Uf": "SE"
        },
        {
            "Id": 1797,
            "Codigo": 2804607,
            "Nome": "Nossa Senhora das Dores",
            "Uf": "SE"
        },
        {
            "Id": 1798,
            "Codigo": 2804706,
            "Nome": "Nossa Senhora de Lourdes",
            "Uf": "SE"
        },
        {
            "Id": 1799,
            "Codigo": 2804805,
            "Nome": "Nossa Senhora do Socorro",
            "Uf": "SE"
        },
        {
            "Id": 1800,
            "Codigo": 2804904,
            "Nome": "Pacatuba",
            "Uf": "SE"
        },
        {
            "Id": 1801,
            "Codigo": 2805000,
            "Nome": "Pedra Mole",
            "Uf": "SE"
        },
        {
            "Id": 1802,
            "Codigo": 2805109,
            "Nome": "Pedrinhas",
            "Uf": "SE"
        },
        {
            "Id": 1803,
            "Codigo": 2805208,
            "Nome": "Pinhão",
            "Uf": "SE"
        },
        {
            "Id": 1804,
            "Codigo": 2805307,
            "Nome": "Pirambu",
            "Uf": "SE"
        },
        {
            "Id": 1805,
            "Codigo": 2805406,
            "Nome": "Poço Redondo",
            "Uf": "SE"
        },
        {
            "Id": 1806,
            "Codigo": 2805505,
            "Nome": "Poço Verde",
            "Uf": "SE"
        },
        {
            "Id": 1807,
            "Codigo": 2805604,
            "Nome": "Porto da Folha",
            "Uf": "SE"
        },
        {
            "Id": 1808,
            "Codigo": 2805703,
            "Nome": "Propriá",
            "Uf": "SE"
        },
        {
            "Id": 1809,
            "Codigo": 2805802,
            "Nome": "Riachão do Dantas",
            "Uf": "SE"
        },
        {
            "Id": 1810,
            "Codigo": 2805901,
            "Nome": "Riachuelo",
            "Uf": "SE"
        },
        {
            "Id": 1811,
            "Codigo": 2806008,
            "Nome": "Ribeirópolis",
            "Uf": "SE"
        },
        {
            "Id": 1812,
            "Codigo": 2806107,
            "Nome": "Rosário do Catete",
            "Uf": "SE"
        },
        {
            "Id": 1813,
            "Codigo": 2806206,
            "Nome": "Salgado",
            "Uf": "SE"
        },
        {
            "Id": 1814,
            "Codigo": 2806305,
            "Nome": "Santa Luzia do Itanhy",
            "Uf": "SE"
        },
        {
            "Id": 1815,
            "Codigo": 2806404,
            "Nome": "Santana do São Francisco",
            "Uf": "SE"
        },
        {
            "Id": 1816,
            "Codigo": 2806503,
            "Nome": "Santa Rosa de Lima",
            "Uf": "SE"
        },
        {
            "Id": 1817,
            "Codigo": 2806602,
            "Nome": "Santo Amaro das Brotas",
            "Uf": "SE"
        },
        {
            "Id": 1818,
            "Codigo": 2806701,
            "Nome": "São Cristóvão",
            "Uf": "SE"
        },
        {
            "Id": 1819,
            "Codigo": 2806800,
            "Nome": "São Domingos",
            "Uf": "SE"
        },
        {
            "Id": 1820,
            "Codigo": 2806909,
            "Nome": "São Francisco",
            "Uf": "SE"
        },
        {
            "Id": 1821,
            "Codigo": 2807006,
            "Nome": "São Miguel do Aleixo",
            "Uf": "SE"
        },
        {
            "Id": 1822,
            "Codigo": 2807105,
            "Nome": "Simão Dias",
            "Uf": "SE"
        },
        {
            "Id": 1823,
            "Codigo": 2807204,
            "Nome": "Siriri",
            "Uf": "SE"
        },
        {
            "Id": 1824,
            "Codigo": 2807303,
            "Nome": "Telha",
            "Uf": "SE"
        },
        {
            "Id": 1825,
            "Codigo": 2807402,
            "Nome": "Tobias Barreto",
            "Uf": "SE"
        },
        {
            "Id": 1826,
            "Codigo": 2807501,
            "Nome": "Tomar do Geru",
            "Uf": "SE"
        },
        {
            "Id": 1827,
            "Codigo": 2807600,
            "Nome": "Umbaúba",
            "Uf": "SE"
        },
        {
            "Id": 1828,
            "Codigo": 2900108,
            "Nome": "Abaíra",
            "Uf": "BA"
        },
        {
            "Id": 1829,
            "Codigo": 2900207,
            "Nome": "Abaré",
            "Uf": "BA"
        },
        {
            "Id": 1830,
            "Codigo": 2900306,
            "Nome": "Acajutiba",
            "Uf": "BA"
        },
        {
            "Id": 1831,
            "Codigo": 2900355,
            "Nome": "Adustina",
            "Uf": "BA"
        },
        {
            "Id": 1832,
            "Codigo": 2900405,
            "Nome": "Água Fria",
            "Uf": "BA"
        },
        {
            "Id": 1833,
            "Codigo": 2900504,
            "Nome": "Érico Cardoso",
            "Uf": "BA"
        },
        {
            "Id": 1834,
            "Codigo": 2900603,
            "Nome": "Aiquara",
            "Uf": "BA"
        },
        {
            "Id": 1835,
            "Codigo": 2900702,
            "Nome": "Alagoinhas",
            "Uf": "BA"
        },
        {
            "Id": 1836,
            "Codigo": 2900801,
            "Nome": "Alcobaça",
            "Uf": "BA"
        },
        {
            "Id": 1837,
            "Codigo": 2900900,
            "Nome": "Almadina",
            "Uf": "BA"
        },
        {
            "Id": 1838,
            "Codigo": 2901007,
            "Nome": "Amargosa",
            "Uf": "BA"
        },
        {
            "Id": 1839,
            "Codigo": 2901106,
            "Nome": "Amélia Rodrigues",
            "Uf": "BA"
        },
        {
            "Id": 1840,
            "Codigo": 2901155,
            "Nome": "América Dourada",
            "Uf": "BA"
        },
        {
            "Id": 1841,
            "Codigo": 2901205,
            "Nome": "Anagé",
            "Uf": "BA"
        },
        {
            "Id": 1842,
            "Codigo": 2901304,
            "Nome": "Andaraí",
            "Uf": "BA"
        },
        {
            "Id": 1843,
            "Codigo": 2901353,
            "Nome": "Andorinha",
            "Uf": "BA"
        },
        {
            "Id": 1844,
            "Codigo": 2901403,
            "Nome": "Angical",
            "Uf": "BA"
        },
        {
            "Id": 1845,
            "Codigo": 2901502,
            "Nome": "Anguera",
            "Uf": "BA"
        },
        {
            "Id": 1846,
            "Codigo": 2901601,
            "Nome": "Antas",
            "Uf": "BA"
        },
        {
            "Id": 1847,
            "Codigo": 2901700,
            "Nome": "Antônio Cardoso",
            "Uf": "BA"
        },
        {
            "Id": 1848,
            "Codigo": 2901809,
            "Nome": "Antônio Gonçalves",
            "Uf": "BA"
        },
        {
            "Id": 1849,
            "Codigo": 2901908,
            "Nome": "Aporá",
            "Uf": "BA"
        },
        {
            "Id": 1850,
            "Codigo": 2901957,
            "Nome": "Apuarema",
            "Uf": "BA"
        },
        {
            "Id": 1851,
            "Codigo": 2902005,
            "Nome": "Aracatu",
            "Uf": "BA"
        },
        {
            "Id": 1852,
            "Codigo": 2902054,
            "Nome": "Araças",
            "Uf": "BA"
        },
        {
            "Id": 1853,
            "Codigo": 2902104,
            "Nome": "Araci",
            "Uf": "BA"
        },
        {
            "Id": 1854,
            "Codigo": 2902203,
            "Nome": "Aramari",
            "Uf": "BA"
        },
        {
            "Id": 1855,
            "Codigo": 2902252,
            "Nome": "Arataca",
            "Uf": "BA"
        },
        {
            "Id": 1856,
            "Codigo": 2902302,
            "Nome": "Aratuípe",
            "Uf": "BA"
        },
        {
            "Id": 1857,
            "Codigo": 2902401,
            "Nome": "Aurelino Leal",
            "Uf": "BA"
        },
        {
            "Id": 1858,
            "Codigo": 2902500,
            "Nome": "Baianópolis",
            "Uf": "BA"
        },
        {
            "Id": 1859,
            "Codigo": 2902609,
            "Nome": "Baixa Grande",
            "Uf": "BA"
        },
        {
            "Id": 1860,
            "Codigo": 2902658,
            "Nome": "Banzaê",
            "Uf": "BA"
        },
        {
            "Id": 1861,
            "Codigo": 2902708,
            "Nome": "Barra",
            "Uf": "BA"
        },
        {
            "Id": 1862,
            "Codigo": 2902807,
            "Nome": "Barra da Estiva",
            "Uf": "BA"
        },
        {
            "Id": 1863,
            "Codigo": 2902906,
            "Nome": "Barra do Choça",
            "Uf": "BA"
        },
        {
            "Id": 1864,
            "Codigo": 2903003,
            "Nome": "Barra do Mendes",
            "Uf": "BA"
        },
        {
            "Id": 1865,
            "Codigo": 2903102,
            "Nome": "Barra do Rocha",
            "Uf": "BA"
        },
        {
            "Id": 1866,
            "Codigo": 2903201,
            "Nome": "Barreiras",
            "Uf": "BA"
        },
        {
            "Id": 1867,
            "Codigo": 2903235,
            "Nome": "Barro Alto",
            "Uf": "BA"
        },
        {
            "Id": 1868,
            "Codigo": 2903276,
            "Nome": "Barrocas",
            "Uf": "BA"
        },
        {
            "Id": 1869,
            "Codigo": 2903300,
            "Nome": "Barro Preto",
            "Uf": "BA"
        },
        {
            "Id": 1870,
            "Codigo": 2903409,
            "Nome": "Belmonte",
            "Uf": "BA"
        },
        {
            "Id": 1871,
            "Codigo": 2903508,
            "Nome": "Belo Campo",
            "Uf": "BA"
        },
        {
            "Id": 1872,
            "Codigo": 2903607,
            "Nome": "Biritinga",
            "Uf": "BA"
        },
        {
            "Id": 1873,
            "Codigo": 2903706,
            "Nome": "Boa Nova",
            "Uf": "BA"
        },
        {
            "Id": 1874,
            "Codigo": 2903805,
            "Nome": "Boa Vista do Tupim",
            "Uf": "BA"
        },
        {
            "Id": 1875,
            "Codigo": 2903904,
            "Nome": "Bom Jesus da Lapa",
            "Uf": "BA"
        },
        {
            "Id": 1876,
            "Codigo": 2903953,
            "Nome": "Bom Jesus da Serra",
            "Uf": "BA"
        },
        {
            "Id": 1877,
            "Codigo": 2904001,
            "Nome": "Boninal",
            "Uf": "BA"
        },
        {
            "Id": 1878,
            "Codigo": 2904050,
            "Nome": "Bonito",
            "Uf": "BA"
        },
        {
            "Id": 1879,
            "Codigo": 2904100,
            "Nome": "Boquira",
            "Uf": "BA"
        },
        {
            "Id": 1880,
            "Codigo": 2904209,
            "Nome": "Botuporã",
            "Uf": "BA"
        },
        {
            "Id": 1881,
            "Codigo": 2904308,
            "Nome": "Brejões",
            "Uf": "BA"
        },
        {
            "Id": 1882,
            "Codigo": 2904407,
            "Nome": "Brejolândia",
            "Uf": "BA"
        },
        {
            "Id": 1883,
            "Codigo": 2904506,
            "Nome": "Brotas de Macaúbas",
            "Uf": "BA"
        },
        {
            "Id": 1884,
            "Codigo": 2904605,
            "Nome": "Brumado",
            "Uf": "BA"
        },
        {
            "Id": 1885,
            "Codigo": 2904704,
            "Nome": "Buerarema",
            "Uf": "BA"
        },
        {
            "Id": 1886,
            "Codigo": 2904753,
            "Nome": "Buritirama",
            "Uf": "BA"
        },
        {
            "Id": 1887,
            "Codigo": 2904803,
            "Nome": "Caatiba",
            "Uf": "BA"
        },
        {
            "Id": 1888,
            "Codigo": 2904852,
            "Nome": "Cabaceiras do Paraguaçu",
            "Uf": "BA"
        },
        {
            "Id": 1889,
            "Codigo": 2904902,
            "Nome": "Cachoeira",
            "Uf": "BA"
        },
        {
            "Id": 1890,
            "Codigo": 2905008,
            "Nome": "Caculé",
            "Uf": "BA"
        },
        {
            "Id": 1891,
            "Codigo": 2905107,
            "Nome": "Caém",
            "Uf": "BA"
        },
        {
            "Id": 1892,
            "Codigo": 2905156,
            "Nome": "Caetanos",
            "Uf": "BA"
        },
        {
            "Id": 1893,
            "Codigo": 2905206,
            "Nome": "Caetité",
            "Uf": "BA"
        },
        {
            "Id": 1894,
            "Codigo": 2905305,
            "Nome": "Cafarnaum",
            "Uf": "BA"
        },
        {
            "Id": 1895,
            "Codigo": 2905404,
            "Nome": "Cairu",
            "Uf": "BA"
        },
        {
            "Id": 1896,
            "Codigo": 2905503,
            "Nome": "Caldeirão Grande",
            "Uf": "BA"
        },
        {
            "Id": 1897,
            "Codigo": 2905602,
            "Nome": "Camacan",
            "Uf": "BA"
        },
        {
            "Id": 1898,
            "Codigo": 2905701,
            "Nome": "Camaçari",
            "Uf": "BA"
        },
        {
            "Id": 1899,
            "Codigo": 2905800,
            "Nome": "Camamu",
            "Uf": "BA"
        },
        {
            "Id": 1900,
            "Codigo": 2905909,
            "Nome": "Campo Alegre de Lourdes",
            "Uf": "BA"
        },
        {
            "Id": 1901,
            "Codigo": 2906006,
            "Nome": "Campo Formoso",
            "Uf": "BA"
        },
        {
            "Id": 1902,
            "Codigo": 2906105,
            "Nome": "Canápolis",
            "Uf": "BA"
        },
        {
            "Id": 1903,
            "Codigo": 2906204,
            "Nome": "Canarana",
            "Uf": "BA"
        },
        {
            "Id": 1904,
            "Codigo": 2906303,
            "Nome": "Canavieiras",
            "Uf": "BA"
        },
        {
            "Id": 1905,
            "Codigo": 2906402,
            "Nome": "Candeal",
            "Uf": "BA"
        },
        {
            "Id": 1906,
            "Codigo": 2906501,
            "Nome": "Candeias",
            "Uf": "BA"
        },
        {
            "Id": 1907,
            "Codigo": 2906600,
            "Nome": "Candiba",
            "Uf": "BA"
        },
        {
            "Id": 1908,
            "Codigo": 2906709,
            "Nome": "Cândido Sales",
            "Uf": "BA"
        },
        {
            "Id": 1909,
            "Codigo": 2906808,
            "Nome": "Cansanção",
            "Uf": "BA"
        },
        {
            "Id": 1910,
            "Codigo": 2906824,
            "Nome": "Canudos",
            "Uf": "BA"
        },
        {
            "Id": 1911,
            "Codigo": 2906857,
            "Nome": "Capela do Alto Alegre",
            "Uf": "BA"
        },
        {
            "Id": 1912,
            "Codigo": 2906873,
            "Nome": "Capim Grosso",
            "Uf": "BA"
        },
        {
            "Id": 1913,
            "Codigo": 2906899,
            "Nome": "Caraíbas",
            "Uf": "BA"
        },
        {
            "Id": 1914,
            "Codigo": 2906907,
            "Nome": "Caravelas",
            "Uf": "BA"
        },
        {
            "Id": 1915,
            "Codigo": 2907004,
            "Nome": "Cardeal da Silva",
            "Uf": "BA"
        },
        {
            "Id": 1916,
            "Codigo": 2907103,
            "Nome": "Carinhanha",
            "Uf": "BA"
        },
        {
            "Id": 1917,
            "Codigo": 2907202,
            "Nome": "Casa Nova",
            "Uf": "BA"
        },
        {
            "Id": 1918,
            "Codigo": 2907301,
            "Nome": "Castro Alves",
            "Uf": "BA"
        },
        {
            "Id": 1919,
            "Codigo": 2907400,
            "Nome": "Catolândia",
            "Uf": "BA"
        },
        {
            "Id": 1920,
            "Codigo": 2907509,
            "Nome": "Catu",
            "Uf": "BA"
        },
        {
            "Id": 1921,
            "Codigo": 2907558,
            "Nome": "Caturama",
            "Uf": "BA"
        },
        {
            "Id": 1922,
            "Codigo": 2907608,
            "Nome": "Central",
            "Uf": "BA"
        },
        {
            "Id": 1923,
            "Codigo": 2907707,
            "Nome": "Chorrochó",
            "Uf": "BA"
        },
        {
            "Id": 1924,
            "Codigo": 2907806,
            "Nome": "Cícero Dantas",
            "Uf": "BA"
        },
        {
            "Id": 1925,
            "Codigo": 2907905,
            "Nome": "Cipó",
            "Uf": "BA"
        },
        {
            "Id": 1926,
            "Codigo": 2908002,
            "Nome": "Coaraci",
            "Uf": "BA"
        },
        {
            "Id": 1927,
            "Codigo": 2908101,
            "Nome": "Cocos",
            "Uf": "BA"
        },
        {
            "Id": 1928,
            "Codigo": 2908200,
            "Nome": "Conceição da Feira",
            "Uf": "BA"
        },
        {
            "Id": 1929,
            "Codigo": 2908309,
            "Nome": "Conceição do Almeida",
            "Uf": "BA"
        },
        {
            "Id": 1930,
            "Codigo": 2908408,
            "Nome": "Conceição do Coité",
            "Uf": "BA"
        },
        {
            "Id": 1931,
            "Codigo": 2908507,
            "Nome": "Conceição do Jacuípe",
            "Uf": "BA"
        },
        {
            "Id": 1932,
            "Codigo": 2908606,
            "Nome": "Conde",
            "Uf": "BA"
        },
        {
            "Id": 1933,
            "Codigo": 2908705,
            "Nome": "Condeúba",
            "Uf": "BA"
        },
        {
            "Id": 1934,
            "Codigo": 2908804,
            "Nome": "Contendas do Sincorá",
            "Uf": "BA"
        },
        {
            "Id": 1935,
            "Codigo": 2908903,
            "Nome": "Coração de Maria",
            "Uf": "BA"
        },
        {
            "Id": 1936,
            "Codigo": 2909000,
            "Nome": "Cordeiros",
            "Uf": "BA"
        },
        {
            "Id": 1937,
            "Codigo": 2909109,
            "Nome": "Coribe",
            "Uf": "BA"
        },
        {
            "Id": 1938,
            "Codigo": 2909208,
            "Nome": "Coronel João Sá",
            "Uf": "BA"
        },
        {
            "Id": 1939,
            "Codigo": 2909307,
            "Nome": "Correntina",
            "Uf": "BA"
        },
        {
            "Id": 1940,
            "Codigo": 2909406,
            "Nome": "Cotegipe",
            "Uf": "BA"
        },
        {
            "Id": 1941,
            "Codigo": 2909505,
            "Nome": "Cravolândia",
            "Uf": "BA"
        },
        {
            "Id": 1942,
            "Codigo": 2909604,
            "Nome": "Crisópolis",
            "Uf": "BA"
        },
        {
            "Id": 1943,
            "Codigo": 2909703,
            "Nome": "Cristópolis",
            "Uf": "BA"
        },
        {
            "Id": 1944,
            "Codigo": 2909802,
            "Nome": "Cruz das Almas",
            "Uf": "BA"
        },
        {
            "Id": 1945,
            "Codigo": 2909901,
            "Nome": "Curaçá",
            "Uf": "BA"
        },
        {
            "Id": 1946,
            "Codigo": 2910008,
            "Nome": "Dário Meira",
            "Uf": "BA"
        },
        {
            "Id": 1947,
            "Codigo": 2910057,
            "Nome": "Dias D'Ávila",
            "Uf": "BA"
        },
        {
            "Id": 1948,
            "Codigo": 2910107,
            "Nome": "Dom Basílio",
            "Uf": "BA"
        },
        {
            "Id": 1949,
            "Codigo": 2910206,
            "Nome": "Dom Macedo Costa",
            "Uf": "BA"
        },
        {
            "Id": 1950,
            "Codigo": 2910305,
            "Nome": "Elísio Medrado",
            "Uf": "BA"
        },
        {
            "Id": 1951,
            "Codigo": 2910404,
            "Nome": "Encruzilhada",
            "Uf": "BA"
        },
        {
            "Id": 1952,
            "Codigo": 2910503,
            "Nome": "Entre Rios",
            "Uf": "BA"
        },
        {
            "Id": 1953,
            "Codigo": 2910602,
            "Nome": "Esplanada",
            "Uf": "BA"
        },
        {
            "Id": 1954,
            "Codigo": 2910701,
            "Nome": "Euclides da Cunha",
            "Uf": "BA"
        },
        {
            "Id": 1955,
            "Codigo": 2910727,
            "Nome": "Eunápolis",
            "Uf": "BA"
        },
        {
            "Id": 1956,
            "Codigo": 2910750,
            "Nome": "Fátima",
            "Uf": "BA"
        },
        {
            "Id": 1957,
            "Codigo": 2910776,
            "Nome": "Feira da Mata",
            "Uf": "BA"
        },
        {
            "Id": 1958,
            "Codigo": 2910800,
            "Nome": "Feira de Santana",
            "Uf": "BA"
        },
        {
            "Id": 1959,
            "Codigo": 2910859,
            "Nome": "Filadélfia",
            "Uf": "BA"
        },
        {
            "Id": 1960,
            "Codigo": 2910909,
            "Nome": "Firmino Alves",
            "Uf": "BA"
        },
        {
            "Id": 1961,
            "Codigo": 2911006,
            "Nome": "Floresta Azul",
            "Uf": "BA"
        },
        {
            "Id": 1962,
            "Codigo": 2911105,
            "Nome": "Formosa do Rio Preto",
            "Uf": "BA"
        },
        {
            "Id": 1963,
            "Codigo": 2911204,
            "Nome": "Gandu",
            "Uf": "BA"
        },
        {
            "Id": 1964,
            "Codigo": 2911253,
            "Nome": "Gavião",
            "Uf": "BA"
        },
        {
            "Id": 1965,
            "Codigo": 2911303,
            "Nome": "Gentio do Ouro",
            "Uf": "BA"
        },
        {
            "Id": 1966,
            "Codigo": 2911402,
            "Nome": "Glória",
            "Uf": "BA"
        },
        {
            "Id": 1967,
            "Codigo": 2911501,
            "Nome": "Gongogi",
            "Uf": "BA"
        },
        {
            "Id": 1968,
            "Codigo": 2911600,
            "Nome": "Governador Mangabeira",
            "Uf": "BA"
        },
        {
            "Id": 1969,
            "Codigo": 2911659,
            "Nome": "Guajeru",
            "Uf": "BA"
        },
        {
            "Id": 1970,
            "Codigo": 2911709,
            "Nome": "Guanambi",
            "Uf": "BA"
        },
        {
            "Id": 1971,
            "Codigo": 2911808,
            "Nome": "Guaratinga",
            "Uf": "BA"
        },
        {
            "Id": 1972,
            "Codigo": 2911857,
            "Nome": "Heliópolis",
            "Uf": "BA"
        },
        {
            "Id": 1973,
            "Codigo": 2911907,
            "Nome": "Iaçu",
            "Uf": "BA"
        },
        {
            "Id": 1974,
            "Codigo": 2912004,
            "Nome": "Ibiassucê",
            "Uf": "BA"
        },
        {
            "Id": 1975,
            "Codigo": 2912103,
            "Nome": "Ibicaraí",
            "Uf": "BA"
        },
        {
            "Id": 1976,
            "Codigo": 2912202,
            "Nome": "Ibicoara",
            "Uf": "BA"
        },
        {
            "Id": 1977,
            "Codigo": 2912301,
            "Nome": "Ibicuí",
            "Uf": "BA"
        },
        {
            "Id": 1978,
            "Codigo": 2912400,
            "Nome": "Ibipeba",
            "Uf": "BA"
        },
        {
            "Id": 1979,
            "Codigo": 2912509,
            "Nome": "Ibipitanga",
            "Uf": "BA"
        },
        {
            "Id": 1980,
            "Codigo": 2912608,
            "Nome": "Ibiquera",
            "Uf": "BA"
        },
        {
            "Id": 1981,
            "Codigo": 2912707,
            "Nome": "Ibirapitanga",
            "Uf": "BA"
        },
        {
            "Id": 1982,
            "Codigo": 2912806,
            "Nome": "Ibirapuã",
            "Uf": "BA"
        },
        {
            "Id": 1983,
            "Codigo": 2912905,
            "Nome": "Ibirataia",
            "Uf": "BA"
        },
        {
            "Id": 1984,
            "Codigo": 2913002,
            "Nome": "Ibitiara",
            "Uf": "BA"
        },
        {
            "Id": 1985,
            "Codigo": 2913101,
            "Nome": "Ibititá",
            "Uf": "BA"
        },
        {
            "Id": 1986,
            "Codigo": 2913200,
            "Nome": "Ibotirama",
            "Uf": "BA"
        },
        {
            "Id": 1987,
            "Codigo": 2913309,
            "Nome": "Ichu",
            "Uf": "BA"
        },
        {
            "Id": 1988,
            "Codigo": 2913408,
            "Nome": "Igaporã",
            "Uf": "BA"
        },
        {
            "Id": 1989,
            "Codigo": 2913457,
            "Nome": "Igrapiúna",
            "Uf": "BA"
        },
        {
            "Id": 1990,
            "Codigo": 2913507,
            "Nome": "Iguaí",
            "Uf": "BA"
        },
        {
            "Id": 1991,
            "Codigo": 2913606,
            "Nome": "Ilhéus",
            "Uf": "BA"
        },
        {
            "Id": 1992,
            "Codigo": 2913705,
            "Nome": "Inhambupe",
            "Uf": "BA"
        },
        {
            "Id": 1993,
            "Codigo": 2913804,
            "Nome": "Ipecaetá",
            "Uf": "BA"
        },
        {
            "Id": 1994,
            "Codigo": 2913903,
            "Nome": "Ipiaú",
            "Uf": "BA"
        },
        {
            "Id": 1995,
            "Codigo": 2914000,
            "Nome": "Ipirá",
            "Uf": "BA"
        },
        {
            "Id": 1996,
            "Codigo": 2914109,
            "Nome": "Ipupiara",
            "Uf": "BA"
        },
        {
            "Id": 1997,
            "Codigo": 2914208,
            "Nome": "Irajuba",
            "Uf": "BA"
        },
        {
            "Id": 1998,
            "Codigo": 2914307,
            "Nome": "Iramaia",
            "Uf": "BA"
        },
        {
            "Id": 1999,
            "Codigo": 2914406,
            "Nome": "Iraquara",
            "Uf": "BA"
        },
        {
            "Id": 2000,
            "Codigo": 2914505,
            "Nome": "Irará",
            "Uf": "BA"
        },
        {
            "Id": 2001,
            "Codigo": 2914604,
            "Nome": "Irecê",
            "Uf": "BA"
        },
        {
            "Id": 2002,
            "Codigo": 2914653,
            "Nome": "Itabela",
            "Uf": "BA"
        },
        {
            "Id": 2003,
            "Codigo": 2914703,
            "Nome": "Itaberaba",
            "Uf": "BA"
        },
        {
            "Id": 2004,
            "Codigo": 2914802,
            "Nome": "Itabuna",
            "Uf": "BA"
        },
        {
            "Id": 2005,
            "Codigo": 2914901,
            "Nome": "Itacaré",
            "Uf": "BA"
        },
        {
            "Id": 2006,
            "Codigo": 2915007,
            "Nome": "Itaeté",
            "Uf": "BA"
        },
        {
            "Id": 2007,
            "Codigo": 2915106,
            "Nome": "Itagi",
            "Uf": "BA"
        },
        {
            "Id": 2008,
            "Codigo": 2915205,
            "Nome": "Itagibá",
            "Uf": "BA"
        },
        {
            "Id": 2009,
            "Codigo": 2915304,
            "Nome": "Itagimirim",
            "Uf": "BA"
        },
        {
            "Id": 2010,
            "Codigo": 2915353,
            "Nome": "Itaguaçu da Bahia",
            "Uf": "BA"
        },
        {
            "Id": 2011,
            "Codigo": 2915403,
            "Nome": "Itaju do Colônia",
            "Uf": "BA"
        },
        {
            "Id": 2012,
            "Codigo": 2915502,
            "Nome": "Itajuípe",
            "Uf": "BA"
        },
        {
            "Id": 2013,
            "Codigo": 2915601,
            "Nome": "Itamaraju",
            "Uf": "BA"
        },
        {
            "Id": 2014,
            "Codigo": 2915700,
            "Nome": "Itamari",
            "Uf": "BA"
        },
        {
            "Id": 2015,
            "Codigo": 2915809,
            "Nome": "Itambé",
            "Uf": "BA"
        },
        {
            "Id": 2016,
            "Codigo": 2915908,
            "Nome": "Itanagra",
            "Uf": "BA"
        },
        {
            "Id": 2017,
            "Codigo": 2916005,
            "Nome": "Itanhém",
            "Uf": "BA"
        },
        {
            "Id": 2018,
            "Codigo": 2916104,
            "Nome": "Itaparica",
            "Uf": "BA"
        },
        {
            "Id": 2019,
            "Codigo": 2916203,
            "Nome": "Itapé",
            "Uf": "BA"
        },
        {
            "Id": 2020,
            "Codigo": 2916302,
            "Nome": "Itapebi",
            "Uf": "BA"
        },
        {
            "Id": 2021,
            "Codigo": 2916401,
            "Nome": "Itapetinga",
            "Uf": "BA"
        },
        {
            "Id": 2022,
            "Codigo": 2916500,
            "Nome": "Itapicuru",
            "Uf": "BA"
        },
        {
            "Id": 2023,
            "Codigo": 2916609,
            "Nome": "Itapitanga",
            "Uf": "BA"
        },
        {
            "Id": 2024,
            "Codigo": 2916708,
            "Nome": "Itaquara",
            "Uf": "BA"
        },
        {
            "Id": 2025,
            "Codigo": 2916807,
            "Nome": "Itarantim",
            "Uf": "BA"
        },
        {
            "Id": 2026,
            "Codigo": 2916856,
            "Nome": "Itatim",
            "Uf": "BA"
        },
        {
            "Id": 2027,
            "Codigo": 2916906,
            "Nome": "Itiruçu",
            "Uf": "BA"
        },
        {
            "Id": 2028,
            "Codigo": 2917003,
            "Nome": "Itiúba",
            "Uf": "BA"
        },
        {
            "Id": 2029,
            "Codigo": 2917102,
            "Nome": "Itororó",
            "Uf": "BA"
        },
        {
            "Id": 2030,
            "Codigo": 2917201,
            "Nome": "Ituaçu",
            "Uf": "BA"
        },
        {
            "Id": 2031,
            "Codigo": 2917300,
            "Nome": "Ituberá",
            "Uf": "BA"
        },
        {
            "Id": 2032,
            "Codigo": 2917334,
            "Nome": "Iuiú",
            "Uf": "BA"
        },
        {
            "Id": 2033,
            "Codigo": 2917359,
            "Nome": "Jaborandi",
            "Uf": "BA"
        },
        {
            "Id": 2034,
            "Codigo": 2917409,
            "Nome": "Jacaraci",
            "Uf": "BA"
        },
        {
            "Id": 2035,
            "Codigo": 2917508,
            "Nome": "Jacobina",
            "Uf": "BA"
        },
        {
            "Id": 2036,
            "Codigo": 2917607,
            "Nome": "Jaguaquara",
            "Uf": "BA"
        },
        {
            "Id": 2037,
            "Codigo": 2917706,
            "Nome": "Jaguarari",
            "Uf": "BA"
        },
        {
            "Id": 2038,
            "Codigo": 2917805,
            "Nome": "Jaguaripe",
            "Uf": "BA"
        },
        {
            "Id": 2039,
            "Codigo": 2917904,
            "Nome": "Jandaíra",
            "Uf": "BA"
        },
        {
            "Id": 2040,
            "Codigo": 2918001,
            "Nome": "Jequié",
            "Uf": "BA"
        },
        {
            "Id": 2041,
            "Codigo": 2918100,
            "Nome": "Jeremoabo",
            "Uf": "BA"
        },
        {
            "Id": 2042,
            "Codigo": 2918209,
            "Nome": "Jiquiriçá",
            "Uf": "BA"
        },
        {
            "Id": 2043,
            "Codigo": 2918308,
            "Nome": "Jitaúna",
            "Uf": "BA"
        },
        {
            "Id": 2044,
            "Codigo": 2918357,
            "Nome": "João Dourado",
            "Uf": "BA"
        },
        {
            "Id": 2045,
            "Codigo": 2918407,
            "Nome": "Juazeiro",
            "Uf": "BA"
        },
        {
            "Id": 2046,
            "Codigo": 2918456,
            "Nome": "Jucuruçu",
            "Uf": "BA"
        },
        {
            "Id": 2047,
            "Codigo": 2918506,
            "Nome": "Jussara",
            "Uf": "BA"
        },
        {
            "Id": 2048,
            "Codigo": 2918555,
            "Nome": "Jussari",
            "Uf": "BA"
        },
        {
            "Id": 2049,
            "Codigo": 2918605,
            "Nome": "Jussiape",
            "Uf": "BA"
        },
        {
            "Id": 2050,
            "Codigo": 2918704,
            "Nome": "Lafaiete Coutinho",
            "Uf": "BA"
        },
        {
            "Id": 2051,
            "Codigo": 2918753,
            "Nome": "Lagoa Real",
            "Uf": "BA"
        },
        {
            "Id": 2052,
            "Codigo": 2918803,
            "Nome": "Laje",
            "Uf": "BA"
        },
        {
            "Id": 2053,
            "Codigo": 2918902,
            "Nome": "Lajedão",
            "Uf": "BA"
        },
        {
            "Id": 2054,
            "Codigo": 2919009,
            "Nome": "Lajedinho",
            "Uf": "BA"
        },
        {
            "Id": 2055,
            "Codigo": 2919058,
            "Nome": "Lajedo do Tabocal",
            "Uf": "BA"
        },
        {
            "Id": 2056,
            "Codigo": 2919108,
            "Nome": "Lamarão",
            "Uf": "BA"
        },
        {
            "Id": 2057,
            "Codigo": 2919157,
            "Nome": "Lapão",
            "Uf": "BA"
        },
        {
            "Id": 2058,
            "Codigo": 2919207,
            "Nome": "Lauro de Freitas",
            "Uf": "BA"
        },
        {
            "Id": 2059,
            "Codigo": 2919306,
            "Nome": "Lençóis",
            "Uf": "BA"
        },
        {
            "Id": 2060,
            "Codigo": 2919405,
            "Nome": "Licínio de Almeida",
            "Uf": "BA"
        },
        {
            "Id": 2061,
            "Codigo": 2919504,
            "Nome": "Livramento de Nossa Senhora",
            "Uf": "BA"
        },
        {
            "Id": 2062,
            "Codigo": 2919553,
            "Nome": "Luís Eduardo Magalhães",
            "Uf": "BA"
        },
        {
            "Id": 2063,
            "Codigo": 2919603,
            "Nome": "Macajuba",
            "Uf": "BA"
        },
        {
            "Id": 2064,
            "Codigo": 2919702,
            "Nome": "Macarani",
            "Uf": "BA"
        },
        {
            "Id": 2065,
            "Codigo": 2919801,
            "Nome": "Macaúbas",
            "Uf": "BA"
        },
        {
            "Id": 2066,
            "Codigo": 2919900,
            "Nome": "Macururé",
            "Uf": "BA"
        },
        {
            "Id": 2067,
            "Codigo": 2919926,
            "Nome": "Madre de Deus",
            "Uf": "BA"
        },
        {
            "Id": 2068,
            "Codigo": 2919959,
            "Nome": "Maetinga",
            "Uf": "BA"
        },
        {
            "Id": 2069,
            "Codigo": 2920007,
            "Nome": "Maiquinique",
            "Uf": "BA"
        },
        {
            "Id": 2070,
            "Codigo": 2920106,
            "Nome": "Mairi",
            "Uf": "BA"
        },
        {
            "Id": 2071,
            "Codigo": 2920205,
            "Nome": "Malhada",
            "Uf": "BA"
        },
        {
            "Id": 2072,
            "Codigo": 2920304,
            "Nome": "Malhada de Pedras",
            "Uf": "BA"
        },
        {
            "Id": 2073,
            "Codigo": 2920403,
            "Nome": "Manoel Vitorino",
            "Uf": "BA"
        },
        {
            "Id": 2074,
            "Codigo": 2920452,
            "Nome": "Mansidão",
            "Uf": "BA"
        },
        {
            "Id": 2075,
            "Codigo": 2920502,
            "Nome": "Maracás",
            "Uf": "BA"
        },
        {
            "Id": 2076,
            "Codigo": 2920601,
            "Nome": "Maragogipe",
            "Uf": "BA"
        },
        {
            "Id": 2077,
            "Codigo": 2920700,
            "Nome": "Maraú",
            "Uf": "BA"
        },
        {
            "Id": 2078,
            "Codigo": 2920809,
            "Nome": "Marcionílio Souza",
            "Uf": "BA"
        },
        {
            "Id": 2079,
            "Codigo": 2920908,
            "Nome": "Mascote",
            "Uf": "BA"
        },
        {
            "Id": 2080,
            "Codigo": 2921005,
            "Nome": "Mata de São João",
            "Uf": "BA"
        },
        {
            "Id": 2081,
            "Codigo": 2921054,
            "Nome": "Matina",
            "Uf": "BA"
        },
        {
            "Id": 2082,
            "Codigo": 2921104,
            "Nome": "Medeiros Neto",
            "Uf": "BA"
        },
        {
            "Id": 2083,
            "Codigo": 2921203,
            "Nome": "Miguel Calmon",
            "Uf": "BA"
        },
        {
            "Id": 2084,
            "Codigo": 2921302,
            "Nome": "Milagres",
            "Uf": "BA"
        },
        {
            "Id": 2085,
            "Codigo": 2921401,
            "Nome": "Mirangaba",
            "Uf": "BA"
        },
        {
            "Id": 2086,
            "Codigo": 2921450,
            "Nome": "Mirante",
            "Uf": "BA"
        },
        {
            "Id": 2087,
            "Codigo": 2921500,
            "Nome": "Monte Santo",
            "Uf": "BA"
        },
        {
            "Id": 2088,
            "Codigo": 2921609,
            "Nome": "Morpará",
            "Uf": "BA"
        },
        {
            "Id": 2089,
            "Codigo": 2921708,
            "Nome": "Morro do Chapéu",
            "Uf": "BA"
        },
        {
            "Id": 2090,
            "Codigo": 2921807,
            "Nome": "Mortugaba",
            "Uf": "BA"
        },
        {
            "Id": 2091,
            "Codigo": 2921906,
            "Nome": "Mucugê",
            "Uf": "BA"
        },
        {
            "Id": 2092,
            "Codigo": 2922003,
            "Nome": "Mucuri",
            "Uf": "BA"
        },
        {
            "Id": 2093,
            "Codigo": 2922052,
            "Nome": "Mulungu do Morro",
            "Uf": "BA"
        },
        {
            "Id": 2094,
            "Codigo": 2922102,
            "Nome": "Mundo Novo",
            "Uf": "BA"
        },
        {
            "Id": 2095,
            "Codigo": 2922201,
            "Nome": "Muniz Ferreira",
            "Uf": "BA"
        },
        {
            "Id": 2096,
            "Codigo": 2922250,
            "Nome": "Muquém de São Francisco",
            "Uf": "BA"
        },
        {
            "Id": 2097,
            "Codigo": 2922300,
            "Nome": "Muritiba",
            "Uf": "BA"
        },
        {
            "Id": 2098,
            "Codigo": 2922409,
            "Nome": "Mutuípe",
            "Uf": "BA"
        },
        {
            "Id": 2099,
            "Codigo": 2922508,
            "Nome": "Nazaré",
            "Uf": "BA"
        },
        {
            "Id": 2100,
            "Codigo": 2922607,
            "Nome": "Nilo Peçanha",
            "Uf": "BA"
        },
        {
            "Id": 2101,
            "Codigo": 2922656,
            "Nome": "Nordestina",
            "Uf": "BA"
        },
        {
            "Id": 2102,
            "Codigo": 2922706,
            "Nome": "Nova Canaã",
            "Uf": "BA"
        },
        {
            "Id": 2103,
            "Codigo": 2922730,
            "Nome": "Nova Fátima",
            "Uf": "BA"
        },
        {
            "Id": 2104,
            "Codigo": 2922755,
            "Nome": "Nova Ibiá",
            "Uf": "BA"
        },
        {
            "Id": 2105,
            "Codigo": 2922805,
            "Nome": "Nova Itarana",
            "Uf": "BA"
        },
        {
            "Id": 2106,
            "Codigo": 2922854,
            "Nome": "Nova Redenção",
            "Uf": "BA"
        },
        {
            "Id": 2107,
            "Codigo": 2922904,
            "Nome": "Nova Soure",
            "Uf": "BA"
        },
        {
            "Id": 2108,
            "Codigo": 2923001,
            "Nome": "Nova Viçosa",
            "Uf": "BA"
        },
        {
            "Id": 2109,
            "Codigo": 2923035,
            "Nome": "Novo Horizonte",
            "Uf": "BA"
        },
        {
            "Id": 2110,
            "Codigo": 2923050,
            "Nome": "Novo Triunfo",
            "Uf": "BA"
        },
        {
            "Id": 2111,
            "Codigo": 2923100,
            "Nome": "Olindina",
            "Uf": "BA"
        },
        {
            "Id": 2112,
            "Codigo": 2923209,
            "Nome": "Oliveira dos Brejinhos",
            "Uf": "BA"
        },
        {
            "Id": 2113,
            "Codigo": 2923308,
            "Nome": "Ouriçangas",
            "Uf": "BA"
        },
        {
            "Id": 2114,
            "Codigo": 2923357,
            "Nome": "Ourolândia",
            "Uf": "BA"
        },
        {
            "Id": 2115,
            "Codigo": 2923407,
            "Nome": "Palmas de Monte Alto",
            "Uf": "BA"
        },
        {
            "Id": 2116,
            "Codigo": 2923506,
            "Nome": "Palmeiras",
            "Uf": "BA"
        },
        {
            "Id": 2117,
            "Codigo": 2923605,
            "Nome": "Paramirim",
            "Uf": "BA"
        },
        {
            "Id": 2118,
            "Codigo": 2923704,
            "Nome": "Paratinga",
            "Uf": "BA"
        },
        {
            "Id": 2119,
            "Codigo": 2923803,
            "Nome": "Paripiranga",
            "Uf": "BA"
        },
        {
            "Id": 2120,
            "Codigo": 2923902,
            "Nome": "Pau Brasil",
            "Uf": "BA"
        },
        {
            "Id": 2121,
            "Codigo": 2924009,
            "Nome": "Paulo Afonso",
            "Uf": "BA"
        },
        {
            "Id": 2122,
            "Codigo": 2924058,
            "Nome": "Pé de Serra",
            "Uf": "BA"
        },
        {
            "Id": 2123,
            "Codigo": 2924108,
            "Nome": "Pedrão",
            "Uf": "BA"
        },
        {
            "Id": 2124,
            "Codigo": 2924207,
            "Nome": "Pedro Alexandre",
            "Uf": "BA"
        },
        {
            "Id": 2125,
            "Codigo": 2924306,
            "Nome": "Piatã",
            "Uf": "BA"
        },
        {
            "Id": 2126,
            "Codigo": 2924405,
            "Nome": "Pilão Arcado",
            "Uf": "BA"
        },
        {
            "Id": 2127,
            "Codigo": 2924504,
            "Nome": "Pindaí",
            "Uf": "BA"
        },
        {
            "Id": 2128,
            "Codigo": 2924603,
            "Nome": "Pindobaçu",
            "Uf": "BA"
        },
        {
            "Id": 2129,
            "Codigo": 2924652,
            "Nome": "Pintadas",
            "Uf": "BA"
        },
        {
            "Id": 2130,
            "Codigo": 2924678,
            "Nome": "Piraí do Norte",
            "Uf": "BA"
        },
        {
            "Id": 2131,
            "Codigo": 2924702,
            "Nome": "Piripá",
            "Uf": "BA"
        },
        {
            "Id": 2132,
            "Codigo": 2924801,
            "Nome": "Piritiba",
            "Uf": "BA"
        },
        {
            "Id": 2133,
            "Codigo": 2924900,
            "Nome": "Planaltino",
            "Uf": "BA"
        },
        {
            "Id": 2134,
            "Codigo": 2925006,
            "Nome": "Planalto",
            "Uf": "BA"
        },
        {
            "Id": 2135,
            "Codigo": 2925105,
            "Nome": "Poções",
            "Uf": "BA"
        },
        {
            "Id": 2136,
            "Codigo": 2925204,
            "Nome": "Pojuca",
            "Uf": "BA"
        },
        {
            "Id": 2137,
            "Codigo": 2925253,
            "Nome": "Ponto Novo",
            "Uf": "BA"
        },
        {
            "Id": 2138,
            "Codigo": 2925303,
            "Nome": "Porto Seguro",
            "Uf": "BA"
        },
        {
            "Id": 2139,
            "Codigo": 2925402,
            "Nome": "Potiraguá",
            "Uf": "BA"
        },
        {
            "Id": 2140,
            "Codigo": 2925501,
            "Nome": "Prado",
            "Uf": "BA"
        },
        {
            "Id": 2141,
            "Codigo": 2925600,
            "Nome": "Presidente Dutra",
            "Uf": "BA"
        },
        {
            "Id": 2142,
            "Codigo": 2925709,
            "Nome": "Presidente Jânio Quadros",
            "Uf": "BA"
        },
        {
            "Id": 2143,
            "Codigo": 2925758,
            "Nome": "Presidente Tancredo Neves",
            "Uf": "BA"
        },
        {
            "Id": 2144,
            "Codigo": 2925808,
            "Nome": "Queimadas",
            "Uf": "BA"
        },
        {
            "Id": 2145,
            "Codigo": 2925907,
            "Nome": "Quijingue",
            "Uf": "BA"
        },
        {
            "Id": 2146,
            "Codigo": 2925931,
            "Nome": "Quixabeira",
            "Uf": "BA"
        },
        {
            "Id": 2147,
            "Codigo": 2925956,
            "Nome": "Rafael Jambeiro",
            "Uf": "BA"
        },
        {
            "Id": 2148,
            "Codigo": 2926004,
            "Nome": "Remanso",
            "Uf": "BA"
        },
        {
            "Id": 2149,
            "Codigo": 2926103,
            "Nome": "Retirolândia",
            "Uf": "BA"
        },
        {
            "Id": 2150,
            "Codigo": 2926202,
            "Nome": "Riachão das Neves",
            "Uf": "BA"
        },
        {
            "Id": 2151,
            "Codigo": 2926301,
            "Nome": "Riachão do Jacuípe",
            "Uf": "BA"
        },
        {
            "Id": 2152,
            "Codigo": 2926400,
            "Nome": "Riacho de Santana",
            "Uf": "BA"
        },
        {
            "Id": 2153,
            "Codigo": 2926509,
            "Nome": "Ribeira do Amparo",
            "Uf": "BA"
        },
        {
            "Id": 2154,
            "Codigo": 2926608,
            "Nome": "Ribeira do Pombal",
            "Uf": "BA"
        },
        {
            "Id": 2155,
            "Codigo": 2926657,
            "Nome": "Ribeirão do Largo",
            "Uf": "BA"
        },
        {
            "Id": 2156,
            "Codigo": 2926707,
            "Nome": "Rio de Contas",
            "Uf": "BA"
        },
        {
            "Id": 2157,
            "Codigo": 2926806,
            "Nome": "Rio do Antônio",
            "Uf": "BA"
        },
        {
            "Id": 2158,
            "Codigo": 2926905,
            "Nome": "Rio do Pires",
            "Uf": "BA"
        },
        {
            "Id": 2159,
            "Codigo": 2927002,
            "Nome": "Rio Real",
            "Uf": "BA"
        },
        {
            "Id": 2160,
            "Codigo": 2927101,
            "Nome": "Rodelas",
            "Uf": "BA"
        },
        {
            "Id": 2161,
            "Codigo": 2927200,
            "Nome": "Ruy Barbosa",
            "Uf": "BA"
        },
        {
            "Id": 2162,
            "Codigo": 2927309,
            "Nome": "Salinas da Margarida",
            "Uf": "BA"
        },
        {
            "Id": 2163,
            "Codigo": 2927408,
            "Nome": "Salvador",
            "Uf": "BA"
        },
        {
            "Id": 2164,
            "Codigo": 2927507,
            "Nome": "Santa Bárbara",
            "Uf": "BA"
        },
        {
            "Id": 2165,
            "Codigo": 2927606,
            "Nome": "Santa Brígida",
            "Uf": "BA"
        },
        {
            "Id": 2166,
            "Codigo": 2927705,
            "Nome": "Santa Cruz Cabrália",
            "Uf": "BA"
        },
        {
            "Id": 2167,
            "Codigo": 2927804,
            "Nome": "Santa Cruz da Vitória",
            "Uf": "BA"
        },
        {
            "Id": 2168,
            "Codigo": 2927903,
            "Nome": "Santa Inês",
            "Uf": "BA"
        },
        {
            "Id": 2169,
            "Codigo": 2928000,
            "Nome": "Santaluz",
            "Uf": "BA"
        },
        {
            "Id": 2170,
            "Codigo": 2928059,
            "Nome": "Santa Luzia",
            "Uf": "BA"
        },
        {
            "Id": 2171,
            "Codigo": 2928109,
            "Nome": "Santa Maria da Vitória",
            "Uf": "BA"
        },
        {
            "Id": 2172,
            "Codigo": 2928208,
            "Nome": "Santana",
            "Uf": "BA"
        },
        {
            "Id": 2173,
            "Codigo": 2928307,
            "Nome": "Santanópolis",
            "Uf": "BA"
        },
        {
            "Id": 2174,
            "Codigo": 2928406,
            "Nome": "Santa Rita de Cássia",
            "Uf": "BA"
        },
        {
            "Id": 2175,
            "Codigo": 2928505,
            "Nome": "Santa Teresinha",
            "Uf": "BA"
        },
        {
            "Id": 2176,
            "Codigo": 2928604,
            "Nome": "Santo Amaro",
            "Uf": "BA"
        },
        {
            "Id": 2177,
            "Codigo": 2928703,
            "Nome": "Santo Antônio de Jesus",
            "Uf": "BA"
        },
        {
            "Id": 2178,
            "Codigo": 2928802,
            "Nome": "Santo Estêvão",
            "Uf": "BA"
        },
        {
            "Id": 2179,
            "Codigo": 2928901,
            "Nome": "São Desidério",
            "Uf": "BA"
        },
        {
            "Id": 2180,
            "Codigo": 2928950,
            "Nome": "São Domingos",
            "Uf": "BA"
        },
        {
            "Id": 2181,
            "Codigo": 2929008,
            "Nome": "São Félix",
            "Uf": "BA"
        },
        {
            "Id": 2182,
            "Codigo": 2929057,
            "Nome": "São Félix do Coribe",
            "Uf": "BA"
        },
        {
            "Id": 2183,
            "Codigo": 2929107,
            "Nome": "São Felipe",
            "Uf": "BA"
        },
        {
            "Id": 2184,
            "Codigo": 2929206,
            "Nome": "São Francisco do Conde",
            "Uf": "BA"
        },
        {
            "Id": 2185,
            "Codigo": 2929255,
            "Nome": "São Gabriel",
            "Uf": "BA"
        },
        {
            "Id": 2186,
            "Codigo": 2929305,
            "Nome": "São Gonçalo dos Campos",
            "Uf": "BA"
        },
        {
            "Id": 2187,
            "Codigo": 2929354,
            "Nome": "São José da Vitória",
            "Uf": "BA"
        },
        {
            "Id": 2188,
            "Codigo": 2929370,
            "Nome": "São José do Jacuípe",
            "Uf": "BA"
        },
        {
            "Id": 2189,
            "Codigo": 2929404,
            "Nome": "São Miguel das Matas",
            "Uf": "BA"
        },
        {
            "Id": 2190,
            "Codigo": 2929503,
            "Nome": "São Sebastião do Passé",
            "Uf": "BA"
        },
        {
            "Id": 2191,
            "Codigo": 2929602,
            "Nome": "Sapeaçu",
            "Uf": "BA"
        },
        {
            "Id": 2192,
            "Codigo": 2929701,
            "Nome": "Sátiro Dias",
            "Uf": "BA"
        },
        {
            "Id": 2193,
            "Codigo": 2929750,
            "Nome": "Saubara",
            "Uf": "BA"
        },
        {
            "Id": 2194,
            "Codigo": 2929800,
            "Nome": "Saúde",
            "Uf": "BA"
        },
        {
            "Id": 2195,
            "Codigo": 2929909,
            "Nome": "Seabra",
            "Uf": "BA"
        },
        {
            "Id": 2196,
            "Codigo": 2930006,
            "Nome": "Sebastião Laranjeiras",
            "Uf": "BA"
        },
        {
            "Id": 2197,
            "Codigo": 2930105,
            "Nome": "Senhor do Bonfim",
            "Uf": "BA"
        },
        {
            "Id": 2198,
            "Codigo": 2930154,
            "Nome": "Serra do Ramalho",
            "Uf": "BA"
        },
        {
            "Id": 2199,
            "Codigo": 2930204,
            "Nome": "Sento Sé",
            "Uf": "BA"
        },
        {
            "Id": 2200,
            "Codigo": 2930303,
            "Nome": "Serra Dourada",
            "Uf": "BA"
        },
        {
            "Id": 2201,
            "Codigo": 2930402,
            "Nome": "Serra Preta",
            "Uf": "BA"
        },
        {
            "Id": 2202,
            "Codigo": 2930501,
            "Nome": "Serrinha",
            "Uf": "BA"
        },
        {
            "Id": 2203,
            "Codigo": 2930600,
            "Nome": "Serrolândia",
            "Uf": "BA"
        },
        {
            "Id": 2204,
            "Codigo": 2930709,
            "Nome": "Simões Filho",
            "Uf": "BA"
        },
        {
            "Id": 2205,
            "Codigo": 2930758,
            "Nome": "Sítio do Mato",
            "Uf": "BA"
        },
        {
            "Id": 2206,
            "Codigo": 2930766,
            "Nome": "Sítio do Quinto",
            "Uf": "BA"
        },
        {
            "Id": 2207,
            "Codigo": 2930774,
            "Nome": "Sobradinho",
            "Uf": "BA"
        },
        {
            "Id": 2208,
            "Codigo": 2930808,
            "Nome": "Souto Soares",
            "Uf": "BA"
        },
        {
            "Id": 2209,
            "Codigo": 2930907,
            "Nome": "Tabocas do Brejo Velho",
            "Uf": "BA"
        },
        {
            "Id": 2210,
            "Codigo": 2931004,
            "Nome": "Tanhaçu",
            "Uf": "BA"
        },
        {
            "Id": 2211,
            "Codigo": 2931053,
            "Nome": "Tanque Novo",
            "Uf": "BA"
        },
        {
            "Id": 2212,
            "Codigo": 2931103,
            "Nome": "Tanquinho",
            "Uf": "BA"
        },
        {
            "Id": 2213,
            "Codigo": 2931202,
            "Nome": "Taperoá",
            "Uf": "BA"
        },
        {
            "Id": 2214,
            "Codigo": 2931301,
            "Nome": "Tapiramutá",
            "Uf": "BA"
        },
        {
            "Id": 2215,
            "Codigo": 2931350,
            "Nome": "Teixeira de Freitas",
            "Uf": "BA"
        },
        {
            "Id": 2216,
            "Codigo": 2931400,
            "Nome": "Teodoro Sampaio",
            "Uf": "BA"
        },
        {
            "Id": 2217,
            "Codigo": 2931509,
            "Nome": "Teofilândia",
            "Uf": "BA"
        },
        {
            "Id": 2218,
            "Codigo": 2931608,
            "Nome": "Teolândia",
            "Uf": "BA"
        },
        {
            "Id": 2219,
            "Codigo": 2931707,
            "Nome": "Terra Nova",
            "Uf": "BA"
        },
        {
            "Id": 2220,
            "Codigo": 2931806,
            "Nome": "Tremedal",
            "Uf": "BA"
        },
        {
            "Id": 2221,
            "Codigo": 2931905,
            "Nome": "Tucano",
            "Uf": "BA"
        },
        {
            "Id": 2222,
            "Codigo": 2932002,
            "Nome": "Uauá",
            "Uf": "BA"
        },
        {
            "Id": 2223,
            "Codigo": 2932101,
            "Nome": "Ubaíra",
            "Uf": "BA"
        },
        {
            "Id": 2224,
            "Codigo": 2932200,
            "Nome": "Ubaitaba",
            "Uf": "BA"
        },
        {
            "Id": 2225,
            "Codigo": 2932309,
            "Nome": "Ubatã",
            "Uf": "BA"
        },
        {
            "Id": 2226,
            "Codigo": 2932408,
            "Nome": "Uibaí",
            "Uf": "BA"
        },
        {
            "Id": 2227,
            "Codigo": 2932457,
            "Nome": "Umburanas",
            "Uf": "BA"
        },
        {
            "Id": 2228,
            "Codigo": 2932507,
            "Nome": "Una",
            "Uf": "BA"
        },
        {
            "Id": 2229,
            "Codigo": 2932606,
            "Nome": "Urandi",
            "Uf": "BA"
        },
        {
            "Id": 2230,
            "Codigo": 2932705,
            "Nome": "Uruçuca",
            "Uf": "BA"
        },
        {
            "Id": 2231,
            "Codigo": 2932804,
            "Nome": "Utinga",
            "Uf": "BA"
        },
        {
            "Id": 2232,
            "Codigo": 2932903,
            "Nome": "Valença",
            "Uf": "BA"
        },
        {
            "Id": 2233,
            "Codigo": 2933000,
            "Nome": "Valente",
            "Uf": "BA"
        },
        {
            "Id": 2234,
            "Codigo": 2933059,
            "Nome": "Várzea da Roça",
            "Uf": "BA"
        },
        {
            "Id": 2235,
            "Codigo": 2933109,
            "Nome": "Várzea do Poço",
            "Uf": "BA"
        },
        {
            "Id": 2236,
            "Codigo": 2933158,
            "Nome": "Várzea Nova",
            "Uf": "BA"
        },
        {
            "Id": 2237,
            "Codigo": 2933174,
            "Nome": "Varzedo",
            "Uf": "BA"
        },
        {
            "Id": 2238,
            "Codigo": 2933208,
            "Nome": "Vera Cruz",
            "Uf": "BA"
        },
        {
            "Id": 2239,
            "Codigo": 2933257,
            "Nome": "Vereda",
            "Uf": "BA"
        },
        {
            "Id": 2240,
            "Codigo": 2933307,
            "Nome": "Vitória da Conquista",
            "Uf": "BA"
        },
        {
            "Id": 2241,
            "Codigo": 2933406,
            "Nome": "Wagner",
            "Uf": "BA"
        },
        {
            "Id": 2242,
            "Codigo": 2933455,
            "Nome": "Wanderley",
            "Uf": "BA"
        },
        {
            "Id": 2243,
            "Codigo": 2933505,
            "Nome": "Wenceslau Guimarães",
            "Uf": "BA"
        },
        {
            "Id": 2244,
            "Codigo": 2933604,
            "Nome": "Xique-Xique",
            "Uf": "BA"
        },
        {
            "Id": 2245,
            "Codigo": 3100104,
            "Nome": "Abadia dos Dourados",
            "Uf": "MG"
        },
        {
            "Id": 2246,
            "Codigo": 3100203,
            "Nome": "Abaeté",
            "Uf": "MG"
        },
        {
            "Id": 2247,
            "Codigo": 3100302,
            "Nome": "Abre Campo",
            "Uf": "MG"
        },
        {
            "Id": 2248,
            "Codigo": 3100401,
            "Nome": "Acaiaca",
            "Uf": "MG"
        },
        {
            "Id": 2249,
            "Codigo": 3100500,
            "Nome": "Açucena",
            "Uf": "MG"
        },
        {
            "Id": 2250,
            "Codigo": 3100609,
            "Nome": "Água Boa",
            "Uf": "MG"
        },
        {
            "Id": 2251,
            "Codigo": 3100708,
            "Nome": "Água Comprida",
            "Uf": "MG"
        },
        {
            "Id": 2252,
            "Codigo": 3100807,
            "Nome": "Aguanil",
            "Uf": "MG"
        },
        {
            "Id": 2253,
            "Codigo": 3100906,
            "Nome": "Águas Formosas",
            "Uf": "MG"
        },
        {
            "Id": 2254,
            "Codigo": 3101003,
            "Nome": "Águas Vermelhas",
            "Uf": "MG"
        },
        {
            "Id": 2255,
            "Codigo": 3101102,
            "Nome": "Aimorés",
            "Uf": "MG"
        },
        {
            "Id": 2256,
            "Codigo": 3101201,
            "Nome": "Aiuruoca",
            "Uf": "MG"
        },
        {
            "Id": 2257,
            "Codigo": 3101300,
            "Nome": "Alagoa",
            "Uf": "MG"
        },
        {
            "Id": 2258,
            "Codigo": 3101409,
            "Nome": "Albertina",
            "Uf": "MG"
        },
        {
            "Id": 2259,
            "Codigo": 3101508,
            "Nome": "Além Paraíba",
            "Uf": "MG"
        },
        {
            "Id": 2260,
            "Codigo": 3101607,
            "Nome": "Alfenas",
            "Uf": "MG"
        },
        {
            "Id": 2261,
            "Codigo": 3101631,
            "Nome": "Alfredo Vasconcelos",
            "Uf": "MG"
        },
        {
            "Id": 2262,
            "Codigo": 3101706,
            "Nome": "Almenara",
            "Uf": "MG"
        },
        {
            "Id": 2263,
            "Codigo": 3101805,
            "Nome": "Alpercata",
            "Uf": "MG"
        },
        {
            "Id": 2264,
            "Codigo": 3101904,
            "Nome": "Alpinópolis",
            "Uf": "MG"
        },
        {
            "Id": 2265,
            "Codigo": 3102001,
            "Nome": "Alterosa",
            "Uf": "MG"
        },
        {
            "Id": 2266,
            "Codigo": 3102050,
            "Nome": "Alto Caparaó",
            "Uf": "MG"
        },
        {
            "Id": 2267,
            "Codigo": 3102100,
            "Nome": "Alto Rio Doce",
            "Uf": "MG"
        },
        {
            "Id": 2268,
            "Codigo": 3102209,
            "Nome": "Alvarenga",
            "Uf": "MG"
        },
        {
            "Id": 2269,
            "Codigo": 3102308,
            "Nome": "Alvinópolis",
            "Uf": "MG"
        },
        {
            "Id": 2270,
            "Codigo": 3102407,
            "Nome": "Alvorada de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2271,
            "Codigo": 3102506,
            "Nome": "Amparo do Serra",
            "Uf": "MG"
        },
        {
            "Id": 2272,
            "Codigo": 3102605,
            "Nome": "Andradas",
            "Uf": "MG"
        },
        {
            "Id": 2273,
            "Codigo": 3102704,
            "Nome": "Cachoeira de Pajeú",
            "Uf": "MG"
        },
        {
            "Id": 2274,
            "Codigo": 3102803,
            "Nome": "Andrelândia",
            "Uf": "MG"
        },
        {
            "Id": 2275,
            "Codigo": 3102852,
            "Nome": "Angelândia",
            "Uf": "MG"
        },
        {
            "Id": 2276,
            "Codigo": 3102902,
            "Nome": "Antônio Carlos",
            "Uf": "MG"
        },
        {
            "Id": 2277,
            "Codigo": 3103009,
            "Nome": "Antônio Dias",
            "Uf": "MG"
        },
        {
            "Id": 2278,
            "Codigo": 3103108,
            "Nome": "Antônio Prado de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2279,
            "Codigo": 3103207,
            "Nome": "Araçaí",
            "Uf": "MG"
        },
        {
            "Id": 2280,
            "Codigo": 3103306,
            "Nome": "Aracitaba",
            "Uf": "MG"
        },
        {
            "Id": 2281,
            "Codigo": 3103405,
            "Nome": "Araçuaí",
            "Uf": "MG"
        },
        {
            "Id": 2282,
            "Codigo": 3103504,
            "Nome": "Araguari",
            "Uf": "MG"
        },
        {
            "Id": 2283,
            "Codigo": 3103603,
            "Nome": "Arantina",
            "Uf": "MG"
        },
        {
            "Id": 2284,
            "Codigo": 3103702,
            "Nome": "Araponga",
            "Uf": "MG"
        },
        {
            "Id": 2285,
            "Codigo": 3103751,
            "Nome": "Araporã",
            "Uf": "MG"
        },
        {
            "Id": 2286,
            "Codigo": 3103801,
            "Nome": "Arapuá",
            "Uf": "MG"
        },
        {
            "Id": 2287,
            "Codigo": 3103900,
            "Nome": "Araújos",
            "Uf": "MG"
        },
        {
            "Id": 2288,
            "Codigo": 3104007,
            "Nome": "Araxá",
            "Uf": "MG"
        },
        {
            "Id": 2289,
            "Codigo": 3104106,
            "Nome": "Arceburgo",
            "Uf": "MG"
        },
        {
            "Id": 2290,
            "Codigo": 3104205,
            "Nome": "Arcos",
            "Uf": "MG"
        },
        {
            "Id": 2291,
            "Codigo": 3104304,
            "Nome": "Areado",
            "Uf": "MG"
        },
        {
            "Id": 2292,
            "Codigo": 3104403,
            "Nome": "Argirita",
            "Uf": "MG"
        },
        {
            "Id": 2293,
            "Codigo": 3104452,
            "Nome": "Aricanduva",
            "Uf": "MG"
        },
        {
            "Id": 2294,
            "Codigo": 3104502,
            "Nome": "Arinos",
            "Uf": "MG"
        },
        {
            "Id": 2295,
            "Codigo": 3104601,
            "Nome": "Astolfo Dutra",
            "Uf": "MG"
        },
        {
            "Id": 2296,
            "Codigo": 3104700,
            "Nome": "Ataléia",
            "Uf": "MG"
        },
        {
            "Id": 2297,
            "Codigo": 3104809,
            "Nome": "Augusto de Lima",
            "Uf": "MG"
        },
        {
            "Id": 2298,
            "Codigo": 3104908,
            "Nome": "Baependi",
            "Uf": "MG"
        },
        {
            "Id": 2299,
            "Codigo": 3105004,
            "Nome": "Baldim",
            "Uf": "MG"
        },
        {
            "Id": 2300,
            "Codigo": 3105103,
            "Nome": "Bambuí",
            "Uf": "MG"
        },
        {
            "Id": 2301,
            "Codigo": 3105202,
            "Nome": "Bandeira",
            "Uf": "MG"
        },
        {
            "Id": 2302,
            "Codigo": 3105301,
            "Nome": "Bandeira do Sul",
            "Uf": "MG"
        },
        {
            "Id": 2303,
            "Codigo": 3105400,
            "Nome": "Barão de Cocais",
            "Uf": "MG"
        },
        {
            "Id": 2304,
            "Codigo": 3105509,
            "Nome": "Barão de Monte Alto",
            "Uf": "MG"
        },
        {
            "Id": 2305,
            "Codigo": 3105608,
            "Nome": "Barbacena",
            "Uf": "MG"
        },
        {
            "Id": 2306,
            "Codigo": 3105707,
            "Nome": "Barra Longa",
            "Uf": "MG"
        },
        {
            "Id": 2307,
            "Codigo": 3105905,
            "Nome": "Barroso",
            "Uf": "MG"
        },
        {
            "Id": 2308,
            "Codigo": 3106002,
            "Nome": "Bela Vista de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2309,
            "Codigo": 3106101,
            "Nome": "Belmiro Braga",
            "Uf": "MG"
        },
        {
            "Id": 2310,
            "Codigo": 3106200,
            "Nome": "Belo Horizonte",
            "Uf": "MG"
        },
        {
            "Id": 2311,
            "Codigo": 3106309,
            "Nome": "Belo Oriente",
            "Uf": "MG"
        },
        {
            "Id": 2312,
            "Codigo": 3106408,
            "Nome": "Belo Vale",
            "Uf": "MG"
        },
        {
            "Id": 2313,
            "Codigo": 3106507,
            "Nome": "Berilo",
            "Uf": "MG"
        },
        {
            "Id": 2314,
            "Codigo": 3106606,
            "Nome": "Bertópolis",
            "Uf": "MG"
        },
        {
            "Id": 2315,
            "Codigo": 3106655,
            "Nome": "Berizal",
            "Uf": "MG"
        },
        {
            "Id": 2316,
            "Codigo": 3106705,
            "Nome": "Betim",
            "Uf": "MG"
        },
        {
            "Id": 2317,
            "Codigo": 3106804,
            "Nome": "Bias Fortes",
            "Uf": "MG"
        },
        {
            "Id": 2318,
            "Codigo": 3106903,
            "Nome": "Bicas",
            "Uf": "MG"
        },
        {
            "Id": 2319,
            "Codigo": 3107000,
            "Nome": "Biquinhas",
            "Uf": "MG"
        },
        {
            "Id": 2320,
            "Codigo": 3107109,
            "Nome": "Boa Esperança",
            "Uf": "MG"
        },
        {
            "Id": 2321,
            "Codigo": 3107208,
            "Nome": "Bocaina de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2322,
            "Codigo": 3107307,
            "Nome": "Bocaiúva",
            "Uf": "MG"
        },
        {
            "Id": 2323,
            "Codigo": 3107406,
            "Nome": "Bom Despacho",
            "Uf": "MG"
        },
        {
            "Id": 2324,
            "Codigo": 3107505,
            "Nome": "Bom Jardim de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2325,
            "Codigo": 3107604,
            "Nome": "Bom Jesus da Penha",
            "Uf": "MG"
        },
        {
            "Id": 2326,
            "Codigo": 3107703,
            "Nome": "Bom Jesus do Amparo",
            "Uf": "MG"
        },
        {
            "Id": 2327,
            "Codigo": 3107802,
            "Nome": "Bom Jesus do Galho",
            "Uf": "MG"
        },
        {
            "Id": 2328,
            "Codigo": 3107901,
            "Nome": "Bom Repouso",
            "Uf": "MG"
        },
        {
            "Id": 2329,
            "Codigo": 3108008,
            "Nome": "Bom Sucesso",
            "Uf": "MG"
        },
        {
            "Id": 2330,
            "Codigo": 3108107,
            "Nome": "Bonfim",
            "Uf": "MG"
        },
        {
            "Id": 2331,
            "Codigo": 3108206,
            "Nome": "Bonfinópolis de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2332,
            "Codigo": 3108255,
            "Nome": "Bonito de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2333,
            "Codigo": 3108305,
            "Nome": "Borda da Mata",
            "Uf": "MG"
        },
        {
            "Id": 2334,
            "Codigo": 3108404,
            "Nome": "Botelhos",
            "Uf": "MG"
        },
        {
            "Id": 2335,
            "Codigo": 3108503,
            "Nome": "Botumirim",
            "Uf": "MG"
        },
        {
            "Id": 2336,
            "Codigo": 3108552,
            "Nome": "Brasilândia de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2337,
            "Codigo": 3108602,
            "Nome": "Brasília de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2338,
            "Codigo": 3108701,
            "Nome": "Brás Pires",
            "Uf": "MG"
        },
        {
            "Id": 2339,
            "Codigo": 3108800,
            "Nome": "Braúnas",
            "Uf": "MG"
        },
        {
            "Id": 2340,
            "Codigo": 3108909,
            "Nome": "Brazópolis",
            "Uf": "MG"
        },
        {
            "Id": 2341,
            "Codigo": 3109006,
            "Nome": "Brumadinho",
            "Uf": "MG"
        },
        {
            "Id": 2342,
            "Codigo": 3109105,
            "Nome": "Bueno Brandão",
            "Uf": "MG"
        },
        {
            "Id": 2343,
            "Codigo": 3109204,
            "Nome": "Buenópolis",
            "Uf": "MG"
        },
        {
            "Id": 2344,
            "Codigo": 3109253,
            "Nome": "Bugre",
            "Uf": "MG"
        },
        {
            "Id": 2345,
            "Codigo": 3109303,
            "Nome": "Buritis",
            "Uf": "MG"
        },
        {
            "Id": 2346,
            "Codigo": 3109402,
            "Nome": "Buritizeiro",
            "Uf": "MG"
        },
        {
            "Id": 2347,
            "Codigo": 3109451,
            "Nome": "Cabeceira Grande",
            "Uf": "MG"
        },
        {
            "Id": 2348,
            "Codigo": 3109501,
            "Nome": "Cabo Verde",
            "Uf": "MG"
        },
        {
            "Id": 2349,
            "Codigo": 3109600,
            "Nome": "Cachoeira da Prata",
            "Uf": "MG"
        },
        {
            "Id": 2350,
            "Codigo": 3109709,
            "Nome": "Cachoeira de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2351,
            "Codigo": 3109808,
            "Nome": "Cachoeira Dourada",
            "Uf": "MG"
        },
        {
            "Id": 2352,
            "Codigo": 3109907,
            "Nome": "Caetanópolis",
            "Uf": "MG"
        },
        {
            "Id": 2353,
            "Codigo": 3110004,
            "Nome": "Caeté",
            "Uf": "MG"
        },
        {
            "Id": 2354,
            "Codigo": 3110103,
            "Nome": "Caiana",
            "Uf": "MG"
        },
        {
            "Id": 2355,
            "Codigo": 3110202,
            "Nome": "Cajuri",
            "Uf": "MG"
        },
        {
            "Id": 2356,
            "Codigo": 3110301,
            "Nome": "Caldas",
            "Uf": "MG"
        },
        {
            "Id": 2357,
            "Codigo": 3110400,
            "Nome": "Camacho",
            "Uf": "MG"
        },
        {
            "Id": 2358,
            "Codigo": 3110509,
            "Nome": "Camanducaia",
            "Uf": "MG"
        },
        {
            "Id": 2359,
            "Codigo": 3110608,
            "Nome": "Cambuí",
            "Uf": "MG"
        },
        {
            "Id": 2360,
            "Codigo": 3110707,
            "Nome": "Cambuquira",
            "Uf": "MG"
        },
        {
            "Id": 2361,
            "Codigo": 3110806,
            "Nome": "Campanário",
            "Uf": "MG"
        },
        {
            "Id": 2362,
            "Codigo": 3110905,
            "Nome": "Campanha",
            "Uf": "MG"
        },
        {
            "Id": 2363,
            "Codigo": 3111002,
            "Nome": "Campestre",
            "Uf": "MG"
        },
        {
            "Id": 2364,
            "Codigo": 3111101,
            "Nome": "Campina Verde",
            "Uf": "MG"
        },
        {
            "Id": 2365,
            "Codigo": 3111150,
            "Nome": "Campo Azul",
            "Uf": "MG"
        },
        {
            "Id": 2366,
            "Codigo": 3111200,
            "Nome": "Campo Belo",
            "Uf": "MG"
        },
        {
            "Id": 2367,
            "Codigo": 3111309,
            "Nome": "Campo do Meio",
            "Uf": "MG"
        },
        {
            "Id": 2368,
            "Codigo": 3111408,
            "Nome": "Campo Florido",
            "Uf": "MG"
        },
        {
            "Id": 2369,
            "Codigo": 3111507,
            "Nome": "Campos Altos",
            "Uf": "MG"
        },
        {
            "Id": 2370,
            "Codigo": 3111606,
            "Nome": "Campos Gerais",
            "Uf": "MG"
        },
        {
            "Id": 2371,
            "Codigo": 3111705,
            "Nome": "Canaã",
            "Uf": "MG"
        },
        {
            "Id": 2372,
            "Codigo": 3111804,
            "Nome": "Canápolis",
            "Uf": "MG"
        },
        {
            "Id": 2373,
            "Codigo": 3111903,
            "Nome": "Cana Verde",
            "Uf": "MG"
        },
        {
            "Id": 2374,
            "Codigo": 3112000,
            "Nome": "Candeias",
            "Uf": "MG"
        },
        {
            "Id": 2375,
            "Codigo": 3112059,
            "Nome": "Cantagalo",
            "Uf": "MG"
        },
        {
            "Id": 2376,
            "Codigo": 3112109,
            "Nome": "Caparaó",
            "Uf": "MG"
        },
        {
            "Id": 2377,
            "Codigo": 3112208,
            "Nome": "Capela Nova",
            "Uf": "MG"
        },
        {
            "Id": 2378,
            "Codigo": 3112307,
            "Nome": "Capelinha",
            "Uf": "MG"
        },
        {
            "Id": 2379,
            "Codigo": 3112406,
            "Nome": "Capetinga",
            "Uf": "MG"
        },
        {
            "Id": 2380,
            "Codigo": 3112505,
            "Nome": "Capim Branco",
            "Uf": "MG"
        },
        {
            "Id": 2381,
            "Codigo": 3112604,
            "Nome": "Capinópolis",
            "Uf": "MG"
        },
        {
            "Id": 2382,
            "Codigo": 3112653,
            "Nome": "Capitão Andrade",
            "Uf": "MG"
        },
        {
            "Id": 2383,
            "Codigo": 3112703,
            "Nome": "Capitão Enéas",
            "Uf": "MG"
        },
        {
            "Id": 2384,
            "Codigo": 3112802,
            "Nome": "Capitólio",
            "Uf": "MG"
        },
        {
            "Id": 2385,
            "Codigo": 3112901,
            "Nome": "Caputira",
            "Uf": "MG"
        },
        {
            "Id": 2386,
            "Codigo": 3113008,
            "Nome": "Caraí",
            "Uf": "MG"
        },
        {
            "Id": 2387,
            "Codigo": 3113107,
            "Nome": "Caranaíba",
            "Uf": "MG"
        },
        {
            "Id": 2388,
            "Codigo": 3113206,
            "Nome": "Carandaí",
            "Uf": "MG"
        },
        {
            "Id": 2389,
            "Codigo": 3113305,
            "Nome": "Carangola",
            "Uf": "MG"
        },
        {
            "Id": 2390,
            "Codigo": 3113404,
            "Nome": "Caratinga",
            "Uf": "MG"
        },
        {
            "Id": 2391,
            "Codigo": 3113503,
            "Nome": "Carbonita",
            "Uf": "MG"
        },
        {
            "Id": 2392,
            "Codigo": 3113602,
            "Nome": "Careaçu",
            "Uf": "MG"
        },
        {
            "Id": 2393,
            "Codigo": 3113701,
            "Nome": "Carlos Chagas",
            "Uf": "MG"
        },
        {
            "Id": 2394,
            "Codigo": 3113800,
            "Nome": "Carmésia",
            "Uf": "MG"
        },
        {
            "Id": 2395,
            "Codigo": 3113909,
            "Nome": "Carmo da Cachoeira",
            "Uf": "MG"
        },
        {
            "Id": 2396,
            "Codigo": 3114006,
            "Nome": "Carmo da Mata",
            "Uf": "MG"
        },
        {
            "Id": 2397,
            "Codigo": 3114105,
            "Nome": "Carmo de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2398,
            "Codigo": 3114204,
            "Nome": "Carmo do Cajuru",
            "Uf": "MG"
        },
        {
            "Id": 2399,
            "Codigo": 3114303,
            "Nome": "Carmo do Paranaíba",
            "Uf": "MG"
        },
        {
            "Id": 2400,
            "Codigo": 3114402,
            "Nome": "Carmo do Rio Claro",
            "Uf": "MG"
        },
        {
            "Id": 2401,
            "Codigo": 3114501,
            "Nome": "Carmópolis de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2402,
            "Codigo": 3114550,
            "Nome": "Carneirinho",
            "Uf": "MG"
        },
        {
            "Id": 2403,
            "Codigo": 3114600,
            "Nome": "Carrancas",
            "Uf": "MG"
        },
        {
            "Id": 2404,
            "Codigo": 3114709,
            "Nome": "Carvalhópolis",
            "Uf": "MG"
        },
        {
            "Id": 2405,
            "Codigo": 3114808,
            "Nome": "Carvalhos",
            "Uf": "MG"
        },
        {
            "Id": 2406,
            "Codigo": 3114907,
            "Nome": "Casa Grande",
            "Uf": "MG"
        },
        {
            "Id": 2407,
            "Codigo": 3115003,
            "Nome": "Cascalho Rico",
            "Uf": "MG"
        },
        {
            "Id": 2408,
            "Codigo": 3115102,
            "Nome": "Cássia",
            "Uf": "MG"
        },
        {
            "Id": 2409,
            "Codigo": 3115201,
            "Nome": "Conceição da Barra de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2410,
            "Codigo": 3115300,
            "Nome": "Cataguases",
            "Uf": "MG"
        },
        {
            "Id": 2411,
            "Codigo": 3115359,
            "Nome": "Catas Altas",
            "Uf": "MG"
        },
        {
            "Id": 2412,
            "Codigo": 3115409,
            "Nome": "Catas Altas da Noruega",
            "Uf": "MG"
        },
        {
            "Id": 2413,
            "Codigo": 3115458,
            "Nome": "Catuji",
            "Uf": "MG"
        },
        {
            "Id": 2414,
            "Codigo": 3115474,
            "Nome": "Catuti",
            "Uf": "MG"
        },
        {
            "Id": 2415,
            "Codigo": 3115508,
            "Nome": "Caxambu",
            "Uf": "MG"
        },
        {
            "Id": 2416,
            "Codigo": 3115607,
            "Nome": "Cedro do Abaeté",
            "Uf": "MG"
        },
        {
            "Id": 2417,
            "Codigo": 3115706,
            "Nome": "Central de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2418,
            "Codigo": 3115805,
            "Nome": "Centralina",
            "Uf": "MG"
        },
        {
            "Id": 2419,
            "Codigo": 3115904,
            "Nome": "Chácara",
            "Uf": "MG"
        },
        {
            "Id": 2420,
            "Codigo": 3116001,
            "Nome": "Chalé",
            "Uf": "MG"
        },
        {
            "Id": 2421,
            "Codigo": 3116100,
            "Nome": "Chapada do Norte",
            "Uf": "MG"
        },
        {
            "Id": 2422,
            "Codigo": 3116159,
            "Nome": "Chapada Gaúcha",
            "Uf": "MG"
        },
        {
            "Id": 2423,
            "Codigo": 3116209,
            "Nome": "Chiador",
            "Uf": "MG"
        },
        {
            "Id": 2424,
            "Codigo": 3116308,
            "Nome": "Cipotânea",
            "Uf": "MG"
        },
        {
            "Id": 2425,
            "Codigo": 3116407,
            "Nome": "Claraval",
            "Uf": "MG"
        },
        {
            "Id": 2426,
            "Codigo": 3116506,
            "Nome": "Claro dos Poções",
            "Uf": "MG"
        },
        {
            "Id": 2427,
            "Codigo": 3116605,
            "Nome": "Cláudio",
            "Uf": "MG"
        },
        {
            "Id": 2428,
            "Codigo": 3116704,
            "Nome": "Coimbra",
            "Uf": "MG"
        },
        {
            "Id": 2429,
            "Codigo": 3116803,
            "Nome": "Coluna",
            "Uf": "MG"
        },
        {
            "Id": 2430,
            "Codigo": 3116902,
            "Nome": "Comendador Gomes",
            "Uf": "MG"
        },
        {
            "Id": 2431,
            "Codigo": 3117009,
            "Nome": "Comercinho",
            "Uf": "MG"
        },
        {
            "Id": 2432,
            "Codigo": 3117108,
            "Nome": "Conceição da Aparecida",
            "Uf": "MG"
        },
        {
            "Id": 2433,
            "Codigo": 3117207,
            "Nome": "Conceição das Pedras",
            "Uf": "MG"
        },
        {
            "Id": 2434,
            "Codigo": 3117306,
            "Nome": "Conceição das Alagoas",
            "Uf": "MG"
        },
        {
            "Id": 2435,
            "Codigo": 3117405,
            "Nome": "Conceição de Ipanema",
            "Uf": "MG"
        },
        {
            "Id": 2436,
            "Codigo": 3117504,
            "Nome": "Conceição do Mato Dentro",
            "Uf": "MG"
        },
        {
            "Id": 2437,
            "Codigo": 3117603,
            "Nome": "Conceição do Pará",
            "Uf": "MG"
        },
        {
            "Id": 2438,
            "Codigo": 3117702,
            "Nome": "Conceição do Rio Verde",
            "Uf": "MG"
        },
        {
            "Id": 2439,
            "Codigo": 3117801,
            "Nome": "Conceição dos Ouros",
            "Uf": "MG"
        },
        {
            "Id": 2440,
            "Codigo": 3117836,
            "Nome": "Cônego Marinho",
            "Uf": "MG"
        },
        {
            "Id": 2441,
            "Codigo": 3117876,
            "Nome": "Confins",
            "Uf": "MG"
        },
        {
            "Id": 2442,
            "Codigo": 3117900,
            "Nome": "Congonhal",
            "Uf": "MG"
        },
        {
            "Id": 2443,
            "Codigo": 3118007,
            "Nome": "Congonhas",
            "Uf": "MG"
        },
        {
            "Id": 2444,
            "Codigo": 3118106,
            "Nome": "Congonhas do Norte",
            "Uf": "MG"
        },
        {
            "Id": 2445,
            "Codigo": 3118205,
            "Nome": "Conquista",
            "Uf": "MG"
        },
        {
            "Id": 2446,
            "Codigo": 3118304,
            "Nome": "Conselheiro Lafaiete",
            "Uf": "MG"
        },
        {
            "Id": 2447,
            "Codigo": 3118403,
            "Nome": "Conselheiro Pena",
            "Uf": "MG"
        },
        {
            "Id": 2448,
            "Codigo": 3118502,
            "Nome": "Consolação",
            "Uf": "MG"
        },
        {
            "Id": 2449,
            "Codigo": 3118601,
            "Nome": "Contagem",
            "Uf": "MG"
        },
        {
            "Id": 2450,
            "Codigo": 3118700,
            "Nome": "Coqueiral",
            "Uf": "MG"
        },
        {
            "Id": 2451,
            "Codigo": 3118809,
            "Nome": "Coração de Jesus",
            "Uf": "MG"
        },
        {
            "Id": 2452,
            "Codigo": 3118908,
            "Nome": "Cordisburgo",
            "Uf": "MG"
        },
        {
            "Id": 2453,
            "Codigo": 3119005,
            "Nome": "Cordislândia",
            "Uf": "MG"
        },
        {
            "Id": 2454,
            "Codigo": 3119104,
            "Nome": "Corinto",
            "Uf": "MG"
        },
        {
            "Id": 2455,
            "Codigo": 3119203,
            "Nome": "Coroaci",
            "Uf": "MG"
        },
        {
            "Id": 2456,
            "Codigo": 3119302,
            "Nome": "Coromandel",
            "Uf": "MG"
        },
        {
            "Id": 2457,
            "Codigo": 3119401,
            "Nome": "Coronel Fabriciano",
            "Uf": "MG"
        },
        {
            "Id": 2458,
            "Codigo": 3119500,
            "Nome": "Coronel Murta",
            "Uf": "MG"
        },
        {
            "Id": 2459,
            "Codigo": 3119609,
            "Nome": "Coronel Pacheco",
            "Uf": "MG"
        },
        {
            "Id": 2460,
            "Codigo": 3119708,
            "Nome": "Coronel Xavier Chaves",
            "Uf": "MG"
        },
        {
            "Id": 2461,
            "Codigo": 3119807,
            "Nome": "Córrego Danta",
            "Uf": "MG"
        },
        {
            "Id": 2462,
            "Codigo": 3119906,
            "Nome": "Córrego do Bom Jesus",
            "Uf": "MG"
        },
        {
            "Id": 2463,
            "Codigo": 3119955,
            "Nome": "Córrego Fundo",
            "Uf": "MG"
        },
        {
            "Id": 2464,
            "Codigo": 3120003,
            "Nome": "Córrego Novo",
            "Uf": "MG"
        },
        {
            "Id": 2465,
            "Codigo": 3120102,
            "Nome": "Couto de Magalhães de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2466,
            "Codigo": 3120151,
            "Nome": "Crisólita",
            "Uf": "MG"
        },
        {
            "Id": 2467,
            "Codigo": 3120201,
            "Nome": "Cristais",
            "Uf": "MG"
        },
        {
            "Id": 2468,
            "Codigo": 3120300,
            "Nome": "Cristália",
            "Uf": "MG"
        },
        {
            "Id": 2469,
            "Codigo": 3120409,
            "Nome": "Cristiano Otoni",
            "Uf": "MG"
        },
        {
            "Id": 2470,
            "Codigo": 3120508,
            "Nome": "Cristina",
            "Uf": "MG"
        },
        {
            "Id": 2471,
            "Codigo": 3120607,
            "Nome": "Crucilândia",
            "Uf": "MG"
        },
        {
            "Id": 2472,
            "Codigo": 3120706,
            "Nome": "Cruzeiro da Fortaleza",
            "Uf": "MG"
        },
        {
            "Id": 2473,
            "Codigo": 3120805,
            "Nome": "Cruzília",
            "Uf": "MG"
        },
        {
            "Id": 2474,
            "Codigo": 3120839,
            "Nome": "Cuparaque",
            "Uf": "MG"
        },
        {
            "Id": 2475,
            "Codigo": 3120870,
            "Nome": "Curral de Dentro",
            "Uf": "MG"
        },
        {
            "Id": 2476,
            "Codigo": 3120904,
            "Nome": "Curvelo",
            "Uf": "MG"
        },
        {
            "Id": 2477,
            "Codigo": 3121001,
            "Nome": "Datas",
            "Uf": "MG"
        },
        {
            "Id": 2478,
            "Codigo": 3121100,
            "Nome": "Delfim Moreira",
            "Uf": "MG"
        },
        {
            "Id": 2479,
            "Codigo": 3121209,
            "Nome": "Delfinópolis",
            "Uf": "MG"
        },
        {
            "Id": 2480,
            "Codigo": 3121258,
            "Nome": "Delta",
            "Uf": "MG"
        },
        {
            "Id": 2481,
            "Codigo": 3121308,
            "Nome": "Descoberto",
            "Uf": "MG"
        },
        {
            "Id": 2482,
            "Codigo": 3121407,
            "Nome": "Desterro de Entre Rios",
            "Uf": "MG"
        },
        {
            "Id": 2483,
            "Codigo": 3121506,
            "Nome": "Desterro do Melo",
            "Uf": "MG"
        },
        {
            "Id": 2484,
            "Codigo": 3121605,
            "Nome": "Diamantina",
            "Uf": "MG"
        },
        {
            "Id": 2485,
            "Codigo": 3121704,
            "Nome": "Diogo de Vasconcelos",
            "Uf": "MG"
        },
        {
            "Id": 2486,
            "Codigo": 3121803,
            "Nome": "Dionísio",
            "Uf": "MG"
        },
        {
            "Id": 2487,
            "Codigo": 3121902,
            "Nome": "Divinésia",
            "Uf": "MG"
        },
        {
            "Id": 2488,
            "Codigo": 3122009,
            "Nome": "Divino",
            "Uf": "MG"
        },
        {
            "Id": 2489,
            "Codigo": 3122108,
            "Nome": "Divino das Laranjeiras",
            "Uf": "MG"
        },
        {
            "Id": 2490,
            "Codigo": 3122207,
            "Nome": "Divinolândia de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2491,
            "Codigo": 3122306,
            "Nome": "Divinópolis",
            "Uf": "MG"
        },
        {
            "Id": 2492,
            "Codigo": 3122355,
            "Nome": "Divisa Alegre",
            "Uf": "MG"
        },
        {
            "Id": 2493,
            "Codigo": 3122405,
            "Nome": "Divisa Nova",
            "Uf": "MG"
        },
        {
            "Id": 2494,
            "Codigo": 3122454,
            "Nome": "Divisópolis",
            "Uf": "MG"
        },
        {
            "Id": 2495,
            "Codigo": 3122470,
            "Nome": "Dom Bosco",
            "Uf": "MG"
        },
        {
            "Id": 2496,
            "Codigo": 3122504,
            "Nome": "Dom Cavati",
            "Uf": "MG"
        },
        {
            "Id": 2497,
            "Codigo": 3122603,
            "Nome": "Dom Joaquim",
            "Uf": "MG"
        },
        {
            "Id": 2498,
            "Codigo": 3122702,
            "Nome": "Dom Silvério",
            "Uf": "MG"
        },
        {
            "Id": 2499,
            "Codigo": 3122801,
            "Nome": "Dom Viçoso",
            "Uf": "MG"
        },
        {
            "Id": 2500,
            "Codigo": 3122900,
            "Nome": "Dona Eusébia",
            "Uf": "MG"
        },
        {
            "Id": 2501,
            "Codigo": 3123007,
            "Nome": "Dores de Campos",
            "Uf": "MG"
        },
        {
            "Id": 2502,
            "Codigo": 3123106,
            "Nome": "Dores de Guanhães",
            "Uf": "MG"
        },
        {
            "Id": 2503,
            "Codigo": 3123205,
            "Nome": "Dores do Indaiá",
            "Uf": "MG"
        },
        {
            "Id": 2504,
            "Codigo": 3123304,
            "Nome": "Dores do Turvo",
            "Uf": "MG"
        },
        {
            "Id": 2505,
            "Codigo": 3123403,
            "Nome": "Doresópolis",
            "Uf": "MG"
        },
        {
            "Id": 2506,
            "Codigo": 3123502,
            "Nome": "Douradoquara",
            "Uf": "MG"
        },
        {
            "Id": 2507,
            "Codigo": 3123528,
            "Nome": "Durandé",
            "Uf": "MG"
        },
        {
            "Id": 2508,
            "Codigo": 3123601,
            "Nome": "Elói Mendes",
            "Uf": "MG"
        },
        {
            "Id": 2509,
            "Codigo": 3123700,
            "Nome": "Engenheiro Caldas",
            "Uf": "MG"
        },
        {
            "Id": 2510,
            "Codigo": 3123809,
            "Nome": "Engenheiro Navarro",
            "Uf": "MG"
        },
        {
            "Id": 2511,
            "Codigo": 3123858,
            "Nome": "Entre Folhas",
            "Uf": "MG"
        },
        {
            "Id": 2512,
            "Codigo": 3123908,
            "Nome": "Entre Rios de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2513,
            "Codigo": 3124005,
            "Nome": "Ervália",
            "Uf": "MG"
        },
        {
            "Id": 2514,
            "Codigo": 3124104,
            "Nome": "Esmeraldas",
            "Uf": "MG"
        },
        {
            "Id": 2515,
            "Codigo": 3124203,
            "Nome": "Espera Feliz",
            "Uf": "MG"
        },
        {
            "Id": 2516,
            "Codigo": 3124302,
            "Nome": "Espinosa",
            "Uf": "MG"
        },
        {
            "Id": 2517,
            "Codigo": 3124401,
            "Nome": "Espírito Santo do Dourado",
            "Uf": "MG"
        },
        {
            "Id": 2518,
            "Codigo": 3124500,
            "Nome": "Estiva",
            "Uf": "MG"
        },
        {
            "Id": 2519,
            "Codigo": 3124609,
            "Nome": "Estrela Dalva",
            "Uf": "MG"
        },
        {
            "Id": 2520,
            "Codigo": 3124708,
            "Nome": "Estrela do Indaiá",
            "Uf": "MG"
        },
        {
            "Id": 2521,
            "Codigo": 3124807,
            "Nome": "Estrela do Sul",
            "Uf": "MG"
        },
        {
            "Id": 2522,
            "Codigo": 3124906,
            "Nome": "Eugenópolis",
            "Uf": "MG"
        },
        {
            "Id": 2523,
            "Codigo": 3125002,
            "Nome": "Ewbank da Câmara",
            "Uf": "MG"
        },
        {
            "Id": 2524,
            "Codigo": 3125101,
            "Nome": "Extrema",
            "Uf": "MG"
        },
        {
            "Id": 2525,
            "Codigo": 3125200,
            "Nome": "Fama",
            "Uf": "MG"
        },
        {
            "Id": 2526,
            "Codigo": 3125309,
            "Nome": "Faria Lemos",
            "Uf": "MG"
        },
        {
            "Id": 2527,
            "Codigo": 3125408,
            "Nome": "Felício dos Santos",
            "Uf": "MG"
        },
        {
            "Id": 2528,
            "Codigo": 3125507,
            "Nome": "São Gonçalo do Rio Preto",
            "Uf": "MG"
        },
        {
            "Id": 2529,
            "Codigo": 3125606,
            "Nome": "Felisburgo",
            "Uf": "MG"
        },
        {
            "Id": 2530,
            "Codigo": 3125705,
            "Nome": "Felixlândia",
            "Uf": "MG"
        },
        {
            "Id": 2531,
            "Codigo": 3125804,
            "Nome": "Fernandes Tourinho",
            "Uf": "MG"
        },
        {
            "Id": 2532,
            "Codigo": 3125903,
            "Nome": "Ferros",
            "Uf": "MG"
        },
        {
            "Id": 2533,
            "Codigo": 3125952,
            "Nome": "Fervedouro",
            "Uf": "MG"
        },
        {
            "Id": 2534,
            "Codigo": 3126000,
            "Nome": "Florestal",
            "Uf": "MG"
        },
        {
            "Id": 2535,
            "Codigo": 3126109,
            "Nome": "Formiga",
            "Uf": "MG"
        },
        {
            "Id": 2536,
            "Codigo": 3126208,
            "Nome": "Formoso",
            "Uf": "MG"
        },
        {
            "Id": 2537,
            "Codigo": 3126307,
            "Nome": "Fortaleza de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2538,
            "Codigo": 3126406,
            "Nome": "Fortuna de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2539,
            "Codigo": 3126505,
            "Nome": "Francisco Badaró",
            "Uf": "MG"
        },
        {
            "Id": 2540,
            "Codigo": 3126604,
            "Nome": "Francisco Dumont",
            "Uf": "MG"
        },
        {
            "Id": 2541,
            "Codigo": 3126703,
            "Nome": "Francisco Sá",
            "Uf": "MG"
        },
        {
            "Id": 2542,
            "Codigo": 3126752,
            "Nome": "Franciscópolis",
            "Uf": "MG"
        },
        {
            "Id": 2543,
            "Codigo": 3126802,
            "Nome": "Frei Gaspar",
            "Uf": "MG"
        },
        {
            "Id": 2544,
            "Codigo": 3126901,
            "Nome": "Frei Inocêncio",
            "Uf": "MG"
        },
        {
            "Id": 2545,
            "Codigo": 3126950,
            "Nome": "Frei Lagonegro",
            "Uf": "MG"
        },
        {
            "Id": 2546,
            "Codigo": 3127008,
            "Nome": "Fronteira",
            "Uf": "MG"
        },
        {
            "Id": 2547,
            "Codigo": 3127057,
            "Nome": "Fronteira dos Vales",
            "Uf": "MG"
        },
        {
            "Id": 2548,
            "Codigo": 3127073,
            "Nome": "Fruta de Leite",
            "Uf": "MG"
        },
        {
            "Id": 2549,
            "Codigo": 3127107,
            "Nome": "Frutal",
            "Uf": "MG"
        },
        {
            "Id": 2550,
            "Codigo": 3127206,
            "Nome": "Funilândia",
            "Uf": "MG"
        },
        {
            "Id": 2551,
            "Codigo": 3127305,
            "Nome": "Galiléia",
            "Uf": "MG"
        },
        {
            "Id": 2552,
            "Codigo": 3127339,
            "Nome": "Gameleiras",
            "Uf": "MG"
        },
        {
            "Id": 2553,
            "Codigo": 3127354,
            "Nome": "Glaucilândia",
            "Uf": "MG"
        },
        {
            "Id": 2554,
            "Codigo": 3127370,
            "Nome": "Goiabeira",
            "Uf": "MG"
        },
        {
            "Id": 2555,
            "Codigo": 3127388,
            "Nome": "Goianá",
            "Uf": "MG"
        },
        {
            "Id": 2556,
            "Codigo": 3127404,
            "Nome": "Gonçalves",
            "Uf": "MG"
        },
        {
            "Id": 2557,
            "Codigo": 3127503,
            "Nome": "Gonzaga",
            "Uf": "MG"
        },
        {
            "Id": 2558,
            "Codigo": 3127602,
            "Nome": "Gouveia",
            "Uf": "MG"
        },
        {
            "Id": 2559,
            "Codigo": 3127701,
            "Nome": "Governador Valadares",
            "Uf": "MG"
        },
        {
            "Id": 2560,
            "Codigo": 3127800,
            "Nome": "Grão Mogol",
            "Uf": "MG"
        },
        {
            "Id": 2561,
            "Codigo": 3127909,
            "Nome": "Grupiara",
            "Uf": "MG"
        },
        {
            "Id": 2562,
            "Codigo": 3128006,
            "Nome": "Guanhães",
            "Uf": "MG"
        },
        {
            "Id": 2563,
            "Codigo": 3128105,
            "Nome": "Guapé",
            "Uf": "MG"
        },
        {
            "Id": 2564,
            "Codigo": 3128204,
            "Nome": "Guaraciaba",
            "Uf": "MG"
        },
        {
            "Id": 2565,
            "Codigo": 3128253,
            "Nome": "Guaraciama",
            "Uf": "MG"
        },
        {
            "Id": 2566,
            "Codigo": 3128303,
            "Nome": "Guaranésia",
            "Uf": "MG"
        },
        {
            "Id": 2567,
            "Codigo": 3128402,
            "Nome": "Guarani",
            "Uf": "MG"
        },
        {
            "Id": 2568,
            "Codigo": 3128501,
            "Nome": "Guarará",
            "Uf": "MG"
        },
        {
            "Id": 2569,
            "Codigo": 3128600,
            "Nome": "Guarda-Mor",
            "Uf": "MG"
        },
        {
            "Id": 2570,
            "Codigo": 3128709,
            "Nome": "Guaxupé",
            "Uf": "MG"
        },
        {
            "Id": 2571,
            "Codigo": 3128808,
            "Nome": "Guidoval",
            "Uf": "MG"
        },
        {
            "Id": 2572,
            "Codigo": 3128907,
            "Nome": "Guimarânia",
            "Uf": "MG"
        },
        {
            "Id": 2573,
            "Codigo": 3129004,
            "Nome": "Guiricema",
            "Uf": "MG"
        },
        {
            "Id": 2574,
            "Codigo": 3129103,
            "Nome": "Gurinhatã",
            "Uf": "MG"
        },
        {
            "Id": 2575,
            "Codigo": 3129202,
            "Nome": "Heliodora",
            "Uf": "MG"
        },
        {
            "Id": 2576,
            "Codigo": 3129301,
            "Nome": "Iapu",
            "Uf": "MG"
        },
        {
            "Id": 2577,
            "Codigo": 3129400,
            "Nome": "Ibertioga",
            "Uf": "MG"
        },
        {
            "Id": 2578,
            "Codigo": 3129509,
            "Nome": "Ibiá",
            "Uf": "MG"
        },
        {
            "Id": 2579,
            "Codigo": 3129608,
            "Nome": "Ibiaí",
            "Uf": "MG"
        },
        {
            "Id": 2580,
            "Codigo": 3129657,
            "Nome": "Ibiracatu",
            "Uf": "MG"
        },
        {
            "Id": 2581,
            "Codigo": 3129707,
            "Nome": "Ibiraci",
            "Uf": "MG"
        },
        {
            "Id": 2582,
            "Codigo": 3129806,
            "Nome": "Ibirité",
            "Uf": "MG"
        },
        {
            "Id": 2583,
            "Codigo": 3129905,
            "Nome": "Ibitiúra de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2584,
            "Codigo": 3130002,
            "Nome": "Ibituruna",
            "Uf": "MG"
        },
        {
            "Id": 2585,
            "Codigo": 3130051,
            "Nome": "Icaraí de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2586,
            "Codigo": 3130101,
            "Nome": "Igarapé",
            "Uf": "MG"
        },
        {
            "Id": 2587,
            "Codigo": 3130200,
            "Nome": "Igaratinga",
            "Uf": "MG"
        },
        {
            "Id": 2588,
            "Codigo": 3130309,
            "Nome": "Iguatama",
            "Uf": "MG"
        },
        {
            "Id": 2589,
            "Codigo": 3130408,
            "Nome": "Ijaci",
            "Uf": "MG"
        },
        {
            "Id": 2590,
            "Codigo": 3130507,
            "Nome": "Ilicínea",
            "Uf": "MG"
        },
        {
            "Id": 2591,
            "Codigo": 3130556,
            "Nome": "Imbé de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2592,
            "Codigo": 3130606,
            "Nome": "Inconfidentes",
            "Uf": "MG"
        },
        {
            "Id": 2593,
            "Codigo": 3130655,
            "Nome": "Indaiabira",
            "Uf": "MG"
        },
        {
            "Id": 2594,
            "Codigo": 3130705,
            "Nome": "Indianópolis",
            "Uf": "MG"
        },
        {
            "Id": 2595,
            "Codigo": 3130804,
            "Nome": "Ingaí",
            "Uf": "MG"
        },
        {
            "Id": 2596,
            "Codigo": 3130903,
            "Nome": "Inhapim",
            "Uf": "MG"
        },
        {
            "Id": 2597,
            "Codigo": 3131000,
            "Nome": "Inhaúma",
            "Uf": "MG"
        },
        {
            "Id": 2598,
            "Codigo": 3131109,
            "Nome": "Inimutaba",
            "Uf": "MG"
        },
        {
            "Id": 2599,
            "Codigo": 3131158,
            "Nome": "Ipaba",
            "Uf": "MG"
        },
        {
            "Id": 2600,
            "Codigo": 3131208,
            "Nome": "Ipanema",
            "Uf": "MG"
        },
        {
            "Id": 2601,
            "Codigo": 3131307,
            "Nome": "Ipatinga",
            "Uf": "MG"
        },
        {
            "Id": 2602,
            "Codigo": 3131406,
            "Nome": "Ipiaçu",
            "Uf": "MG"
        },
        {
            "Id": 2603,
            "Codigo": 3131505,
            "Nome": "Ipuiúna",
            "Uf": "MG"
        },
        {
            "Id": 2604,
            "Codigo": 3131604,
            "Nome": "Iraí de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2605,
            "Codigo": 3131703,
            "Nome": "Itabira",
            "Uf": "MG"
        },
        {
            "Id": 2606,
            "Codigo": 3131802,
            "Nome": "Itabirinha",
            "Uf": "MG"
        },
        {
            "Id": 2607,
            "Codigo": 3131901,
            "Nome": "Itabirito",
            "Uf": "MG"
        },
        {
            "Id": 2608,
            "Codigo": 3132008,
            "Nome": "Itacambira",
            "Uf": "MG"
        },
        {
            "Id": 2609,
            "Codigo": 3132107,
            "Nome": "Itacarambi",
            "Uf": "MG"
        },
        {
            "Id": 2610,
            "Codigo": 3132206,
            "Nome": "Itaguara",
            "Uf": "MG"
        },
        {
            "Id": 2611,
            "Codigo": 3132305,
            "Nome": "Itaipé",
            "Uf": "MG"
        },
        {
            "Id": 2612,
            "Codigo": 3132404,
            "Nome": "Itajubá",
            "Uf": "MG"
        },
        {
            "Id": 2613,
            "Codigo": 3132503,
            "Nome": "Itamarandiba",
            "Uf": "MG"
        },
        {
            "Id": 2614,
            "Codigo": 3132602,
            "Nome": "Itamarati de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2615,
            "Codigo": 3132701,
            "Nome": "Itambacuri",
            "Uf": "MG"
        },
        {
            "Id": 2616,
            "Codigo": 3132800,
            "Nome": "Itambé do Mato Dentro",
            "Uf": "MG"
        },
        {
            "Id": 2617,
            "Codigo": 3132909,
            "Nome": "Itamogi",
            "Uf": "MG"
        },
        {
            "Id": 2618,
            "Codigo": 3133006,
            "Nome": "Itamonte",
            "Uf": "MG"
        },
        {
            "Id": 2619,
            "Codigo": 3133105,
            "Nome": "Itanhandu",
            "Uf": "MG"
        },
        {
            "Id": 2620,
            "Codigo": 3133204,
            "Nome": "Itanhomi",
            "Uf": "MG"
        },
        {
            "Id": 2621,
            "Codigo": 3133303,
            "Nome": "Itaobim",
            "Uf": "MG"
        },
        {
            "Id": 2622,
            "Codigo": 3133402,
            "Nome": "Itapagipe",
            "Uf": "MG"
        },
        {
            "Id": 2623,
            "Codigo": 3133501,
            "Nome": "Itapecerica",
            "Uf": "MG"
        },
        {
            "Id": 2624,
            "Codigo": 3133600,
            "Nome": "Itapeva",
            "Uf": "MG"
        },
        {
            "Id": 2625,
            "Codigo": 3133709,
            "Nome": "Itatiaiuçu",
            "Uf": "MG"
        },
        {
            "Id": 2626,
            "Codigo": 3133758,
            "Nome": "Itaú de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2627,
            "Codigo": 3133808,
            "Nome": "Itaúna",
            "Uf": "MG"
        },
        {
            "Id": 2628,
            "Codigo": 3133907,
            "Nome": "Itaverava",
            "Uf": "MG"
        },
        {
            "Id": 2629,
            "Codigo": 3134004,
            "Nome": "Itinga",
            "Uf": "MG"
        },
        {
            "Id": 2630,
            "Codigo": 3134103,
            "Nome": "Itueta",
            "Uf": "MG"
        },
        {
            "Id": 2631,
            "Codigo": 3134202,
            "Nome": "Ituiutaba",
            "Uf": "MG"
        },
        {
            "Id": 2632,
            "Codigo": 3134301,
            "Nome": "Itumirim",
            "Uf": "MG"
        },
        {
            "Id": 2633,
            "Codigo": 3134400,
            "Nome": "Iturama",
            "Uf": "MG"
        },
        {
            "Id": 2634,
            "Codigo": 3134509,
            "Nome": "Itutinga",
            "Uf": "MG"
        },
        {
            "Id": 2635,
            "Codigo": 3134608,
            "Nome": "Jaboticatubas",
            "Uf": "MG"
        },
        {
            "Id": 2636,
            "Codigo": 3134707,
            "Nome": "Jacinto",
            "Uf": "MG"
        },
        {
            "Id": 2637,
            "Codigo": 3134806,
            "Nome": "Jacuí",
            "Uf": "MG"
        },
        {
            "Id": 2638,
            "Codigo": 3134905,
            "Nome": "Jacutinga",
            "Uf": "MG"
        },
        {
            "Id": 2639,
            "Codigo": 3135001,
            "Nome": "Jaguaraçu",
            "Uf": "MG"
        },
        {
            "Id": 2640,
            "Codigo": 3135050,
            "Nome": "Jaíba",
            "Uf": "MG"
        },
        {
            "Id": 2641,
            "Codigo": 3135076,
            "Nome": "Jampruca",
            "Uf": "MG"
        },
        {
            "Id": 2642,
            "Codigo": 3135100,
            "Nome": "Janaúba",
            "Uf": "MG"
        },
        {
            "Id": 2643,
            "Codigo": 3135209,
            "Nome": "Januária",
            "Uf": "MG"
        },
        {
            "Id": 2644,
            "Codigo": 3135308,
            "Nome": "Japaraíba",
            "Uf": "MG"
        },
        {
            "Id": 2645,
            "Codigo": 3135357,
            "Nome": "Japonvar",
            "Uf": "MG"
        },
        {
            "Id": 2646,
            "Codigo": 3135407,
            "Nome": "Jeceaba",
            "Uf": "MG"
        },
        {
            "Id": 2647,
            "Codigo": 3135456,
            "Nome": "Jenipapo de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2648,
            "Codigo": 3135506,
            "Nome": "Jequeri",
            "Uf": "MG"
        },
        {
            "Id": 2649,
            "Codigo": 3135605,
            "Nome": "Jequitaí",
            "Uf": "MG"
        },
        {
            "Id": 2650,
            "Codigo": 3135704,
            "Nome": "Jequitibá",
            "Uf": "MG"
        },
        {
            "Id": 2651,
            "Codigo": 3135803,
            "Nome": "Jequitinhonha",
            "Uf": "MG"
        },
        {
            "Id": 2652,
            "Codigo": 3135902,
            "Nome": "Jesuânia",
            "Uf": "MG"
        },
        {
            "Id": 2653,
            "Codigo": 3136009,
            "Nome": "Joaíma",
            "Uf": "MG"
        },
        {
            "Id": 2654,
            "Codigo": 3136108,
            "Nome": "Joanésia",
            "Uf": "MG"
        },
        {
            "Id": 2655,
            "Codigo": 3136207,
            "Nome": "João Monlevade",
            "Uf": "MG"
        },
        {
            "Id": 2656,
            "Codigo": 3136306,
            "Nome": "João Pinheiro",
            "Uf": "MG"
        },
        {
            "Id": 2657,
            "Codigo": 3136405,
            "Nome": "Joaquim Felício",
            "Uf": "MG"
        },
        {
            "Id": 2658,
            "Codigo": 3136504,
            "Nome": "Jordânia",
            "Uf": "MG"
        },
        {
            "Id": 2659,
            "Codigo": 3136520,
            "Nome": "José Gonçalves de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2660,
            "Codigo": 3136553,
            "Nome": "José Raydan",
            "Uf": "MG"
        },
        {
            "Id": 2661,
            "Codigo": 3136579,
            "Nome": "Josenópolis",
            "Uf": "MG"
        },
        {
            "Id": 2662,
            "Codigo": 3136603,
            "Nome": "Nova União",
            "Uf": "MG"
        },
        {
            "Id": 2663,
            "Codigo": 3136652,
            "Nome": "Juatuba",
            "Uf": "MG"
        },
        {
            "Id": 2664,
            "Codigo": 3136702,
            "Nome": "Juiz de Fora",
            "Uf": "MG"
        },
        {
            "Id": 2665,
            "Codigo": 3136801,
            "Nome": "Juramento",
            "Uf": "MG"
        },
        {
            "Id": 2666,
            "Codigo": 3136900,
            "Nome": "Juruaia",
            "Uf": "MG"
        },
        {
            "Id": 2667,
            "Codigo": 3136959,
            "Nome": "Juvenília",
            "Uf": "MG"
        },
        {
            "Id": 2668,
            "Codigo": 3137007,
            "Nome": "Ladainha",
            "Uf": "MG"
        },
        {
            "Id": 2669,
            "Codigo": 3137106,
            "Nome": "Lagamar",
            "Uf": "MG"
        },
        {
            "Id": 2670,
            "Codigo": 3137205,
            "Nome": "Lagoa da Prata",
            "Uf": "MG"
        },
        {
            "Id": 2671,
            "Codigo": 3137304,
            "Nome": "Lagoa dos Patos",
            "Uf": "MG"
        },
        {
            "Id": 2672,
            "Codigo": 3137403,
            "Nome": "Lagoa Dourada",
            "Uf": "MG"
        },
        {
            "Id": 2673,
            "Codigo": 3137502,
            "Nome": "Lagoa Formosa",
            "Uf": "MG"
        },
        {
            "Id": 2674,
            "Codigo": 3137536,
            "Nome": "Lagoa Grande",
            "Uf": "MG"
        },
        {
            "Id": 2675,
            "Codigo": 3137601,
            "Nome": "Lagoa Santa",
            "Uf": "MG"
        },
        {
            "Id": 2676,
            "Codigo": 3137700,
            "Nome": "Lajinha",
            "Uf": "MG"
        },
        {
            "Id": 2677,
            "Codigo": 3137809,
            "Nome": "Lambari",
            "Uf": "MG"
        },
        {
            "Id": 2678,
            "Codigo": 3137908,
            "Nome": "Lamim",
            "Uf": "MG"
        },
        {
            "Id": 2679,
            "Codigo": 3138005,
            "Nome": "Laranjal",
            "Uf": "MG"
        },
        {
            "Id": 2680,
            "Codigo": 3138104,
            "Nome": "Lassance",
            "Uf": "MG"
        },
        {
            "Id": 2681,
            "Codigo": 3138203,
            "Nome": "Lavras",
            "Uf": "MG"
        },
        {
            "Id": 2682,
            "Codigo": 3138302,
            "Nome": "Leandro Ferreira",
            "Uf": "MG"
        },
        {
            "Id": 2683,
            "Codigo": 3138351,
            "Nome": "Leme do Prado",
            "Uf": "MG"
        },
        {
            "Id": 2684,
            "Codigo": 3138401,
            "Nome": "Leopoldina",
            "Uf": "MG"
        },
        {
            "Id": 2685,
            "Codigo": 3138500,
            "Nome": "Liberdade",
            "Uf": "MG"
        },
        {
            "Id": 2686,
            "Codigo": 3138609,
            "Nome": "Lima Duarte",
            "Uf": "MG"
        },
        {
            "Id": 2687,
            "Codigo": 3138625,
            "Nome": "Limeira do Oeste",
            "Uf": "MG"
        },
        {
            "Id": 2688,
            "Codigo": 3138658,
            "Nome": "Lontra",
            "Uf": "MG"
        },
        {
            "Id": 2689,
            "Codigo": 3138674,
            "Nome": "Luisburgo",
            "Uf": "MG"
        },
        {
            "Id": 2690,
            "Codigo": 3138682,
            "Nome": "Luislândia",
            "Uf": "MG"
        },
        {
            "Id": 2691,
            "Codigo": 3138708,
            "Nome": "Luminárias",
            "Uf": "MG"
        },
        {
            "Id": 2692,
            "Codigo": 3138807,
            "Nome": "Luz",
            "Uf": "MG"
        },
        {
            "Id": 2693,
            "Codigo": 3138906,
            "Nome": "Machacalis",
            "Uf": "MG"
        },
        {
            "Id": 2694,
            "Codigo": 3139003,
            "Nome": "Machado",
            "Uf": "MG"
        },
        {
            "Id": 2695,
            "Codigo": 3139102,
            "Nome": "Madre de Deus de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2696,
            "Codigo": 3139201,
            "Nome": "Malacacheta",
            "Uf": "MG"
        },
        {
            "Id": 2697,
            "Codigo": 3139250,
            "Nome": "Mamonas",
            "Uf": "MG"
        },
        {
            "Id": 2698,
            "Codigo": 3139300,
            "Nome": "Manga",
            "Uf": "MG"
        },
        {
            "Id": 2699,
            "Codigo": 3139409,
            "Nome": "Manhuaçu",
            "Uf": "MG"
        },
        {
            "Id": 2700,
            "Codigo": 3139508,
            "Nome": "Manhumirim",
            "Uf": "MG"
        },
        {
            "Id": 2701,
            "Codigo": 3139607,
            "Nome": "Mantena",
            "Uf": "MG"
        },
        {
            "Id": 2702,
            "Codigo": 3139706,
            "Nome": "Maravilhas",
            "Uf": "MG"
        },
        {
            "Id": 2703,
            "Codigo": 3139805,
            "Nome": "Mar de Espanha",
            "Uf": "MG"
        },
        {
            "Id": 2704,
            "Codigo": 3139904,
            "Nome": "Maria da Fé",
            "Uf": "MG"
        },
        {
            "Id": 2705,
            "Codigo": 3140001,
            "Nome": "Mariana",
            "Uf": "MG"
        },
        {
            "Id": 2706,
            "Codigo": 3140100,
            "Nome": "Marilac",
            "Uf": "MG"
        },
        {
            "Id": 2707,
            "Codigo": 3140159,
            "Nome": "Mário Campos",
            "Uf": "MG"
        },
        {
            "Id": 2708,
            "Codigo": 3140209,
            "Nome": "Maripá de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2709,
            "Codigo": 3140308,
            "Nome": "Marliéria",
            "Uf": "MG"
        },
        {
            "Id": 2710,
            "Codigo": 3140407,
            "Nome": "Marmelópolis",
            "Uf": "MG"
        },
        {
            "Id": 2711,
            "Codigo": 3140506,
            "Nome": "Martinho Campos",
            "Uf": "MG"
        },
        {
            "Id": 2712,
            "Codigo": 3140530,
            "Nome": "Martins Soares",
            "Uf": "MG"
        },
        {
            "Id": 2713,
            "Codigo": 3140555,
            "Nome": "Mata Verde",
            "Uf": "MG"
        },
        {
            "Id": 2714,
            "Codigo": 3140605,
            "Nome": "Materlândia",
            "Uf": "MG"
        },
        {
            "Id": 2715,
            "Codigo": 3140704,
            "Nome": "Mateus Leme",
            "Uf": "MG"
        },
        {
            "Id": 2716,
            "Codigo": 3140803,
            "Nome": "Matias Barbosa",
            "Uf": "MG"
        },
        {
            "Id": 2717,
            "Codigo": 3140852,
            "Nome": "Matias Cardoso",
            "Uf": "MG"
        },
        {
            "Id": 2718,
            "Codigo": 3140902,
            "Nome": "Matipó",
            "Uf": "MG"
        },
        {
            "Id": 2719,
            "Codigo": 3141009,
            "Nome": "Mato Verde",
            "Uf": "MG"
        },
        {
            "Id": 2720,
            "Codigo": 3141108,
            "Nome": "Matozinhos",
            "Uf": "MG"
        },
        {
            "Id": 2721,
            "Codigo": 3141207,
            "Nome": "Matutina",
            "Uf": "MG"
        },
        {
            "Id": 2722,
            "Codigo": 3141306,
            "Nome": "Medeiros",
            "Uf": "MG"
        },
        {
            "Id": 2723,
            "Codigo": 3141405,
            "Nome": "Medina",
            "Uf": "MG"
        },
        {
            "Id": 2724,
            "Codigo": 3141504,
            "Nome": "Mendes Pimentel",
            "Uf": "MG"
        },
        {
            "Id": 2725,
            "Codigo": 3141603,
            "Nome": "Mercês",
            "Uf": "MG"
        },
        {
            "Id": 2726,
            "Codigo": 3141702,
            "Nome": "Mesquita",
            "Uf": "MG"
        },
        {
            "Id": 2727,
            "Codigo": 3141801,
            "Nome": "Minas Novas",
            "Uf": "MG"
        },
        {
            "Id": 2728,
            "Codigo": 3141900,
            "Nome": "Minduri",
            "Uf": "MG"
        },
        {
            "Id": 2729,
            "Codigo": 3142007,
            "Nome": "Mirabela",
            "Uf": "MG"
        },
        {
            "Id": 2730,
            "Codigo": 3142106,
            "Nome": "Miradouro",
            "Uf": "MG"
        },
        {
            "Id": 2731,
            "Codigo": 3142205,
            "Nome": "Miraí",
            "Uf": "MG"
        },
        {
            "Id": 2732,
            "Codigo": 3142254,
            "Nome": "Miravânia",
            "Uf": "MG"
        },
        {
            "Id": 2733,
            "Codigo": 3142304,
            "Nome": "Moeda",
            "Uf": "MG"
        },
        {
            "Id": 2734,
            "Codigo": 3142403,
            "Nome": "Moema",
            "Uf": "MG"
        },
        {
            "Id": 2735,
            "Codigo": 3142502,
            "Nome": "Monjolos",
            "Uf": "MG"
        },
        {
            "Id": 2736,
            "Codigo": 3142601,
            "Nome": "Monsenhor Paulo",
            "Uf": "MG"
        },
        {
            "Id": 2737,
            "Codigo": 3142700,
            "Nome": "Montalvânia",
            "Uf": "MG"
        },
        {
            "Id": 2738,
            "Codigo": 3142809,
            "Nome": "Monte Alegre de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2739,
            "Codigo": 3142908,
            "Nome": "Monte Azul",
            "Uf": "MG"
        },
        {
            "Id": 2740,
            "Codigo": 3143005,
            "Nome": "Monte Belo",
            "Uf": "MG"
        },
        {
            "Id": 2741,
            "Codigo": 3143104,
            "Nome": "Monte Carmelo",
            "Uf": "MG"
        },
        {
            "Id": 2742,
            "Codigo": 3143153,
            "Nome": "Monte Formoso",
            "Uf": "MG"
        },
        {
            "Id": 2743,
            "Codigo": 3143203,
            "Nome": "Monte Santo de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2744,
            "Codigo": 3143302,
            "Nome": "Montes Claros",
            "Uf": "MG"
        },
        {
            "Id": 2745,
            "Codigo": 3143401,
            "Nome": "Monte Sião",
            "Uf": "MG"
        },
        {
            "Id": 2746,
            "Codigo": 3143450,
            "Nome": "Montezuma",
            "Uf": "MG"
        },
        {
            "Id": 2747,
            "Codigo": 3143500,
            "Nome": "Morada Nova de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2748,
            "Codigo": 3143609,
            "Nome": "Morro da Garça",
            "Uf": "MG"
        },
        {
            "Id": 2749,
            "Codigo": 3143708,
            "Nome": "Morro do Pilar",
            "Uf": "MG"
        },
        {
            "Id": 2750,
            "Codigo": 3143807,
            "Nome": "Munhoz",
            "Uf": "MG"
        },
        {
            "Id": 2751,
            "Codigo": 3143906,
            "Nome": "Muriaé",
            "Uf": "MG"
        },
        {
            "Id": 2752,
            "Codigo": 3144003,
            "Nome": "Mutum",
            "Uf": "MG"
        },
        {
            "Id": 2753,
            "Codigo": 3144102,
            "Nome": "Muzambinho",
            "Uf": "MG"
        },
        {
            "Id": 2754,
            "Codigo": 3144201,
            "Nome": "Nacip Raydan",
            "Uf": "MG"
        },
        {
            "Id": 2755,
            "Codigo": 3144300,
            "Nome": "Nanuque",
            "Uf": "MG"
        },
        {
            "Id": 2756,
            "Codigo": 3144359,
            "Nome": "Naque",
            "Uf": "MG"
        },
        {
            "Id": 2757,
            "Codigo": 3144375,
            "Nome": "Natalândia",
            "Uf": "MG"
        },
        {
            "Id": 2758,
            "Codigo": 3144409,
            "Nome": "Natércia",
            "Uf": "MG"
        },
        {
            "Id": 2759,
            "Codigo": 3144508,
            "Nome": "Nazareno",
            "Uf": "MG"
        },
        {
            "Id": 2760,
            "Codigo": 3144607,
            "Nome": "Nepomuceno",
            "Uf": "MG"
        },
        {
            "Id": 2761,
            "Codigo": 3144656,
            "Nome": "Ninheira",
            "Uf": "MG"
        },
        {
            "Id": 2762,
            "Codigo": 3144672,
            "Nome": "Nova Belém",
            "Uf": "MG"
        },
        {
            "Id": 2763,
            "Codigo": 3144706,
            "Nome": "Nova Era",
            "Uf": "MG"
        },
        {
            "Id": 2764,
            "Codigo": 3144805,
            "Nome": "Nova Lima",
            "Uf": "MG"
        },
        {
            "Id": 2765,
            "Codigo": 3144904,
            "Nome": "Nova Módica",
            "Uf": "MG"
        },
        {
            "Id": 2766,
            "Codigo": 3145000,
            "Nome": "Nova Ponte",
            "Uf": "MG"
        },
        {
            "Id": 2767,
            "Codigo": 3145059,
            "Nome": "Nova Porteirinha",
            "Uf": "MG"
        },
        {
            "Id": 2768,
            "Codigo": 3145109,
            "Nome": "Nova Resende",
            "Uf": "MG"
        },
        {
            "Id": 2769,
            "Codigo": 3145208,
            "Nome": "Nova Serrana",
            "Uf": "MG"
        },
        {
            "Id": 2770,
            "Codigo": 3145307,
            "Nome": "Novo Cruzeiro",
            "Uf": "MG"
        },
        {
            "Id": 2771,
            "Codigo": 3145356,
            "Nome": "Novo Oriente de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2772,
            "Codigo": 3145372,
            "Nome": "Novorizonte",
            "Uf": "MG"
        },
        {
            "Id": 2773,
            "Codigo": 3145406,
            "Nome": "Olaria",
            "Uf": "MG"
        },
        {
            "Id": 2774,
            "Codigo": 3145455,
            "Nome": "Olhos-D'Água",
            "Uf": "MG"
        },
        {
            "Id": 2775,
            "Codigo": 3145505,
            "Nome": "Olímpio Noronha",
            "Uf": "MG"
        },
        {
            "Id": 2776,
            "Codigo": 3145604,
            "Nome": "Oliveira",
            "Uf": "MG"
        },
        {
            "Id": 2777,
            "Codigo": 3145703,
            "Nome": "Oliveira Fortes",
            "Uf": "MG"
        },
        {
            "Id": 2778,
            "Codigo": 3145802,
            "Nome": "Onça de Pitangui",
            "Uf": "MG"
        },
        {
            "Id": 2779,
            "Codigo": 3145851,
            "Nome": "Oratórios",
            "Uf": "MG"
        },
        {
            "Id": 2780,
            "Codigo": 3145877,
            "Nome": "Orizânia",
            "Uf": "MG"
        },
        {
            "Id": 2781,
            "Codigo": 3145901,
            "Nome": "Ouro Branco",
            "Uf": "MG"
        },
        {
            "Id": 2782,
            "Codigo": 3146008,
            "Nome": "Ouro Fino",
            "Uf": "MG"
        },
        {
            "Id": 2783,
            "Codigo": 3146107,
            "Nome": "Ouro Preto",
            "Uf": "MG"
        },
        {
            "Id": 2784,
            "Codigo": 3146206,
            "Nome": "Ouro Verde de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2785,
            "Codigo": 3146255,
            "Nome": "Padre Carvalho",
            "Uf": "MG"
        },
        {
            "Id": 2786,
            "Codigo": 3146305,
            "Nome": "Padre Paraíso",
            "Uf": "MG"
        },
        {
            "Id": 2787,
            "Codigo": 3146404,
            "Nome": "Paineiras",
            "Uf": "MG"
        },
        {
            "Id": 2788,
            "Codigo": 3146503,
            "Nome": "Pains",
            "Uf": "MG"
        },
        {
            "Id": 2789,
            "Codigo": 3146552,
            "Nome": "Pai Pedro",
            "Uf": "MG"
        },
        {
            "Id": 2790,
            "Codigo": 3146602,
            "Nome": "Paiva",
            "Uf": "MG"
        },
        {
            "Id": 2791,
            "Codigo": 3146701,
            "Nome": "Palma",
            "Uf": "MG"
        },
        {
            "Id": 2792,
            "Codigo": 3146750,
            "Nome": "Palmópolis",
            "Uf": "MG"
        },
        {
            "Id": 2793,
            "Codigo": 3146909,
            "Nome": "Papagaios",
            "Uf": "MG"
        },
        {
            "Id": 2794,
            "Codigo": 3147006,
            "Nome": "Paracatu",
            "Uf": "MG"
        },
        {
            "Id": 2795,
            "Codigo": 3147105,
            "Nome": "Pará de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2796,
            "Codigo": 3147204,
            "Nome": "Paraguaçu",
            "Uf": "MG"
        },
        {
            "Id": 2797,
            "Codigo": 3147303,
            "Nome": "Paraisópolis",
            "Uf": "MG"
        },
        {
            "Id": 2798,
            "Codigo": 3147402,
            "Nome": "Paraopeba",
            "Uf": "MG"
        },
        {
            "Id": 2799,
            "Codigo": 3147501,
            "Nome": "Passabém",
            "Uf": "MG"
        },
        {
            "Id": 2800,
            "Codigo": 3147600,
            "Nome": "Passa Quatro",
            "Uf": "MG"
        },
        {
            "Id": 2801,
            "Codigo": 3147709,
            "Nome": "Passa Tempo",
            "Uf": "MG"
        },
        {
            "Id": 2802,
            "Codigo": 3147808,
            "Nome": "Passa-Vinte",
            "Uf": "MG"
        },
        {
            "Id": 2803,
            "Codigo": 3147907,
            "Nome": "Passos",
            "Uf": "MG"
        },
        {
            "Id": 2804,
            "Codigo": 3147956,
            "Nome": "Patis",
            "Uf": "MG"
        },
        {
            "Id": 2805,
            "Codigo": 3148004,
            "Nome": "Patos de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2806,
            "Codigo": 3148103,
            "Nome": "Patrocínio",
            "Uf": "MG"
        },
        {
            "Id": 2807,
            "Codigo": 3148202,
            "Nome": "Patrocínio do Muriaé",
            "Uf": "MG"
        },
        {
            "Id": 2808,
            "Codigo": 3148301,
            "Nome": "Paula Cândido",
            "Uf": "MG"
        },
        {
            "Id": 2809,
            "Codigo": 3148400,
            "Nome": "Paulistas",
            "Uf": "MG"
        },
        {
            "Id": 2810,
            "Codigo": 3148509,
            "Nome": "Pavão",
            "Uf": "MG"
        },
        {
            "Id": 2811,
            "Codigo": 3148608,
            "Nome": "Peçanha",
            "Uf": "MG"
        },
        {
            "Id": 2812,
            "Codigo": 3148707,
            "Nome": "Pedra Azul",
            "Uf": "MG"
        },
        {
            "Id": 2813,
            "Codigo": 3148756,
            "Nome": "Pedra Bonita",
            "Uf": "MG"
        },
        {
            "Id": 2814,
            "Codigo": 3148806,
            "Nome": "Pedra do Anta",
            "Uf": "MG"
        },
        {
            "Id": 2815,
            "Codigo": 3148905,
            "Nome": "Pedra do Indaiá",
            "Uf": "MG"
        },
        {
            "Id": 2816,
            "Codigo": 3149002,
            "Nome": "Pedra Dourada",
            "Uf": "MG"
        },
        {
            "Id": 2817,
            "Codigo": 3149101,
            "Nome": "Pedralva",
            "Uf": "MG"
        },
        {
            "Id": 2818,
            "Codigo": 3149150,
            "Nome": "Pedras de Maria da Cruz",
            "Uf": "MG"
        },
        {
            "Id": 2819,
            "Codigo": 3149200,
            "Nome": "Pedrinópolis",
            "Uf": "MG"
        },
        {
            "Id": 2820,
            "Codigo": 3149309,
            "Nome": "Pedro Leopoldo",
            "Uf": "MG"
        },
        {
            "Id": 2821,
            "Codigo": 3149408,
            "Nome": "Pedro Teixeira",
            "Uf": "MG"
        },
        {
            "Id": 2822,
            "Codigo": 3149507,
            "Nome": "Pequeri",
            "Uf": "MG"
        },
        {
            "Id": 2823,
            "Codigo": 3149606,
            "Nome": "Pequi",
            "Uf": "MG"
        },
        {
            "Id": 2824,
            "Codigo": 3149705,
            "Nome": "Perdigão",
            "Uf": "MG"
        },
        {
            "Id": 2825,
            "Codigo": 3149804,
            "Nome": "Perdizes",
            "Uf": "MG"
        },
        {
            "Id": 2826,
            "Codigo": 3149903,
            "Nome": "Perdões",
            "Uf": "MG"
        },
        {
            "Id": 2827,
            "Codigo": 3149952,
            "Nome": "Periquito",
            "Uf": "MG"
        },
        {
            "Id": 2828,
            "Codigo": 3150000,
            "Nome": "Pescador",
            "Uf": "MG"
        },
        {
            "Id": 2829,
            "Codigo": 3150109,
            "Nome": "Piau",
            "Uf": "MG"
        },
        {
            "Id": 2830,
            "Codigo": 3150158,
            "Nome": "Piedade de Caratinga",
            "Uf": "MG"
        },
        {
            "Id": 2831,
            "Codigo": 3150208,
            "Nome": "Piedade de Ponte Nova",
            "Uf": "MG"
        },
        {
            "Id": 2832,
            "Codigo": 3150307,
            "Nome": "Piedade do Rio Grande",
            "Uf": "MG"
        },
        {
            "Id": 2833,
            "Codigo": 3150406,
            "Nome": "Piedade dos Gerais",
            "Uf": "MG"
        },
        {
            "Id": 2834,
            "Codigo": 3150505,
            "Nome": "Pimenta",
            "Uf": "MG"
        },
        {
            "Id": 2835,
            "Codigo": 3150539,
            "Nome": "Pingo-D'Água",
            "Uf": "MG"
        },
        {
            "Id": 2836,
            "Codigo": 3150570,
            "Nome": "Pintópolis",
            "Uf": "MG"
        },
        {
            "Id": 2837,
            "Codigo": 3150604,
            "Nome": "Piracema",
            "Uf": "MG"
        },
        {
            "Id": 2838,
            "Codigo": 3150703,
            "Nome": "Pirajuba",
            "Uf": "MG"
        },
        {
            "Id": 2839,
            "Codigo": 3150802,
            "Nome": "Piranga",
            "Uf": "MG"
        },
        {
            "Id": 2840,
            "Codigo": 3150901,
            "Nome": "Piranguçu",
            "Uf": "MG"
        },
        {
            "Id": 2841,
            "Codigo": 3151008,
            "Nome": "Piranguinho",
            "Uf": "MG"
        },
        {
            "Id": 2842,
            "Codigo": 3151107,
            "Nome": "Pirapetinga",
            "Uf": "MG"
        },
        {
            "Id": 2843,
            "Codigo": 3151206,
            "Nome": "Pirapora",
            "Uf": "MG"
        },
        {
            "Id": 2844,
            "Codigo": 3151305,
            "Nome": "Piraúba",
            "Uf": "MG"
        },
        {
            "Id": 2845,
            "Codigo": 3151404,
            "Nome": "Pitangui",
            "Uf": "MG"
        },
        {
            "Id": 2846,
            "Codigo": 3151503,
            "Nome": "Piumhi",
            "Uf": "MG"
        },
        {
            "Id": 2847,
            "Codigo": 3151602,
            "Nome": "Planura",
            "Uf": "MG"
        },
        {
            "Id": 2848,
            "Codigo": 3151701,
            "Nome": "Poço Fundo",
            "Uf": "MG"
        },
        {
            "Id": 2849,
            "Codigo": 3151800,
            "Nome": "Poços de Caldas",
            "Uf": "MG"
        },
        {
            "Id": 2850,
            "Codigo": 3151909,
            "Nome": "Pocrane",
            "Uf": "MG"
        },
        {
            "Id": 2851,
            "Codigo": 3152006,
            "Nome": "Pompéu",
            "Uf": "MG"
        },
        {
            "Id": 2852,
            "Codigo": 3152105,
            "Nome": "Ponte Nova",
            "Uf": "MG"
        },
        {
            "Id": 2853,
            "Codigo": 3152131,
            "Nome": "Ponto Chique",
            "Uf": "MG"
        },
        {
            "Id": 2854,
            "Codigo": 3152170,
            "Nome": "Ponto dos Volantes",
            "Uf": "MG"
        },
        {
            "Id": 2855,
            "Codigo": 3152204,
            "Nome": "Porteirinha",
            "Uf": "MG"
        },
        {
            "Id": 2856,
            "Codigo": 3152303,
            "Nome": "Porto Firme",
            "Uf": "MG"
        },
        {
            "Id": 2857,
            "Codigo": 3152402,
            "Nome": "Poté",
            "Uf": "MG"
        },
        {
            "Id": 2858,
            "Codigo": 3152501,
            "Nome": "Pouso Alegre",
            "Uf": "MG"
        },
        {
            "Id": 2859,
            "Codigo": 3152600,
            "Nome": "Pouso Alto",
            "Uf": "MG"
        },
        {
            "Id": 2860,
            "Codigo": 3152709,
            "Nome": "Prados",
            "Uf": "MG"
        },
        {
            "Id": 2861,
            "Codigo": 3152808,
            "Nome": "Prata",
            "Uf": "MG"
        },
        {
            "Id": 2862,
            "Codigo": 3152907,
            "Nome": "Pratápolis",
            "Uf": "MG"
        },
        {
            "Id": 2863,
            "Codigo": 3153004,
            "Nome": "Pratinha",
            "Uf": "MG"
        },
        {
            "Id": 2864,
            "Codigo": 3153103,
            "Nome": "Presidente Bernardes",
            "Uf": "MG"
        },
        {
            "Id": 2865,
            "Codigo": 3153202,
            "Nome": "Presidente Juscelino",
            "Uf": "MG"
        },
        {
            "Id": 2866,
            "Codigo": 3153301,
            "Nome": "Presidente Kubitschek",
            "Uf": "MG"
        },
        {
            "Id": 2867,
            "Codigo": 3153400,
            "Nome": "Presidente Olegário",
            "Uf": "MG"
        },
        {
            "Id": 2868,
            "Codigo": 3153509,
            "Nome": "Alto Jequitibá",
            "Uf": "MG"
        },
        {
            "Id": 2869,
            "Codigo": 3153608,
            "Nome": "Prudente de Morais",
            "Uf": "MG"
        },
        {
            "Id": 2870,
            "Codigo": 3153707,
            "Nome": "Quartel Geral",
            "Uf": "MG"
        },
        {
            "Id": 2871,
            "Codigo": 3153806,
            "Nome": "Queluzito",
            "Uf": "MG"
        },
        {
            "Id": 2872,
            "Codigo": 3153905,
            "Nome": "Raposos",
            "Uf": "MG"
        },
        {
            "Id": 2873,
            "Codigo": 3154002,
            "Nome": "Raul Soares",
            "Uf": "MG"
        },
        {
            "Id": 2874,
            "Codigo": 3154101,
            "Nome": "Recreio",
            "Uf": "MG"
        },
        {
            "Id": 2875,
            "Codigo": 3154150,
            "Nome": "Reduto",
            "Uf": "MG"
        },
        {
            "Id": 2876,
            "Codigo": 3154200,
            "Nome": "Resende Costa",
            "Uf": "MG"
        },
        {
            "Id": 2877,
            "Codigo": 3154309,
            "Nome": "Resplendor",
            "Uf": "MG"
        },
        {
            "Id": 2878,
            "Codigo": 3154408,
            "Nome": "Ressaquinha",
            "Uf": "MG"
        },
        {
            "Id": 2879,
            "Codigo": 3154457,
            "Nome": "Riachinho",
            "Uf": "MG"
        },
        {
            "Id": 2880,
            "Codigo": 3154507,
            "Nome": "Riacho dos Machados",
            "Uf": "MG"
        },
        {
            "Id": 2881,
            "Codigo": 3154606,
            "Nome": "Ribeirão das Neves",
            "Uf": "MG"
        },
        {
            "Id": 2882,
            "Codigo": 3154705,
            "Nome": "Ribeirão Vermelho",
            "Uf": "MG"
        },
        {
            "Id": 2883,
            "Codigo": 3154804,
            "Nome": "Rio Acima",
            "Uf": "MG"
        },
        {
            "Id": 2884,
            "Codigo": 3154903,
            "Nome": "Rio Casca",
            "Uf": "MG"
        },
        {
            "Id": 2885,
            "Codigo": 3155009,
            "Nome": "Rio Doce",
            "Uf": "MG"
        },
        {
            "Id": 2886,
            "Codigo": 3155108,
            "Nome": "Rio do Prado",
            "Uf": "MG"
        },
        {
            "Id": 2887,
            "Codigo": 3155207,
            "Nome": "Rio Espera",
            "Uf": "MG"
        },
        {
            "Id": 2888,
            "Codigo": 3155306,
            "Nome": "Rio Manso",
            "Uf": "MG"
        },
        {
            "Id": 2889,
            "Codigo": 3155405,
            "Nome": "Rio Novo",
            "Uf": "MG"
        },
        {
            "Id": 2890,
            "Codigo": 3155504,
            "Nome": "Rio Paranaíba",
            "Uf": "MG"
        },
        {
            "Id": 2891,
            "Codigo": 3155603,
            "Nome": "Rio Pardo de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2892,
            "Codigo": 3155702,
            "Nome": "Rio Piracicaba",
            "Uf": "MG"
        },
        {
            "Id": 2893,
            "Codigo": 3155801,
            "Nome": "Rio Pomba",
            "Uf": "MG"
        },
        {
            "Id": 2894,
            "Codigo": 3155900,
            "Nome": "Rio Preto",
            "Uf": "MG"
        },
        {
            "Id": 2895,
            "Codigo": 3156007,
            "Nome": "Rio Vermelho",
            "Uf": "MG"
        },
        {
            "Id": 2896,
            "Codigo": 3156106,
            "Nome": "Ritápolis",
            "Uf": "MG"
        },
        {
            "Id": 2897,
            "Codigo": 3156205,
            "Nome": "Rochedo de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2898,
            "Codigo": 3156304,
            "Nome": "Rodeiro",
            "Uf": "MG"
        },
        {
            "Id": 2899,
            "Codigo": 3156403,
            "Nome": "Romaria",
            "Uf": "MG"
        },
        {
            "Id": 2900,
            "Codigo": 3156452,
            "Nome": "Rosário da Limeira",
            "Uf": "MG"
        },
        {
            "Id": 2901,
            "Codigo": 3156502,
            "Nome": "Rubelita",
            "Uf": "MG"
        },
        {
            "Id": 2902,
            "Codigo": 3156601,
            "Nome": "Rubim",
            "Uf": "MG"
        },
        {
            "Id": 2903,
            "Codigo": 3156700,
            "Nome": "Sabará",
            "Uf": "MG"
        },
        {
            "Id": 2904,
            "Codigo": 3156809,
            "Nome": "Sabinópolis",
            "Uf": "MG"
        },
        {
            "Id": 2905,
            "Codigo": 3156908,
            "Nome": "Sacramento",
            "Uf": "MG"
        },
        {
            "Id": 2906,
            "Codigo": 3157005,
            "Nome": "Salinas",
            "Uf": "MG"
        },
        {
            "Id": 2907,
            "Codigo": 3157104,
            "Nome": "Salto da Divisa",
            "Uf": "MG"
        },
        {
            "Id": 2908,
            "Codigo": 3157203,
            "Nome": "Santa Bárbara",
            "Uf": "MG"
        },
        {
            "Id": 2909,
            "Codigo": 3157252,
            "Nome": "Santa Bárbara do Leste",
            "Uf": "MG"
        },
        {
            "Id": 2910,
            "Codigo": 3157278,
            "Nome": "Santa Bárbara do Monte Verde",
            "Uf": "MG"
        },
        {
            "Id": 2911,
            "Codigo": 3157302,
            "Nome": "Santa Bárbara do Tugúrio",
            "Uf": "MG"
        },
        {
            "Id": 2912,
            "Codigo": 3157336,
            "Nome": "Santa Cruz de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2913,
            "Codigo": 3157377,
            "Nome": "Santa Cruz de Salinas",
            "Uf": "MG"
        },
        {
            "Id": 2914,
            "Codigo": 3157401,
            "Nome": "Santa Cruz do Escalvado",
            "Uf": "MG"
        },
        {
            "Id": 2915,
            "Codigo": 3157500,
            "Nome": "Santa Efigênia de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2916,
            "Codigo": 3157609,
            "Nome": "Santa Fé de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2917,
            "Codigo": 3157658,
            "Nome": "Santa Helena de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2918,
            "Codigo": 3157708,
            "Nome": "Santa Juliana",
            "Uf": "MG"
        },
        {
            "Id": 2919,
            "Codigo": 3157807,
            "Nome": "Santa Luzia",
            "Uf": "MG"
        },
        {
            "Id": 2920,
            "Codigo": 3157906,
            "Nome": "Santa Margarida",
            "Uf": "MG"
        },
        {
            "Id": 2921,
            "Codigo": 3158003,
            "Nome": "Santa Maria de Itabira",
            "Uf": "MG"
        },
        {
            "Id": 2922,
            "Codigo": 3158102,
            "Nome": "Santa Maria do Salto",
            "Uf": "MG"
        },
        {
            "Id": 2923,
            "Codigo": 3158201,
            "Nome": "Santa Maria do Suaçuí",
            "Uf": "MG"
        },
        {
            "Id": 2924,
            "Codigo": 3158300,
            "Nome": "Santana da Vargem",
            "Uf": "MG"
        },
        {
            "Id": 2925,
            "Codigo": 3158409,
            "Nome": "Santana de Cataguases",
            "Uf": "MG"
        },
        {
            "Id": 2926,
            "Codigo": 3158508,
            "Nome": "Santana de Pirapama",
            "Uf": "MG"
        },
        {
            "Id": 2927,
            "Codigo": 3158607,
            "Nome": "Santana do Deserto",
            "Uf": "MG"
        },
        {
            "Id": 2928,
            "Codigo": 3158706,
            "Nome": "Santana do Garambéu",
            "Uf": "MG"
        },
        {
            "Id": 2929,
            "Codigo": 3158805,
            "Nome": "Santana do Jacaré",
            "Uf": "MG"
        },
        {
            "Id": 2930,
            "Codigo": 3158904,
            "Nome": "Santana do Manhuaçu",
            "Uf": "MG"
        },
        {
            "Id": 2931,
            "Codigo": 3158953,
            "Nome": "Santana do Paraíso",
            "Uf": "MG"
        },
        {
            "Id": 2932,
            "Codigo": 3159001,
            "Nome": "Santana do Riacho",
            "Uf": "MG"
        },
        {
            "Id": 2933,
            "Codigo": 3159100,
            "Nome": "Santana dos Montes",
            "Uf": "MG"
        },
        {
            "Id": 2934,
            "Codigo": 3159209,
            "Nome": "Santa Rita de Caldas",
            "Uf": "MG"
        },
        {
            "Id": 2935,
            "Codigo": 3159308,
            "Nome": "Santa Rita de Jacutinga",
            "Uf": "MG"
        },
        {
            "Id": 2936,
            "Codigo": 3159357,
            "Nome": "Santa Rita de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2937,
            "Codigo": 3159407,
            "Nome": "Santa Rita de Ibitipoca",
            "Uf": "MG"
        },
        {
            "Id": 2938,
            "Codigo": 3159506,
            "Nome": "Santa Rita do Itueto",
            "Uf": "MG"
        },
        {
            "Id": 2939,
            "Codigo": 3159605,
            "Nome": "Santa Rita do Sapucaí",
            "Uf": "MG"
        },
        {
            "Id": 2940,
            "Codigo": 3159704,
            "Nome": "Santa Rosa da Serra",
            "Uf": "MG"
        },
        {
            "Id": 2941,
            "Codigo": 3159803,
            "Nome": "Santa Vitória",
            "Uf": "MG"
        },
        {
            "Id": 2942,
            "Codigo": 3159902,
            "Nome": "Santo Antônio do Amparo",
            "Uf": "MG"
        },
        {
            "Id": 2943,
            "Codigo": 3160009,
            "Nome": "Santo Antônio do Aventureiro",
            "Uf": "MG"
        },
        {
            "Id": 2944,
            "Codigo": 3160108,
            "Nome": "Santo Antônio do Grama",
            "Uf": "MG"
        },
        {
            "Id": 2945,
            "Codigo": 3160207,
            "Nome": "Santo Antônio do Itambé",
            "Uf": "MG"
        },
        {
            "Id": 2946,
            "Codigo": 3160306,
            "Nome": "Santo Antônio do Jacinto",
            "Uf": "MG"
        },
        {
            "Id": 2947,
            "Codigo": 3160405,
            "Nome": "Santo Antônio do Monte",
            "Uf": "MG"
        },
        {
            "Id": 2948,
            "Codigo": 3160454,
            "Nome": "Santo Antônio do Retiro",
            "Uf": "MG"
        },
        {
            "Id": 2949,
            "Codigo": 3160504,
            "Nome": "Santo Antônio do Rio Abaixo",
            "Uf": "MG"
        },
        {
            "Id": 2950,
            "Codigo": 3160603,
            "Nome": "Santo Hipólito",
            "Uf": "MG"
        },
        {
            "Id": 2951,
            "Codigo": 3160702,
            "Nome": "Santos Dumont",
            "Uf": "MG"
        },
        {
            "Id": 2952,
            "Codigo": 3160801,
            "Nome": "São Bento Abade",
            "Uf": "MG"
        },
        {
            "Id": 2953,
            "Codigo": 3160900,
            "Nome": "São Brás do Suaçuí",
            "Uf": "MG"
        },
        {
            "Id": 2954,
            "Codigo": 3160959,
            "Nome": "São Domingos das Dores",
            "Uf": "MG"
        },
        {
            "Id": 2955,
            "Codigo": 3161007,
            "Nome": "São Domingos do Prata",
            "Uf": "MG"
        },
        {
            "Id": 2956,
            "Codigo": 3161056,
            "Nome": "São Félix de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2957,
            "Codigo": 3161106,
            "Nome": "São Francisco",
            "Uf": "MG"
        },
        {
            "Id": 2958,
            "Codigo": 3161205,
            "Nome": "São Francisco de Paula",
            "Uf": "MG"
        },
        {
            "Id": 2959,
            "Codigo": 3161304,
            "Nome": "São Francisco de Sales",
            "Uf": "MG"
        },
        {
            "Id": 2960,
            "Codigo": 3161403,
            "Nome": "São Francisco do Glória",
            "Uf": "MG"
        },
        {
            "Id": 2961,
            "Codigo": 3161502,
            "Nome": "São Geraldo",
            "Uf": "MG"
        },
        {
            "Id": 2962,
            "Codigo": 3161601,
            "Nome": "São Geraldo da Piedade",
            "Uf": "MG"
        },
        {
            "Id": 2963,
            "Codigo": 3161650,
            "Nome": "São Geraldo do Baixio",
            "Uf": "MG"
        },
        {
            "Id": 2964,
            "Codigo": 3161700,
            "Nome": "São Gonçalo do Abaeté",
            "Uf": "MG"
        },
        {
            "Id": 2965,
            "Codigo": 3161809,
            "Nome": "São Gonçalo do Pará",
            "Uf": "MG"
        },
        {
            "Id": 2966,
            "Codigo": 3161908,
            "Nome": "São Gonçalo do Rio Abaixo",
            "Uf": "MG"
        },
        {
            "Id": 2967,
            "Codigo": 3162005,
            "Nome": "São Gonçalo do Sapucaí",
            "Uf": "MG"
        },
        {
            "Id": 2968,
            "Codigo": 3162104,
            "Nome": "São Gotardo",
            "Uf": "MG"
        },
        {
            "Id": 2969,
            "Codigo": 3162203,
            "Nome": "São João Batista do Glória",
            "Uf": "MG"
        },
        {
            "Id": 2970,
            "Codigo": 3162252,
            "Nome": "São João da Lagoa",
            "Uf": "MG"
        },
        {
            "Id": 2971,
            "Codigo": 3162302,
            "Nome": "São João da Mata",
            "Uf": "MG"
        },
        {
            "Id": 2972,
            "Codigo": 3162401,
            "Nome": "São João da Ponte",
            "Uf": "MG"
        },
        {
            "Id": 2973,
            "Codigo": 3162450,
            "Nome": "São João das Missões",
            "Uf": "MG"
        },
        {
            "Id": 2974,
            "Codigo": 3162500,
            "Nome": "São João del Rei",
            "Uf": "MG"
        },
        {
            "Id": 2975,
            "Codigo": 3162559,
            "Nome": "São João do Manhuaçu",
            "Uf": "MG"
        },
        {
            "Id": 2976,
            "Codigo": 3162575,
            "Nome": "São João do Manteninha",
            "Uf": "MG"
        },
        {
            "Id": 2977,
            "Codigo": 3162609,
            "Nome": "São João do Oriente",
            "Uf": "MG"
        },
        {
            "Id": 2978,
            "Codigo": 3162658,
            "Nome": "São João do Pacuí",
            "Uf": "MG"
        },
        {
            "Id": 2979,
            "Codigo": 3162708,
            "Nome": "São João do Paraíso",
            "Uf": "MG"
        },
        {
            "Id": 2980,
            "Codigo": 3162807,
            "Nome": "São João Evangelista",
            "Uf": "MG"
        },
        {
            "Id": 2981,
            "Codigo": 3162906,
            "Nome": "São João Nepomuceno",
            "Uf": "MG"
        },
        {
            "Id": 2982,
            "Codigo": 3162922,
            "Nome": "São Joaquim de Bicas",
            "Uf": "MG"
        },
        {
            "Id": 2983,
            "Codigo": 3162948,
            "Nome": "São José da Barra",
            "Uf": "MG"
        },
        {
            "Id": 2984,
            "Codigo": 3162955,
            "Nome": "São José da Lapa",
            "Uf": "MG"
        },
        {
            "Id": 2985,
            "Codigo": 3163003,
            "Nome": "São José da Safira",
            "Uf": "MG"
        },
        {
            "Id": 2986,
            "Codigo": 3163102,
            "Nome": "São José da Varginha",
            "Uf": "MG"
        },
        {
            "Id": 2987,
            "Codigo": 3163201,
            "Nome": "São José do Alegre",
            "Uf": "MG"
        },
        {
            "Id": 2988,
            "Codigo": 3163300,
            "Nome": "São José do Divino",
            "Uf": "MG"
        },
        {
            "Id": 2989,
            "Codigo": 3163409,
            "Nome": "São José do Goiabal",
            "Uf": "MG"
        },
        {
            "Id": 2990,
            "Codigo": 3163508,
            "Nome": "São José do Jacuri",
            "Uf": "MG"
        },
        {
            "Id": 2991,
            "Codigo": 3163607,
            "Nome": "São José do Mantimento",
            "Uf": "MG"
        },
        {
            "Id": 2992,
            "Codigo": 3163706,
            "Nome": "São Lourenço",
            "Uf": "MG"
        },
        {
            "Id": 2993,
            "Codigo": 3163805,
            "Nome": "São Miguel do Anta",
            "Uf": "MG"
        },
        {
            "Id": 2994,
            "Codigo": 3163904,
            "Nome": "São Pedro da União",
            "Uf": "MG"
        },
        {
            "Id": 2995,
            "Codigo": 3164001,
            "Nome": "São Pedro dos Ferros",
            "Uf": "MG"
        },
        {
            "Id": 2996,
            "Codigo": 3164100,
            "Nome": "São Pedro do Suaçuí",
            "Uf": "MG"
        },
        {
            "Id": 2997,
            "Codigo": 3164209,
            "Nome": "São Romão",
            "Uf": "MG"
        },
        {
            "Id": 2998,
            "Codigo": 3164308,
            "Nome": "São Roque de Minas",
            "Uf": "MG"
        },
        {
            "Id": 2999,
            "Codigo": 3164407,
            "Nome": "São Sebastião da Bela Vista",
            "Uf": "MG"
        },
        {
            "Id": 3000,
            "Codigo": 3164431,
            "Nome": "São Sebastião da Vargem Alegre",
            "Uf": "MG"
        },
        {
            "Id": 3001,
            "Codigo": 3164472,
            "Nome": "São Sebastião do Anta",
            "Uf": "MG"
        },
        {
            "Id": 3002,
            "Codigo": 3164506,
            "Nome": "São Sebastião do Maranhão",
            "Uf": "MG"
        },
        {
            "Id": 3003,
            "Codigo": 3164605,
            "Nome": "São Sebastião do Oeste",
            "Uf": "MG"
        },
        {
            "Id": 3004,
            "Codigo": 3164704,
            "Nome": "São Sebastião do Paraíso",
            "Uf": "MG"
        },
        {
            "Id": 3005,
            "Codigo": 3164803,
            "Nome": "São Sebastião do Rio Preto",
            "Uf": "MG"
        },
        {
            "Id": 3006,
            "Codigo": 3164902,
            "Nome": "São Sebastião do Rio Verde",
            "Uf": "MG"
        },
        {
            "Id": 3007,
            "Codigo": 3165008,
            "Nome": "São Tiago",
            "Uf": "MG"
        },
        {
            "Id": 3008,
            "Codigo": 3165107,
            "Nome": "São Tomás de Aquino",
            "Uf": "MG"
        },
        {
            "Id": 3009,
            "Codigo": 3165206,
            "Nome": "São Thomé das Letras",
            "Uf": "MG"
        },
        {
            "Id": 3010,
            "Codigo": 3165305,
            "Nome": "São Vicente de Minas",
            "Uf": "MG"
        },
        {
            "Id": 3011,
            "Codigo": 3165404,
            "Nome": "Sapucaí-Mirim",
            "Uf": "MG"
        },
        {
            "Id": 3012,
            "Codigo": 3165503,
            "Nome": "Sardoá",
            "Uf": "MG"
        },
        {
            "Id": 3013,
            "Codigo": 3165537,
            "Nome": "Sarzedo",
            "Uf": "MG"
        },
        {
            "Id": 3014,
            "Codigo": 3165552,
            "Nome": "Setubinha",
            "Uf": "MG"
        },
        {
            "Id": 3015,
            "Codigo": 3165560,
            "Nome": "Sem-Peixe",
            "Uf": "MG"
        },
        {
            "Id": 3016,
            "Codigo": 3165578,
            "Nome": "Senador Amaral",
            "Uf": "MG"
        },
        {
            "Id": 3017,
            "Codigo": 3165602,
            "Nome": "Senador Cortes",
            "Uf": "MG"
        },
        {
            "Id": 3018,
            "Codigo": 3165701,
            "Nome": "Senador Firmino",
            "Uf": "MG"
        },
        {
            "Id": 3019,
            "Codigo": 3165800,
            "Nome": "Senador José Bento",
            "Uf": "MG"
        },
        {
            "Id": 3020,
            "Codigo": 3165909,
            "Nome": "Senador Modestino Gonçalves",
            "Uf": "MG"
        },
        {
            "Id": 3021,
            "Codigo": 3166006,
            "Nome": "Senhora de Oliveira",
            "Uf": "MG"
        },
        {
            "Id": 3022,
            "Codigo": 3166105,
            "Nome": "Senhora do Porto",
            "Uf": "MG"
        },
        {
            "Id": 3023,
            "Codigo": 3166204,
            "Nome": "Senhora dos Remédios",
            "Uf": "MG"
        },
        {
            "Id": 3024,
            "Codigo": 3166303,
            "Nome": "Sericita",
            "Uf": "MG"
        },
        {
            "Id": 3025,
            "Codigo": 3166402,
            "Nome": "Seritinga",
            "Uf": "MG"
        },
        {
            "Id": 3026,
            "Codigo": 3166501,
            "Nome": "Serra Azul de Minas",
            "Uf": "MG"
        },
        {
            "Id": 3027,
            "Codigo": 3166600,
            "Nome": "Serra da Saudade",
            "Uf": "MG"
        },
        {
            "Id": 3028,
            "Codigo": 3166709,
            "Nome": "Serra dos Aimorés",
            "Uf": "MG"
        },
        {
            "Id": 3029,
            "Codigo": 3166808,
            "Nome": "Serra do Salitre",
            "Uf": "MG"
        },
        {
            "Id": 3030,
            "Codigo": 3166907,
            "Nome": "Serrania",
            "Uf": "MG"
        },
        {
            "Id": 3031,
            "Codigo": 3166956,
            "Nome": "Serranópolis de Minas",
            "Uf": "MG"
        },
        {
            "Id": 3032,
            "Codigo": 3167004,
            "Nome": "Serranos",
            "Uf": "MG"
        },
        {
            "Id": 3033,
            "Codigo": 3167103,
            "Nome": "Serro",
            "Uf": "MG"
        },
        {
            "Id": 3034,
            "Codigo": 3167202,
            "Nome": "Sete Lagoas",
            "Uf": "MG"
        },
        {
            "Id": 3035,
            "Codigo": 3167301,
            "Nome": "Silveirânia",
            "Uf": "MG"
        },
        {
            "Id": 3036,
            "Codigo": 3167400,
            "Nome": "Silvianópolis",
            "Uf": "MG"
        },
        {
            "Id": 3037,
            "Codigo": 3167509,
            "Nome": "Simão Pereira",
            "Uf": "MG"
        },
        {
            "Id": 3038,
            "Codigo": 3167608,
            "Nome": "Simonésia",
            "Uf": "MG"
        },
        {
            "Id": 3039,
            "Codigo": 3167707,
            "Nome": "Sobrália",
            "Uf": "MG"
        },
        {
            "Id": 3040,
            "Codigo": 3167806,
            "Nome": "Soledade de Minas",
            "Uf": "MG"
        },
        {
            "Id": 3041,
            "Codigo": 3167905,
            "Nome": "Tabuleiro",
            "Uf": "MG"
        },
        {
            "Id": 3042,
            "Codigo": 3168002,
            "Nome": "Taiobeiras",
            "Uf": "MG"
        },
        {
            "Id": 3043,
            "Codigo": 3168051,
            "Nome": "Taparuba",
            "Uf": "MG"
        },
        {
            "Id": 3044,
            "Codigo": 3168101,
            "Nome": "Tapira",
            "Uf": "MG"
        },
        {
            "Id": 3045,
            "Codigo": 3168200,
            "Nome": "Tapiraí",
            "Uf": "MG"
        },
        {
            "Id": 3046,
            "Codigo": 3168309,
            "Nome": "Taquaraçu de Minas",
            "Uf": "MG"
        },
        {
            "Id": 3047,
            "Codigo": 3168408,
            "Nome": "Tarumirim",
            "Uf": "MG"
        },
        {
            "Id": 3048,
            "Codigo": 3168507,
            "Nome": "Teixeiras",
            "Uf": "MG"
        },
        {
            "Id": 3049,
            "Codigo": 3168606,
            "Nome": "Teófilo Otoni",
            "Uf": "MG"
        },
        {
            "Id": 3050,
            "Codigo": 3168705,
            "Nome": "Timóteo",
            "Uf": "MG"
        },
        {
            "Id": 3051,
            "Codigo": 3168804,
            "Nome": "Tiradentes",
            "Uf": "MG"
        },
        {
            "Id": 3052,
            "Codigo": 3168903,
            "Nome": "Tiros",
            "Uf": "MG"
        },
        {
            "Id": 3053,
            "Codigo": 3169000,
            "Nome": "Tocantins",
            "Uf": "MG"
        },
        {
            "Id": 3054,
            "Codigo": 3169059,
            "Nome": "Tocos do Moji",
            "Uf": "MG"
        },
        {
            "Id": 3055,
            "Codigo": 3169109,
            "Nome": "Toledo",
            "Uf": "MG"
        },
        {
            "Id": 3056,
            "Codigo": 3169208,
            "Nome": "Tombos",
            "Uf": "MG"
        },
        {
            "Id": 3057,
            "Codigo": 3169307,
            "Nome": "Três Corações",
            "Uf": "MG"
        },
        {
            "Id": 3058,
            "Codigo": 3169356,
            "Nome": "Três Marias",
            "Uf": "MG"
        },
        {
            "Id": 3059,
            "Codigo": 3169406,
            "Nome": "Três Pontas",
            "Uf": "MG"
        },
        {
            "Id": 3060,
            "Codigo": 3169505,
            "Nome": "Tumiritinga",
            "Uf": "MG"
        },
        {
            "Id": 3061,
            "Codigo": 3169604,
            "Nome": "Tupaciguara",
            "Uf": "MG"
        },
        {
            "Id": 3062,
            "Codigo": 3169703,
            "Nome": "Turmalina",
            "Uf": "MG"
        },
        {
            "Id": 3063,
            "Codigo": 3169802,
            "Nome": "Turvolândia",
            "Uf": "MG"
        },
        {
            "Id": 3064,
            "Codigo": 3169901,
            "Nome": "Ubá",
            "Uf": "MG"
        },
        {
            "Id": 3065,
            "Codigo": 3170008,
            "Nome": "Ubaí",
            "Uf": "MG"
        },
        {
            "Id": 3066,
            "Codigo": 3170057,
            "Nome": "Ubaporanga",
            "Uf": "MG"
        },
        {
            "Id": 3067,
            "Codigo": 3170107,
            "Nome": "Uberaba",
            "Uf": "MG"
        },
        {
            "Id": 3068,
            "Codigo": 3170206,
            "Nome": "Uberlândia",
            "Uf": "MG"
        },
        {
            "Id": 3069,
            "Codigo": 3170305,
            "Nome": "Umburatiba",
            "Uf": "MG"
        },
        {
            "Id": 3070,
            "Codigo": 3170404,
            "Nome": "Unaí",
            "Uf": "MG"
        },
        {
            "Id": 3071,
            "Codigo": 3170438,
            "Nome": "União de Minas",
            "Uf": "MG"
        },
        {
            "Id": 3072,
            "Codigo": 3170479,
            "Nome": "Uruana de Minas",
            "Uf": "MG"
        },
        {
            "Id": 3073,
            "Codigo": 3170503,
            "Nome": "Urucânia",
            "Uf": "MG"
        },
        {
            "Id": 3074,
            "Codigo": 3170529,
            "Nome": "Urucuia",
            "Uf": "MG"
        },
        {
            "Id": 3075,
            "Codigo": 3170578,
            "Nome": "Vargem Alegre",
            "Uf": "MG"
        },
        {
            "Id": 3076,
            "Codigo": 3170602,
            "Nome": "Vargem Bonita",
            "Uf": "MG"
        },
        {
            "Id": 3077,
            "Codigo": 3170651,
            "Nome": "Vargem Grande do Rio Pardo",
            "Uf": "MG"
        },
        {
            "Id": 3078,
            "Codigo": 3170701,
            "Nome": "Varginha",
            "Uf": "MG"
        },
        {
            "Id": 3079,
            "Codigo": 3170750,
            "Nome": "Varjão de Minas",
            "Uf": "MG"
        },
        {
            "Id": 3080,
            "Codigo": 3170800,
            "Nome": "Várzea da Palma",
            "Uf": "MG"
        },
        {
            "Id": 3081,
            "Codigo": 3170909,
            "Nome": "Varzelândia",
            "Uf": "MG"
        },
        {
            "Id": 3082,
            "Codigo": 3171006,
            "Nome": "Vazante",
            "Uf": "MG"
        },
        {
            "Id": 3083,
            "Codigo": 3171030,
            "Nome": "Verdelândia",
            "Uf": "MG"
        },
        {
            "Id": 3084,
            "Codigo": 3171071,
            "Nome": "Veredinha",
            "Uf": "MG"
        },
        {
            "Id": 3085,
            "Codigo": 3171105,
            "Nome": "Veríssimo",
            "Uf": "MG"
        },
        {
            "Id": 3086,
            "Codigo": 3171154,
            "Nome": "Vermelho Novo",
            "Uf": "MG"
        },
        {
            "Id": 3087,
            "Codigo": 3171204,
            "Nome": "Vespasiano",
            "Uf": "MG"
        },
        {
            "Id": 3088,
            "Codigo": 3171303,
            "Nome": "Viçosa",
            "Uf": "MG"
        },
        {
            "Id": 3089,
            "Codigo": 3171402,
            "Nome": "Vieiras",
            "Uf": "MG"
        },
        {
            "Id": 3090,
            "Codigo": 3171501,
            "Nome": "Mathias Lobato",
            "Uf": "MG"
        },
        {
            "Id": 3091,
            "Codigo": 3171600,
            "Nome": "Virgem da Lapa",
            "Uf": "MG"
        },
        {
            "Id": 3092,
            "Codigo": 3171709,
            "Nome": "Virgínia",
            "Uf": "MG"
        },
        {
            "Id": 3093,
            "Codigo": 3171808,
            "Nome": "Virginópolis",
            "Uf": "MG"
        },
        {
            "Id": 3094,
            "Codigo": 3171907,
            "Nome": "Virgolândia",
            "Uf": "MG"
        },
        {
            "Id": 3095,
            "Codigo": 3172004,
            "Nome": "Visconde do Rio Branco",
            "Uf": "MG"
        },
        {
            "Id": 3096,
            "Codigo": 3172103,
            "Nome": "Volta Grande",
            "Uf": "MG"
        },
        {
            "Id": 3097,
            "Codigo": 3172202,
            "Nome": "Wenceslau Braz",
            "Uf": "MG"
        },
        {
            "Id": 3098,
            "Codigo": 3200102,
            "Nome": "Afonso Cláudio",
            "Uf": "ES"
        },
        {
            "Id": 3099,
            "Codigo": 3200136,
            "Nome": "Águia Branca",
            "Uf": "ES"
        },
        {
            "Id": 3100,
            "Codigo": 3200169,
            "Nome": "Água Doce do Norte",
            "Uf": "ES"
        },
        {
            "Id": 3101,
            "Codigo": 3200201,
            "Nome": "Alegre",
            "Uf": "ES"
        },
        {
            "Id": 3102,
            "Codigo": 3200300,
            "Nome": "Alfredo Chaves",
            "Uf": "ES"
        },
        {
            "Id": 3103,
            "Codigo": 3200359,
            "Nome": "Alto Rio Novo",
            "Uf": "ES"
        },
        {
            "Id": 3104,
            "Codigo": 3200409,
            "Nome": "Anchieta",
            "Uf": "ES"
        },
        {
            "Id": 3105,
            "Codigo": 3200508,
            "Nome": "Apiacá",
            "Uf": "ES"
        },
        {
            "Id": 3106,
            "Codigo": 3200607,
            "Nome": "Aracruz",
            "Uf": "ES"
        },
        {
            "Id": 3107,
            "Codigo": 3200706,
            "Nome": "Atilio Vivacqua",
            "Uf": "ES"
        },
        {
            "Id": 3108,
            "Codigo": 3200805,
            "Nome": "Baixo Guandu",
            "Uf": "ES"
        },
        {
            "Id": 3109,
            "Codigo": 3200904,
            "Nome": "Barra de São Francisco",
            "Uf": "ES"
        },
        {
            "Id": 3110,
            "Codigo": 3201001,
            "Nome": "Boa Esperança",
            "Uf": "ES"
        },
        {
            "Id": 3111,
            "Codigo": 3201100,
            "Nome": "Bom Jesus do Norte",
            "Uf": "ES"
        },
        {
            "Id": 3112,
            "Codigo": 3201159,
            "Nome": "Brejetuba",
            "Uf": "ES"
        },
        {
            "Id": 3113,
            "Codigo": 3201209,
            "Nome": "Cachoeiro de Itapemirim",
            "Uf": "ES"
        },
        {
            "Id": 3114,
            "Codigo": 3201308,
            "Nome": "Cariacica",
            "Uf": "ES"
        },
        {
            "Id": 3115,
            "Codigo": 3201407,
            "Nome": "Castelo",
            "Uf": "ES"
        },
        {
            "Id": 3116,
            "Codigo": 3201506,
            "Nome": "Colatina",
            "Uf": "ES"
        },
        {
            "Id": 3117,
            "Codigo": 3201605,
            "Nome": "Conceição da Barra",
            "Uf": "ES"
        },
        {
            "Id": 3118,
            "Codigo": 3201704,
            "Nome": "Conceição do Castelo",
            "Uf": "ES"
        },
        {
            "Id": 3119,
            "Codigo": 3201803,
            "Nome": "Divino de São Lourenço",
            "Uf": "ES"
        },
        {
            "Id": 3120,
            "Codigo": 3201902,
            "Nome": "Domingos Martins",
            "Uf": "ES"
        },
        {
            "Id": 3121,
            "Codigo": 3202009,
            "Nome": "Dores do Rio Preto",
            "Uf": "ES"
        },
        {
            "Id": 3122,
            "Codigo": 3202108,
            "Nome": "Ecoporanga",
            "Uf": "ES"
        },
        {
            "Id": 3123,
            "Codigo": 3202207,
            "Nome": "Fundão",
            "Uf": "ES"
        },
        {
            "Id": 3124,
            "Codigo": 3202256,
            "Nome": "Governador Lindenberg",
            "Uf": "ES"
        },
        {
            "Id": 3125,
            "Codigo": 3202306,
            "Nome": "Guaçuí",
            "Uf": "ES"
        },
        {
            "Id": 3126,
            "Codigo": 3202405,
            "Nome": "Guarapari",
            "Uf": "ES"
        },
        {
            "Id": 3127,
            "Codigo": 3202454,
            "Nome": "Ibatiba",
            "Uf": "ES"
        },
        {
            "Id": 3128,
            "Codigo": 3202504,
            "Nome": "Ibiraçu",
            "Uf": "ES"
        },
        {
            "Id": 3129,
            "Codigo": 3202553,
            "Nome": "Ibitirama",
            "Uf": "ES"
        },
        {
            "Id": 3130,
            "Codigo": 3202603,
            "Nome": "Iconha",
            "Uf": "ES"
        },
        {
            "Id": 3131,
            "Codigo": 3202652,
            "Nome": "Irupi",
            "Uf": "ES"
        },
        {
            "Id": 3132,
            "Codigo": 3202702,
            "Nome": "Itaguaçu",
            "Uf": "ES"
        },
        {
            "Id": 3133,
            "Codigo": 3202801,
            "Nome": "Itapemirim",
            "Uf": "ES"
        },
        {
            "Id": 3134,
            "Codigo": 3202900,
            "Nome": "Itarana",
            "Uf": "ES"
        },
        {
            "Id": 3135,
            "Codigo": 3203007,
            "Nome": "Iúna",
            "Uf": "ES"
        },
        {
            "Id": 3136,
            "Codigo": 3203056,
            "Nome": "Jaguaré",
            "Uf": "ES"
        },
        {
            "Id": 3137,
            "Codigo": 3203106,
            "Nome": "Jerônimo Monteiro",
            "Uf": "ES"
        },
        {
            "Id": 3138,
            "Codigo": 3203130,
            "Nome": "João Neiva",
            "Uf": "ES"
        },
        {
            "Id": 3139,
            "Codigo": 3203163,
            "Nome": "Laranja da Terra",
            "Uf": "ES"
        },
        {
            "Id": 3140,
            "Codigo": 3203205,
            "Nome": "Linhares",
            "Uf": "ES"
        },
        {
            "Id": 3141,
            "Codigo": 3203304,
            "Nome": "Mantenópolis",
            "Uf": "ES"
        },
        {
            "Id": 3142,
            "Codigo": 3203320,
            "Nome": "Marataízes",
            "Uf": "ES"
        },
        {
            "Id": 3143,
            "Codigo": 3203346,
            "Nome": "Marechal Floriano",
            "Uf": "ES"
        },
        {
            "Id": 3144,
            "Codigo": 3203353,
            "Nome": "Marilândia",
            "Uf": "ES"
        },
        {
            "Id": 3145,
            "Codigo": 3203403,
            "Nome": "Mimoso do Sul",
            "Uf": "ES"
        },
        {
            "Id": 3146,
            "Codigo": 3203502,
            "Nome": "Montanha",
            "Uf": "ES"
        },
        {
            "Id": 3147,
            "Codigo": 3203601,
            "Nome": "Mucurici",
            "Uf": "ES"
        },
        {
            "Id": 3148,
            "Codigo": 3203700,
            "Nome": "Muniz Freire",
            "Uf": "ES"
        },
        {
            "Id": 3149,
            "Codigo": 3203809,
            "Nome": "Muqui",
            "Uf": "ES"
        },
        {
            "Id": 3150,
            "Codigo": 3203908,
            "Nome": "Nova Venécia",
            "Uf": "ES"
        },
        {
            "Id": 3151,
            "Codigo": 3204005,
            "Nome": "Pancas",
            "Uf": "ES"
        },
        {
            "Id": 3152,
            "Codigo": 3204054,
            "Nome": "Pedro Canário",
            "Uf": "ES"
        },
        {
            "Id": 3153,
            "Codigo": 3204104,
            "Nome": "Pinheiros",
            "Uf": "ES"
        },
        {
            "Id": 3154,
            "Codigo": 3204203,
            "Nome": "Piúma",
            "Uf": "ES"
        },
        {
            "Id": 3155,
            "Codigo": 3204252,
            "Nome": "Ponto Belo",
            "Uf": "ES"
        },
        {
            "Id": 3156,
            "Codigo": 3204302,
            "Nome": "Presidente Kennedy",
            "Uf": "ES"
        },
        {
            "Id": 3157,
            "Codigo": 3204351,
            "Nome": "Rio Bananal",
            "Uf": "ES"
        },
        {
            "Id": 3158,
            "Codigo": 3204401,
            "Nome": "Rio Novo do Sul",
            "Uf": "ES"
        },
        {
            "Id": 3159,
            "Codigo": 3204500,
            "Nome": "Santa Leopoldina",
            "Uf": "ES"
        },
        {
            "Id": 3160,
            "Codigo": 3204559,
            "Nome": "Santa Maria de Jetibá",
            "Uf": "ES"
        },
        {
            "Id": 3161,
            "Codigo": 3204609,
            "Nome": "Santa Teresa",
            "Uf": "ES"
        },
        {
            "Id": 3162,
            "Codigo": 3204658,
            "Nome": "São Domingos do Norte",
            "Uf": "ES"
        },
        {
            "Id": 3163,
            "Codigo": 3204708,
            "Nome": "São Gabriel da Palha",
            "Uf": "ES"
        },
        {
            "Id": 3164,
            "Codigo": 3204807,
            "Nome": "São José do Calçado",
            "Uf": "ES"
        },
        {
            "Id": 3165,
            "Codigo": 3204906,
            "Nome": "São Mateus",
            "Uf": "ES"
        },
        {
            "Id": 3166,
            "Codigo": 3204955,
            "Nome": "São Roque do Canaã",
            "Uf": "ES"
        },
        {
            "Id": 3167,
            "Codigo": 3205002,
            "Nome": "Serra",
            "Uf": "ES"
        },
        {
            "Id": 3168,
            "Codigo": 3205010,
            "Nome": "Sooretama",
            "Uf": "ES"
        },
        {
            "Id": 3169,
            "Codigo": 3205036,
            "Nome": "Vargem Alta",
            "Uf": "ES"
        },
        {
            "Id": 3170,
            "Codigo": 3205069,
            "Nome": "Venda Nova do Imigrante",
            "Uf": "ES"
        },
        {
            "Id": 3171,
            "Codigo": 3205101,
            "Nome": "Viana",
            "Uf": "ES"
        },
        {
            "Id": 3172,
            "Codigo": 3205150,
            "Nome": "Vila Pavão",
            "Uf": "ES"
        },
        {
            "Id": 3173,
            "Codigo": 3205176,
            "Nome": "Vila Valério",
            "Uf": "ES"
        },
        {
            "Id": 3174,
            "Codigo": 3205200,
            "Nome": "Vila Velha",
            "Uf": "ES"
        },
        {
            "Id": 3175,
            "Codigo": 3205309,
            "Nome": "Vitória",
            "Uf": "ES"
        },
        {
            "Id": 3176,
            "Codigo": 3300100,
            "Nome": "Angra dos Reis",
            "Uf": "RJ"
        },
        {
            "Id": 3177,
            "Codigo": 3300159,
            "Nome": "Aperibé",
            "Uf": "RJ"
        },
        {
            "Id": 3178,
            "Codigo": 3300209,
            "Nome": "Araruama",
            "Uf": "RJ"
        },
        {
            "Id": 3179,
            "Codigo": 3300225,
            "Nome": "Areal",
            "Uf": "RJ"
        },
        {
            "Id": 3180,
            "Codigo": 3300233,
            "Nome": "Armação dos Búzios",
            "Uf": "RJ"
        },
        {
            "Id": 3181,
            "Codigo": 3300258,
            "Nome": "Arraial do Cabo",
            "Uf": "RJ"
        },
        {
            "Id": 3182,
            "Codigo": 3300308,
            "Nome": "Barra do Piraí",
            "Uf": "RJ"
        },
        {
            "Id": 3183,
            "Codigo": 3300407,
            "Nome": "Barra Mansa",
            "Uf": "RJ"
        },
        {
            "Id": 3184,
            "Codigo": 3300456,
            "Nome": "Belford Roxo",
            "Uf": "RJ"
        },
        {
            "Id": 3185,
            "Codigo": 3300506,
            "Nome": "Bom Jardim",
            "Uf": "RJ"
        },
        {
            "Id": 3186,
            "Codigo": 3300605,
            "Nome": "Bom Jesus do Itabapoana",
            "Uf": "RJ"
        },
        {
            "Id": 3187,
            "Codigo": 3300704,
            "Nome": "Cabo Frio",
            "Uf": "RJ"
        },
        {
            "Id": 3188,
            "Codigo": 3300803,
            "Nome": "Cachoeiras de Macacu",
            "Uf": "RJ"
        },
        {
            "Id": 3189,
            "Codigo": 3300902,
            "Nome": "Cambuci",
            "Uf": "RJ"
        },
        {
            "Id": 3190,
            "Codigo": 3300936,
            "Nome": "Carapebus",
            "Uf": "RJ"
        },
        {
            "Id": 3191,
            "Codigo": 3300951,
            "Nome": "Comendador Levy Gasparian",
            "Uf": "RJ"
        },
        {
            "Id": 3192,
            "Codigo": 3301009,
            "Nome": "Campos dos Goytacazes",
            "Uf": "RJ"
        },
        {
            "Id": 3193,
            "Codigo": 3301108,
            "Nome": "Cantagalo",
            "Uf": "RJ"
        },
        {
            "Id": 3194,
            "Codigo": 3301157,
            "Nome": "Cardoso Moreira",
            "Uf": "RJ"
        },
        {
            "Id": 3195,
            "Codigo": 3301207,
            "Nome": "Carmo",
            "Uf": "RJ"
        },
        {
            "Id": 3196,
            "Codigo": 3301306,
            "Nome": "Casimiro de Abreu",
            "Uf": "RJ"
        },
        {
            "Id": 3197,
            "Codigo": 3301405,
            "Nome": "Conceição de Macabu",
            "Uf": "RJ"
        },
        {
            "Id": 3198,
            "Codigo": 3301504,
            "Nome": "Cordeiro",
            "Uf": "RJ"
        },
        {
            "Id": 3199,
            "Codigo": 3301603,
            "Nome": "Duas Barras",
            "Uf": "RJ"
        },
        {
            "Id": 3200,
            "Codigo": 3301702,
            "Nome": "Duque de Caxias",
            "Uf": "RJ"
        },
        {
            "Id": 3201,
            "Codigo": 3301801,
            "Nome": "Engenheiro Paulo de Frontin",
            "Uf": "RJ"
        },
        {
            "Id": 3202,
            "Codigo": 3301850,
            "Nome": "Guapimirim",
            "Uf": "RJ"
        },
        {
            "Id": 3203,
            "Codigo": 3301876,
            "Nome": "Iguaba Grande",
            "Uf": "RJ"
        },
        {
            "Id": 3204,
            "Codigo": 3301900,
            "Nome": "Itaboraí",
            "Uf": "RJ"
        },
        {
            "Id": 3205,
            "Codigo": 3302007,
            "Nome": "Itaguaí",
            "Uf": "RJ"
        },
        {
            "Id": 3206,
            "Codigo": 3302056,
            "Nome": "Italva",
            "Uf": "RJ"
        },
        {
            "Id": 3207,
            "Codigo": 3302106,
            "Nome": "Itaocara",
            "Uf": "RJ"
        },
        {
            "Id": 3208,
            "Codigo": 3302205,
            "Nome": "Itaperuna",
            "Uf": "RJ"
        },
        {
            "Id": 3209,
            "Codigo": 3302254,
            "Nome": "Itatiaia",
            "Uf": "RJ"
        },
        {
            "Id": 3210,
            "Codigo": 3302270,
            "Nome": "Japeri",
            "Uf": "RJ"
        },
        {
            "Id": 3211,
            "Codigo": 3302304,
            "Nome": "Laje do Muriaé",
            "Uf": "RJ"
        },
        {
            "Id": 3212,
            "Codigo": 3302403,
            "Nome": "Macaé",
            "Uf": "RJ"
        },
        {
            "Id": 3213,
            "Codigo": 3302452,
            "Nome": "Macuco",
            "Uf": "RJ"
        },
        {
            "Id": 3214,
            "Codigo": 3302502,
            "Nome": "Magé",
            "Uf": "RJ"
        },
        {
            "Id": 3215,
            "Codigo": 3302601,
            "Nome": "Mangaratiba",
            "Uf": "RJ"
        },
        {
            "Id": 3216,
            "Codigo": 3302700,
            "Nome": "Maricá",
            "Uf": "RJ"
        },
        {
            "Id": 3217,
            "Codigo": 3302809,
            "Nome": "Mendes",
            "Uf": "RJ"
        },
        {
            "Id": 3218,
            "Codigo": 3302858,
            "Nome": "Mesquita",
            "Uf": "RJ"
        },
        {
            "Id": 3219,
            "Codigo": 3302908,
            "Nome": "Miguel Pereira",
            "Uf": "RJ"
        },
        {
            "Id": 3220,
            "Codigo": 3303005,
            "Nome": "Miracema",
            "Uf": "RJ"
        },
        {
            "Id": 3221,
            "Codigo": 3303104,
            "Nome": "Natividade",
            "Uf": "RJ"
        },
        {
            "Id": 3222,
            "Codigo": 3303203,
            "Nome": "Nilópolis",
            "Uf": "RJ"
        },
        {
            "Id": 3223,
            "Codigo": 3303302,
            "Nome": "Niterói",
            "Uf": "RJ"
        },
        {
            "Id": 3224,
            "Codigo": 3303401,
            "Nome": "Nova Friburgo",
            "Uf": "RJ"
        },
        {
            "Id": 3225,
            "Codigo": 3303500,
            "Nome": "Nova Iguaçu",
            "Uf": "RJ"
        },
        {
            "Id": 3226,
            "Codigo": 3303609,
            "Nome": "Paracambi",
            "Uf": "RJ"
        },
        {
            "Id": 3227,
            "Codigo": 3303708,
            "Nome": "Paraíba do Sul",
            "Uf": "RJ"
        },
        {
            "Id": 3228,
            "Codigo": 3303807,
            "Nome": "Paraty",
            "Uf": "RJ"
        },
        {
            "Id": 3229,
            "Codigo": 3303856,
            "Nome": "Paty do Alferes",
            "Uf": "RJ"
        },
        {
            "Id": 3230,
            "Codigo": 3303906,
            "Nome": "Petrópolis",
            "Uf": "RJ"
        },
        {
            "Id": 3231,
            "Codigo": 3303955,
            "Nome": "Pinheiral",
            "Uf": "RJ"
        },
        {
            "Id": 3232,
            "Codigo": 3304003,
            "Nome": "Piraí",
            "Uf": "RJ"
        },
        {
            "Id": 3233,
            "Codigo": 3304102,
            "Nome": "Porciúncula",
            "Uf": "RJ"
        },
        {
            "Id": 3234,
            "Codigo": 3304110,
            "Nome": "Porto Real",
            "Uf": "RJ"
        },
        {
            "Id": 3235,
            "Codigo": 3304128,
            "Nome": "Quatis",
            "Uf": "RJ"
        },
        {
            "Id": 3236,
            "Codigo": 3304144,
            "Nome": "Queimados",
            "Uf": "RJ"
        },
        {
            "Id": 3237,
            "Codigo": 3304151,
            "Nome": "Quissamã",
            "Uf": "RJ"
        },
        {
            "Id": 3238,
            "Codigo": 3304201,
            "Nome": "Resende",
            "Uf": "RJ"
        },
        {
            "Id": 3239,
            "Codigo": 3304300,
            "Nome": "Rio Bonito",
            "Uf": "RJ"
        },
        {
            "Id": 3240,
            "Codigo": 3304409,
            "Nome": "Rio Claro",
            "Uf": "RJ"
        },
        {
            "Id": 3241,
            "Codigo": 3304508,
            "Nome": "Rio das Flores",
            "Uf": "RJ"
        },
        {
            "Id": 3242,
            "Codigo": 3304524,
            "Nome": "Rio das Ostras",
            "Uf": "RJ"
        },
        {
            "Id": 3243,
            "Codigo": 3304557,
            "Nome": "Rio de Janeiro",
            "Uf": "RJ"
        },
        {
            "Id": 3244,
            "Codigo": 3304607,
            "Nome": "Santa Maria Madalena",
            "Uf": "RJ"
        },
        {
            "Id": 3245,
            "Codigo": 3304706,
            "Nome": "Santo Antônio de Pádua",
            "Uf": "RJ"
        },
        {
            "Id": 3246,
            "Codigo": 3304755,
            "Nome": "São Francisco de Itabapoana",
            "Uf": "RJ"
        },
        {
            "Id": 3247,
            "Codigo": 3304805,
            "Nome": "São Fidélis",
            "Uf": "RJ"
        },
        {
            "Id": 3248,
            "Codigo": 3304904,
            "Nome": "São Gonçalo",
            "Uf": "RJ"
        },
        {
            "Id": 3249,
            "Codigo": 3305000,
            "Nome": "São João da Barra",
            "Uf": "RJ"
        },
        {
            "Id": 3250,
            "Codigo": 3305109,
            "Nome": "São João de Meriti",
            "Uf": "RJ"
        },
        {
            "Id": 3251,
            "Codigo": 3305133,
            "Nome": "São José de Ubá",
            "Uf": "RJ"
        },
        {
            "Id": 3252,
            "Codigo": 3305158,
            "Nome": "São José do Vale do Rio Preto",
            "Uf": "RJ"
        },
        {
            "Id": 3253,
            "Codigo": 3305208,
            "Nome": "São Pedro da Aldeia",
            "Uf": "RJ"
        },
        {
            "Id": 3254,
            "Codigo": 3305307,
            "Nome": "São Sebastião do Alto",
            "Uf": "RJ"
        },
        {
            "Id": 3255,
            "Codigo": 3305406,
            "Nome": "Sapucaia",
            "Uf": "RJ"
        },
        {
            "Id": 3256,
            "Codigo": 3305505,
            "Nome": "Saquarema",
            "Uf": "RJ"
        },
        {
            "Id": 3257,
            "Codigo": 3305554,
            "Nome": "Seropédica",
            "Uf": "RJ"
        },
        {
            "Id": 3258,
            "Codigo": 3305604,
            "Nome": "Silva Jardim",
            "Uf": "RJ"
        },
        {
            "Id": 3259,
            "Codigo": 3305703,
            "Nome": "Sumidouro",
            "Uf": "RJ"
        },
        {
            "Id": 3260,
            "Codigo": 3305752,
            "Nome": "Tanguá",
            "Uf": "RJ"
        },
        {
            "Id": 3261,
            "Codigo": 3305802,
            "Nome": "Teresópolis",
            "Uf": "RJ"
        },
        {
            "Id": 3262,
            "Codigo": 3305901,
            "Nome": "Trajano de Moraes",
            "Uf": "RJ"
        },
        {
            "Id": 3263,
            "Codigo": 3306008,
            "Nome": "Três Rios",
            "Uf": "RJ"
        },
        {
            "Id": 3264,
            "Codigo": 3306107,
            "Nome": "Valença",
            "Uf": "RJ"
        },
        {
            "Id": 3265,
            "Codigo": 3306156,
            "Nome": "Varre-Sai",
            "Uf": "RJ"
        },
        {
            "Id": 3266,
            "Codigo": 3306206,
            "Nome": "Vassouras",
            "Uf": "RJ"
        },
        {
            "Id": 3267,
            "Codigo": 3306305,
            "Nome": "Volta Redonda",
            "Uf": "RJ"
        },
        {
            "Id": 3268,
            "Codigo": 3500105,
            "Nome": "Adamantina",
            "Uf": "SP"
        },
        {
            "Id": 3269,
            "Codigo": 3500204,
            "Nome": "Adolfo",
            "Uf": "SP"
        },
        {
            "Id": 3270,
            "Codigo": 3500303,
            "Nome": "Aguaí",
            "Uf": "SP"
        },
        {
            "Id": 3271,
            "Codigo": 3500402,
            "Nome": "Águas da Prata",
            "Uf": "SP"
        },
        {
            "Id": 3272,
            "Codigo": 3500501,
            "Nome": "Águas de Lindóia",
            "Uf": "SP"
        },
        {
            "Id": 3273,
            "Codigo": 3500550,
            "Nome": "Águas de Santa Bárbara",
            "Uf": "SP"
        },
        {
            "Id": 3274,
            "Codigo": 3500600,
            "Nome": "Águas de São Pedro",
            "Uf": "SP"
        },
        {
            "Id": 3275,
            "Codigo": 3500709,
            "Nome": "Agudos",
            "Uf": "SP"
        },
        {
            "Id": 3276,
            "Codigo": 3500758,
            "Nome": "Alambari",
            "Uf": "SP"
        },
        {
            "Id": 3277,
            "Codigo": 3500808,
            "Nome": "Alfredo Marcondes",
            "Uf": "SP"
        },
        {
            "Id": 3278,
            "Codigo": 3500907,
            "Nome": "Altair",
            "Uf": "SP"
        },
        {
            "Id": 3279,
            "Codigo": 3501004,
            "Nome": "Altinópolis",
            "Uf": "SP"
        },
        {
            "Id": 3280,
            "Codigo": 3501103,
            "Nome": "Alto Alegre",
            "Uf": "SP"
        },
        {
            "Id": 3281,
            "Codigo": 3501152,
            "Nome": "Alumínio",
            "Uf": "SP"
        },
        {
            "Id": 3282,
            "Codigo": 3501202,
            "Nome": "Álvares Florence",
            "Uf": "SP"
        },
        {
            "Id": 3283,
            "Codigo": 3501301,
            "Nome": "Álvares Machado",
            "Uf": "SP"
        },
        {
            "Id": 3284,
            "Codigo": 3501400,
            "Nome": "Álvaro de Carvalho",
            "Uf": "SP"
        },
        {
            "Id": 3285,
            "Codigo": 3501509,
            "Nome": "Alvinlândia",
            "Uf": "SP"
        },
        {
            "Id": 3286,
            "Codigo": 3501608,
            "Nome": "Americana",
            "Uf": "SP"
        },
        {
            "Id": 3287,
            "Codigo": 3501707,
            "Nome": "Américo Brasiliense",
            "Uf": "SP"
        },
        {
            "Id": 3288,
            "Codigo": 3501806,
            "Nome": "Américo de Campos",
            "Uf": "SP"
        },
        {
            "Id": 3289,
            "Codigo": 3501905,
            "Nome": "Amparo",
            "Uf": "SP"
        },
        {
            "Id": 3290,
            "Codigo": 3502002,
            "Nome": "Analândia",
            "Uf": "SP"
        },
        {
            "Id": 3291,
            "Codigo": 3502101,
            "Nome": "Andradina",
            "Uf": "SP"
        },
        {
            "Id": 3292,
            "Codigo": 3502200,
            "Nome": "Angatuba",
            "Uf": "SP"
        },
        {
            "Id": 3293,
            "Codigo": 3502309,
            "Nome": "Anhembi",
            "Uf": "SP"
        },
        {
            "Id": 3294,
            "Codigo": 3502408,
            "Nome": "Anhumas",
            "Uf": "SP"
        },
        {
            "Id": 3295,
            "Codigo": 3502507,
            "Nome": "Aparecida",
            "Uf": "SP"
        },
        {
            "Id": 3296,
            "Codigo": 3502606,
            "Nome": "Aparecida D'Oeste",
            "Uf": "SP"
        },
        {
            "Id": 3297,
            "Codigo": 3502705,
            "Nome": "Apiaí",
            "Uf": "SP"
        },
        {
            "Id": 3298,
            "Codigo": 3502754,
            "Nome": "Araçariguama",
            "Uf": "SP"
        },
        {
            "Id": 3299,
            "Codigo": 3502804,
            "Nome": "Araçatuba",
            "Uf": "SP"
        },
        {
            "Id": 3300,
            "Codigo": 3502903,
            "Nome": "Araçoiaba da Serra",
            "Uf": "SP"
        },
        {
            "Id": 3301,
            "Codigo": 3503000,
            "Nome": "Aramina",
            "Uf": "SP"
        },
        {
            "Id": 3302,
            "Codigo": 3503109,
            "Nome": "Arandu",
            "Uf": "SP"
        },
        {
            "Id": 3303,
            "Codigo": 3503158,
            "Nome": "Arapeí",
            "Uf": "SP"
        },
        {
            "Id": 3304,
            "Codigo": 3503208,
            "Nome": "Araraquara",
            "Uf": "SP"
        },
        {
            "Id": 3305,
            "Codigo": 3503307,
            "Nome": "Araras",
            "Uf": "SP"
        },
        {
            "Id": 3306,
            "Codigo": 3503356,
            "Nome": "Arco-Íris",
            "Uf": "SP"
        },
        {
            "Id": 3307,
            "Codigo": 3503406,
            "Nome": "Arealva",
            "Uf": "SP"
        },
        {
            "Id": 3308,
            "Codigo": 3503505,
            "Nome": "Areias",
            "Uf": "SP"
        },
        {
            "Id": 3309,
            "Codigo": 3503604,
            "Nome": "Areiópolis",
            "Uf": "SP"
        },
        {
            "Id": 3310,
            "Codigo": 3503703,
            "Nome": "Ariranha",
            "Uf": "SP"
        },
        {
            "Id": 3311,
            "Codigo": 3503802,
            "Nome": "Artur Nogueira",
            "Uf": "SP"
        },
        {
            "Id": 3312,
            "Codigo": 3503901,
            "Nome": "Arujá",
            "Uf": "SP"
        },
        {
            "Id": 3313,
            "Codigo": 3503950,
            "Nome": "Aspásia",
            "Uf": "SP"
        },
        {
            "Id": 3314,
            "Codigo": 3504008,
            "Nome": "Assis",
            "Uf": "SP"
        },
        {
            "Id": 3315,
            "Codigo": 3504107,
            "Nome": "Atibaia",
            "Uf": "SP"
        },
        {
            "Id": 3316,
            "Codigo": 3504206,
            "Nome": "Auriflama",
            "Uf": "SP"
        },
        {
            "Id": 3317,
            "Codigo": 3504305,
            "Nome": "Avaí",
            "Uf": "SP"
        },
        {
            "Id": 3318,
            "Codigo": 3504404,
            "Nome": "Avanhandava",
            "Uf": "SP"
        },
        {
            "Id": 3319,
            "Codigo": 3504503,
            "Nome": "Avaré",
            "Uf": "SP"
        },
        {
            "Id": 3320,
            "Codigo": 3504602,
            "Nome": "Bady Bassitt",
            "Uf": "SP"
        },
        {
            "Id": 3321,
            "Codigo": 3504701,
            "Nome": "Balbinos",
            "Uf": "SP"
        },
        {
            "Id": 3322,
            "Codigo": 3504800,
            "Nome": "Bálsamo",
            "Uf": "SP"
        },
        {
            "Id": 3323,
            "Codigo": 3504909,
            "Nome": "Bananal",
            "Uf": "SP"
        },
        {
            "Id": 3324,
            "Codigo": 3505005,
            "Nome": "Barão de Antonina",
            "Uf": "SP"
        },
        {
            "Id": 3325,
            "Codigo": 3505104,
            "Nome": "Barbosa",
            "Uf": "SP"
        },
        {
            "Id": 3326,
            "Codigo": 3505203,
            "Nome": "Bariri",
            "Uf": "SP"
        },
        {
            "Id": 3327,
            "Codigo": 3505302,
            "Nome": "Barra Bonita",
            "Uf": "SP"
        },
        {
            "Id": 3328,
            "Codigo": 3505351,
            "Nome": "Barra do Chapéu",
            "Uf": "SP"
        },
        {
            "Id": 3329,
            "Codigo": 3505401,
            "Nome": "Barra do Turvo",
            "Uf": "SP"
        },
        {
            "Id": 3330,
            "Codigo": 3505500,
            "Nome": "Barretos",
            "Uf": "SP"
        },
        {
            "Id": 3331,
            "Codigo": 3505609,
            "Nome": "Barrinha",
            "Uf": "SP"
        },
        {
            "Id": 3332,
            "Codigo": 3505708,
            "Nome": "Barueri",
            "Uf": "SP"
        },
        {
            "Id": 3333,
            "Codigo": 3505807,
            "Nome": "Bastos",
            "Uf": "SP"
        },
        {
            "Id": 3334,
            "Codigo": 3505906,
            "Nome": "Batatais",
            "Uf": "SP"
        },
        {
            "Id": 3335,
            "Codigo": 3506003,
            "Nome": "Bauru",
            "Uf": "SP"
        },
        {
            "Id": 3336,
            "Codigo": 3506102,
            "Nome": "Bebedouro",
            "Uf": "SP"
        },
        {
            "Id": 3337,
            "Codigo": 3506201,
            "Nome": "Bento de Abreu",
            "Uf": "SP"
        },
        {
            "Id": 3338,
            "Codigo": 3506300,
            "Nome": "Bernardino de Campos",
            "Uf": "SP"
        },
        {
            "Id": 3339,
            "Codigo": 3506359,
            "Nome": "Bertioga",
            "Uf": "SP"
        },
        {
            "Id": 3340,
            "Codigo": 3506409,
            "Nome": "Bilac",
            "Uf": "SP"
        },
        {
            "Id": 3341,
            "Codigo": 3506508,
            "Nome": "Birigui",
            "Uf": "SP"
        },
        {
            "Id": 3342,
            "Codigo": 3506607,
            "Nome": "Biritiba-Mirim",
            "Uf": "SP"
        },
        {
            "Id": 3343,
            "Codigo": 3506706,
            "Nome": "Boa Esperança do Sul",
            "Uf": "SP"
        },
        {
            "Id": 3344,
            "Codigo": 3506805,
            "Nome": "Bocaina",
            "Uf": "SP"
        },
        {
            "Id": 3345,
            "Codigo": 3506904,
            "Nome": "Bofete",
            "Uf": "SP"
        },
        {
            "Id": 3346,
            "Codigo": 3507001,
            "Nome": "Boituva",
            "Uf": "SP"
        },
        {
            "Id": 3347,
            "Codigo": 3507100,
            "Nome": "Bom Jesus dos Perdões",
            "Uf": "SP"
        },
        {
            "Id": 3348,
            "Codigo": 3507159,
            "Nome": "Bom Sucesso de Itararé",
            "Uf": "SP"
        },
        {
            "Id": 3349,
            "Codigo": 3507209,
            "Nome": "Borá",
            "Uf": "SP"
        },
        {
            "Id": 3350,
            "Codigo": 3507308,
            "Nome": "Boracéia",
            "Uf": "SP"
        },
        {
            "Id": 3351,
            "Codigo": 3507407,
            "Nome": "Borborema",
            "Uf": "SP"
        },
        {
            "Id": 3352,
            "Codigo": 3507456,
            "Nome": "Borebi",
            "Uf": "SP"
        },
        {
            "Id": 3353,
            "Codigo": 3507506,
            "Nome": "Botucatu",
            "Uf": "SP"
        },
        {
            "Id": 3354,
            "Codigo": 3507605,
            "Nome": "Bragança Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3355,
            "Codigo": 3507704,
            "Nome": "Braúna",
            "Uf": "SP"
        },
        {
            "Id": 3356,
            "Codigo": 3507753,
            "Nome": "Brejo Alegre",
            "Uf": "SP"
        },
        {
            "Id": 3357,
            "Codigo": 3507803,
            "Nome": "Brodowski",
            "Uf": "SP"
        },
        {
            "Id": 3358,
            "Codigo": 3507902,
            "Nome": "Brotas",
            "Uf": "SP"
        },
        {
            "Id": 3359,
            "Codigo": 3508009,
            "Nome": "Buri",
            "Uf": "SP"
        },
        {
            "Id": 3360,
            "Codigo": 3508108,
            "Nome": "Buritama",
            "Uf": "SP"
        },
        {
            "Id": 3361,
            "Codigo": 3508207,
            "Nome": "Buritizal",
            "Uf": "SP"
        },
        {
            "Id": 3362,
            "Codigo": 3508306,
            "Nome": "Cabrália Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3363,
            "Codigo": 3508405,
            "Nome": "Cabreúva",
            "Uf": "SP"
        },
        {
            "Id": 3364,
            "Codigo": 3508504,
            "Nome": "Caçapava",
            "Uf": "SP"
        },
        {
            "Id": 3365,
            "Codigo": 3508603,
            "Nome": "Cachoeira Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3366,
            "Codigo": 3508702,
            "Nome": "Caconde",
            "Uf": "SP"
        },
        {
            "Id": 3367,
            "Codigo": 3508801,
            "Nome": "Cafelândia",
            "Uf": "SP"
        },
        {
            "Id": 3368,
            "Codigo": 3508900,
            "Nome": "Caiabu",
            "Uf": "SP"
        },
        {
            "Id": 3369,
            "Codigo": 3509007,
            "Nome": "Caieiras",
            "Uf": "SP"
        },
        {
            "Id": 3370,
            "Codigo": 3509106,
            "Nome": "Caiuá",
            "Uf": "SP"
        },
        {
            "Id": 3371,
            "Codigo": 3509205,
            "Nome": "Cajamar",
            "Uf": "SP"
        },
        {
            "Id": 3372,
            "Codigo": 3509254,
            "Nome": "Cajati",
            "Uf": "SP"
        },
        {
            "Id": 3373,
            "Codigo": 3509304,
            "Nome": "Cajobi",
            "Uf": "SP"
        },
        {
            "Id": 3374,
            "Codigo": 3509403,
            "Nome": "Cajuru",
            "Uf": "SP"
        },
        {
            "Id": 3375,
            "Codigo": 3509452,
            "Nome": "Campina do Monte Alegre",
            "Uf": "SP"
        },
        {
            "Id": 3376,
            "Codigo": 3509502,
            "Nome": "Campinas",
            "Uf": "SP"
        },
        {
            "Id": 3377,
            "Codigo": 3509601,
            "Nome": "Campo Limpo Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3378,
            "Codigo": 3509700,
            "Nome": "Campos do Jordão",
            "Uf": "SP"
        },
        {
            "Id": 3379,
            "Codigo": 3509809,
            "Nome": "Campos Novos Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3380,
            "Codigo": 3509908,
            "Nome": "Cananéia",
            "Uf": "SP"
        },
        {
            "Id": 3381,
            "Codigo": 3509957,
            "Nome": "Canas",
            "Uf": "SP"
        },
        {
            "Id": 3382,
            "Codigo": 3510005,
            "Nome": "Cândido Mota",
            "Uf": "SP"
        },
        {
            "Id": 3383,
            "Codigo": 3510104,
            "Nome": "Cândido Rodrigues",
            "Uf": "SP"
        },
        {
            "Id": 3384,
            "Codigo": 3510153,
            "Nome": "Canitar",
            "Uf": "SP"
        },
        {
            "Id": 3385,
            "Codigo": 3510203,
            "Nome": "Capão Bonito",
            "Uf": "SP"
        },
        {
            "Id": 3386,
            "Codigo": 3510302,
            "Nome": "Capela do Alto",
            "Uf": "SP"
        },
        {
            "Id": 3387,
            "Codigo": 3510401,
            "Nome": "Capivari",
            "Uf": "SP"
        },
        {
            "Id": 3388,
            "Codigo": 3510500,
            "Nome": "Caraguatatuba",
            "Uf": "SP"
        },
        {
            "Id": 3389,
            "Codigo": 3510609,
            "Nome": "Carapicuíba",
            "Uf": "SP"
        },
        {
            "Id": 3390,
            "Codigo": 3510708,
            "Nome": "Cardoso",
            "Uf": "SP"
        },
        {
            "Id": 3391,
            "Codigo": 3510807,
            "Nome": "Casa Branca",
            "Uf": "SP"
        },
        {
            "Id": 3392,
            "Codigo": 3510906,
            "Nome": "Cássia dos Coqueiros",
            "Uf": "SP"
        },
        {
            "Id": 3393,
            "Codigo": 3511003,
            "Nome": "Castilho",
            "Uf": "SP"
        },
        {
            "Id": 3394,
            "Codigo": 3511102,
            "Nome": "Catanduva",
            "Uf": "SP"
        },
        {
            "Id": 3395,
            "Codigo": 3511201,
            "Nome": "Catiguá",
            "Uf": "SP"
        },
        {
            "Id": 3396,
            "Codigo": 3511300,
            "Nome": "Cedral",
            "Uf": "SP"
        },
        {
            "Id": 3397,
            "Codigo": 3511409,
            "Nome": "Cerqueira César",
            "Uf": "SP"
        },
        {
            "Id": 3398,
            "Codigo": 3511508,
            "Nome": "Cerquilho",
            "Uf": "SP"
        },
        {
            "Id": 3399,
            "Codigo": 3511607,
            "Nome": "Cesário Lange",
            "Uf": "SP"
        },
        {
            "Id": 3400,
            "Codigo": 3511706,
            "Nome": "Charqueada",
            "Uf": "SP"
        },
        {
            "Id": 3401,
            "Codigo": 3511904,
            "Nome": "Clementina",
            "Uf": "SP"
        },
        {
            "Id": 3402,
            "Codigo": 3512001,
            "Nome": "Colina",
            "Uf": "SP"
        },
        {
            "Id": 3403,
            "Codigo": 3512100,
            "Nome": "Colômbia",
            "Uf": "SP"
        },
        {
            "Id": 3404,
            "Codigo": 3512209,
            "Nome": "Conchal",
            "Uf": "SP"
        },
        {
            "Id": 3405,
            "Codigo": 3512308,
            "Nome": "Conchas",
            "Uf": "SP"
        },
        {
            "Id": 3406,
            "Codigo": 3512407,
            "Nome": "Cordeirópolis",
            "Uf": "SP"
        },
        {
            "Id": 3407,
            "Codigo": 3512506,
            "Nome": "Coroados",
            "Uf": "SP"
        },
        {
            "Id": 3408,
            "Codigo": 3512605,
            "Nome": "Coronel Macedo",
            "Uf": "SP"
        },
        {
            "Id": 3409,
            "Codigo": 3512704,
            "Nome": "Corumbataí",
            "Uf": "SP"
        },
        {
            "Id": 3410,
            "Codigo": 3512803,
            "Nome": "Cosmópolis",
            "Uf": "SP"
        },
        {
            "Id": 3411,
            "Codigo": 3512902,
            "Nome": "Cosmorama",
            "Uf": "SP"
        },
        {
            "Id": 3412,
            "Codigo": 3513009,
            "Nome": "Cotia",
            "Uf": "SP"
        },
        {
            "Id": 3413,
            "Codigo": 3513108,
            "Nome": "Cravinhos",
            "Uf": "SP"
        },
        {
            "Id": 3414,
            "Codigo": 3513207,
            "Nome": "Cristais Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3415,
            "Codigo": 3513306,
            "Nome": "Cruzália",
            "Uf": "SP"
        },
        {
            "Id": 3416,
            "Codigo": 3513405,
            "Nome": "Cruzeiro",
            "Uf": "SP"
        },
        {
            "Id": 3417,
            "Codigo": 3513504,
            "Nome": "Cubatão",
            "Uf": "SP"
        },
        {
            "Id": 3418,
            "Codigo": 3513603,
            "Nome": "Cunha",
            "Uf": "SP"
        },
        {
            "Id": 3419,
            "Codigo": 3513702,
            "Nome": "Descalvado",
            "Uf": "SP"
        },
        {
            "Id": 3420,
            "Codigo": 3513801,
            "Nome": "Diadema",
            "Uf": "SP"
        },
        {
            "Id": 3421,
            "Codigo": 3513850,
            "Nome": "Dirce Reis",
            "Uf": "SP"
        },
        {
            "Id": 3422,
            "Codigo": 3513900,
            "Nome": "Divinolândia",
            "Uf": "SP"
        },
        {
            "Id": 3423,
            "Codigo": 3514007,
            "Nome": "Dobrada",
            "Uf": "SP"
        },
        {
            "Id": 3424,
            "Codigo": 3514106,
            "Nome": "Dois Córregos",
            "Uf": "SP"
        },
        {
            "Id": 3425,
            "Codigo": 3514205,
            "Nome": "Dolcinópolis",
            "Uf": "SP"
        },
        {
            "Id": 3426,
            "Codigo": 3514304,
            "Nome": "Dourado",
            "Uf": "SP"
        },
        {
            "Id": 3427,
            "Codigo": 3514403,
            "Nome": "Dracena",
            "Uf": "SP"
        },
        {
            "Id": 3428,
            "Codigo": 3514502,
            "Nome": "Duartina",
            "Uf": "SP"
        },
        {
            "Id": 3429,
            "Codigo": 3514601,
            "Nome": "Dumont",
            "Uf": "SP"
        },
        {
            "Id": 3430,
            "Codigo": 3514700,
            "Nome": "Echaporã",
            "Uf": "SP"
        },
        {
            "Id": 3431,
            "Codigo": 3514809,
            "Nome": "Eldorado",
            "Uf": "SP"
        },
        {
            "Id": 3432,
            "Codigo": 3514908,
            "Nome": "Elias Fausto",
            "Uf": "SP"
        },
        {
            "Id": 3433,
            "Codigo": 3514924,
            "Nome": "Elisiário",
            "Uf": "SP"
        },
        {
            "Id": 3434,
            "Codigo": 3514957,
            "Nome": "Embaúba",
            "Uf": "SP"
        },
        {
            "Id": 3435,
            "Codigo": 3515004,
            "Nome": "Embu das Artes",
            "Uf": "SP"
        },
        {
            "Id": 3436,
            "Codigo": 3515103,
            "Nome": "Embu-Guaçu",
            "Uf": "SP"
        },
        {
            "Id": 3437,
            "Codigo": 3515129,
            "Nome": "Emilianópolis",
            "Uf": "SP"
        },
        {
            "Id": 3438,
            "Codigo": 3515152,
            "Nome": "Engenheiro Coelho",
            "Uf": "SP"
        },
        {
            "Id": 3439,
            "Codigo": 3515186,
            "Nome": "Espírito Santo do Pinhal",
            "Uf": "SP"
        },
        {
            "Id": 3440,
            "Codigo": 3515194,
            "Nome": "Espírito Santo do Turvo",
            "Uf": "SP"
        },
        {
            "Id": 3441,
            "Codigo": 3515202,
            "Nome": "Estrela D'Oeste",
            "Uf": "SP"
        },
        {
            "Id": 3442,
            "Codigo": 3515301,
            "Nome": "Estrela do Norte",
            "Uf": "SP"
        },
        {
            "Id": 3443,
            "Codigo": 3515350,
            "Nome": "Euclides da Cunha Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3444,
            "Codigo": 3515400,
            "Nome": "Fartura",
            "Uf": "SP"
        },
        {
            "Id": 3445,
            "Codigo": 3515509,
            "Nome": "Fernandópolis",
            "Uf": "SP"
        },
        {
            "Id": 3446,
            "Codigo": 3515608,
            "Nome": "Fernando Prestes",
            "Uf": "SP"
        },
        {
            "Id": 3447,
            "Codigo": 3515657,
            "Nome": "Fernão",
            "Uf": "SP"
        },
        {
            "Id": 3448,
            "Codigo": 3515707,
            "Nome": "Ferraz de Vasconcelos",
            "Uf": "SP"
        },
        {
            "Id": 3449,
            "Codigo": 3515806,
            "Nome": "Flora Rica",
            "Uf": "SP"
        },
        {
            "Id": 3450,
            "Codigo": 3515905,
            "Nome": "Floreal",
            "Uf": "SP"
        },
        {
            "Id": 3451,
            "Codigo": 3516002,
            "Nome": "Flórida Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3452,
            "Codigo": 3516101,
            "Nome": "Florínia",
            "Uf": "SP"
        },
        {
            "Id": 3453,
            "Codigo": 3516200,
            "Nome": "Franca",
            "Uf": "SP"
        },
        {
            "Id": 3454,
            "Codigo": 3516309,
            "Nome": "Francisco Morato",
            "Uf": "SP"
        },
        {
            "Id": 3455,
            "Codigo": 3516408,
            "Nome": "Franco da Rocha",
            "Uf": "SP"
        },
        {
            "Id": 3456,
            "Codigo": 3516507,
            "Nome": "Gabriel Monteiro",
            "Uf": "SP"
        },
        {
            "Id": 3457,
            "Codigo": 3516606,
            "Nome": "Gália",
            "Uf": "SP"
        },
        {
            "Id": 3458,
            "Codigo": 3516705,
            "Nome": "Garça",
            "Uf": "SP"
        },
        {
            "Id": 3459,
            "Codigo": 3516804,
            "Nome": "Gastão Vidigal",
            "Uf": "SP"
        },
        {
            "Id": 3460,
            "Codigo": 3516853,
            "Nome": "Gavião Peixoto",
            "Uf": "SP"
        },
        {
            "Id": 3461,
            "Codigo": 3516903,
            "Nome": "General Salgado",
            "Uf": "SP"
        },
        {
            "Id": 3462,
            "Codigo": 3517000,
            "Nome": "Getulina",
            "Uf": "SP"
        },
        {
            "Id": 3463,
            "Codigo": 3517109,
            "Nome": "Glicério",
            "Uf": "SP"
        },
        {
            "Id": 3464,
            "Codigo": 3517208,
            "Nome": "Guaiçara",
            "Uf": "SP"
        },
        {
            "Id": 3465,
            "Codigo": 3517307,
            "Nome": "Guaimbê",
            "Uf": "SP"
        },
        {
            "Id": 3466,
            "Codigo": 3517406,
            "Nome": "Guaíra",
            "Uf": "SP"
        },
        {
            "Id": 3467,
            "Codigo": 3517505,
            "Nome": "Guapiaçu",
            "Uf": "SP"
        },
        {
            "Id": 3468,
            "Codigo": 3517604,
            "Nome": "Guapiara",
            "Uf": "SP"
        },
        {
            "Id": 3469,
            "Codigo": 3517703,
            "Nome": "Guará",
            "Uf": "SP"
        },
        {
            "Id": 3470,
            "Codigo": 3517802,
            "Nome": "Guaraçaí",
            "Uf": "SP"
        },
        {
            "Id": 3471,
            "Codigo": 3517901,
            "Nome": "Guaraci",
            "Uf": "SP"
        },
        {
            "Id": 3472,
            "Codigo": 3518008,
            "Nome": "Guarani D'Oeste",
            "Uf": "SP"
        },
        {
            "Id": 3473,
            "Codigo": 3518107,
            "Nome": "Guarantã",
            "Uf": "SP"
        },
        {
            "Id": 3474,
            "Codigo": 3518206,
            "Nome": "Guararapes",
            "Uf": "SP"
        },
        {
            "Id": 3475,
            "Codigo": 3518305,
            "Nome": "Guararema",
            "Uf": "SP"
        },
        {
            "Id": 3476,
            "Codigo": 3518404,
            "Nome": "Guaratinguetá",
            "Uf": "SP"
        },
        {
            "Id": 3477,
            "Codigo": 3518503,
            "Nome": "Guareí",
            "Uf": "SP"
        },
        {
            "Id": 3478,
            "Codigo": 3518602,
            "Nome": "Guariba",
            "Uf": "SP"
        },
        {
            "Id": 3479,
            "Codigo": 3518701,
            "Nome": "Guarujá",
            "Uf": "SP"
        },
        {
            "Id": 3480,
            "Codigo": 3518800,
            "Nome": "Guarulhos",
            "Uf": "SP"
        },
        {
            "Id": 3481,
            "Codigo": 3518859,
            "Nome": "Guatapará",
            "Uf": "SP"
        },
        {
            "Id": 3482,
            "Codigo": 3518909,
            "Nome": "Guzolândia",
            "Uf": "SP"
        },
        {
            "Id": 3483,
            "Codigo": 3519006,
            "Nome": "Herculândia",
            "Uf": "SP"
        },
        {
            "Id": 3484,
            "Codigo": 3519055,
            "Nome": "Holambra",
            "Uf": "SP"
        },
        {
            "Id": 3485,
            "Codigo": 3519071,
            "Nome": "Hortolândia",
            "Uf": "SP"
        },
        {
            "Id": 3486,
            "Codigo": 3519105,
            "Nome": "Iacanga",
            "Uf": "SP"
        },
        {
            "Id": 3487,
            "Codigo": 3519204,
            "Nome": "Iacri",
            "Uf": "SP"
        },
        {
            "Id": 3488,
            "Codigo": 3519253,
            "Nome": "Iaras",
            "Uf": "SP"
        },
        {
            "Id": 3489,
            "Codigo": 3519303,
            "Nome": "Ibaté",
            "Uf": "SP"
        },
        {
            "Id": 3490,
            "Codigo": 3519402,
            "Nome": "Ibirá",
            "Uf": "SP"
        },
        {
            "Id": 3491,
            "Codigo": 3519501,
            "Nome": "Ibirarema",
            "Uf": "SP"
        },
        {
            "Id": 3492,
            "Codigo": 3519600,
            "Nome": "Ibitinga",
            "Uf": "SP"
        },
        {
            "Id": 3493,
            "Codigo": 3519709,
            "Nome": "Ibiúna",
            "Uf": "SP"
        },
        {
            "Id": 3494,
            "Codigo": 3519808,
            "Nome": "Icém",
            "Uf": "SP"
        },
        {
            "Id": 3495,
            "Codigo": 3519907,
            "Nome": "Iepê",
            "Uf": "SP"
        },
        {
            "Id": 3496,
            "Codigo": 3520004,
            "Nome": "Igaraçu do Tietê",
            "Uf": "SP"
        },
        {
            "Id": 3497,
            "Codigo": 3520103,
            "Nome": "Igarapava",
            "Uf": "SP"
        },
        {
            "Id": 3498,
            "Codigo": 3520202,
            "Nome": "Igaratá",
            "Uf": "SP"
        },
        {
            "Id": 3499,
            "Codigo": 3520301,
            "Nome": "Iguape",
            "Uf": "SP"
        },
        {
            "Id": 3500,
            "Codigo": 3520400,
            "Nome": "Ilhabela",
            "Uf": "SP"
        },
        {
            "Id": 3501,
            "Codigo": 3520426,
            "Nome": "Ilha Comprida",
            "Uf": "SP"
        },
        {
            "Id": 3502,
            "Codigo": 3520442,
            "Nome": "Ilha Solteira",
            "Uf": "SP"
        },
        {
            "Id": 3503,
            "Codigo": 3520509,
            "Nome": "Indaiatuba",
            "Uf": "SP"
        },
        {
            "Id": 3504,
            "Codigo": 3520608,
            "Nome": "Indiana",
            "Uf": "SP"
        },
        {
            "Id": 3505,
            "Codigo": 3520707,
            "Nome": "Indiaporã",
            "Uf": "SP"
        },
        {
            "Id": 3506,
            "Codigo": 3520806,
            "Nome": "Inúbia Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3507,
            "Codigo": 3520905,
            "Nome": "Ipaussu",
            "Uf": "SP"
        },
        {
            "Id": 3508,
            "Codigo": 3521002,
            "Nome": "Iperó",
            "Uf": "SP"
        },
        {
            "Id": 3509,
            "Codigo": 3521101,
            "Nome": "Ipeúna",
            "Uf": "SP"
        },
        {
            "Id": 3510,
            "Codigo": 3521150,
            "Nome": "Ipiguá",
            "Uf": "SP"
        },
        {
            "Id": 3511,
            "Codigo": 3521200,
            "Nome": "Iporanga",
            "Uf": "SP"
        },
        {
            "Id": 3512,
            "Codigo": 3521309,
            "Nome": "Ipuã",
            "Uf": "SP"
        },
        {
            "Id": 3513,
            "Codigo": 3521408,
            "Nome": "Iracemápolis",
            "Uf": "SP"
        },
        {
            "Id": 3514,
            "Codigo": 3521507,
            "Nome": "Irapuã",
            "Uf": "SP"
        },
        {
            "Id": 3515,
            "Codigo": 3521606,
            "Nome": "Irapuru",
            "Uf": "SP"
        },
        {
            "Id": 3516,
            "Codigo": 3521705,
            "Nome": "Itaberá",
            "Uf": "SP"
        },
        {
            "Id": 3517,
            "Codigo": 3521804,
            "Nome": "Itaí",
            "Uf": "SP"
        },
        {
            "Id": 3518,
            "Codigo": 3521903,
            "Nome": "Itajobi",
            "Uf": "SP"
        },
        {
            "Id": 3519,
            "Codigo": 3522000,
            "Nome": "Itaju",
            "Uf": "SP"
        },
        {
            "Id": 3520,
            "Codigo": 3522109,
            "Nome": "Itanhaém",
            "Uf": "SP"
        },
        {
            "Id": 3521,
            "Codigo": 3522158,
            "Nome": "Itaóca",
            "Uf": "SP"
        },
        {
            "Id": 3522,
            "Codigo": 3522208,
            "Nome": "Itapecerica da Serra",
            "Uf": "SP"
        },
        {
            "Id": 3523,
            "Codigo": 3522307,
            "Nome": "Itapetininga",
            "Uf": "SP"
        },
        {
            "Id": 3524,
            "Codigo": 3522406,
            "Nome": "Itapeva",
            "Uf": "SP"
        },
        {
            "Id": 3525,
            "Codigo": 3522505,
            "Nome": "Itapevi",
            "Uf": "SP"
        },
        {
            "Id": 3526,
            "Codigo": 3522604,
            "Nome": "Itapira",
            "Uf": "SP"
        },
        {
            "Id": 3527,
            "Codigo": 3522653,
            "Nome": "Itapirapuã Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3528,
            "Codigo": 3522703,
            "Nome": "Itápolis",
            "Uf": "SP"
        },
        {
            "Id": 3529,
            "Codigo": 3522802,
            "Nome": "Itaporanga",
            "Uf": "SP"
        },
        {
            "Id": 3530,
            "Codigo": 3522901,
            "Nome": "Itapuí",
            "Uf": "SP"
        },
        {
            "Id": 3531,
            "Codigo": 3523008,
            "Nome": "Itapura",
            "Uf": "SP"
        },
        {
            "Id": 3532,
            "Codigo": 3523107,
            "Nome": "Itaquaquecetuba",
            "Uf": "SP"
        },
        {
            "Id": 3533,
            "Codigo": 3523206,
            "Nome": "Itararé",
            "Uf": "SP"
        },
        {
            "Id": 3534,
            "Codigo": 3523305,
            "Nome": "Itariri",
            "Uf": "SP"
        },
        {
            "Id": 3535,
            "Codigo": 3523404,
            "Nome": "Itatiba",
            "Uf": "SP"
        },
        {
            "Id": 3536,
            "Codigo": 3523503,
            "Nome": "Itatinga",
            "Uf": "SP"
        },
        {
            "Id": 3537,
            "Codigo": 3523602,
            "Nome": "Itirapina",
            "Uf": "SP"
        },
        {
            "Id": 3538,
            "Codigo": 3523701,
            "Nome": "Itirapuã",
            "Uf": "SP"
        },
        {
            "Id": 3539,
            "Codigo": 3523800,
            "Nome": "Itobi",
            "Uf": "SP"
        },
        {
            "Id": 3540,
            "Codigo": 3523909,
            "Nome": "Itu",
            "Uf": "SP"
        },
        {
            "Id": 3541,
            "Codigo": 3524006,
            "Nome": "Itupeva",
            "Uf": "SP"
        },
        {
            "Id": 3542,
            "Codigo": 3524105,
            "Nome": "Ituverava",
            "Uf": "SP"
        },
        {
            "Id": 3543,
            "Codigo": 3524204,
            "Nome": "Jaborandi",
            "Uf": "SP"
        },
        {
            "Id": 3544,
            "Codigo": 3524303,
            "Nome": "Jaboticabal",
            "Uf": "SP"
        },
        {
            "Id": 3545,
            "Codigo": 3524402,
            "Nome": "Jacareí",
            "Uf": "SP"
        },
        {
            "Id": 3546,
            "Codigo": 3524501,
            "Nome": "Jaci",
            "Uf": "SP"
        },
        {
            "Id": 3547,
            "Codigo": 3524600,
            "Nome": "Jacupiranga",
            "Uf": "SP"
        },
        {
            "Id": 3548,
            "Codigo": 3524709,
            "Nome": "Jaguariúna",
            "Uf": "SP"
        },
        {
            "Id": 3549,
            "Codigo": 3524808,
            "Nome": "Jales",
            "Uf": "SP"
        },
        {
            "Id": 3550,
            "Codigo": 3524907,
            "Nome": "Jambeiro",
            "Uf": "SP"
        },
        {
            "Id": 3551,
            "Codigo": 3525003,
            "Nome": "Jandira",
            "Uf": "SP"
        },
        {
            "Id": 3552,
            "Codigo": 3525102,
            "Nome": "Jardinópolis",
            "Uf": "SP"
        },
        {
            "Id": 3553,
            "Codigo": 3525201,
            "Nome": "Jarinu",
            "Uf": "SP"
        },
        {
            "Id": 3554,
            "Codigo": 3525300,
            "Nome": "Jaú",
            "Uf": "SP"
        },
        {
            "Id": 3555,
            "Codigo": 3525409,
            "Nome": "Jeriquara",
            "Uf": "SP"
        },
        {
            "Id": 3556,
            "Codigo": 3525508,
            "Nome": "Joanópolis",
            "Uf": "SP"
        },
        {
            "Id": 3557,
            "Codigo": 3525607,
            "Nome": "João Ramalho",
            "Uf": "SP"
        },
        {
            "Id": 3558,
            "Codigo": 3525706,
            "Nome": "José Bonifácio",
            "Uf": "SP"
        },
        {
            "Id": 3559,
            "Codigo": 3525805,
            "Nome": "Júlio Mesquita",
            "Uf": "SP"
        },
        {
            "Id": 3560,
            "Codigo": 3525854,
            "Nome": "Jumirim",
            "Uf": "SP"
        },
        {
            "Id": 3561,
            "Codigo": 3525904,
            "Nome": "Jundiaí",
            "Uf": "SP"
        },
        {
            "Id": 3562,
            "Codigo": 3526001,
            "Nome": "Junqueirópolis",
            "Uf": "SP"
        },
        {
            "Id": 3563,
            "Codigo": 3526100,
            "Nome": "Juquiá",
            "Uf": "SP"
        },
        {
            "Id": 3564,
            "Codigo": 3526209,
            "Nome": "Juquitiba",
            "Uf": "SP"
        },
        {
            "Id": 3565,
            "Codigo": 3526308,
            "Nome": "Lagoinha",
            "Uf": "SP"
        },
        {
            "Id": 3566,
            "Codigo": 3526407,
            "Nome": "Laranjal Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3567,
            "Codigo": 3526506,
            "Nome": "Lavínia",
            "Uf": "SP"
        },
        {
            "Id": 3568,
            "Codigo": 3526605,
            "Nome": "Lavrinhas",
            "Uf": "SP"
        },
        {
            "Id": 3569,
            "Codigo": 3526704,
            "Nome": "Leme",
            "Uf": "SP"
        },
        {
            "Id": 3570,
            "Codigo": 3526803,
            "Nome": "Lençóis Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3571,
            "Codigo": 3526902,
            "Nome": "Limeira",
            "Uf": "SP"
        },
        {
            "Id": 3572,
            "Codigo": 3527009,
            "Nome": "Lindóia",
            "Uf": "SP"
        },
        {
            "Id": 3573,
            "Codigo": 3527108,
            "Nome": "Lins",
            "Uf": "SP"
        },
        {
            "Id": 3574,
            "Codigo": 3527207,
            "Nome": "Lorena",
            "Uf": "SP"
        },
        {
            "Id": 3575,
            "Codigo": 3527256,
            "Nome": "Lourdes",
            "Uf": "SP"
        },
        {
            "Id": 3576,
            "Codigo": 3527306,
            "Nome": "Louveira",
            "Uf": "SP"
        },
        {
            "Id": 3577,
            "Codigo": 3527405,
            "Nome": "Lucélia",
            "Uf": "SP"
        },
        {
            "Id": 3578,
            "Codigo": 3527504,
            "Nome": "Lucianópolis",
            "Uf": "SP"
        },
        {
            "Id": 3579,
            "Codigo": 3527603,
            "Nome": "Luís Antônio",
            "Uf": "SP"
        },
        {
            "Id": 3580,
            "Codigo": 3527702,
            "Nome": "Luiziânia",
            "Uf": "SP"
        },
        {
            "Id": 3581,
            "Codigo": 3527801,
            "Nome": "Lupércio",
            "Uf": "SP"
        },
        {
            "Id": 3582,
            "Codigo": 3527900,
            "Nome": "Lutécia",
            "Uf": "SP"
        },
        {
            "Id": 3583,
            "Codigo": 3528007,
            "Nome": "Macatuba",
            "Uf": "SP"
        },
        {
            "Id": 3584,
            "Codigo": 3528106,
            "Nome": "Macaubal",
            "Uf": "SP"
        },
        {
            "Id": 3585,
            "Codigo": 3528205,
            "Nome": "Macedônia",
            "Uf": "SP"
        },
        {
            "Id": 3586,
            "Codigo": 3528304,
            "Nome": "Magda",
            "Uf": "SP"
        },
        {
            "Id": 3587,
            "Codigo": 3528403,
            "Nome": "Mairinque",
            "Uf": "SP"
        },
        {
            "Id": 3588,
            "Codigo": 3528502,
            "Nome": "Mairiporã",
            "Uf": "SP"
        },
        {
            "Id": 3589,
            "Codigo": 3528601,
            "Nome": "Manduri",
            "Uf": "SP"
        },
        {
            "Id": 3590,
            "Codigo": 3528700,
            "Nome": "Marabá Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3591,
            "Codigo": 3528809,
            "Nome": "Maracaí",
            "Uf": "SP"
        },
        {
            "Id": 3592,
            "Codigo": 3528858,
            "Nome": "Marapoama",
            "Uf": "SP"
        },
        {
            "Id": 3593,
            "Codigo": 3528908,
            "Nome": "Mariápolis",
            "Uf": "SP"
        },
        {
            "Id": 3594,
            "Codigo": 3529005,
            "Nome": "Marília",
            "Uf": "SP"
        },
        {
            "Id": 3595,
            "Codigo": 3529104,
            "Nome": "Marinópolis",
            "Uf": "SP"
        },
        {
            "Id": 3596,
            "Codigo": 3529203,
            "Nome": "Martinópolis",
            "Uf": "SP"
        },
        {
            "Id": 3597,
            "Codigo": 3529302,
            "Nome": "Matão",
            "Uf": "SP"
        },
        {
            "Id": 3598,
            "Codigo": 3529401,
            "Nome": "Mauá",
            "Uf": "SP"
        },
        {
            "Id": 3599,
            "Codigo": 3529500,
            "Nome": "Mendonça",
            "Uf": "SP"
        },
        {
            "Id": 3600,
            "Codigo": 3529609,
            "Nome": "Meridiano",
            "Uf": "SP"
        },
        {
            "Id": 3601,
            "Codigo": 3529658,
            "Nome": "Mesópolis",
            "Uf": "SP"
        },
        {
            "Id": 3602,
            "Codigo": 3529708,
            "Nome": "Miguelópolis",
            "Uf": "SP"
        },
        {
            "Id": 3603,
            "Codigo": 3529807,
            "Nome": "Mineiros do Tietê",
            "Uf": "SP"
        },
        {
            "Id": 3604,
            "Codigo": 3529906,
            "Nome": "Miracatu",
            "Uf": "SP"
        },
        {
            "Id": 3605,
            "Codigo": 3530003,
            "Nome": "Mira Estrela",
            "Uf": "SP"
        },
        {
            "Id": 3606,
            "Codigo": 3530102,
            "Nome": "Mirandópolis",
            "Uf": "SP"
        },
        {
            "Id": 3607,
            "Codigo": 3530201,
            "Nome": "Mirante do Paranapanema",
            "Uf": "SP"
        },
        {
            "Id": 3608,
            "Codigo": 3530300,
            "Nome": "Mirassol",
            "Uf": "SP"
        },
        {
            "Id": 3609,
            "Codigo": 3530409,
            "Nome": "Mirassolândia",
            "Uf": "SP"
        },
        {
            "Id": 3610,
            "Codigo": 3530508,
            "Nome": "Mococa",
            "Uf": "SP"
        },
        {
            "Id": 3611,
            "Codigo": 3530607,
            "Nome": "Mogi das Cruzes",
            "Uf": "SP"
        },
        {
            "Id": 3612,
            "Codigo": 3530706,
            "Nome": "Mogi Guaçu",
            "Uf": "SP"
        },
        {
            "Id": 3613,
            "Codigo": 3530805,
            "Nome": "Moji Mirim",
            "Uf": "SP"
        },
        {
            "Id": 3614,
            "Codigo": 3530904,
            "Nome": "Mombuca",
            "Uf": "SP"
        },
        {
            "Id": 3615,
            "Codigo": 3531001,
            "Nome": "Monções",
            "Uf": "SP"
        },
        {
            "Id": 3616,
            "Codigo": 3531100,
            "Nome": "Mongaguá",
            "Uf": "SP"
        },
        {
            "Id": 3617,
            "Codigo": 3531209,
            "Nome": "Monte Alegre do Sul",
            "Uf": "SP"
        },
        {
            "Id": 3618,
            "Codigo": 3531308,
            "Nome": "Monte Alto",
            "Uf": "SP"
        },
        {
            "Id": 3619,
            "Codigo": 3531407,
            "Nome": "Monte Aprazível",
            "Uf": "SP"
        },
        {
            "Id": 3620,
            "Codigo": 3531506,
            "Nome": "Monte Azul Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3621,
            "Codigo": 3531605,
            "Nome": "Monte Castelo",
            "Uf": "SP"
        },
        {
            "Id": 3622,
            "Codigo": 3531704,
            "Nome": "Monteiro Lobato",
            "Uf": "SP"
        },
        {
            "Id": 3623,
            "Codigo": 3531803,
            "Nome": "Monte Mor",
            "Uf": "SP"
        },
        {
            "Id": 3624,
            "Codigo": 3531902,
            "Nome": "Morro Agudo",
            "Uf": "SP"
        },
        {
            "Id": 3625,
            "Codigo": 3532009,
            "Nome": "Morungaba",
            "Uf": "SP"
        },
        {
            "Id": 3626,
            "Codigo": 3532058,
            "Nome": "Motuca",
            "Uf": "SP"
        },
        {
            "Id": 3627,
            "Codigo": 3532108,
            "Nome": "Murutinga do Sul",
            "Uf": "SP"
        },
        {
            "Id": 3628,
            "Codigo": 3532157,
            "Nome": "Nantes",
            "Uf": "SP"
        },
        {
            "Id": 3629,
            "Codigo": 3532207,
            "Nome": "Narandiba",
            "Uf": "SP"
        },
        {
            "Id": 3630,
            "Codigo": 3532306,
            "Nome": "Natividade da Serra",
            "Uf": "SP"
        },
        {
            "Id": 3631,
            "Codigo": 3532405,
            "Nome": "Nazaré Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3632,
            "Codigo": 3532504,
            "Nome": "Neves Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3633,
            "Codigo": 3532603,
            "Nome": "Nhandeara",
            "Uf": "SP"
        },
        {
            "Id": 3634,
            "Codigo": 3532702,
            "Nome": "Nipoã",
            "Uf": "SP"
        },
        {
            "Id": 3635,
            "Codigo": 3532801,
            "Nome": "Nova Aliança",
            "Uf": "SP"
        },
        {
            "Id": 3636,
            "Codigo": 3532827,
            "Nome": "Nova Campina",
            "Uf": "SP"
        },
        {
            "Id": 3637,
            "Codigo": 3532843,
            "Nome": "Nova Canaã Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3638,
            "Codigo": 3532868,
            "Nome": "Nova Castilho",
            "Uf": "SP"
        },
        {
            "Id": 3639,
            "Codigo": 3532900,
            "Nome": "Nova Europa",
            "Uf": "SP"
        },
        {
            "Id": 3640,
            "Codigo": 3533007,
            "Nome": "Nova Granada",
            "Uf": "SP"
        },
        {
            "Id": 3641,
            "Codigo": 3533106,
            "Nome": "Nova Guataporanga",
            "Uf": "SP"
        },
        {
            "Id": 3642,
            "Codigo": 3533205,
            "Nome": "Nova Independência",
            "Uf": "SP"
        },
        {
            "Id": 3643,
            "Codigo": 3533254,
            "Nome": "Novais",
            "Uf": "SP"
        },
        {
            "Id": 3644,
            "Codigo": 3533304,
            "Nome": "Nova Luzitânia",
            "Uf": "SP"
        },
        {
            "Id": 3645,
            "Codigo": 3533403,
            "Nome": "Nova Odessa",
            "Uf": "SP"
        },
        {
            "Id": 3646,
            "Codigo": 3533502,
            "Nome": "Novo Horizonte",
            "Uf": "SP"
        },
        {
            "Id": 3647,
            "Codigo": 3533601,
            "Nome": "Nuporanga",
            "Uf": "SP"
        },
        {
            "Id": 3648,
            "Codigo": 3533700,
            "Nome": "Ocauçu",
            "Uf": "SP"
        },
        {
            "Id": 3649,
            "Codigo": 3533809,
            "Nome": "Óleo",
            "Uf": "SP"
        },
        {
            "Id": 3650,
            "Codigo": 3533908,
            "Nome": "Olímpia",
            "Uf": "SP"
        },
        {
            "Id": 3651,
            "Codigo": 3534005,
            "Nome": "Onda Verde",
            "Uf": "SP"
        },
        {
            "Id": 3652,
            "Codigo": 3534104,
            "Nome": "Oriente",
            "Uf": "SP"
        },
        {
            "Id": 3653,
            "Codigo": 3534203,
            "Nome": "Orindiúva",
            "Uf": "SP"
        },
        {
            "Id": 3654,
            "Codigo": 3534302,
            "Nome": "Orlândia",
            "Uf": "SP"
        },
        {
            "Id": 3655,
            "Codigo": 3534401,
            "Nome": "Osasco",
            "Uf": "SP"
        },
        {
            "Id": 3656,
            "Codigo": 3534500,
            "Nome": "Oscar Bressane",
            "Uf": "SP"
        },
        {
            "Id": 3657,
            "Codigo": 3534609,
            "Nome": "Osvaldo Cruz",
            "Uf": "SP"
        },
        {
            "Id": 3658,
            "Codigo": 3534708,
            "Nome": "Ourinhos",
            "Uf": "SP"
        },
        {
            "Id": 3659,
            "Codigo": 3534757,
            "Nome": "Ouroeste",
            "Uf": "SP"
        },
        {
            "Id": 3660,
            "Codigo": 3534807,
            "Nome": "Ouro Verde",
            "Uf": "SP"
        },
        {
            "Id": 3661,
            "Codigo": 3534906,
            "Nome": "Pacaembu",
            "Uf": "SP"
        },
        {
            "Id": 3662,
            "Codigo": 3535002,
            "Nome": "Palestina",
            "Uf": "SP"
        },
        {
            "Id": 3663,
            "Codigo": 3535101,
            "Nome": "Palmares Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3664,
            "Codigo": 3535200,
            "Nome": "Palmeira D'Oeste",
            "Uf": "SP"
        },
        {
            "Id": 3665,
            "Codigo": 3535309,
            "Nome": "Palmital",
            "Uf": "SP"
        },
        {
            "Id": 3666,
            "Codigo": 3535408,
            "Nome": "Panorama",
            "Uf": "SP"
        },
        {
            "Id": 3667,
            "Codigo": 3535507,
            "Nome": "Paraguaçu Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3668,
            "Codigo": 3535606,
            "Nome": "Paraibuna",
            "Uf": "SP"
        },
        {
            "Id": 3669,
            "Codigo": 3535705,
            "Nome": "Paraíso",
            "Uf": "SP"
        },
        {
            "Id": 3670,
            "Codigo": 3535804,
            "Nome": "Paranapanema",
            "Uf": "SP"
        },
        {
            "Id": 3671,
            "Codigo": 3535903,
            "Nome": "Paranapuã",
            "Uf": "SP"
        },
        {
            "Id": 3672,
            "Codigo": 3536000,
            "Nome": "Parapuã",
            "Uf": "SP"
        },
        {
            "Id": 3673,
            "Codigo": 3536109,
            "Nome": "Pardinho",
            "Uf": "SP"
        },
        {
            "Id": 3674,
            "Codigo": 3536208,
            "Nome": "Pariquera-Açu",
            "Uf": "SP"
        },
        {
            "Id": 3675,
            "Codigo": 3536257,
            "Nome": "Parisi",
            "Uf": "SP"
        },
        {
            "Id": 3676,
            "Codigo": 3536307,
            "Nome": "Patrocínio Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3677,
            "Codigo": 3536406,
            "Nome": "Paulicéia",
            "Uf": "SP"
        },
        {
            "Id": 3678,
            "Codigo": 3536505,
            "Nome": "Paulínia",
            "Uf": "SP"
        },
        {
            "Id": 3679,
            "Codigo": 3536570,
            "Nome": "Paulistânia",
            "Uf": "SP"
        },
        {
            "Id": 3680,
            "Codigo": 3536604,
            "Nome": "Paulo de Faria",
            "Uf": "SP"
        },
        {
            "Id": 3681,
            "Codigo": 3536703,
            "Nome": "Pederneiras",
            "Uf": "SP"
        },
        {
            "Id": 3682,
            "Codigo": 3536802,
            "Nome": "Pedra Bela",
            "Uf": "SP"
        },
        {
            "Id": 3683,
            "Codigo": 3536901,
            "Nome": "Pedranópolis",
            "Uf": "SP"
        },
        {
            "Id": 3684,
            "Codigo": 3537008,
            "Nome": "Pedregulho",
            "Uf": "SP"
        },
        {
            "Id": 3685,
            "Codigo": 3537107,
            "Nome": "Pedreira",
            "Uf": "SP"
        },
        {
            "Id": 3686,
            "Codigo": 3537156,
            "Nome": "Pedrinhas Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3687,
            "Codigo": 3537206,
            "Nome": "Pedro de Toledo",
            "Uf": "SP"
        },
        {
            "Id": 3688,
            "Codigo": 3537305,
            "Nome": "Penápolis",
            "Uf": "SP"
        },
        {
            "Id": 3689,
            "Codigo": 3537404,
            "Nome": "Pereira Barreto",
            "Uf": "SP"
        },
        {
            "Id": 3690,
            "Codigo": 3537503,
            "Nome": "Pereiras",
            "Uf": "SP"
        },
        {
            "Id": 3691,
            "Codigo": 3537602,
            "Nome": "Peruíbe",
            "Uf": "SP"
        },
        {
            "Id": 3692,
            "Codigo": 3537701,
            "Nome": "Piacatu",
            "Uf": "SP"
        },
        {
            "Id": 3693,
            "Codigo": 3537800,
            "Nome": "Piedade",
            "Uf": "SP"
        },
        {
            "Id": 3694,
            "Codigo": 3537909,
            "Nome": "Pilar do Sul",
            "Uf": "SP"
        },
        {
            "Id": 3695,
            "Codigo": 3538006,
            "Nome": "Pindamonhangaba",
            "Uf": "SP"
        },
        {
            "Id": 3696,
            "Codigo": 3538105,
            "Nome": "Pindorama",
            "Uf": "SP"
        },
        {
            "Id": 3697,
            "Codigo": 3538204,
            "Nome": "Pinhalzinho",
            "Uf": "SP"
        },
        {
            "Id": 3698,
            "Codigo": 3538303,
            "Nome": "Piquerobi",
            "Uf": "SP"
        },
        {
            "Id": 3699,
            "Codigo": 3538501,
            "Nome": "Piquete",
            "Uf": "SP"
        },
        {
            "Id": 3700,
            "Codigo": 3538600,
            "Nome": "Piracaia",
            "Uf": "SP"
        },
        {
            "Id": 3701,
            "Codigo": 3538709,
            "Nome": "Piracicaba",
            "Uf": "SP"
        },
        {
            "Id": 3702,
            "Codigo": 3538808,
            "Nome": "Piraju",
            "Uf": "SP"
        },
        {
            "Id": 3703,
            "Codigo": 3538907,
            "Nome": "Pirajuí",
            "Uf": "SP"
        },
        {
            "Id": 3704,
            "Codigo": 3539004,
            "Nome": "Pirangi",
            "Uf": "SP"
        },
        {
            "Id": 3705,
            "Codigo": 3539103,
            "Nome": "Pirapora do Bom Jesus",
            "Uf": "SP"
        },
        {
            "Id": 3706,
            "Codigo": 3539202,
            "Nome": "Pirapozinho",
            "Uf": "SP"
        },
        {
            "Id": 3707,
            "Codigo": 3539301,
            "Nome": "Pirassununga",
            "Uf": "SP"
        },
        {
            "Id": 3708,
            "Codigo": 3539400,
            "Nome": "Piratininga",
            "Uf": "SP"
        },
        {
            "Id": 3709,
            "Codigo": 3539509,
            "Nome": "Pitangueiras",
            "Uf": "SP"
        },
        {
            "Id": 3710,
            "Codigo": 3539608,
            "Nome": "Planalto",
            "Uf": "SP"
        },
        {
            "Id": 3711,
            "Codigo": 3539707,
            "Nome": "Platina",
            "Uf": "SP"
        },
        {
            "Id": 3712,
            "Codigo": 3539806,
            "Nome": "Poá",
            "Uf": "SP"
        },
        {
            "Id": 3713,
            "Codigo": 3539905,
            "Nome": "Poloni",
            "Uf": "SP"
        },
        {
            "Id": 3714,
            "Codigo": 3540002,
            "Nome": "Pompéia",
            "Uf": "SP"
        },
        {
            "Id": 3715,
            "Codigo": 3540101,
            "Nome": "Pongaí",
            "Uf": "SP"
        },
        {
            "Id": 3716,
            "Codigo": 3540200,
            "Nome": "Pontal",
            "Uf": "SP"
        },
        {
            "Id": 3717,
            "Codigo": 3540259,
            "Nome": "Pontalinda",
            "Uf": "SP"
        },
        {
            "Id": 3718,
            "Codigo": 3540309,
            "Nome": "Pontes Gestal",
            "Uf": "SP"
        },
        {
            "Id": 3719,
            "Codigo": 3540408,
            "Nome": "Populina",
            "Uf": "SP"
        },
        {
            "Id": 3720,
            "Codigo": 3540507,
            "Nome": "Porangaba",
            "Uf": "SP"
        },
        {
            "Id": 3721,
            "Codigo": 3540606,
            "Nome": "Porto Feliz",
            "Uf": "SP"
        },
        {
            "Id": 3722,
            "Codigo": 3540705,
            "Nome": "Porto Ferreira",
            "Uf": "SP"
        },
        {
            "Id": 3723,
            "Codigo": 3540754,
            "Nome": "Potim",
            "Uf": "SP"
        },
        {
            "Id": 3724,
            "Codigo": 3540804,
            "Nome": "Potirendaba",
            "Uf": "SP"
        },
        {
            "Id": 3725,
            "Codigo": 3540853,
            "Nome": "Pracinha",
            "Uf": "SP"
        },
        {
            "Id": 3726,
            "Codigo": 3540903,
            "Nome": "Pradópolis",
            "Uf": "SP"
        },
        {
            "Id": 3727,
            "Codigo": 3541000,
            "Nome": "Praia Grande",
            "Uf": "SP"
        },
        {
            "Id": 3728,
            "Codigo": 3541059,
            "Nome": "Pratânia",
            "Uf": "SP"
        },
        {
            "Id": 3729,
            "Codigo": 3541109,
            "Nome": "Presidente Alves",
            "Uf": "SP"
        },
        {
            "Id": 3730,
            "Codigo": 3541208,
            "Nome": "Presidente Bernardes",
            "Uf": "SP"
        },
        {
            "Id": 3731,
            "Codigo": 3541307,
            "Nome": "Presidente Epitácio",
            "Uf": "SP"
        },
        {
            "Id": 3732,
            "Codigo": 3541406,
            "Nome": "Presidente Prudente",
            "Uf": "SP"
        },
        {
            "Id": 3733,
            "Codigo": 3541505,
            "Nome": "Presidente Venceslau",
            "Uf": "SP"
        },
        {
            "Id": 3734,
            "Codigo": 3541604,
            "Nome": "Promissão",
            "Uf": "SP"
        },
        {
            "Id": 3735,
            "Codigo": 3541653,
            "Nome": "Quadra",
            "Uf": "SP"
        },
        {
            "Id": 3736,
            "Codigo": 3541703,
            "Nome": "Quatá",
            "Uf": "SP"
        },
        {
            "Id": 3737,
            "Codigo": 3541802,
            "Nome": "Queiroz",
            "Uf": "SP"
        },
        {
            "Id": 3738,
            "Codigo": 3541901,
            "Nome": "Queluz",
            "Uf": "SP"
        },
        {
            "Id": 3739,
            "Codigo": 3542008,
            "Nome": "Quintana",
            "Uf": "SP"
        },
        {
            "Id": 3740,
            "Codigo": 3542107,
            "Nome": "Rafard",
            "Uf": "SP"
        },
        {
            "Id": 3741,
            "Codigo": 3542206,
            "Nome": "Rancharia",
            "Uf": "SP"
        },
        {
            "Id": 3742,
            "Codigo": 3542305,
            "Nome": "Redenção da Serra",
            "Uf": "SP"
        },
        {
            "Id": 3743,
            "Codigo": 3542404,
            "Nome": "Regente Feijó",
            "Uf": "SP"
        },
        {
            "Id": 3744,
            "Codigo": 3542503,
            "Nome": "Reginópolis",
            "Uf": "SP"
        },
        {
            "Id": 3745,
            "Codigo": 3542602,
            "Nome": "Registro",
            "Uf": "SP"
        },
        {
            "Id": 3746,
            "Codigo": 3542701,
            "Nome": "Restinga",
            "Uf": "SP"
        },
        {
            "Id": 3747,
            "Codigo": 3542800,
            "Nome": "Ribeira",
            "Uf": "SP"
        },
        {
            "Id": 3748,
            "Codigo": 3542909,
            "Nome": "Ribeirão Bonito",
            "Uf": "SP"
        },
        {
            "Id": 3749,
            "Codigo": 3543006,
            "Nome": "Ribeirão Branco",
            "Uf": "SP"
        },
        {
            "Id": 3750,
            "Codigo": 3543105,
            "Nome": "Ribeirão Corrente",
            "Uf": "SP"
        },
        {
            "Id": 3751,
            "Codigo": 3543204,
            "Nome": "Ribeirão do Sul",
            "Uf": "SP"
        },
        {
            "Id": 3752,
            "Codigo": 3543238,
            "Nome": "Ribeirão dos Índios",
            "Uf": "SP"
        },
        {
            "Id": 3753,
            "Codigo": 3543253,
            "Nome": "Ribeirão Grande",
            "Uf": "SP"
        },
        {
            "Id": 3754,
            "Codigo": 3543303,
            "Nome": "Ribeirão Pires",
            "Uf": "SP"
        },
        {
            "Id": 3755,
            "Codigo": 3543402,
            "Nome": "Ribeirão Preto",
            "Uf": "SP"
        },
        {
            "Id": 3756,
            "Codigo": 3543501,
            "Nome": "Riversul",
            "Uf": "SP"
        },
        {
            "Id": 3757,
            "Codigo": 3543600,
            "Nome": "Rifaina",
            "Uf": "SP"
        },
        {
            "Id": 3758,
            "Codigo": 3543709,
            "Nome": "Rincão",
            "Uf": "SP"
        },
        {
            "Id": 3759,
            "Codigo": 3543808,
            "Nome": "Rinópolis",
            "Uf": "SP"
        },
        {
            "Id": 3760,
            "Codigo": 3543907,
            "Nome": "Rio Claro",
            "Uf": "SP"
        },
        {
            "Id": 3761,
            "Codigo": 3544004,
            "Nome": "Rio das Pedras",
            "Uf": "SP"
        },
        {
            "Id": 3762,
            "Codigo": 3544103,
            "Nome": "Rio Grande da Serra",
            "Uf": "SP"
        },
        {
            "Id": 3763,
            "Codigo": 3544202,
            "Nome": "Riolândia",
            "Uf": "SP"
        },
        {
            "Id": 3764,
            "Codigo": 3544251,
            "Nome": "Rosana",
            "Uf": "SP"
        },
        {
            "Id": 3765,
            "Codigo": 3544301,
            "Nome": "Roseira",
            "Uf": "SP"
        },
        {
            "Id": 3766,
            "Codigo": 3544400,
            "Nome": "Rubiácea",
            "Uf": "SP"
        },
        {
            "Id": 3767,
            "Codigo": 3544509,
            "Nome": "Rubinéia",
            "Uf": "SP"
        },
        {
            "Id": 3768,
            "Codigo": 3544608,
            "Nome": "Sabino",
            "Uf": "SP"
        },
        {
            "Id": 3769,
            "Codigo": 3544707,
            "Nome": "Sagres",
            "Uf": "SP"
        },
        {
            "Id": 3770,
            "Codigo": 3544806,
            "Nome": "Sales",
            "Uf": "SP"
        },
        {
            "Id": 3771,
            "Codigo": 3544905,
            "Nome": "Sales Oliveira",
            "Uf": "SP"
        },
        {
            "Id": 3772,
            "Codigo": 3545001,
            "Nome": "Salesópolis",
            "Uf": "SP"
        },
        {
            "Id": 3773,
            "Codigo": 3545100,
            "Nome": "Salmourão",
            "Uf": "SP"
        },
        {
            "Id": 3774,
            "Codigo": 3545159,
            "Nome": "Saltinho",
            "Uf": "SP"
        },
        {
            "Id": 3775,
            "Codigo": 3545209,
            "Nome": "Salto",
            "Uf": "SP"
        },
        {
            "Id": 3776,
            "Codigo": 3545308,
            "Nome": "Salto de Pirapora",
            "Uf": "SP"
        },
        {
            "Id": 3777,
            "Codigo": 3545407,
            "Nome": "Salto Grande",
            "Uf": "SP"
        },
        {
            "Id": 3778,
            "Codigo": 3545506,
            "Nome": "Sandovalina",
            "Uf": "SP"
        },
        {
            "Id": 3779,
            "Codigo": 3545605,
            "Nome": "Santa Adélia",
            "Uf": "SP"
        },
        {
            "Id": 3780,
            "Codigo": 3545704,
            "Nome": "Santa Albertina",
            "Uf": "SP"
        },
        {
            "Id": 3781,
            "Codigo": 3545803,
            "Nome": "Santa Bárbara D'Oeste",
            "Uf": "SP"
        },
        {
            "Id": 3782,
            "Codigo": 3546009,
            "Nome": "Santa Branca",
            "Uf": "SP"
        },
        {
            "Id": 3783,
            "Codigo": 3546108,
            "Nome": "Santa Clara D'Oeste",
            "Uf": "SP"
        },
        {
            "Id": 3784,
            "Codigo": 3546207,
            "Nome": "Santa Cruz da Conceição",
            "Uf": "SP"
        },
        {
            "Id": 3785,
            "Codigo": 3546256,
            "Nome": "Santa Cruz da Esperança",
            "Uf": "SP"
        },
        {
            "Id": 3786,
            "Codigo": 3546306,
            "Nome": "Santa Cruz das Palmeiras",
            "Uf": "SP"
        },
        {
            "Id": 3787,
            "Codigo": 3546405,
            "Nome": "Santa Cruz do Rio Pardo",
            "Uf": "SP"
        },
        {
            "Id": 3788,
            "Codigo": 3546504,
            "Nome": "Santa Ernestina",
            "Uf": "SP"
        },
        {
            "Id": 3789,
            "Codigo": 3546603,
            "Nome": "Santa Fé do Sul",
            "Uf": "SP"
        },
        {
            "Id": 3790,
            "Codigo": 3546702,
            "Nome": "Santa Gertrudes",
            "Uf": "SP"
        },
        {
            "Id": 3791,
            "Codigo": 3546801,
            "Nome": "Santa Isabel",
            "Uf": "SP"
        },
        {
            "Id": 3792,
            "Codigo": 3546900,
            "Nome": "Santa Lúcia",
            "Uf": "SP"
        },
        {
            "Id": 3793,
            "Codigo": 3547007,
            "Nome": "Santa Maria da Serra",
            "Uf": "SP"
        },
        {
            "Id": 3794,
            "Codigo": 3547106,
            "Nome": "Santa Mercedes",
            "Uf": "SP"
        },
        {
            "Id": 3795,
            "Codigo": 3547205,
            "Nome": "Santana da Ponte Pensa",
            "Uf": "SP"
        },
        {
            "Id": 3796,
            "Codigo": 3547304,
            "Nome": "Santana de Parnaíba",
            "Uf": "SP"
        },
        {
            "Id": 3797,
            "Codigo": 3547403,
            "Nome": "Santa Rita D'Oeste",
            "Uf": "SP"
        },
        {
            "Id": 3798,
            "Codigo": 3547502,
            "Nome": "Santa Rita do Passa Quatro",
            "Uf": "SP"
        },
        {
            "Id": 3799,
            "Codigo": 3547601,
            "Nome": "Santa Rosa de Viterbo",
            "Uf": "SP"
        },
        {
            "Id": 3800,
            "Codigo": 3547650,
            "Nome": "Santa Salete",
            "Uf": "SP"
        },
        {
            "Id": 3801,
            "Codigo": 3547700,
            "Nome": "Santo Anastácio",
            "Uf": "SP"
        },
        {
            "Id": 3802,
            "Codigo": 3547809,
            "Nome": "Santo André",
            "Uf": "SP"
        },
        {
            "Id": 3803,
            "Codigo": 3547908,
            "Nome": "Santo Antônio da Alegria",
            "Uf": "SP"
        },
        {
            "Id": 3804,
            "Codigo": 3548005,
            "Nome": "Santo Antônio de Posse",
            "Uf": "SP"
        },
        {
            "Id": 3805,
            "Codigo": 3548054,
            "Nome": "Santo Antônio do Aracanguá",
            "Uf": "SP"
        },
        {
            "Id": 3806,
            "Codigo": 3548104,
            "Nome": "Santo Antônio do Jardim",
            "Uf": "SP"
        },
        {
            "Id": 3807,
            "Codigo": 3548203,
            "Nome": "Santo Antônio do Pinhal",
            "Uf": "SP"
        },
        {
            "Id": 3808,
            "Codigo": 3548302,
            "Nome": "Santo Expedito",
            "Uf": "SP"
        },
        {
            "Id": 3809,
            "Codigo": 3548401,
            "Nome": "Santópolis do Aguapeí",
            "Uf": "SP"
        },
        {
            "Id": 3810,
            "Codigo": 3548500,
            "Nome": "Santos",
            "Uf": "SP"
        },
        {
            "Id": 3811,
            "Codigo": 3548609,
            "Nome": "São Bento do Sapucaí",
            "Uf": "SP"
        },
        {
            "Id": 3812,
            "Codigo": 3548708,
            "Nome": "São Bernardo do Campo",
            "Uf": "SP"
        },
        {
            "Id": 3813,
            "Codigo": 3548807,
            "Nome": "São Caetano do Sul",
            "Uf": "SP"
        },
        {
            "Id": 3814,
            "Codigo": 3548906,
            "Nome": "São Carlos",
            "Uf": "SP"
        },
        {
            "Id": 3815,
            "Codigo": 3549003,
            "Nome": "São Francisco",
            "Uf": "SP"
        },
        {
            "Id": 3816,
            "Codigo": 3549102,
            "Nome": "São João da Boa Vista",
            "Uf": "SP"
        },
        {
            "Id": 3817,
            "Codigo": 3549201,
            "Nome": "São João das Duas Pontes",
            "Uf": "SP"
        },
        {
            "Id": 3818,
            "Codigo": 3549250,
            "Nome": "São João de Iracema",
            "Uf": "SP"
        },
        {
            "Id": 3819,
            "Codigo": 3549300,
            "Nome": "São João do Pau D'Alho",
            "Uf": "SP"
        },
        {
            "Id": 3820,
            "Codigo": 3549409,
            "Nome": "São Joaquim da Barra",
            "Uf": "SP"
        },
        {
            "Id": 3821,
            "Codigo": 3549508,
            "Nome": "São José da Bela Vista",
            "Uf": "SP"
        },
        {
            "Id": 3822,
            "Codigo": 3549607,
            "Nome": "São José do Barreiro",
            "Uf": "SP"
        },
        {
            "Id": 3823,
            "Codigo": 3549706,
            "Nome": "São José do Rio Pardo",
            "Uf": "SP"
        },
        {
            "Id": 3824,
            "Codigo": 3549805,
            "Nome": "São José do Rio Preto",
            "Uf": "SP"
        },
        {
            "Id": 3825,
            "Codigo": 3549904,
            "Nome": "São José dos Campos",
            "Uf": "SP"
        },
        {
            "Id": 3826,
            "Codigo": 3549953,
            "Nome": "São Lourenço da Serra",
            "Uf": "SP"
        },
        {
            "Id": 3827,
            "Codigo": 3550001,
            "Nome": "São Luís do Paraitinga",
            "Uf": "SP"
        },
        {
            "Id": 3828,
            "Codigo": 3550100,
            "Nome": "São Manuel",
            "Uf": "SP"
        },
        {
            "Id": 3829,
            "Codigo": 3550209,
            "Nome": "São Miguel Arcanjo",
            "Uf": "SP"
        },
        {
            "Id": 3830,
            "Codigo": 3550308,
            "Nome": "São Paulo",
            "Uf": "SP"
        },
        {
            "Id": 3831,
            "Codigo": 3550407,
            "Nome": "São Pedro",
            "Uf": "SP"
        },
        {
            "Id": 3832,
            "Codigo": 3550506,
            "Nome": "São Pedro do Turvo",
            "Uf": "SP"
        },
        {
            "Id": 3833,
            "Codigo": 3550605,
            "Nome": "São Roque",
            "Uf": "SP"
        },
        {
            "Id": 3834,
            "Codigo": 3550704,
            "Nome": "São Sebastião",
            "Uf": "SP"
        },
        {
            "Id": 3835,
            "Codigo": 3550803,
            "Nome": "São Sebastião da Grama",
            "Uf": "SP"
        },
        {
            "Id": 3836,
            "Codigo": 3550902,
            "Nome": "São Simão",
            "Uf": "SP"
        },
        {
            "Id": 3837,
            "Codigo": 3551009,
            "Nome": "São Vicente",
            "Uf": "SP"
        },
        {
            "Id": 3838,
            "Codigo": 3551108,
            "Nome": "Sarapuí",
            "Uf": "SP"
        },
        {
            "Id": 3839,
            "Codigo": 3551207,
            "Nome": "Sarutaiá",
            "Uf": "SP"
        },
        {
            "Id": 3840,
            "Codigo": 3551306,
            "Nome": "Sebastianópolis do Sul",
            "Uf": "SP"
        },
        {
            "Id": 3841,
            "Codigo": 3551405,
            "Nome": "Serra Azul",
            "Uf": "SP"
        },
        {
            "Id": 3842,
            "Codigo": 3551504,
            "Nome": "Serrana",
            "Uf": "SP"
        },
        {
            "Id": 3843,
            "Codigo": 3551603,
            "Nome": "Serra Negra",
            "Uf": "SP"
        },
        {
            "Id": 3844,
            "Codigo": 3551702,
            "Nome": "Sertãozinho",
            "Uf": "SP"
        },
        {
            "Id": 3845,
            "Codigo": 3551801,
            "Nome": "Sete Barras",
            "Uf": "SP"
        },
        {
            "Id": 3846,
            "Codigo": 3551900,
            "Nome": "Severínia",
            "Uf": "SP"
        },
        {
            "Id": 3847,
            "Codigo": 3552007,
            "Nome": "Silveiras",
            "Uf": "SP"
        },
        {
            "Id": 3848,
            "Codigo": 3552106,
            "Nome": "Socorro",
            "Uf": "SP"
        },
        {
            "Id": 3849,
            "Codigo": 3552205,
            "Nome": "Sorocaba",
            "Uf": "SP"
        },
        {
            "Id": 3850,
            "Codigo": 3552304,
            "Nome": "Sud Mennucci",
            "Uf": "SP"
        },
        {
            "Id": 3851,
            "Codigo": 3552403,
            "Nome": "Sumaré",
            "Uf": "SP"
        },
        {
            "Id": 3852,
            "Codigo": 3552502,
            "Nome": "Suzano",
            "Uf": "SP"
        },
        {
            "Id": 3853,
            "Codigo": 3552551,
            "Nome": "Suzanápolis",
            "Uf": "SP"
        },
        {
            "Id": 3854,
            "Codigo": 3552601,
            "Nome": "Tabapuã",
            "Uf": "SP"
        },
        {
            "Id": 3855,
            "Codigo": 3552700,
            "Nome": "Tabatinga",
            "Uf": "SP"
        },
        {
            "Id": 3856,
            "Codigo": 3552809,
            "Nome": "Taboão da Serra",
            "Uf": "SP"
        },
        {
            "Id": 3857,
            "Codigo": 3552908,
            "Nome": "Taciba",
            "Uf": "SP"
        },
        {
            "Id": 3858,
            "Codigo": 3553005,
            "Nome": "Taguaí",
            "Uf": "SP"
        },
        {
            "Id": 3859,
            "Codigo": 3553104,
            "Nome": "Taiaçu",
            "Uf": "SP"
        },
        {
            "Id": 3860,
            "Codigo": 3553203,
            "Nome": "Taiúva",
            "Uf": "SP"
        },
        {
            "Id": 3861,
            "Codigo": 3553302,
            "Nome": "Tambaú",
            "Uf": "SP"
        },
        {
            "Id": 3862,
            "Codigo": 3553401,
            "Nome": "Tanabi",
            "Uf": "SP"
        },
        {
            "Id": 3863,
            "Codigo": 3553500,
            "Nome": "Tapiraí",
            "Uf": "SP"
        },
        {
            "Id": 3864,
            "Codigo": 3553609,
            "Nome": "Tapiratiba",
            "Uf": "SP"
        },
        {
            "Id": 3865,
            "Codigo": 3553658,
            "Nome": "Taquaral",
            "Uf": "SP"
        },
        {
            "Id": 3866,
            "Codigo": 3553708,
            "Nome": "Taquaritinga",
            "Uf": "SP"
        },
        {
            "Id": 3867,
            "Codigo": 3553807,
            "Nome": "Taquarituba",
            "Uf": "SP"
        },
        {
            "Id": 3868,
            "Codigo": 3553856,
            "Nome": "Taquarivaí",
            "Uf": "SP"
        },
        {
            "Id": 3869,
            "Codigo": 3553906,
            "Nome": "Tarabai",
            "Uf": "SP"
        },
        {
            "Id": 3870,
            "Codigo": 3553955,
            "Nome": "Tarumã",
            "Uf": "SP"
        },
        {
            "Id": 3871,
            "Codigo": 3554003,
            "Nome": "Tatuí",
            "Uf": "SP"
        },
        {
            "Id": 3872,
            "Codigo": 3554102,
            "Nome": "Taubaté",
            "Uf": "SP"
        },
        {
            "Id": 3873,
            "Codigo": 3554201,
            "Nome": "Tejupá",
            "Uf": "SP"
        },
        {
            "Id": 3874,
            "Codigo": 3554300,
            "Nome": "Teodoro Sampaio",
            "Uf": "SP"
        },
        {
            "Id": 3875,
            "Codigo": 3554409,
            "Nome": "Terra Roxa",
            "Uf": "SP"
        },
        {
            "Id": 3876,
            "Codigo": 3554508,
            "Nome": "Tietê",
            "Uf": "SP"
        },
        {
            "Id": 3877,
            "Codigo": 3554607,
            "Nome": "Timburi",
            "Uf": "SP"
        },
        {
            "Id": 3878,
            "Codigo": 3554656,
            "Nome": "Torre de Pedra",
            "Uf": "SP"
        },
        {
            "Id": 3879,
            "Codigo": 3554706,
            "Nome": "Torrinha",
            "Uf": "SP"
        },
        {
            "Id": 3880,
            "Codigo": 3554755,
            "Nome": "Trabiju",
            "Uf": "SP"
        },
        {
            "Id": 3881,
            "Codigo": 3554805,
            "Nome": "Tremembé",
            "Uf": "SP"
        },
        {
            "Id": 3882,
            "Codigo": 3554904,
            "Nome": "Três Fronteiras",
            "Uf": "SP"
        },
        {
            "Id": 3883,
            "Codigo": 3554953,
            "Nome": "Tuiuti",
            "Uf": "SP"
        },
        {
            "Id": 3884,
            "Codigo": 3555000,
            "Nome": "Tupã",
            "Uf": "SP"
        },
        {
            "Id": 3885,
            "Codigo": 3555109,
            "Nome": "Tupi Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3886,
            "Codigo": 3555208,
            "Nome": "Turiúba",
            "Uf": "SP"
        },
        {
            "Id": 3887,
            "Codigo": 3555307,
            "Nome": "Turmalina",
            "Uf": "SP"
        },
        {
            "Id": 3888,
            "Codigo": 3555356,
            "Nome": "Ubarana",
            "Uf": "SP"
        },
        {
            "Id": 3889,
            "Codigo": 3555406,
            "Nome": "Ubatuba",
            "Uf": "SP"
        },
        {
            "Id": 3890,
            "Codigo": 3555505,
            "Nome": "Ubirajara",
            "Uf": "SP"
        },
        {
            "Id": 3891,
            "Codigo": 3555604,
            "Nome": "Uchoa",
            "Uf": "SP"
        },
        {
            "Id": 3892,
            "Codigo": 3555703,
            "Nome": "União Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3893,
            "Codigo": 3555802,
            "Nome": "Urânia",
            "Uf": "SP"
        },
        {
            "Id": 3894,
            "Codigo": 3555901,
            "Nome": "Uru",
            "Uf": "SP"
        },
        {
            "Id": 3895,
            "Codigo": 3556008,
            "Nome": "Urupês",
            "Uf": "SP"
        },
        {
            "Id": 3896,
            "Codigo": 3556107,
            "Nome": "Valentim Gentil",
            "Uf": "SP"
        },
        {
            "Id": 3897,
            "Codigo": 3556206,
            "Nome": "Valinhos",
            "Uf": "SP"
        },
        {
            "Id": 3898,
            "Codigo": 3556305,
            "Nome": "Valparaíso",
            "Uf": "SP"
        },
        {
            "Id": 3899,
            "Codigo": 3556354,
            "Nome": "Vargem",
            "Uf": "SP"
        },
        {
            "Id": 3900,
            "Codigo": 3556404,
            "Nome": "Vargem Grande do Sul",
            "Uf": "SP"
        },
        {
            "Id": 3901,
            "Codigo": 3556453,
            "Nome": "Vargem Grande Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3902,
            "Codigo": 3556503,
            "Nome": "Várzea Paulista",
            "Uf": "SP"
        },
        {
            "Id": 3903,
            "Codigo": 3556602,
            "Nome": "Vera Cruz",
            "Uf": "SP"
        },
        {
            "Id": 3904,
            "Codigo": 3556701,
            "Nome": "Vinhedo",
            "Uf": "SP"
        },
        {
            "Id": 3905,
            "Codigo": 3556800,
            "Nome": "Viradouro",
            "Uf": "SP"
        },
        {
            "Id": 3906,
            "Codigo": 3556909,
            "Nome": "Vista Alegre do Alto",
            "Uf": "SP"
        },
        {
            "Id": 3907,
            "Codigo": 3556958,
            "Nome": "Vitória Brasil",
            "Uf": "SP"
        },
        {
            "Id": 3908,
            "Codigo": 3557006,
            "Nome": "Votorantim",
            "Uf": "SP"
        },
        {
            "Id": 3909,
            "Codigo": 3557105,
            "Nome": "Votuporanga",
            "Uf": "SP"
        },
        {
            "Id": 3910,
            "Codigo": 3557154,
            "Nome": "Zacarias",
            "Uf": "SP"
        },
        {
            "Id": 3911,
            "Codigo": 3557204,
            "Nome": "Chavantes",
            "Uf": "SP"
        },
        {
            "Id": 3912,
            "Codigo": 3557303,
            "Nome": "Estiva Gerbi",
            "Uf": "SP"
        },
        {
            "Id": 3913,
            "Codigo": 4100103,
            "Nome": "Abatiá",
            "Uf": "PR"
        },
        {
            "Id": 3914,
            "Codigo": 4100202,
            "Nome": "Adrianópolis",
            "Uf": "PR"
        },
        {
            "Id": 3915,
            "Codigo": 4100301,
            "Nome": "Agudos do Sul",
            "Uf": "PR"
        },
        {
            "Id": 3916,
            "Codigo": 4100400,
            "Nome": "Almirante Tamandaré",
            "Uf": "PR"
        },
        {
            "Id": 3917,
            "Codigo": 4100459,
            "Nome": "Altamira do Paraná",
            "Uf": "PR"
        },
        {
            "Id": 3918,
            "Codigo": 4100509,
            "Nome": "Altônia",
            "Uf": "PR"
        },
        {
            "Id": 3919,
            "Codigo": 4100608,
            "Nome": "Alto Paraná",
            "Uf": "PR"
        },
        {
            "Id": 3920,
            "Codigo": 4100707,
            "Nome": "Alto Piquiri",
            "Uf": "PR"
        },
        {
            "Id": 3921,
            "Codigo": 4100806,
            "Nome": "Alvorada do Sul",
            "Uf": "PR"
        },
        {
            "Id": 3922,
            "Codigo": 4100905,
            "Nome": "Amaporã",
            "Uf": "PR"
        },
        {
            "Id": 3923,
            "Codigo": 4101002,
            "Nome": "Ampére",
            "Uf": "PR"
        },
        {
            "Id": 3924,
            "Codigo": 4101051,
            "Nome": "Anahy",
            "Uf": "PR"
        },
        {
            "Id": 3925,
            "Codigo": 4101101,
            "Nome": "Andirá",
            "Uf": "PR"
        },
        {
            "Id": 3926,
            "Codigo": 4101150,
            "Nome": "Ângulo",
            "Uf": "PR"
        },
        {
            "Id": 3927,
            "Codigo": 4101200,
            "Nome": "Antonina",
            "Uf": "PR"
        },
        {
            "Id": 3928,
            "Codigo": 4101309,
            "Nome": "Antônio Olinto",
            "Uf": "PR"
        },
        {
            "Id": 3929,
            "Codigo": 4101408,
            "Nome": "Apucarana",
            "Uf": "PR"
        },
        {
            "Id": 3930,
            "Codigo": 4101507,
            "Nome": "Arapongas",
            "Uf": "PR"
        },
        {
            "Id": 3931,
            "Codigo": 4101606,
            "Nome": "Arapoti",
            "Uf": "PR"
        },
        {
            "Id": 3932,
            "Codigo": 4101655,
            "Nome": "Arapuã",
            "Uf": "PR"
        },
        {
            "Id": 3933,
            "Codigo": 4101705,
            "Nome": "Araruna",
            "Uf": "PR"
        },
        {
            "Id": 3934,
            "Codigo": 4101804,
            "Nome": "Araucária",
            "Uf": "PR"
        },
        {
            "Id": 3935,
            "Codigo": 4101853,
            "Nome": "Ariranha do Ivaí",
            "Uf": "PR"
        },
        {
            "Id": 3936,
            "Codigo": 4101903,
            "Nome": "Assaí",
            "Uf": "PR"
        },
        {
            "Id": 3937,
            "Codigo": 4102000,
            "Nome": "Assis Chateaubriand",
            "Uf": "PR"
        },
        {
            "Id": 3938,
            "Codigo": 4102109,
            "Nome": "Astorga",
            "Uf": "PR"
        },
        {
            "Id": 3939,
            "Codigo": 4102208,
            "Nome": "Atalaia",
            "Uf": "PR"
        },
        {
            "Id": 3940,
            "Codigo": 4102307,
            "Nome": "Balsa Nova",
            "Uf": "PR"
        },
        {
            "Id": 3941,
            "Codigo": 4102406,
            "Nome": "Bandeirantes",
            "Uf": "PR"
        },
        {
            "Id": 3942,
            "Codigo": 4102505,
            "Nome": "Barbosa Ferraz",
            "Uf": "PR"
        },
        {
            "Id": 3943,
            "Codigo": 4102604,
            "Nome": "Barracão",
            "Uf": "PR"
        },
        {
            "Id": 3944,
            "Codigo": 4102703,
            "Nome": "Barra do Jacaré",
            "Uf": "PR"
        },
        {
            "Id": 3945,
            "Codigo": 4102752,
            "Nome": "Bela Vista da Caroba",
            "Uf": "PR"
        },
        {
            "Id": 3946,
            "Codigo": 4102802,
            "Nome": "Bela Vista do Paraíso",
            "Uf": "PR"
        },
        {
            "Id": 3947,
            "Codigo": 4102901,
            "Nome": "Bituruna",
            "Uf": "PR"
        },
        {
            "Id": 3948,
            "Codigo": 4103008,
            "Nome": "Boa Esperança",
            "Uf": "PR"
        },
        {
            "Id": 3949,
            "Codigo": 4103024,
            "Nome": "Boa Esperança do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 3950,
            "Codigo": 4103040,
            "Nome": "Boa Ventura de São Roque",
            "Uf": "PR"
        },
        {
            "Id": 3951,
            "Codigo": 4103057,
            "Nome": "Boa Vista da Aparecida",
            "Uf": "PR"
        },
        {
            "Id": 3952,
            "Codigo": 4103107,
            "Nome": "Bocaiúva do Sul",
            "Uf": "PR"
        },
        {
            "Id": 3953,
            "Codigo": 4103156,
            "Nome": "Bom Jesus do Sul",
            "Uf": "PR"
        },
        {
            "Id": 3954,
            "Codigo": 4103206,
            "Nome": "Bom Sucesso",
            "Uf": "PR"
        },
        {
            "Id": 3955,
            "Codigo": 4103222,
            "Nome": "Bom Sucesso do Sul",
            "Uf": "PR"
        },
        {
            "Id": 3956,
            "Codigo": 4103305,
            "Nome": "Borrazópolis",
            "Uf": "PR"
        },
        {
            "Id": 3957,
            "Codigo": 4103354,
            "Nome": "Braganey",
            "Uf": "PR"
        },
        {
            "Id": 3958,
            "Codigo": 4103370,
            "Nome": "Brasilândia do Sul",
            "Uf": "PR"
        },
        {
            "Id": 3959,
            "Codigo": 4103404,
            "Nome": "Cafeara",
            "Uf": "PR"
        },
        {
            "Id": 3960,
            "Codigo": 4103453,
            "Nome": "Cafelândia",
            "Uf": "PR"
        },
        {
            "Id": 3961,
            "Codigo": 4103479,
            "Nome": "Cafezal do Sul",
            "Uf": "PR"
        },
        {
            "Id": 3962,
            "Codigo": 4103503,
            "Nome": "Califórnia",
            "Uf": "PR"
        },
        {
            "Id": 3963,
            "Codigo": 4103602,
            "Nome": "Cambará",
            "Uf": "PR"
        },
        {
            "Id": 3964,
            "Codigo": 4103701,
            "Nome": "Cambé",
            "Uf": "PR"
        },
        {
            "Id": 3965,
            "Codigo": 4103800,
            "Nome": "Cambira",
            "Uf": "PR"
        },
        {
            "Id": 3966,
            "Codigo": 4103909,
            "Nome": "Campina da Lagoa",
            "Uf": "PR"
        },
        {
            "Id": 3967,
            "Codigo": 4103958,
            "Nome": "Campina do Simão",
            "Uf": "PR"
        },
        {
            "Id": 3968,
            "Codigo": 4104006,
            "Nome": "Campina Grande do Sul",
            "Uf": "PR"
        },
        {
            "Id": 3969,
            "Codigo": 4104055,
            "Nome": "Campo Bonito",
            "Uf": "PR"
        },
        {
            "Id": 3970,
            "Codigo": 4104105,
            "Nome": "Campo do Tenente",
            "Uf": "PR"
        },
        {
            "Id": 3971,
            "Codigo": 4104204,
            "Nome": "Campo Largo",
            "Uf": "PR"
        },
        {
            "Id": 3972,
            "Codigo": 4104253,
            "Nome": "Campo Magro",
            "Uf": "PR"
        },
        {
            "Id": 3973,
            "Codigo": 4104303,
            "Nome": "Campo Mourão",
            "Uf": "PR"
        },
        {
            "Id": 3974,
            "Codigo": 4104402,
            "Nome": "Cândido de Abreu",
            "Uf": "PR"
        },
        {
            "Id": 3975,
            "Codigo": 4104428,
            "Nome": "Candói",
            "Uf": "PR"
        },
        {
            "Id": 3976,
            "Codigo": 4104451,
            "Nome": "Cantagalo",
            "Uf": "PR"
        },
        {
            "Id": 3977,
            "Codigo": 4104501,
            "Nome": "Capanema",
            "Uf": "PR"
        },
        {
            "Id": 3978,
            "Codigo": 4104600,
            "Nome": "Capitão Leônidas Marques",
            "Uf": "PR"
        },
        {
            "Id": 3979,
            "Codigo": 4104659,
            "Nome": "Carambeí",
            "Uf": "PR"
        },
        {
            "Id": 3980,
            "Codigo": 4104709,
            "Nome": "Carlópolis",
            "Uf": "PR"
        },
        {
            "Id": 3981,
            "Codigo": 4104808,
            "Nome": "Cascavel",
            "Uf": "PR"
        },
        {
            "Id": 3982,
            "Codigo": 4104907,
            "Nome": "Castro",
            "Uf": "PR"
        },
        {
            "Id": 3983,
            "Codigo": 4105003,
            "Nome": "Catanduvas",
            "Uf": "PR"
        },
        {
            "Id": 3984,
            "Codigo": 4105102,
            "Nome": "Centenário do Sul",
            "Uf": "PR"
        },
        {
            "Id": 3985,
            "Codigo": 4105201,
            "Nome": "Cerro Azul",
            "Uf": "PR"
        },
        {
            "Id": 3986,
            "Codigo": 4105300,
            "Nome": "Céu Azul",
            "Uf": "PR"
        },
        {
            "Id": 3987,
            "Codigo": 4105409,
            "Nome": "Chopinzinho",
            "Uf": "PR"
        },
        {
            "Id": 3988,
            "Codigo": 4105508,
            "Nome": "Cianorte",
            "Uf": "PR"
        },
        {
            "Id": 3989,
            "Codigo": 4105607,
            "Nome": "Cidade Gaúcha",
            "Uf": "PR"
        },
        {
            "Id": 3990,
            "Codigo": 4105706,
            "Nome": "Clevelândia",
            "Uf": "PR"
        },
        {
            "Id": 3991,
            "Codigo": 4105805,
            "Nome": "Colombo",
            "Uf": "PR"
        },
        {
            "Id": 3992,
            "Codigo": 4105904,
            "Nome": "Colorado",
            "Uf": "PR"
        },
        {
            "Id": 3993,
            "Codigo": 4106001,
            "Nome": "Congonhinhas",
            "Uf": "PR"
        },
        {
            "Id": 3994,
            "Codigo": 4106100,
            "Nome": "Conselheiro Mairinck",
            "Uf": "PR"
        },
        {
            "Id": 3995,
            "Codigo": 4106209,
            "Nome": "Contenda",
            "Uf": "PR"
        },
        {
            "Id": 3996,
            "Codigo": 4106308,
            "Nome": "Corbélia",
            "Uf": "PR"
        },
        {
            "Id": 3997,
            "Codigo": 4106407,
            "Nome": "Cornélio Procópio",
            "Uf": "PR"
        },
        {
            "Id": 3998,
            "Codigo": 4106456,
            "Nome": "Coronel Domingos Soares",
            "Uf": "PR"
        },
        {
            "Id": 3999,
            "Codigo": 4106506,
            "Nome": "Coronel Vivida",
            "Uf": "PR"
        },
        {
            "Id": 4000,
            "Codigo": 4106555,
            "Nome": "Corumbataí do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4001,
            "Codigo": 4106571,
            "Nome": "Cruzeiro do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4002,
            "Codigo": 4106605,
            "Nome": "Cruzeiro do Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4003,
            "Codigo": 4106704,
            "Nome": "Cruzeiro do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4004,
            "Codigo": 4106803,
            "Nome": "Cruz Machado",
            "Uf": "PR"
        },
        {
            "Id": 4005,
            "Codigo": 4106852,
            "Nome": "Cruzmaltina",
            "Uf": "PR"
        },
        {
            "Id": 4006,
            "Codigo": 4106902,
            "Nome": "Curitiba",
            "Uf": "PR"
        },
        {
            "Id": 4007,
            "Codigo": 4107009,
            "Nome": "Curiúva",
            "Uf": "PR"
        },
        {
            "Id": 4008,
            "Codigo": 4107108,
            "Nome": "Diamante do Norte",
            "Uf": "PR"
        },
        {
            "Id": 4009,
            "Codigo": 4107124,
            "Nome": "Diamante do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4010,
            "Codigo": 4107157,
            "Nome": "Diamante D'Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4011,
            "Codigo": 4107207,
            "Nome": "Dois Vizinhos",
            "Uf": "PR"
        },
        {
            "Id": 4012,
            "Codigo": 4107256,
            "Nome": "Douradina",
            "Uf": "PR"
        },
        {
            "Id": 4013,
            "Codigo": 4107306,
            "Nome": "Doutor Camargo",
            "Uf": "PR"
        },
        {
            "Id": 4014,
            "Codigo": 4107405,
            "Nome": "Enéas Marques",
            "Uf": "PR"
        },
        {
            "Id": 4015,
            "Codigo": 4107504,
            "Nome": "Engenheiro Beltrão",
            "Uf": "PR"
        },
        {
            "Id": 4016,
            "Codigo": 4107520,
            "Nome": "Esperança Nova",
            "Uf": "PR"
        },
        {
            "Id": 4017,
            "Codigo": 4107538,
            "Nome": "Entre Rios do Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4018,
            "Codigo": 4107546,
            "Nome": "Espigão Alto do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4019,
            "Codigo": 4107553,
            "Nome": "Farol",
            "Uf": "PR"
        },
        {
            "Id": 4020,
            "Codigo": 4107603,
            "Nome": "Faxinal",
            "Uf": "PR"
        },
        {
            "Id": 4021,
            "Codigo": 4107652,
            "Nome": "Fazenda Rio Grande",
            "Uf": "PR"
        },
        {
            "Id": 4022,
            "Codigo": 4107702,
            "Nome": "Fênix",
            "Uf": "PR"
        },
        {
            "Id": 4023,
            "Codigo": 4107736,
            "Nome": "Fernandes Pinheiro",
            "Uf": "PR"
        },
        {
            "Id": 4024,
            "Codigo": 4107751,
            "Nome": "Figueira",
            "Uf": "PR"
        },
        {
            "Id": 4025,
            "Codigo": 4107801,
            "Nome": "Floraí",
            "Uf": "PR"
        },
        {
            "Id": 4026,
            "Codigo": 4107850,
            "Nome": "Flor da Serra do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4027,
            "Codigo": 4107900,
            "Nome": "Floresta",
            "Uf": "PR"
        },
        {
            "Id": 4028,
            "Codigo": 4108007,
            "Nome": "Florestópolis",
            "Uf": "PR"
        },
        {
            "Id": 4029,
            "Codigo": 4108106,
            "Nome": "Flórida",
            "Uf": "PR"
        },
        {
            "Id": 4030,
            "Codigo": 4108205,
            "Nome": "Formosa do Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4031,
            "Codigo": 4108304,
            "Nome": "Foz do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4032,
            "Codigo": 4108320,
            "Nome": "Francisco Alves",
            "Uf": "PR"
        },
        {
            "Id": 4033,
            "Codigo": 4108403,
            "Nome": "Francisco Beltrão",
            "Uf": "PR"
        },
        {
            "Id": 4034,
            "Codigo": 4108452,
            "Nome": "Foz do Jordão",
            "Uf": "PR"
        },
        {
            "Id": 4035,
            "Codigo": 4108502,
            "Nome": "General Carneiro",
            "Uf": "PR"
        },
        {
            "Id": 4036,
            "Codigo": 4108551,
            "Nome": "Godoy Moreira",
            "Uf": "PR"
        },
        {
            "Id": 4037,
            "Codigo": 4108601,
            "Nome": "Goioerê",
            "Uf": "PR"
        },
        {
            "Id": 4038,
            "Codigo": 4108650,
            "Nome": "Goioxim",
            "Uf": "PR"
        },
        {
            "Id": 4039,
            "Codigo": 4108700,
            "Nome": "Grandes Rios",
            "Uf": "PR"
        },
        {
            "Id": 4040,
            "Codigo": 4108809,
            "Nome": "Guaíra",
            "Uf": "PR"
        },
        {
            "Id": 4041,
            "Codigo": 4108908,
            "Nome": "Guairaçá",
            "Uf": "PR"
        },
        {
            "Id": 4042,
            "Codigo": 4108957,
            "Nome": "Guamiranga",
            "Uf": "PR"
        },
        {
            "Id": 4043,
            "Codigo": 4109005,
            "Nome": "Guapirama",
            "Uf": "PR"
        },
        {
            "Id": 4044,
            "Codigo": 4109104,
            "Nome": "Guaporema",
            "Uf": "PR"
        },
        {
            "Id": 4045,
            "Codigo": 4109203,
            "Nome": "Guaraci",
            "Uf": "PR"
        },
        {
            "Id": 4046,
            "Codigo": 4109302,
            "Nome": "Guaraniaçu",
            "Uf": "PR"
        },
        {
            "Id": 4047,
            "Codigo": 4109401,
            "Nome": "Guarapuava",
            "Uf": "PR"
        },
        {
            "Id": 4048,
            "Codigo": 4109500,
            "Nome": "Guaraqueçaba",
            "Uf": "PR"
        },
        {
            "Id": 4049,
            "Codigo": 4109609,
            "Nome": "Guaratuba",
            "Uf": "PR"
        },
        {
            "Id": 4050,
            "Codigo": 4109658,
            "Nome": "Honório Serpa",
            "Uf": "PR"
        },
        {
            "Id": 4051,
            "Codigo": 4109708,
            "Nome": "Ibaiti",
            "Uf": "PR"
        },
        {
            "Id": 4052,
            "Codigo": 4109757,
            "Nome": "Ibema",
            "Uf": "PR"
        },
        {
            "Id": 4053,
            "Codigo": 4109807,
            "Nome": "Ibiporã",
            "Uf": "PR"
        },
        {
            "Id": 4054,
            "Codigo": 4109906,
            "Nome": "Icaraíma",
            "Uf": "PR"
        },
        {
            "Id": 4055,
            "Codigo": 4110003,
            "Nome": "Iguaraçu",
            "Uf": "PR"
        },
        {
            "Id": 4056,
            "Codigo": 4110052,
            "Nome": "Iguatu",
            "Uf": "PR"
        },
        {
            "Id": 4057,
            "Codigo": 4110078,
            "Nome": "Imbaú",
            "Uf": "PR"
        },
        {
            "Id": 4058,
            "Codigo": 4110102,
            "Nome": "Imbituva",
            "Uf": "PR"
        },
        {
            "Id": 4059,
            "Codigo": 4110201,
            "Nome": "Inácio Martins",
            "Uf": "PR"
        },
        {
            "Id": 4060,
            "Codigo": 4110300,
            "Nome": "Inajá",
            "Uf": "PR"
        },
        {
            "Id": 4061,
            "Codigo": 4110409,
            "Nome": "Indianópolis",
            "Uf": "PR"
        },
        {
            "Id": 4062,
            "Codigo": 4110508,
            "Nome": "Ipiranga",
            "Uf": "PR"
        },
        {
            "Id": 4063,
            "Codigo": 4110607,
            "Nome": "Iporã",
            "Uf": "PR"
        },
        {
            "Id": 4064,
            "Codigo": 4110656,
            "Nome": "Iracema do Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4065,
            "Codigo": 4110706,
            "Nome": "Irati",
            "Uf": "PR"
        },
        {
            "Id": 4066,
            "Codigo": 4110805,
            "Nome": "Iretama",
            "Uf": "PR"
        },
        {
            "Id": 4067,
            "Codigo": 4110904,
            "Nome": "Itaguajé",
            "Uf": "PR"
        },
        {
            "Id": 4068,
            "Codigo": 4110953,
            "Nome": "Itaipulândia",
            "Uf": "PR"
        },
        {
            "Id": 4069,
            "Codigo": 4111001,
            "Nome": "Itambaracá",
            "Uf": "PR"
        },
        {
            "Id": 4070,
            "Codigo": 4111100,
            "Nome": "Itambé",
            "Uf": "PR"
        },
        {
            "Id": 4071,
            "Codigo": 4111209,
            "Nome": "Itapejara D'Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4072,
            "Codigo": 4111258,
            "Nome": "Itaperuçu",
            "Uf": "PR"
        },
        {
            "Id": 4073,
            "Codigo": 4111308,
            "Nome": "Itaúna do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4074,
            "Codigo": 4111407,
            "Nome": "Ivaí",
            "Uf": "PR"
        },
        {
            "Id": 4075,
            "Codigo": 4111506,
            "Nome": "Ivaiporã",
            "Uf": "PR"
        },
        {
            "Id": 4076,
            "Codigo": 4111555,
            "Nome": "Ivaté",
            "Uf": "PR"
        },
        {
            "Id": 4077,
            "Codigo": 4111605,
            "Nome": "Ivatuba",
            "Uf": "PR"
        },
        {
            "Id": 4078,
            "Codigo": 4111704,
            "Nome": "Jaboti",
            "Uf": "PR"
        },
        {
            "Id": 4079,
            "Codigo": 4111803,
            "Nome": "Jacarezinho",
            "Uf": "PR"
        },
        {
            "Id": 4080,
            "Codigo": 4111902,
            "Nome": "Jaguapitã",
            "Uf": "PR"
        },
        {
            "Id": 4081,
            "Codigo": 4112009,
            "Nome": "Jaguariaíva",
            "Uf": "PR"
        },
        {
            "Id": 4082,
            "Codigo": 4112108,
            "Nome": "Jandaia do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4083,
            "Codigo": 4112207,
            "Nome": "Janiópolis",
            "Uf": "PR"
        },
        {
            "Id": 4084,
            "Codigo": 4112306,
            "Nome": "Japira",
            "Uf": "PR"
        },
        {
            "Id": 4085,
            "Codigo": 4112405,
            "Nome": "Japurá",
            "Uf": "PR"
        },
        {
            "Id": 4086,
            "Codigo": 4112504,
            "Nome": "Jardim Alegre",
            "Uf": "PR"
        },
        {
            "Id": 4087,
            "Codigo": 4112603,
            "Nome": "Jardim Olinda",
            "Uf": "PR"
        },
        {
            "Id": 4088,
            "Codigo": 4112702,
            "Nome": "Jataizinho",
            "Uf": "PR"
        },
        {
            "Id": 4089,
            "Codigo": 4112751,
            "Nome": "Jesuítas",
            "Uf": "PR"
        },
        {
            "Id": 4090,
            "Codigo": 4112801,
            "Nome": "Joaquim Távora",
            "Uf": "PR"
        },
        {
            "Id": 4091,
            "Codigo": 4112900,
            "Nome": "Jundiaí do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4092,
            "Codigo": 4112959,
            "Nome": "Juranda",
            "Uf": "PR"
        },
        {
            "Id": 4093,
            "Codigo": 4113007,
            "Nome": "Jussara",
            "Uf": "PR"
        },
        {
            "Id": 4094,
            "Codigo": 4113106,
            "Nome": "Kaloré",
            "Uf": "PR"
        },
        {
            "Id": 4095,
            "Codigo": 4113205,
            "Nome": "Lapa",
            "Uf": "PR"
        },
        {
            "Id": 4096,
            "Codigo": 4113254,
            "Nome": "Laranjal",
            "Uf": "PR"
        },
        {
            "Id": 4097,
            "Codigo": 4113304,
            "Nome": "Laranjeiras do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4098,
            "Codigo": 4113403,
            "Nome": "Leópolis",
            "Uf": "PR"
        },
        {
            "Id": 4099,
            "Codigo": 4113429,
            "Nome": "Lidianópolis",
            "Uf": "PR"
        },
        {
            "Id": 4100,
            "Codigo": 4113452,
            "Nome": "Lindoeste",
            "Uf": "PR"
        },
        {
            "Id": 4101,
            "Codigo": 4113502,
            "Nome": "Loanda",
            "Uf": "PR"
        },
        {
            "Id": 4102,
            "Codigo": 4113601,
            "Nome": "Lobato",
            "Uf": "PR"
        },
        {
            "Id": 4103,
            "Codigo": 4113700,
            "Nome": "Londrina",
            "Uf": "PR"
        },
        {
            "Id": 4104,
            "Codigo": 4113734,
            "Nome": "Luiziana",
            "Uf": "PR"
        },
        {
            "Id": 4105,
            "Codigo": 4113759,
            "Nome": "Lunardelli",
            "Uf": "PR"
        },
        {
            "Id": 4106,
            "Codigo": 4113809,
            "Nome": "Lupionópolis",
            "Uf": "PR"
        },
        {
            "Id": 4107,
            "Codigo": 4113908,
            "Nome": "Mallet",
            "Uf": "PR"
        },
        {
            "Id": 4108,
            "Codigo": 4114005,
            "Nome": "Mamborê",
            "Uf": "PR"
        },
        {
            "Id": 4109,
            "Codigo": 4114104,
            "Nome": "Mandaguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4110,
            "Codigo": 4114203,
            "Nome": "Mandaguari",
            "Uf": "PR"
        },
        {
            "Id": 4111,
            "Codigo": 4114302,
            "Nome": "Mandirituba",
            "Uf": "PR"
        },
        {
            "Id": 4112,
            "Codigo": 4114351,
            "Nome": "Manfrinópolis",
            "Uf": "PR"
        },
        {
            "Id": 4113,
            "Codigo": 4114401,
            "Nome": "Mangueirinha",
            "Uf": "PR"
        },
        {
            "Id": 4114,
            "Codigo": 4114500,
            "Nome": "Manoel Ribas",
            "Uf": "PR"
        },
        {
            "Id": 4115,
            "Codigo": 4114609,
            "Nome": "Marechal Cândido Rondon",
            "Uf": "PR"
        },
        {
            "Id": 4116,
            "Codigo": 4114708,
            "Nome": "Maria Helena",
            "Uf": "PR"
        },
        {
            "Id": 4117,
            "Codigo": 4114807,
            "Nome": "Marialva",
            "Uf": "PR"
        },
        {
            "Id": 4118,
            "Codigo": 4114906,
            "Nome": "Marilândia do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4119,
            "Codigo": 4115002,
            "Nome": "Marilena",
            "Uf": "PR"
        },
        {
            "Id": 4120,
            "Codigo": 4115101,
            "Nome": "Mariluz",
            "Uf": "PR"
        },
        {
            "Id": 4121,
            "Codigo": 4115200,
            "Nome": "Maringá",
            "Uf": "PR"
        },
        {
            "Id": 4122,
            "Codigo": 4115309,
            "Nome": "Mariópolis",
            "Uf": "PR"
        },
        {
            "Id": 4123,
            "Codigo": 4115358,
            "Nome": "Maripá",
            "Uf": "PR"
        },
        {
            "Id": 4124,
            "Codigo": 4115408,
            "Nome": "Marmeleiro",
            "Uf": "PR"
        },
        {
            "Id": 4125,
            "Codigo": 4115457,
            "Nome": "Marquinho",
            "Uf": "PR"
        },
        {
            "Id": 4126,
            "Codigo": 4115507,
            "Nome": "Marumbi",
            "Uf": "PR"
        },
        {
            "Id": 4127,
            "Codigo": 4115606,
            "Nome": "Matelândia",
            "Uf": "PR"
        },
        {
            "Id": 4128,
            "Codigo": 4115705,
            "Nome": "Matinhos",
            "Uf": "PR"
        },
        {
            "Id": 4129,
            "Codigo": 4115739,
            "Nome": "Mato Rico",
            "Uf": "PR"
        },
        {
            "Id": 4130,
            "Codigo": 4115754,
            "Nome": "Mauá da Serra",
            "Uf": "PR"
        },
        {
            "Id": 4131,
            "Codigo": 4115804,
            "Nome": "Medianeira",
            "Uf": "PR"
        },
        {
            "Id": 4132,
            "Codigo": 4115853,
            "Nome": "Mercedes",
            "Uf": "PR"
        },
        {
            "Id": 4133,
            "Codigo": 4115903,
            "Nome": "Mirador",
            "Uf": "PR"
        },
        {
            "Id": 4134,
            "Codigo": 4116000,
            "Nome": "Miraselva",
            "Uf": "PR"
        },
        {
            "Id": 4135,
            "Codigo": 4116059,
            "Nome": "Missal",
            "Uf": "PR"
        },
        {
            "Id": 4136,
            "Codigo": 4116109,
            "Nome": "Moreira Sales",
            "Uf": "PR"
        },
        {
            "Id": 4137,
            "Codigo": 4116208,
            "Nome": "Morretes",
            "Uf": "PR"
        },
        {
            "Id": 4138,
            "Codigo": 4116307,
            "Nome": "Munhoz de Melo",
            "Uf": "PR"
        },
        {
            "Id": 4139,
            "Codigo": 4116406,
            "Nome": "Nossa Senhora das Graças",
            "Uf": "PR"
        },
        {
            "Id": 4140,
            "Codigo": 4116505,
            "Nome": "Nova Aliança do Ivaí",
            "Uf": "PR"
        },
        {
            "Id": 4141,
            "Codigo": 4116604,
            "Nome": "Nova América da Colina",
            "Uf": "PR"
        },
        {
            "Id": 4142,
            "Codigo": 4116703,
            "Nome": "Nova Aurora",
            "Uf": "PR"
        },
        {
            "Id": 4143,
            "Codigo": 4116802,
            "Nome": "Nova Cantu",
            "Uf": "PR"
        },
        {
            "Id": 4144,
            "Codigo": 4116901,
            "Nome": "Nova Esperança",
            "Uf": "PR"
        },
        {
            "Id": 4145,
            "Codigo": 4116950,
            "Nome": "Nova Esperança do Sudoeste",
            "Uf": "PR"
        },
        {
            "Id": 4146,
            "Codigo": 4117008,
            "Nome": "Nova Fátima",
            "Uf": "PR"
        },
        {
            "Id": 4147,
            "Codigo": 4117057,
            "Nome": "Nova Laranjeiras",
            "Uf": "PR"
        },
        {
            "Id": 4148,
            "Codigo": 4117107,
            "Nome": "Nova Londrina",
            "Uf": "PR"
        },
        {
            "Id": 4149,
            "Codigo": 4117206,
            "Nome": "Nova Olímpia",
            "Uf": "PR"
        },
        {
            "Id": 4150,
            "Codigo": 4117214,
            "Nome": "Nova Santa Bárbara",
            "Uf": "PR"
        },
        {
            "Id": 4151,
            "Codigo": 4117222,
            "Nome": "Nova Santa Rosa",
            "Uf": "PR"
        },
        {
            "Id": 4152,
            "Codigo": 4117255,
            "Nome": "Nova Prata do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4153,
            "Codigo": 4117271,
            "Nome": "Nova Tebas",
            "Uf": "PR"
        },
        {
            "Id": 4154,
            "Codigo": 4117297,
            "Nome": "Novo Itacolomi",
            "Uf": "PR"
        },
        {
            "Id": 4155,
            "Codigo": 4117305,
            "Nome": "Ortigueira",
            "Uf": "PR"
        },
        {
            "Id": 4156,
            "Codigo": 4117404,
            "Nome": "Ourizona",
            "Uf": "PR"
        },
        {
            "Id": 4157,
            "Codigo": 4117453,
            "Nome": "Ouro Verde do Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4158,
            "Codigo": 4117503,
            "Nome": "Paiçandu",
            "Uf": "PR"
        },
        {
            "Id": 4159,
            "Codigo": 4117602,
            "Nome": "Palmas",
            "Uf": "PR"
        },
        {
            "Id": 4160,
            "Codigo": 4117701,
            "Nome": "Palmeira",
            "Uf": "PR"
        },
        {
            "Id": 4161,
            "Codigo": 4117800,
            "Nome": "Palmital",
            "Uf": "PR"
        },
        {
            "Id": 4162,
            "Codigo": 4117909,
            "Nome": "Palotina",
            "Uf": "PR"
        },
        {
            "Id": 4163,
            "Codigo": 4118006,
            "Nome": "Paraíso do Norte",
            "Uf": "PR"
        },
        {
            "Id": 4164,
            "Codigo": 4118105,
            "Nome": "Paranacity",
            "Uf": "PR"
        },
        {
            "Id": 4165,
            "Codigo": 4118204,
            "Nome": "Paranaguá",
            "Uf": "PR"
        },
        {
            "Id": 4166,
            "Codigo": 4118303,
            "Nome": "Paranapoema",
            "Uf": "PR"
        },
        {
            "Id": 4167,
            "Codigo": 4118402,
            "Nome": "Paranavaí",
            "Uf": "PR"
        },
        {
            "Id": 4168,
            "Codigo": 4118451,
            "Nome": "Pato Bragado",
            "Uf": "PR"
        },
        {
            "Id": 4169,
            "Codigo": 4118501,
            "Nome": "Pato Branco",
            "Uf": "PR"
        },
        {
            "Id": 4170,
            "Codigo": 4118600,
            "Nome": "Paula Freitas",
            "Uf": "PR"
        },
        {
            "Id": 4171,
            "Codigo": 4118709,
            "Nome": "Paulo Frontin",
            "Uf": "PR"
        },
        {
            "Id": 4172,
            "Codigo": 4118808,
            "Nome": "Peabiru",
            "Uf": "PR"
        },
        {
            "Id": 4173,
            "Codigo": 4118857,
            "Nome": "Perobal",
            "Uf": "PR"
        },
        {
            "Id": 4174,
            "Codigo": 4118907,
            "Nome": "Pérola",
            "Uf": "PR"
        },
        {
            "Id": 4175,
            "Codigo": 4119004,
            "Nome": "Pérola D'Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4176,
            "Codigo": 4119103,
            "Nome": "Piên",
            "Uf": "PR"
        },
        {
            "Id": 4177,
            "Codigo": 4119152,
            "Nome": "Pinhais",
            "Uf": "PR"
        },
        {
            "Id": 4178,
            "Codigo": 4119202,
            "Nome": "Pinhalão",
            "Uf": "PR"
        },
        {
            "Id": 4179,
            "Codigo": 4119251,
            "Nome": "Pinhal de São Bento",
            "Uf": "PR"
        },
        {
            "Id": 4180,
            "Codigo": 4119301,
            "Nome": "Pinhão",
            "Uf": "PR"
        },
        {
            "Id": 4181,
            "Codigo": 4119400,
            "Nome": "Piraí do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4182,
            "Codigo": 4119509,
            "Nome": "Piraquara",
            "Uf": "PR"
        },
        {
            "Id": 4183,
            "Codigo": 4119608,
            "Nome": "Pitanga",
            "Uf": "PR"
        },
        {
            "Id": 4184,
            "Codigo": 4119657,
            "Nome": "Pitangueiras",
            "Uf": "PR"
        },
        {
            "Id": 4185,
            "Codigo": 4119707,
            "Nome": "Planaltina do Paraná",
            "Uf": "PR"
        },
        {
            "Id": 4186,
            "Codigo": 4119806,
            "Nome": "Planalto",
            "Uf": "PR"
        },
        {
            "Id": 4187,
            "Codigo": 4119905,
            "Nome": "Ponta Grossa",
            "Uf": "PR"
        },
        {
            "Id": 4188,
            "Codigo": 4119954,
            "Nome": "Pontal do Paraná",
            "Uf": "PR"
        },
        {
            "Id": 4189,
            "Codigo": 4120002,
            "Nome": "Porecatu",
            "Uf": "PR"
        },
        {
            "Id": 4190,
            "Codigo": 4120101,
            "Nome": "Porto Amazonas",
            "Uf": "PR"
        },
        {
            "Id": 4191,
            "Codigo": 4120150,
            "Nome": "Porto Barreiro",
            "Uf": "PR"
        },
        {
            "Id": 4192,
            "Codigo": 4120200,
            "Nome": "Porto Rico",
            "Uf": "PR"
        },
        {
            "Id": 4193,
            "Codigo": 4120309,
            "Nome": "Porto Vitória",
            "Uf": "PR"
        },
        {
            "Id": 4194,
            "Codigo": 4120333,
            "Nome": "Prado Ferreira",
            "Uf": "PR"
        },
        {
            "Id": 4195,
            "Codigo": 4120358,
            "Nome": "Pranchita",
            "Uf": "PR"
        },
        {
            "Id": 4196,
            "Codigo": 4120408,
            "Nome": "Presidente Castelo Branco",
            "Uf": "PR"
        },
        {
            "Id": 4197,
            "Codigo": 4120507,
            "Nome": "Primeiro de Maio",
            "Uf": "PR"
        },
        {
            "Id": 4198,
            "Codigo": 4120606,
            "Nome": "Prudentópolis",
            "Uf": "PR"
        },
        {
            "Id": 4199,
            "Codigo": 4120655,
            "Nome": "Quarto Centenário",
            "Uf": "PR"
        },
        {
            "Id": 4200,
            "Codigo": 4120705,
            "Nome": "Quatiguá",
            "Uf": "PR"
        },
        {
            "Id": 4201,
            "Codigo": 4120804,
            "Nome": "Quatro Barras",
            "Uf": "PR"
        },
        {
            "Id": 4202,
            "Codigo": 4120853,
            "Nome": "Quatro Pontes",
            "Uf": "PR"
        },
        {
            "Id": 4203,
            "Codigo": 4120903,
            "Nome": "Quedas do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4204,
            "Codigo": 4121000,
            "Nome": "Querência do Norte",
            "Uf": "PR"
        },
        {
            "Id": 4205,
            "Codigo": 4121109,
            "Nome": "Quinta do Sol",
            "Uf": "PR"
        },
        {
            "Id": 4206,
            "Codigo": 4121208,
            "Nome": "Quitandinha",
            "Uf": "PR"
        },
        {
            "Id": 4207,
            "Codigo": 4121257,
            "Nome": "Ramilândia",
            "Uf": "PR"
        },
        {
            "Id": 4208,
            "Codigo": 4121307,
            "Nome": "Rancho Alegre",
            "Uf": "PR"
        },
        {
            "Id": 4209,
            "Codigo": 4121356,
            "Nome": "Rancho Alegre D'Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4210,
            "Codigo": 4121406,
            "Nome": "Realeza",
            "Uf": "PR"
        },
        {
            "Id": 4211,
            "Codigo": 4121505,
            "Nome": "Rebouças",
            "Uf": "PR"
        },
        {
            "Id": 4212,
            "Codigo": 4121604,
            "Nome": "Renascença",
            "Uf": "PR"
        },
        {
            "Id": 4213,
            "Codigo": 4121703,
            "Nome": "Reserva",
            "Uf": "PR"
        },
        {
            "Id": 4214,
            "Codigo": 4121752,
            "Nome": "Reserva do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4215,
            "Codigo": 4121802,
            "Nome": "Ribeirão Claro",
            "Uf": "PR"
        },
        {
            "Id": 4216,
            "Codigo": 4121901,
            "Nome": "Ribeirão do Pinhal",
            "Uf": "PR"
        },
        {
            "Id": 4217,
            "Codigo": 4122008,
            "Nome": "Rio Azul",
            "Uf": "PR"
        },
        {
            "Id": 4218,
            "Codigo": 4122107,
            "Nome": "Rio Bom",
            "Uf": "PR"
        },
        {
            "Id": 4219,
            "Codigo": 4122156,
            "Nome": "Rio Bonito do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4220,
            "Codigo": 4122172,
            "Nome": "Rio Branco do Ivaí",
            "Uf": "PR"
        },
        {
            "Id": 4221,
            "Codigo": 4122206,
            "Nome": "Rio Branco do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4222,
            "Codigo": 4122305,
            "Nome": "Rio Negro",
            "Uf": "PR"
        },
        {
            "Id": 4223,
            "Codigo": 4122404,
            "Nome": "Rolândia",
            "Uf": "PR"
        },
        {
            "Id": 4224,
            "Codigo": 4122503,
            "Nome": "Roncador",
            "Uf": "PR"
        },
        {
            "Id": 4225,
            "Codigo": 4122602,
            "Nome": "Rondon",
            "Uf": "PR"
        },
        {
            "Id": 4226,
            "Codigo": 4122651,
            "Nome": "Rosário do Ivaí",
            "Uf": "PR"
        },
        {
            "Id": 4227,
            "Codigo": 4122701,
            "Nome": "Sabáudia",
            "Uf": "PR"
        },
        {
            "Id": 4228,
            "Codigo": 4122800,
            "Nome": "Salgado Filho",
            "Uf": "PR"
        },
        {
            "Id": 4229,
            "Codigo": 4122909,
            "Nome": "Salto do Itararé",
            "Uf": "PR"
        },
        {
            "Id": 4230,
            "Codigo": 4123006,
            "Nome": "Salto do Lontra",
            "Uf": "PR"
        },
        {
            "Id": 4231,
            "Codigo": 4123105,
            "Nome": "Santa Amélia",
            "Uf": "PR"
        },
        {
            "Id": 4232,
            "Codigo": 4123204,
            "Nome": "Santa Cecília do Pavão",
            "Uf": "PR"
        },
        {
            "Id": 4233,
            "Codigo": 4123303,
            "Nome": "Santa Cruz de Monte Castelo",
            "Uf": "PR"
        },
        {
            "Id": 4234,
            "Codigo": 4123402,
            "Nome": "Santa Fé",
            "Uf": "PR"
        },
        {
            "Id": 4235,
            "Codigo": 4123501,
            "Nome": "Santa Helena",
            "Uf": "PR"
        },
        {
            "Id": 4236,
            "Codigo": 4123600,
            "Nome": "Santa Inês",
            "Uf": "PR"
        },
        {
            "Id": 4237,
            "Codigo": 4123709,
            "Nome": "Santa Isabel do Ivaí",
            "Uf": "PR"
        },
        {
            "Id": 4238,
            "Codigo": 4123808,
            "Nome": "Santa Izabel do Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4239,
            "Codigo": 4123824,
            "Nome": "Santa Lúcia",
            "Uf": "PR"
        },
        {
            "Id": 4240,
            "Codigo": 4123857,
            "Nome": "Santa Maria do Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4241,
            "Codigo": 4123907,
            "Nome": "Santa Mariana",
            "Uf": "PR"
        },
        {
            "Id": 4242,
            "Codigo": 4123956,
            "Nome": "Santa Mônica",
            "Uf": "PR"
        },
        {
            "Id": 4243,
            "Codigo": 4124004,
            "Nome": "Santana do Itararé",
            "Uf": "PR"
        },
        {
            "Id": 4244,
            "Codigo": 4124020,
            "Nome": "Santa Tereza do Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4245,
            "Codigo": 4124053,
            "Nome": "Santa Terezinha de Itaipu",
            "Uf": "PR"
        },
        {
            "Id": 4246,
            "Codigo": 4124103,
            "Nome": "Santo Antônio da Platina",
            "Uf": "PR"
        },
        {
            "Id": 4247,
            "Codigo": 4124202,
            "Nome": "Santo Antônio do Caiuá",
            "Uf": "PR"
        },
        {
            "Id": 4248,
            "Codigo": 4124301,
            "Nome": "Santo Antônio do Paraíso",
            "Uf": "PR"
        },
        {
            "Id": 4249,
            "Codigo": 4124400,
            "Nome": "Santo Antônio do Sudoeste",
            "Uf": "PR"
        },
        {
            "Id": 4250,
            "Codigo": 4124509,
            "Nome": "Santo Inácio",
            "Uf": "PR"
        },
        {
            "Id": 4251,
            "Codigo": 4124608,
            "Nome": "São Carlos do Ivaí",
            "Uf": "PR"
        },
        {
            "Id": 4252,
            "Codigo": 4124707,
            "Nome": "São Jerônimo da Serra",
            "Uf": "PR"
        },
        {
            "Id": 4253,
            "Codigo": 4124806,
            "Nome": "São João",
            "Uf": "PR"
        },
        {
            "Id": 4254,
            "Codigo": 4124905,
            "Nome": "São João do Caiuá",
            "Uf": "PR"
        },
        {
            "Id": 4255,
            "Codigo": 4125001,
            "Nome": "São João do Ivaí",
            "Uf": "PR"
        },
        {
            "Id": 4256,
            "Codigo": 4125100,
            "Nome": "São João do Triunfo",
            "Uf": "PR"
        },
        {
            "Id": 4257,
            "Codigo": 4125209,
            "Nome": "São Jorge D'Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4258,
            "Codigo": 4125308,
            "Nome": "São Jorge do Ivaí",
            "Uf": "PR"
        },
        {
            "Id": 4259,
            "Codigo": 4125357,
            "Nome": "São Jorge do Patrocínio",
            "Uf": "PR"
        },
        {
            "Id": 4260,
            "Codigo": 4125407,
            "Nome": "São José da Boa Vista",
            "Uf": "PR"
        },
        {
            "Id": 4261,
            "Codigo": 4125456,
            "Nome": "São José das Palmeiras",
            "Uf": "PR"
        },
        {
            "Id": 4262,
            "Codigo": 4125506,
            "Nome": "São José dos Pinhais",
            "Uf": "PR"
        },
        {
            "Id": 4263,
            "Codigo": 4125555,
            "Nome": "São Manoel do Paraná",
            "Uf": "PR"
        },
        {
            "Id": 4264,
            "Codigo": 4125605,
            "Nome": "São Mateus do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4265,
            "Codigo": 4125704,
            "Nome": "São Miguel do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4266,
            "Codigo": 4125753,
            "Nome": "São Pedro do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4267,
            "Codigo": 4125803,
            "Nome": "São Pedro do Ivaí",
            "Uf": "PR"
        },
        {
            "Id": 4268,
            "Codigo": 4125902,
            "Nome": "São Pedro do Paraná",
            "Uf": "PR"
        },
        {
            "Id": 4269,
            "Codigo": 4126009,
            "Nome": "São Sebastião da Amoreira",
            "Uf": "PR"
        },
        {
            "Id": 4270,
            "Codigo": 4126108,
            "Nome": "São Tomé",
            "Uf": "PR"
        },
        {
            "Id": 4271,
            "Codigo": 4126207,
            "Nome": "Sapopema",
            "Uf": "PR"
        },
        {
            "Id": 4272,
            "Codigo": 4126256,
            "Nome": "Sarandi",
            "Uf": "PR"
        },
        {
            "Id": 4273,
            "Codigo": 4126272,
            "Nome": "Saudade do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4274,
            "Codigo": 4126306,
            "Nome": "Sengés",
            "Uf": "PR"
        },
        {
            "Id": 4275,
            "Codigo": 4126355,
            "Nome": "Serranópolis do Iguaçu",
            "Uf": "PR"
        },
        {
            "Id": 4276,
            "Codigo": 4126405,
            "Nome": "Sertaneja",
            "Uf": "PR"
        },
        {
            "Id": 4277,
            "Codigo": 4126504,
            "Nome": "Sertanópolis",
            "Uf": "PR"
        },
        {
            "Id": 4278,
            "Codigo": 4126603,
            "Nome": "Siqueira Campos",
            "Uf": "PR"
        },
        {
            "Id": 4279,
            "Codigo": 4126652,
            "Nome": "Sulina",
            "Uf": "PR"
        },
        {
            "Id": 4280,
            "Codigo": 4126678,
            "Nome": "Tamarana",
            "Uf": "PR"
        },
        {
            "Id": 4281,
            "Codigo": 4126702,
            "Nome": "Tamboara",
            "Uf": "PR"
        },
        {
            "Id": 4282,
            "Codigo": 4126801,
            "Nome": "Tapejara",
            "Uf": "PR"
        },
        {
            "Id": 4283,
            "Codigo": 4126900,
            "Nome": "Tapira",
            "Uf": "PR"
        },
        {
            "Id": 4284,
            "Codigo": 4127007,
            "Nome": "Teixeira Soares",
            "Uf": "PR"
        },
        {
            "Id": 4285,
            "Codigo": 4127106,
            "Nome": "Telêmaco Borba",
            "Uf": "PR"
        },
        {
            "Id": 4286,
            "Codigo": 4127205,
            "Nome": "Terra Boa",
            "Uf": "PR"
        },
        {
            "Id": 4287,
            "Codigo": 4127304,
            "Nome": "Terra Rica",
            "Uf": "PR"
        },
        {
            "Id": 4288,
            "Codigo": 4127403,
            "Nome": "Terra Roxa",
            "Uf": "PR"
        },
        {
            "Id": 4289,
            "Codigo": 4127502,
            "Nome": "Tibagi",
            "Uf": "PR"
        },
        {
            "Id": 4290,
            "Codigo": 4127601,
            "Nome": "Tijucas do Sul",
            "Uf": "PR"
        },
        {
            "Id": 4291,
            "Codigo": 4127700,
            "Nome": "Toledo",
            "Uf": "PR"
        },
        {
            "Id": 4292,
            "Codigo": 4127809,
            "Nome": "Tomazina",
            "Uf": "PR"
        },
        {
            "Id": 4293,
            "Codigo": 4127858,
            "Nome": "Três Barras do Paraná",
            "Uf": "PR"
        },
        {
            "Id": 4294,
            "Codigo": 4127882,
            "Nome": "Tunas do Paraná",
            "Uf": "PR"
        },
        {
            "Id": 4295,
            "Codigo": 4127908,
            "Nome": "Tuneiras do Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4296,
            "Codigo": 4127957,
            "Nome": "Tupãssi",
            "Uf": "PR"
        },
        {
            "Id": 4297,
            "Codigo": 4127965,
            "Nome": "Turvo",
            "Uf": "PR"
        },
        {
            "Id": 4298,
            "Codigo": 4128005,
            "Nome": "Ubiratã",
            "Uf": "PR"
        },
        {
            "Id": 4299,
            "Codigo": 4128104,
            "Nome": "Umuarama",
            "Uf": "PR"
        },
        {
            "Id": 4300,
            "Codigo": 4128203,
            "Nome": "União da Vitória",
            "Uf": "PR"
        },
        {
            "Id": 4301,
            "Codigo": 4128302,
            "Nome": "Uniflor",
            "Uf": "PR"
        },
        {
            "Id": 4302,
            "Codigo": 4128401,
            "Nome": "Uraí",
            "Uf": "PR"
        },
        {
            "Id": 4303,
            "Codigo": 4128500,
            "Nome": "Wenceslau Braz",
            "Uf": "PR"
        },
        {
            "Id": 4304,
            "Codigo": 4128534,
            "Nome": "Ventania",
            "Uf": "PR"
        },
        {
            "Id": 4305,
            "Codigo": 4128559,
            "Nome": "Vera Cruz do Oeste",
            "Uf": "PR"
        },
        {
            "Id": 4306,
            "Codigo": 4128609,
            "Nome": "Verê",
            "Uf": "PR"
        },
        {
            "Id": 4307,
            "Codigo": 4128625,
            "Nome": "Alto Paraíso",
            "Uf": "PR"
        },
        {
            "Id": 4308,
            "Codigo": 4128633,
            "Nome": "Doutor Ulysses",
            "Uf": "PR"
        },
        {
            "Id": 4309,
            "Codigo": 4128658,
            "Nome": "Virmond",
            "Uf": "PR"
        },
        {
            "Id": 4310,
            "Codigo": 4128708,
            "Nome": "Vitorino",
            "Uf": "PR"
        },
        {
            "Id": 4311,
            "Codigo": 4128807,
            "Nome": "Xambrê",
            "Uf": "PR"
        },
        {
            "Id": 4312,
            "Codigo": 4200051,
            "Nome": "Abdon Batista",
            "Uf": "SC"
        },
        {
            "Id": 4313,
            "Codigo": 4200101,
            "Nome": "Abelardo Luz",
            "Uf": "SC"
        },
        {
            "Id": 4314,
            "Codigo": 4200200,
            "Nome": "Agrolândia",
            "Uf": "SC"
        },
        {
            "Id": 4315,
            "Codigo": 4200309,
            "Nome": "Agronômica",
            "Uf": "SC"
        },
        {
            "Id": 4316,
            "Codigo": 4200408,
            "Nome": "Água Doce",
            "Uf": "SC"
        },
        {
            "Id": 4317,
            "Codigo": 4200507,
            "Nome": "Águas de Chapecó",
            "Uf": "SC"
        },
        {
            "Id": 4318,
            "Codigo": 4200556,
            "Nome": "Águas Frias",
            "Uf": "SC"
        },
        {
            "Id": 4319,
            "Codigo": 4200606,
            "Nome": "Águas Mornas",
            "Uf": "SC"
        },
        {
            "Id": 4320,
            "Codigo": 4200705,
            "Nome": "Alfredo Wagner",
            "Uf": "SC"
        },
        {
            "Id": 4321,
            "Codigo": 4200754,
            "Nome": "Alto Bela Vista",
            "Uf": "SC"
        },
        {
            "Id": 4322,
            "Codigo": 4200804,
            "Nome": "Anchieta",
            "Uf": "SC"
        },
        {
            "Id": 4323,
            "Codigo": 4200903,
            "Nome": "Angelina",
            "Uf": "SC"
        },
        {
            "Id": 4324,
            "Codigo": 4201000,
            "Nome": "Anita Garibaldi",
            "Uf": "SC"
        },
        {
            "Id": 4325,
            "Codigo": 4201109,
            "Nome": "Anitápolis",
            "Uf": "SC"
        },
        {
            "Id": 4326,
            "Codigo": 4201208,
            "Nome": "Antônio Carlos",
            "Uf": "SC"
        },
        {
            "Id": 4327,
            "Codigo": 4201257,
            "Nome": "Apiúna",
            "Uf": "SC"
        },
        {
            "Id": 4328,
            "Codigo": 4201273,
            "Nome": "Arabutã",
            "Uf": "SC"
        },
        {
            "Id": 4329,
            "Codigo": 4201307,
            "Nome": "Araquari",
            "Uf": "SC"
        },
        {
            "Id": 4330,
            "Codigo": 4201406,
            "Nome": "Araranguá",
            "Uf": "SC"
        },
        {
            "Id": 4331,
            "Codigo": 4201505,
            "Nome": "Armazém",
            "Uf": "SC"
        },
        {
            "Id": 4332,
            "Codigo": 4201604,
            "Nome": "Arroio Trinta",
            "Uf": "SC"
        },
        {
            "Id": 4333,
            "Codigo": 4201653,
            "Nome": "Arvoredo",
            "Uf": "SC"
        },
        {
            "Id": 4334,
            "Codigo": 4201703,
            "Nome": "Ascurra",
            "Uf": "SC"
        },
        {
            "Id": 4335,
            "Codigo": 4201802,
            "Nome": "Atalanta",
            "Uf": "SC"
        },
        {
            "Id": 4336,
            "Codigo": 4201901,
            "Nome": "Aurora",
            "Uf": "SC"
        },
        {
            "Id": 4337,
            "Codigo": 4201950,
            "Nome": "Balneário Arroio do Silva",
            "Uf": "SC"
        },
        {
            "Id": 4338,
            "Codigo": 4202008,
            "Nome": "Balneário Camboriú",
            "Uf": "SC"
        },
        {
            "Id": 4339,
            "Codigo": 4202057,
            "Nome": "Balneário Barra do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4340,
            "Codigo": 4202073,
            "Nome": "Balneário Gaivota",
            "Uf": "SC"
        },
        {
            "Id": 4341,
            "Codigo": 4202081,
            "Nome": "Bandeirante",
            "Uf": "SC"
        },
        {
            "Id": 4342,
            "Codigo": 4202099,
            "Nome": "Barra Bonita",
            "Uf": "SC"
        },
        {
            "Id": 4343,
            "Codigo": 4202107,
            "Nome": "Barra Velha",
            "Uf": "SC"
        },
        {
            "Id": 4344,
            "Codigo": 4202131,
            "Nome": "Bela Vista do Toldo",
            "Uf": "SC"
        },
        {
            "Id": 4345,
            "Codigo": 4202156,
            "Nome": "Belmonte",
            "Uf": "SC"
        },
        {
            "Id": 4346,
            "Codigo": 4202206,
            "Nome": "Benedito Novo",
            "Uf": "SC"
        },
        {
            "Id": 4347,
            "Codigo": 4202305,
            "Nome": "Biguaçu",
            "Uf": "SC"
        },
        {
            "Id": 4348,
            "Codigo": 4202404,
            "Nome": "Blumenau",
            "Uf": "SC"
        },
        {
            "Id": 4349,
            "Codigo": 4202438,
            "Nome": "Bocaina do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4350,
            "Codigo": 4202453,
            "Nome": "Bombinhas",
            "Uf": "SC"
        },
        {
            "Id": 4351,
            "Codigo": 4202503,
            "Nome": "Bom Jardim da Serra",
            "Uf": "SC"
        },
        {
            "Id": 4352,
            "Codigo": 4202537,
            "Nome": "Bom Jesus",
            "Uf": "SC"
        },
        {
            "Id": 4353,
            "Codigo": 4202578,
            "Nome": "Bom Jesus do Oeste",
            "Uf": "SC"
        },
        {
            "Id": 4354,
            "Codigo": 4202602,
            "Nome": "Bom Retiro",
            "Uf": "SC"
        },
        {
            "Id": 4355,
            "Codigo": 4202701,
            "Nome": "Botuverá",
            "Uf": "SC"
        },
        {
            "Id": 4356,
            "Codigo": 4202800,
            "Nome": "Braço do Norte",
            "Uf": "SC"
        },
        {
            "Id": 4357,
            "Codigo": 4202859,
            "Nome": "Braço do Trombudo",
            "Uf": "SC"
        },
        {
            "Id": 4358,
            "Codigo": 4202875,
            "Nome": "Brunópolis",
            "Uf": "SC"
        },
        {
            "Id": 4359,
            "Codigo": 4202909,
            "Nome": "Brusque",
            "Uf": "SC"
        },
        {
            "Id": 4360,
            "Codigo": 4203006,
            "Nome": "Caçador",
            "Uf": "SC"
        },
        {
            "Id": 4361,
            "Codigo": 4203105,
            "Nome": "Caibi",
            "Uf": "SC"
        },
        {
            "Id": 4362,
            "Codigo": 4203154,
            "Nome": "Calmon",
            "Uf": "SC"
        },
        {
            "Id": 4363,
            "Codigo": 4203204,
            "Nome": "Camboriú",
            "Uf": "SC"
        },
        {
            "Id": 4364,
            "Codigo": 4203253,
            "Nome": "Capão Alto",
            "Uf": "SC"
        },
        {
            "Id": 4365,
            "Codigo": 4203303,
            "Nome": "Campo Alegre",
            "Uf": "SC"
        },
        {
            "Id": 4366,
            "Codigo": 4203402,
            "Nome": "Campo Belo do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4367,
            "Codigo": 4203501,
            "Nome": "Campo Erê",
            "Uf": "SC"
        },
        {
            "Id": 4368,
            "Codigo": 4203600,
            "Nome": "Campos Novos",
            "Uf": "SC"
        },
        {
            "Id": 4369,
            "Codigo": 4203709,
            "Nome": "Canelinha",
            "Uf": "SC"
        },
        {
            "Id": 4370,
            "Codigo": 4203808,
            "Nome": "Canoinhas",
            "Uf": "SC"
        },
        {
            "Id": 4371,
            "Codigo": 4203907,
            "Nome": "Capinzal",
            "Uf": "SC"
        },
        {
            "Id": 4372,
            "Codigo": 4203956,
            "Nome": "Capivari de Baixo",
            "Uf": "SC"
        },
        {
            "Id": 4373,
            "Codigo": 4204004,
            "Nome": "Catanduvas",
            "Uf": "SC"
        },
        {
            "Id": 4374,
            "Codigo": 4204103,
            "Nome": "Caxambu do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4375,
            "Codigo": 4204152,
            "Nome": "Celso Ramos",
            "Uf": "SC"
        },
        {
            "Id": 4376,
            "Codigo": 4204178,
            "Nome": "Cerro Negro",
            "Uf": "SC"
        },
        {
            "Id": 4377,
            "Codigo": 4204194,
            "Nome": "Chapadão do Lageado",
            "Uf": "SC"
        },
        {
            "Id": 4378,
            "Codigo": 4204202,
            "Nome": "Chapecó",
            "Uf": "SC"
        },
        {
            "Id": 4379,
            "Codigo": 4204251,
            "Nome": "Cocal do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4380,
            "Codigo": 4204301,
            "Nome": "Concórdia",
            "Uf": "SC"
        },
        {
            "Id": 4381,
            "Codigo": 4204350,
            "Nome": "Cordilheira Alta",
            "Uf": "SC"
        },
        {
            "Id": 4382,
            "Codigo": 4204400,
            "Nome": "Coronel Freitas",
            "Uf": "SC"
        },
        {
            "Id": 4383,
            "Codigo": 4204459,
            "Nome": "Coronel Martins",
            "Uf": "SC"
        },
        {
            "Id": 4384,
            "Codigo": 4204509,
            "Nome": "Corupá",
            "Uf": "SC"
        },
        {
            "Id": 4385,
            "Codigo": 4204558,
            "Nome": "Correia Pinto",
            "Uf": "SC"
        },
        {
            "Id": 4386,
            "Codigo": 4204608,
            "Nome": "Criciúma",
            "Uf": "SC"
        },
        {
            "Id": 4387,
            "Codigo": 4204707,
            "Nome": "Cunha Porã",
            "Uf": "SC"
        },
        {
            "Id": 4388,
            "Codigo": 4204756,
            "Nome": "Cunhataí",
            "Uf": "SC"
        },
        {
            "Id": 4389,
            "Codigo": 4204806,
            "Nome": "Curitibanos",
            "Uf": "SC"
        },
        {
            "Id": 4390,
            "Codigo": 4204905,
            "Nome": "Descanso",
            "Uf": "SC"
        },
        {
            "Id": 4391,
            "Codigo": 4205001,
            "Nome": "Dionísio Cerqueira",
            "Uf": "SC"
        },
        {
            "Id": 4392,
            "Codigo": 4205100,
            "Nome": "Dona Emma",
            "Uf": "SC"
        },
        {
            "Id": 4393,
            "Codigo": 4205159,
            "Nome": "Doutor Pedrinho",
            "Uf": "SC"
        },
        {
            "Id": 4394,
            "Codigo": 4205175,
            "Nome": "Entre Rios",
            "Uf": "SC"
        },
        {
            "Id": 4395,
            "Codigo": 4205191,
            "Nome": "Ermo",
            "Uf": "SC"
        },
        {
            "Id": 4396,
            "Codigo": 4205209,
            "Nome": "Erval Velho",
            "Uf": "SC"
        },
        {
            "Id": 4397,
            "Codigo": 4205308,
            "Nome": "Faxinal dos Guedes",
            "Uf": "SC"
        },
        {
            "Id": 4398,
            "Codigo": 4205357,
            "Nome": "Flor do Sertão",
            "Uf": "SC"
        },
        {
            "Id": 4399,
            "Codigo": 4205407,
            "Nome": "Florianópolis",
            "Uf": "SC"
        },
        {
            "Id": 4400,
            "Codigo": 4205431,
            "Nome": "Formosa do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4401,
            "Codigo": 4205456,
            "Nome": "Forquilhinha",
            "Uf": "SC"
        },
        {
            "Id": 4402,
            "Codigo": 4205506,
            "Nome": "Fraiburgo",
            "Uf": "SC"
        },
        {
            "Id": 4403,
            "Codigo": 4205555,
            "Nome": "Frei Rogério",
            "Uf": "SC"
        },
        {
            "Id": 4404,
            "Codigo": 4205605,
            "Nome": "Galvão",
            "Uf": "SC"
        },
        {
            "Id": 4405,
            "Codigo": 4205704,
            "Nome": "Garopaba",
            "Uf": "SC"
        },
        {
            "Id": 4406,
            "Codigo": 4205803,
            "Nome": "Garuva",
            "Uf": "SC"
        },
        {
            "Id": 4407,
            "Codigo": 4205902,
            "Nome": "Gaspar",
            "Uf": "SC"
        },
        {
            "Id": 4408,
            "Codigo": 4206009,
            "Nome": "Governador Celso Ramos",
            "Uf": "SC"
        },
        {
            "Id": 4409,
            "Codigo": 4206108,
            "Nome": "Grão Pará",
            "Uf": "SC"
        },
        {
            "Id": 4410,
            "Codigo": 4206207,
            "Nome": "Gravatal",
            "Uf": "SC"
        },
        {
            "Id": 4411,
            "Codigo": 4206306,
            "Nome": "Guabiruba",
            "Uf": "SC"
        },
        {
            "Id": 4412,
            "Codigo": 4206405,
            "Nome": "Guaraciaba",
            "Uf": "SC"
        },
        {
            "Id": 4413,
            "Codigo": 4206504,
            "Nome": "Guaramirim",
            "Uf": "SC"
        },
        {
            "Id": 4414,
            "Codigo": 4206603,
            "Nome": "Guarujá do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4415,
            "Codigo": 4206652,
            "Nome": "Guatambú",
            "Uf": "SC"
        },
        {
            "Id": 4416,
            "Codigo": 4206702,
            "Nome": "Herval D'Oeste",
            "Uf": "SC"
        },
        {
            "Id": 4417,
            "Codigo": 4206751,
            "Nome": "Ibiam",
            "Uf": "SC"
        },
        {
            "Id": 4418,
            "Codigo": 4206801,
            "Nome": "Ibicaré",
            "Uf": "SC"
        },
        {
            "Id": 4419,
            "Codigo": 4206900,
            "Nome": "Ibirama",
            "Uf": "SC"
        },
        {
            "Id": 4420,
            "Codigo": 4207007,
            "Nome": "Içara",
            "Uf": "SC"
        },
        {
            "Id": 4421,
            "Codigo": 4207106,
            "Nome": "Ilhota",
            "Uf": "SC"
        },
        {
            "Id": 4422,
            "Codigo": 4207205,
            "Nome": "Imaruí",
            "Uf": "SC"
        },
        {
            "Id": 4423,
            "Codigo": 4207304,
            "Nome": "Imbituba",
            "Uf": "SC"
        },
        {
            "Id": 4424,
            "Codigo": 4207403,
            "Nome": "Imbuia",
            "Uf": "SC"
        },
        {
            "Id": 4425,
            "Codigo": 4207502,
            "Nome": "Indaial",
            "Uf": "SC"
        },
        {
            "Id": 4426,
            "Codigo": 4207577,
            "Nome": "Iomerê",
            "Uf": "SC"
        },
        {
            "Id": 4427,
            "Codigo": 4207601,
            "Nome": "Ipira",
            "Uf": "SC"
        },
        {
            "Id": 4428,
            "Codigo": 4207650,
            "Nome": "Iporã do Oeste",
            "Uf": "SC"
        },
        {
            "Id": 4429,
            "Codigo": 4207684,
            "Nome": "Ipuaçu",
            "Uf": "SC"
        },
        {
            "Id": 4430,
            "Codigo": 4207700,
            "Nome": "Ipumirim",
            "Uf": "SC"
        },
        {
            "Id": 4431,
            "Codigo": 4207759,
            "Nome": "Iraceminha",
            "Uf": "SC"
        },
        {
            "Id": 4432,
            "Codigo": 4207809,
            "Nome": "Irani",
            "Uf": "SC"
        },
        {
            "Id": 4433,
            "Codigo": 4207858,
            "Nome": "Irati",
            "Uf": "SC"
        },
        {
            "Id": 4434,
            "Codigo": 4207908,
            "Nome": "Irineópolis",
            "Uf": "SC"
        },
        {
            "Id": 4435,
            "Codigo": 4208005,
            "Nome": "Itá",
            "Uf": "SC"
        },
        {
            "Id": 4436,
            "Codigo": 4208104,
            "Nome": "Itaiópolis",
            "Uf": "SC"
        },
        {
            "Id": 4437,
            "Codigo": 4208203,
            "Nome": "Itajaí",
            "Uf": "SC"
        },
        {
            "Id": 4438,
            "Codigo": 4208302,
            "Nome": "Itapema",
            "Uf": "SC"
        },
        {
            "Id": 4439,
            "Codigo": 4208401,
            "Nome": "Itapiranga",
            "Uf": "SC"
        },
        {
            "Id": 4440,
            "Codigo": 4208450,
            "Nome": "Itapoá",
            "Uf": "SC"
        },
        {
            "Id": 4441,
            "Codigo": 4208500,
            "Nome": "Ituporanga",
            "Uf": "SC"
        },
        {
            "Id": 4442,
            "Codigo": 4208609,
            "Nome": "Jaborá",
            "Uf": "SC"
        },
        {
            "Id": 4443,
            "Codigo": 4208708,
            "Nome": "Jacinto Machado",
            "Uf": "SC"
        },
        {
            "Id": 4444,
            "Codigo": 4208807,
            "Nome": "Jaguaruna",
            "Uf": "SC"
        },
        {
            "Id": 4445,
            "Codigo": 4208906,
            "Nome": "Jaraguá do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4446,
            "Codigo": 4208955,
            "Nome": "Jardinópolis",
            "Uf": "SC"
        },
        {
            "Id": 4447,
            "Codigo": 4209003,
            "Nome": "Joaçaba",
            "Uf": "SC"
        },
        {
            "Id": 4448,
            "Codigo": 4209102,
            "Nome": "Joinville",
            "Uf": "SC"
        },
        {
            "Id": 4449,
            "Codigo": 4209151,
            "Nome": "José Boiteux",
            "Uf": "SC"
        },
        {
            "Id": 4450,
            "Codigo": 4209177,
            "Nome": "Jupiá",
            "Uf": "SC"
        },
        {
            "Id": 4451,
            "Codigo": 4209201,
            "Nome": "Lacerdópolis",
            "Uf": "SC"
        },
        {
            "Id": 4452,
            "Codigo": 4209300,
            "Nome": "Lages",
            "Uf": "SC"
        },
        {
            "Id": 4453,
            "Codigo": 4209409,
            "Nome": "Laguna",
            "Uf": "SC"
        },
        {
            "Id": 4454,
            "Codigo": 4209458,
            "Nome": "Lajeado Grande",
            "Uf": "SC"
        },
        {
            "Id": 4455,
            "Codigo": 4209508,
            "Nome": "Laurentino",
            "Uf": "SC"
        },
        {
            "Id": 4456,
            "Codigo": 4209607,
            "Nome": "Lauro Muller",
            "Uf": "SC"
        },
        {
            "Id": 4457,
            "Codigo": 4209706,
            "Nome": "Lebon Régis",
            "Uf": "SC"
        },
        {
            "Id": 4458,
            "Codigo": 4209805,
            "Nome": "Leoberto Leal",
            "Uf": "SC"
        },
        {
            "Id": 4459,
            "Codigo": 4209854,
            "Nome": "Lindóia do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4460,
            "Codigo": 4209904,
            "Nome": "Lontras",
            "Uf": "SC"
        },
        {
            "Id": 4461,
            "Codigo": 4210001,
            "Nome": "Luiz Alves",
            "Uf": "SC"
        },
        {
            "Id": 4462,
            "Codigo": 4210035,
            "Nome": "Luzerna",
            "Uf": "SC"
        },
        {
            "Id": 4463,
            "Codigo": 4210050,
            "Nome": "Macieira",
            "Uf": "SC"
        },
        {
            "Id": 4464,
            "Codigo": 4210100,
            "Nome": "Mafra",
            "Uf": "SC"
        },
        {
            "Id": 4465,
            "Codigo": 4210209,
            "Nome": "Major Gercino",
            "Uf": "SC"
        },
        {
            "Id": 4466,
            "Codigo": 4210308,
            "Nome": "Major Vieira",
            "Uf": "SC"
        },
        {
            "Id": 4467,
            "Codigo": 4210407,
            "Nome": "Maracajá",
            "Uf": "SC"
        },
        {
            "Id": 4468,
            "Codigo": 4210506,
            "Nome": "Maravilha",
            "Uf": "SC"
        },
        {
            "Id": 4469,
            "Codigo": 4210555,
            "Nome": "Marema",
            "Uf": "SC"
        },
        {
            "Id": 4470,
            "Codigo": 4210605,
            "Nome": "Massaranduba",
            "Uf": "SC"
        },
        {
            "Id": 4471,
            "Codigo": 4210704,
            "Nome": "Matos Costa",
            "Uf": "SC"
        },
        {
            "Id": 4472,
            "Codigo": 4210803,
            "Nome": "Meleiro",
            "Uf": "SC"
        },
        {
            "Id": 4473,
            "Codigo": 4210852,
            "Nome": "Mirim Doce",
            "Uf": "SC"
        },
        {
            "Id": 4474,
            "Codigo": 4210902,
            "Nome": "Modelo",
            "Uf": "SC"
        },
        {
            "Id": 4475,
            "Codigo": 4211009,
            "Nome": "Mondaí",
            "Uf": "SC"
        },
        {
            "Id": 4476,
            "Codigo": 4211058,
            "Nome": "Monte Carlo",
            "Uf": "SC"
        },
        {
            "Id": 4477,
            "Codigo": 4211108,
            "Nome": "Monte Castelo",
            "Uf": "SC"
        },
        {
            "Id": 4478,
            "Codigo": 4211207,
            "Nome": "Morro da Fumaça",
            "Uf": "SC"
        },
        {
            "Id": 4479,
            "Codigo": 4211256,
            "Nome": "Morro Grande",
            "Uf": "SC"
        },
        {
            "Id": 4480,
            "Codigo": 4211306,
            "Nome": "Navegantes",
            "Uf": "SC"
        },
        {
            "Id": 4481,
            "Codigo": 4211405,
            "Nome": "Nova Erechim",
            "Uf": "SC"
        },
        {
            "Id": 4482,
            "Codigo": 4211454,
            "Nome": "Nova Itaberaba",
            "Uf": "SC"
        },
        {
            "Id": 4483,
            "Codigo": 4211504,
            "Nome": "Nova Trento",
            "Uf": "SC"
        },
        {
            "Id": 4484,
            "Codigo": 4211603,
            "Nome": "Nova Veneza",
            "Uf": "SC"
        },
        {
            "Id": 4485,
            "Codigo": 4211652,
            "Nome": "Novo Horizonte",
            "Uf": "SC"
        },
        {
            "Id": 4486,
            "Codigo": 4211702,
            "Nome": "Orleans",
            "Uf": "SC"
        },
        {
            "Id": 4487,
            "Codigo": 4211751,
            "Nome": "Otacílio Costa",
            "Uf": "SC"
        },
        {
            "Id": 4488,
            "Codigo": 4211801,
            "Nome": "Ouro",
            "Uf": "SC"
        },
        {
            "Id": 4489,
            "Codigo": 4211850,
            "Nome": "Ouro Verde",
            "Uf": "SC"
        },
        {
            "Id": 4490,
            "Codigo": 4211876,
            "Nome": "Paial",
            "Uf": "SC"
        },
        {
            "Id": 4491,
            "Codigo": 4211892,
            "Nome": "Painel",
            "Uf": "SC"
        },
        {
            "Id": 4492,
            "Codigo": 4211900,
            "Nome": "Palhoça",
            "Uf": "SC"
        },
        {
            "Id": 4493,
            "Codigo": 4212007,
            "Nome": "Palma Sola",
            "Uf": "SC"
        },
        {
            "Id": 4494,
            "Codigo": 4212056,
            "Nome": "Palmeira",
            "Uf": "SC"
        },
        {
            "Id": 4495,
            "Codigo": 4212106,
            "Nome": "Palmitos",
            "Uf": "SC"
        },
        {
            "Id": 4496,
            "Codigo": 4212205,
            "Nome": "Papanduva",
            "Uf": "SC"
        },
        {
            "Id": 4497,
            "Codigo": 4212239,
            "Nome": "Paraíso",
            "Uf": "SC"
        },
        {
            "Id": 4498,
            "Codigo": 4212254,
            "Nome": "Passo de Torres",
            "Uf": "SC"
        },
        {
            "Id": 4499,
            "Codigo": 4212270,
            "Nome": "Passos Maia",
            "Uf": "SC"
        },
        {
            "Id": 4500,
            "Codigo": 4212304,
            "Nome": "Paulo Lopes",
            "Uf": "SC"
        },
        {
            "Id": 4501,
            "Codigo": 4212403,
            "Nome": "Pedras Grandes",
            "Uf": "SC"
        },
        {
            "Id": 4502,
            "Codigo": 4212502,
            "Nome": "Penha",
            "Uf": "SC"
        },
        {
            "Id": 4503,
            "Codigo": 4212601,
            "Nome": "Peritiba",
            "Uf": "SC"
        },
        {
            "Id": 4504,
            "Codigo": 4212650,
            "Nome": "Pescaria Brava",
            "Uf": "SC"
        },
        {
            "Id": 4505,
            "Codigo": 4212700,
            "Nome": "Petrolândia",
            "Uf": "SC"
        },
        {
            "Id": 4506,
            "Codigo": 4212809,
            "Nome": "Balneário Piçarras",
            "Uf": "SC"
        },
        {
            "Id": 4507,
            "Codigo": 4212908,
            "Nome": "Pinhalzinho",
            "Uf": "SC"
        },
        {
            "Id": 4508,
            "Codigo": 4213005,
            "Nome": "Pinheiro Preto",
            "Uf": "SC"
        },
        {
            "Id": 4509,
            "Codigo": 4213104,
            "Nome": "Piratuba",
            "Uf": "SC"
        },
        {
            "Id": 4510,
            "Codigo": 4213153,
            "Nome": "Planalto Alegre",
            "Uf": "SC"
        },
        {
            "Id": 4511,
            "Codigo": 4213203,
            "Nome": "Pomerode",
            "Uf": "SC"
        },
        {
            "Id": 4512,
            "Codigo": 4213302,
            "Nome": "Ponte Alta",
            "Uf": "SC"
        },
        {
            "Id": 4513,
            "Codigo": 4213351,
            "Nome": "Ponte Alta do Norte",
            "Uf": "SC"
        },
        {
            "Id": 4514,
            "Codigo": 4213401,
            "Nome": "Ponte Serrada",
            "Uf": "SC"
        },
        {
            "Id": 4515,
            "Codigo": 4213500,
            "Nome": "Porto Belo",
            "Uf": "SC"
        },
        {
            "Id": 4516,
            "Codigo": 4213609,
            "Nome": "Porto União",
            "Uf": "SC"
        },
        {
            "Id": 4517,
            "Codigo": 4213708,
            "Nome": "Pouso Redondo",
            "Uf": "SC"
        },
        {
            "Id": 4518,
            "Codigo": 4213807,
            "Nome": "Praia Grande",
            "Uf": "SC"
        },
        {
            "Id": 4519,
            "Codigo": 4213906,
            "Nome": "Presidente Castello Branco",
            "Uf": "SC"
        },
        {
            "Id": 4520,
            "Codigo": 4214003,
            "Nome": "Presidente Getúlio",
            "Uf": "SC"
        },
        {
            "Id": 4521,
            "Codigo": 4214102,
            "Nome": "Presidente Nereu",
            "Uf": "SC"
        },
        {
            "Id": 4522,
            "Codigo": 4214151,
            "Nome": "Princesa",
            "Uf": "SC"
        },
        {
            "Id": 4523,
            "Codigo": 4214201,
            "Nome": "Quilombo",
            "Uf": "SC"
        },
        {
            "Id": 4524,
            "Codigo": 4214300,
            "Nome": "Rancho Queimado",
            "Uf": "SC"
        },
        {
            "Id": 4525,
            "Codigo": 4214409,
            "Nome": "Rio das Antas",
            "Uf": "SC"
        },
        {
            "Id": 4526,
            "Codigo": 4214508,
            "Nome": "Rio do Campo",
            "Uf": "SC"
        },
        {
            "Id": 4527,
            "Codigo": 4214607,
            "Nome": "Rio do Oeste",
            "Uf": "SC"
        },
        {
            "Id": 4528,
            "Codigo": 4214706,
            "Nome": "Rio dos Cedros",
            "Uf": "SC"
        },
        {
            "Id": 4529,
            "Codigo": 4214805,
            "Nome": "Rio do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4530,
            "Codigo": 4214904,
            "Nome": "Rio Fortuna",
            "Uf": "SC"
        },
        {
            "Id": 4531,
            "Codigo": 4215000,
            "Nome": "Rio Negrinho",
            "Uf": "SC"
        },
        {
            "Id": 4532,
            "Codigo": 4215059,
            "Nome": "Rio Rufino",
            "Uf": "SC"
        },
        {
            "Id": 4533,
            "Codigo": 4215075,
            "Nome": "Riqueza",
            "Uf": "SC"
        },
        {
            "Id": 4534,
            "Codigo": 4215109,
            "Nome": "Rodeio",
            "Uf": "SC"
        },
        {
            "Id": 4535,
            "Codigo": 4215208,
            "Nome": "Romelândia",
            "Uf": "SC"
        },
        {
            "Id": 4536,
            "Codigo": 4215307,
            "Nome": "Salete",
            "Uf": "SC"
        },
        {
            "Id": 4537,
            "Codigo": 4215356,
            "Nome": "Saltinho",
            "Uf": "SC"
        },
        {
            "Id": 4538,
            "Codigo": 4215406,
            "Nome": "Salto Veloso",
            "Uf": "SC"
        },
        {
            "Id": 4539,
            "Codigo": 4215455,
            "Nome": "Sangão",
            "Uf": "SC"
        },
        {
            "Id": 4540,
            "Codigo": 4215505,
            "Nome": "Santa Cecília",
            "Uf": "SC"
        },
        {
            "Id": 4541,
            "Codigo": 4215554,
            "Nome": "Santa Helena",
            "Uf": "SC"
        },
        {
            "Id": 4542,
            "Codigo": 4215604,
            "Nome": "Santa Rosa de Lima",
            "Uf": "SC"
        },
        {
            "Id": 4543,
            "Codigo": 4215653,
            "Nome": "Santa Rosa do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4544,
            "Codigo": 4215679,
            "Nome": "Santa Terezinha",
            "Uf": "SC"
        },
        {
            "Id": 4545,
            "Codigo": 4215687,
            "Nome": "Santa Terezinha do Progresso",
            "Uf": "SC"
        },
        {
            "Id": 4546,
            "Codigo": 4215695,
            "Nome": "Santiago do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4547,
            "Codigo": 4215703,
            "Nome": "Santo Amaro da Imperatriz",
            "Uf": "SC"
        },
        {
            "Id": 4548,
            "Codigo": 4215752,
            "Nome": "São Bernardino",
            "Uf": "SC"
        },
        {
            "Id": 4549,
            "Codigo": 4215802,
            "Nome": "São Bento do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4550,
            "Codigo": 4215901,
            "Nome": "São Bonifácio",
            "Uf": "SC"
        },
        {
            "Id": 4551,
            "Codigo": 4216008,
            "Nome": "São Carlos",
            "Uf": "SC"
        },
        {
            "Id": 4552,
            "Codigo": 4216057,
            "Nome": "São Cristovão do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4553,
            "Codigo": 4216107,
            "Nome": "São Domingos",
            "Uf": "SC"
        },
        {
            "Id": 4554,
            "Codigo": 4216206,
            "Nome": "São Francisco do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4555,
            "Codigo": 4216255,
            "Nome": "São João do Oeste",
            "Uf": "SC"
        },
        {
            "Id": 4556,
            "Codigo": 4216305,
            "Nome": "São João Batista",
            "Uf": "SC"
        },
        {
            "Id": 4557,
            "Codigo": 4216354,
            "Nome": "São João do Itaperiú",
            "Uf": "SC"
        },
        {
            "Id": 4558,
            "Codigo": 4216404,
            "Nome": "São João do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4559,
            "Codigo": 4216503,
            "Nome": "São Joaquim",
            "Uf": "SC"
        },
        {
            "Id": 4560,
            "Codigo": 4216602,
            "Nome": "São José",
            "Uf": "SC"
        },
        {
            "Id": 4561,
            "Codigo": 4216701,
            "Nome": "São José do Cedro",
            "Uf": "SC"
        },
        {
            "Id": 4562,
            "Codigo": 4216800,
            "Nome": "São José do Cerrito",
            "Uf": "SC"
        },
        {
            "Id": 4563,
            "Codigo": 4216909,
            "Nome": "São Lourenço do Oeste",
            "Uf": "SC"
        },
        {
            "Id": 4564,
            "Codigo": 4217006,
            "Nome": "São Ludgero",
            "Uf": "SC"
        },
        {
            "Id": 4565,
            "Codigo": 4217105,
            "Nome": "São Martinho",
            "Uf": "SC"
        },
        {
            "Id": 4566,
            "Codigo": 4217154,
            "Nome": "São Miguel da Boa Vista",
            "Uf": "SC"
        },
        {
            "Id": 4567,
            "Codigo": 4217204,
            "Nome": "São Miguel do Oeste",
            "Uf": "SC"
        },
        {
            "Id": 4568,
            "Codigo": 4217253,
            "Nome": "São Pedro de Alcântara",
            "Uf": "SC"
        },
        {
            "Id": 4569,
            "Codigo": 4217303,
            "Nome": "Saudades",
            "Uf": "SC"
        },
        {
            "Id": 4570,
            "Codigo": 4217402,
            "Nome": "Schroeder",
            "Uf": "SC"
        },
        {
            "Id": 4571,
            "Codigo": 4217501,
            "Nome": "Seara",
            "Uf": "SC"
        },
        {
            "Id": 4572,
            "Codigo": 4217550,
            "Nome": "Serra Alta",
            "Uf": "SC"
        },
        {
            "Id": 4573,
            "Codigo": 4217600,
            "Nome": "Siderópolis",
            "Uf": "SC"
        },
        {
            "Id": 4574,
            "Codigo": 4217709,
            "Nome": "Sombrio",
            "Uf": "SC"
        },
        {
            "Id": 4575,
            "Codigo": 4217758,
            "Nome": "Sul Brasil",
            "Uf": "SC"
        },
        {
            "Id": 4576,
            "Codigo": 4217808,
            "Nome": "Taió",
            "Uf": "SC"
        },
        {
            "Id": 4577,
            "Codigo": 4217907,
            "Nome": "Tangará",
            "Uf": "SC"
        },
        {
            "Id": 4578,
            "Codigo": 4217956,
            "Nome": "Tigrinhos",
            "Uf": "SC"
        },
        {
            "Id": 4579,
            "Codigo": 4218004,
            "Nome": "Tijucas",
            "Uf": "SC"
        },
        {
            "Id": 4580,
            "Codigo": 4218103,
            "Nome": "Timbé do Sul",
            "Uf": "SC"
        },
        {
            "Id": 4581,
            "Codigo": 4218202,
            "Nome": "Timbó",
            "Uf": "SC"
        },
        {
            "Id": 4582,
            "Codigo": 4218251,
            "Nome": "Timbó Grande",
            "Uf": "SC"
        },
        {
            "Id": 4583,
            "Codigo": 4218301,
            "Nome": "Três Barras",
            "Uf": "SC"
        },
        {
            "Id": 4584,
            "Codigo": 4218350,
            "Nome": "Treviso",
            "Uf": "SC"
        },
        {
            "Id": 4585,
            "Codigo": 4218400,
            "Nome": "Treze de Maio",
            "Uf": "SC"
        },
        {
            "Id": 4586,
            "Codigo": 4218509,
            "Nome": "Treze Tílias",
            "Uf": "SC"
        },
        {
            "Id": 4587,
            "Codigo": 4218608,
            "Nome": "Trombudo Central",
            "Uf": "SC"
        },
        {
            "Id": 4588,
            "Codigo": 4218707,
            "Nome": "Tubarão",
            "Uf": "SC"
        },
        {
            "Id": 4589,
            "Codigo": 4218756,
            "Nome": "Tunápolis",
            "Uf": "SC"
        },
        {
            "Id": 4590,
            "Codigo": 4218806,
            "Nome": "Turvo",
            "Uf": "SC"
        },
        {
            "Id": 4591,
            "Codigo": 4218855,
            "Nome": "União do Oeste",
            "Uf": "SC"
        },
        {
            "Id": 4592,
            "Codigo": 4218905,
            "Nome": "Urubici",
            "Uf": "SC"
        },
        {
            "Id": 4593,
            "Codigo": 4218954,
            "Nome": "Urupema",
            "Uf": "SC"
        },
        {
            "Id": 4594,
            "Codigo": 4219002,
            "Nome": "Urussanga",
            "Uf": "SC"
        },
        {
            "Id": 4595,
            "Codigo": 4219101,
            "Nome": "Vargeão",
            "Uf": "SC"
        },
        {
            "Id": 4596,
            "Codigo": 4219150,
            "Nome": "Vargem",
            "Uf": "SC"
        },
        {
            "Id": 4597,
            "Codigo": 4219176,
            "Nome": "Vargem Bonita",
            "Uf": "SC"
        },
        {
            "Id": 4598,
            "Codigo": 4219200,
            "Nome": "Vidal Ramos",
            "Uf": "SC"
        },
        {
            "Id": 4599,
            "Codigo": 4219309,
            "Nome": "Videira",
            "Uf": "SC"
        },
        {
            "Id": 4600,
            "Codigo": 4219358,
            "Nome": "Vitor Meireles",
            "Uf": "SC"
        },
        {
            "Id": 4601,
            "Codigo": 4219408,
            "Nome": "Witmarsum",
            "Uf": "SC"
        },
        {
            "Id": 4602,
            "Codigo": 4219507,
            "Nome": "Xanxerê",
            "Uf": "SC"
        },
        {
            "Id": 4603,
            "Codigo": 4219606,
            "Nome": "Xavantina",
            "Uf": "SC"
        },
        {
            "Id": 4604,
            "Codigo": 4219705,
            "Nome": "Xaxim",
            "Uf": "SC"
        },
        {
            "Id": 4605,
            "Codigo": 4219853,
            "Nome": "Zortéa",
            "Uf": "SC"
        },
        {
            "Id": 4606,
            "Codigo": 4220000,
            "Nome": "Balneário Rincão",
            "Uf": "SC"
        },
        {
            "Id": 4607,
            "Codigo": 4300034,
            "Nome": "Aceguá",
            "Uf": "RS"
        },
        {
            "Id": 4608,
            "Codigo": 4300059,
            "Nome": "Água Santa",
            "Uf": "RS"
        },
        {
            "Id": 4609,
            "Codigo": 4300109,
            "Nome": "Agudo",
            "Uf": "RS"
        },
        {
            "Id": 4610,
            "Codigo": 4300208,
            "Nome": "Ajuricaba",
            "Uf": "RS"
        },
        {
            "Id": 4611,
            "Codigo": 4300307,
            "Nome": "Alecrim",
            "Uf": "RS"
        },
        {
            "Id": 4612,
            "Codigo": 4300406,
            "Nome": "Alegrete",
            "Uf": "RS"
        },
        {
            "Id": 4613,
            "Codigo": 4300455,
            "Nome": "Alegria",
            "Uf": "RS"
        },
        {
            "Id": 4614,
            "Codigo": 4300471,
            "Nome": "Almirante Tamandaré do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4615,
            "Codigo": 4300505,
            "Nome": "Alpestre",
            "Uf": "RS"
        },
        {
            "Id": 4616,
            "Codigo": 4300554,
            "Nome": "Alto Alegre",
            "Uf": "RS"
        },
        {
            "Id": 4617,
            "Codigo": 4300570,
            "Nome": "Alto Feliz",
            "Uf": "RS"
        },
        {
            "Id": 4618,
            "Codigo": 4300604,
            "Nome": "Alvorada",
            "Uf": "RS"
        },
        {
            "Id": 4619,
            "Codigo": 4300638,
            "Nome": "Amaral Ferrador",
            "Uf": "RS"
        },
        {
            "Id": 4620,
            "Codigo": 4300646,
            "Nome": "Ametista do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4621,
            "Codigo": 4300661,
            "Nome": "André da Rocha",
            "Uf": "RS"
        },
        {
            "Id": 4622,
            "Codigo": 4300703,
            "Nome": "Anta Gorda",
            "Uf": "RS"
        },
        {
            "Id": 4623,
            "Codigo": 4300802,
            "Nome": "Antônio Prado",
            "Uf": "RS"
        },
        {
            "Id": 4624,
            "Codigo": 4300851,
            "Nome": "Arambaré",
            "Uf": "RS"
        },
        {
            "Id": 4625,
            "Codigo": 4300877,
            "Nome": "Araricá",
            "Uf": "RS"
        },
        {
            "Id": 4626,
            "Codigo": 4300901,
            "Nome": "Aratiba",
            "Uf": "RS"
        },
        {
            "Id": 4627,
            "Codigo": 4301008,
            "Nome": "Arroio do Meio",
            "Uf": "RS"
        },
        {
            "Id": 4628,
            "Codigo": 4301057,
            "Nome": "Arroio do Sal",
            "Uf": "RS"
        },
        {
            "Id": 4629,
            "Codigo": 4301073,
            "Nome": "Arroio do Padre",
            "Uf": "RS"
        },
        {
            "Id": 4630,
            "Codigo": 4301107,
            "Nome": "Arroio dos Ratos",
            "Uf": "RS"
        },
        {
            "Id": 4631,
            "Codigo": 4301206,
            "Nome": "Arroio do Tigre",
            "Uf": "RS"
        },
        {
            "Id": 4632,
            "Codigo": 4301305,
            "Nome": "Arroio Grande",
            "Uf": "RS"
        },
        {
            "Id": 4633,
            "Codigo": 4301404,
            "Nome": "Arvorezinha",
            "Uf": "RS"
        },
        {
            "Id": 4634,
            "Codigo": 4301503,
            "Nome": "Augusto Pestana",
            "Uf": "RS"
        },
        {
            "Id": 4635,
            "Codigo": 4301552,
            "Nome": "Áurea",
            "Uf": "RS"
        },
        {
            "Id": 4636,
            "Codigo": 4301602,
            "Nome": "Bagé",
            "Uf": "RS"
        },
        {
            "Id": 4637,
            "Codigo": 4301636,
            "Nome": "Balneário Pinhal",
            "Uf": "RS"
        },
        {
            "Id": 4638,
            "Codigo": 4301651,
            "Nome": "Barão",
            "Uf": "RS"
        },
        {
            "Id": 4639,
            "Codigo": 4301701,
            "Nome": "Barão de Cotegipe",
            "Uf": "RS"
        },
        {
            "Id": 4640,
            "Codigo": 4301750,
            "Nome": "Barão do Triunfo",
            "Uf": "RS"
        },
        {
            "Id": 4641,
            "Codigo": 4301800,
            "Nome": "Barracão",
            "Uf": "RS"
        },
        {
            "Id": 4642,
            "Codigo": 4301859,
            "Nome": "Barra do Guarita",
            "Uf": "RS"
        },
        {
            "Id": 4643,
            "Codigo": 4301875,
            "Nome": "Barra do Quaraí",
            "Uf": "RS"
        },
        {
            "Id": 4644,
            "Codigo": 4301909,
            "Nome": "Barra do Ribeiro",
            "Uf": "RS"
        },
        {
            "Id": 4645,
            "Codigo": 4301925,
            "Nome": "Barra do Rio Azul",
            "Uf": "RS"
        },
        {
            "Id": 4646,
            "Codigo": 4301958,
            "Nome": "Barra Funda",
            "Uf": "RS"
        },
        {
            "Id": 4647,
            "Codigo": 4302006,
            "Nome": "Barros Cassal",
            "Uf": "RS"
        },
        {
            "Id": 4648,
            "Codigo": 4302055,
            "Nome": "Benjamin Constant do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4649,
            "Codigo": 4302105,
            "Nome": "Bento Gonçalves",
            "Uf": "RS"
        },
        {
            "Id": 4650,
            "Codigo": 4302154,
            "Nome": "Boa Vista das Missões",
            "Uf": "RS"
        },
        {
            "Id": 4651,
            "Codigo": 4302204,
            "Nome": "Boa Vista do Buricá",
            "Uf": "RS"
        },
        {
            "Id": 4652,
            "Codigo": 4302220,
            "Nome": "Boa Vista do Cadeado",
            "Uf": "RS"
        },
        {
            "Id": 4653,
            "Codigo": 4302238,
            "Nome": "Boa Vista do Incra",
            "Uf": "RS"
        },
        {
            "Id": 4654,
            "Codigo": 4302253,
            "Nome": "Boa Vista do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4655,
            "Codigo": 4302303,
            "Nome": "Bom Jesus",
            "Uf": "RS"
        },
        {
            "Id": 4656,
            "Codigo": 4302352,
            "Nome": "Bom Princípio",
            "Uf": "RS"
        },
        {
            "Id": 4657,
            "Codigo": 4302378,
            "Nome": "Bom Progresso",
            "Uf": "RS"
        },
        {
            "Id": 4658,
            "Codigo": 4302402,
            "Nome": "Bom Retiro do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4659,
            "Codigo": 4302451,
            "Nome": "Boqueirão do Leão",
            "Uf": "RS"
        },
        {
            "Id": 4660,
            "Codigo": 4302501,
            "Nome": "Bossoroca",
            "Uf": "RS"
        },
        {
            "Id": 4661,
            "Codigo": 4302584,
            "Nome": "Bozano",
            "Uf": "RS"
        },
        {
            "Id": 4662,
            "Codigo": 4302600,
            "Nome": "Braga",
            "Uf": "RS"
        },
        {
            "Id": 4663,
            "Codigo": 4302659,
            "Nome": "Brochier",
            "Uf": "RS"
        },
        {
            "Id": 4664,
            "Codigo": 4302709,
            "Nome": "Butiá",
            "Uf": "RS"
        },
        {
            "Id": 4665,
            "Codigo": 4302808,
            "Nome": "Caçapava do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4666,
            "Codigo": 4302907,
            "Nome": "Cacequi",
            "Uf": "RS"
        },
        {
            "Id": 4667,
            "Codigo": 4303004,
            "Nome": "Cachoeira do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4668,
            "Codigo": 4303103,
            "Nome": "Cachoeirinha",
            "Uf": "RS"
        },
        {
            "Id": 4669,
            "Codigo": 4303202,
            "Nome": "Cacique Doble",
            "Uf": "RS"
        },
        {
            "Id": 4670,
            "Codigo": 4303301,
            "Nome": "Caibaté",
            "Uf": "RS"
        },
        {
            "Id": 4671,
            "Codigo": 4303400,
            "Nome": "Caiçara",
            "Uf": "RS"
        },
        {
            "Id": 4672,
            "Codigo": 4303509,
            "Nome": "Camaquã",
            "Uf": "RS"
        },
        {
            "Id": 4673,
            "Codigo": 4303558,
            "Nome": "Camargo",
            "Uf": "RS"
        },
        {
            "Id": 4674,
            "Codigo": 4303608,
            "Nome": "Cambará do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4675,
            "Codigo": 4303673,
            "Nome": "Campestre da Serra",
            "Uf": "RS"
        },
        {
            "Id": 4676,
            "Codigo": 4303707,
            "Nome": "Campina das Missões",
            "Uf": "RS"
        },
        {
            "Id": 4677,
            "Codigo": 4303806,
            "Nome": "Campinas do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4678,
            "Codigo": 4303905,
            "Nome": "Campo Bom",
            "Uf": "RS"
        },
        {
            "Id": 4679,
            "Codigo": 4304002,
            "Nome": "Campo Novo",
            "Uf": "RS"
        },
        {
            "Id": 4680,
            "Codigo": 4304101,
            "Nome": "Campos Borges",
            "Uf": "RS"
        },
        {
            "Id": 4681,
            "Codigo": 4304200,
            "Nome": "Candelária",
            "Uf": "RS"
        },
        {
            "Id": 4682,
            "Codigo": 4304309,
            "Nome": "Cândido Godói",
            "Uf": "RS"
        },
        {
            "Id": 4683,
            "Codigo": 4304358,
            "Nome": "Candiota",
            "Uf": "RS"
        },
        {
            "Id": 4684,
            "Codigo": 4304408,
            "Nome": "Canela",
            "Uf": "RS"
        },
        {
            "Id": 4685,
            "Codigo": 4304507,
            "Nome": "Canguçu",
            "Uf": "RS"
        },
        {
            "Id": 4686,
            "Codigo": 4304606,
            "Nome": "Canoas",
            "Uf": "RS"
        },
        {
            "Id": 4687,
            "Codigo": 4304614,
            "Nome": "Canudos do Vale",
            "Uf": "RS"
        },
        {
            "Id": 4688,
            "Codigo": 4304622,
            "Nome": "Capão Bonito do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4689,
            "Codigo": 4304630,
            "Nome": "Capão da Canoa",
            "Uf": "RS"
        },
        {
            "Id": 4690,
            "Codigo": 4304655,
            "Nome": "Capão do Cipó",
            "Uf": "RS"
        },
        {
            "Id": 4691,
            "Codigo": 4304663,
            "Nome": "Capão do Leão",
            "Uf": "RS"
        },
        {
            "Id": 4692,
            "Codigo": 4304671,
            "Nome": "Capivari do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4693,
            "Codigo": 4304689,
            "Nome": "Capela de Santana",
            "Uf": "RS"
        },
        {
            "Id": 4694,
            "Codigo": 4304697,
            "Nome": "Capitão",
            "Uf": "RS"
        },
        {
            "Id": 4695,
            "Codigo": 4304705,
            "Nome": "Carazinho",
            "Uf": "RS"
        },
        {
            "Id": 4696,
            "Codigo": 4304713,
            "Nome": "Caraá",
            "Uf": "RS"
        },
        {
            "Id": 4697,
            "Codigo": 4304804,
            "Nome": "Carlos Barbosa",
            "Uf": "RS"
        },
        {
            "Id": 4698,
            "Codigo": 4304853,
            "Nome": "Carlos Gomes",
            "Uf": "RS"
        },
        {
            "Id": 4699,
            "Codigo": 4304903,
            "Nome": "Casca",
            "Uf": "RS"
        },
        {
            "Id": 4700,
            "Codigo": 4304952,
            "Nome": "Caseiros",
            "Uf": "RS"
        },
        {
            "Id": 4701,
            "Codigo": 4305009,
            "Nome": "Catuípe",
            "Uf": "RS"
        },
        {
            "Id": 4702,
            "Codigo": 4305108,
            "Nome": "Caxias do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4703,
            "Codigo": 4305116,
            "Nome": "Centenário",
            "Uf": "RS"
        },
        {
            "Id": 4704,
            "Codigo": 4305124,
            "Nome": "Cerrito",
            "Uf": "RS"
        },
        {
            "Id": 4705,
            "Codigo": 4305132,
            "Nome": "Cerro Branco",
            "Uf": "RS"
        },
        {
            "Id": 4706,
            "Codigo": 4305157,
            "Nome": "Cerro Grande",
            "Uf": "RS"
        },
        {
            "Id": 4707,
            "Codigo": 4305173,
            "Nome": "Cerro Grande do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4708,
            "Codigo": 4305207,
            "Nome": "Cerro Largo",
            "Uf": "RS"
        },
        {
            "Id": 4709,
            "Codigo": 4305306,
            "Nome": "Chapada",
            "Uf": "RS"
        },
        {
            "Id": 4710,
            "Codigo": 4305355,
            "Nome": "Charqueadas",
            "Uf": "RS"
        },
        {
            "Id": 4711,
            "Codigo": 4305371,
            "Nome": "Charrua",
            "Uf": "RS"
        },
        {
            "Id": 4712,
            "Codigo": 4305405,
            "Nome": "Chiapetta",
            "Uf": "RS"
        },
        {
            "Id": 4713,
            "Codigo": 4305439,
            "Nome": "Chuí",
            "Uf": "RS"
        },
        {
            "Id": 4714,
            "Codigo": 4305447,
            "Nome": "Chuvisca",
            "Uf": "RS"
        },
        {
            "Id": 4715,
            "Codigo": 4305454,
            "Nome": "Cidreira",
            "Uf": "RS"
        },
        {
            "Id": 4716,
            "Codigo": 4305504,
            "Nome": "Ciríaco",
            "Uf": "RS"
        },
        {
            "Id": 4717,
            "Codigo": 4305587,
            "Nome": "Colinas",
            "Uf": "RS"
        },
        {
            "Id": 4718,
            "Codigo": 4305603,
            "Nome": "Colorado",
            "Uf": "RS"
        },
        {
            "Id": 4719,
            "Codigo": 4305702,
            "Nome": "Condor",
            "Uf": "RS"
        },
        {
            "Id": 4720,
            "Codigo": 4305801,
            "Nome": "Constantina",
            "Uf": "RS"
        },
        {
            "Id": 4721,
            "Codigo": 4305835,
            "Nome": "Coqueiro Baixo",
            "Uf": "RS"
        },
        {
            "Id": 4722,
            "Codigo": 4305850,
            "Nome": "Coqueiros do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4723,
            "Codigo": 4305871,
            "Nome": "Coronel Barros",
            "Uf": "RS"
        },
        {
            "Id": 4724,
            "Codigo": 4305900,
            "Nome": "Coronel Bicaco",
            "Uf": "RS"
        },
        {
            "Id": 4725,
            "Codigo": 4305934,
            "Nome": "Coronel Pilar",
            "Uf": "RS"
        },
        {
            "Id": 4726,
            "Codigo": 4305959,
            "Nome": "Cotiporã",
            "Uf": "RS"
        },
        {
            "Id": 4727,
            "Codigo": 4305975,
            "Nome": "Coxilha",
            "Uf": "RS"
        },
        {
            "Id": 4728,
            "Codigo": 4306007,
            "Nome": "Crissiumal",
            "Uf": "RS"
        },
        {
            "Id": 4729,
            "Codigo": 4306056,
            "Nome": "Cristal",
            "Uf": "RS"
        },
        {
            "Id": 4730,
            "Codigo": 4306072,
            "Nome": "Cristal do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4731,
            "Codigo": 4306106,
            "Nome": "Cruz Alta",
            "Uf": "RS"
        },
        {
            "Id": 4732,
            "Codigo": 4306130,
            "Nome": "Cruzaltense",
            "Uf": "RS"
        },
        {
            "Id": 4733,
            "Codigo": 4306205,
            "Nome": "Cruzeiro do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4734,
            "Codigo": 4306304,
            "Nome": "David Canabarro",
            "Uf": "RS"
        },
        {
            "Id": 4735,
            "Codigo": 4306320,
            "Nome": "Derrubadas",
            "Uf": "RS"
        },
        {
            "Id": 4736,
            "Codigo": 4306353,
            "Nome": "Dezesseis de Novembro",
            "Uf": "RS"
        },
        {
            "Id": 4737,
            "Codigo": 4306379,
            "Nome": "Dilermando de Aguiar",
            "Uf": "RS"
        },
        {
            "Id": 4738,
            "Codigo": 4306403,
            "Nome": "Dois Irmãos",
            "Uf": "RS"
        },
        {
            "Id": 4739,
            "Codigo": 4306429,
            "Nome": "Dois Irmãos das Missões",
            "Uf": "RS"
        },
        {
            "Id": 4740,
            "Codigo": 4306452,
            "Nome": "Dois Lajeados",
            "Uf": "RS"
        },
        {
            "Id": 4741,
            "Codigo": 4306502,
            "Nome": "Dom Feliciano",
            "Uf": "RS"
        },
        {
            "Id": 4742,
            "Codigo": 4306551,
            "Nome": "Dom Pedro de Alcântara",
            "Uf": "RS"
        },
        {
            "Id": 4743,
            "Codigo": 4306601,
            "Nome": "Dom Pedrito",
            "Uf": "RS"
        },
        {
            "Id": 4744,
            "Codigo": 4306700,
            "Nome": "Dona Francisca",
            "Uf": "RS"
        },
        {
            "Id": 4745,
            "Codigo": 4306734,
            "Nome": "Doutor Maurício Cardoso",
            "Uf": "RS"
        },
        {
            "Id": 4746,
            "Codigo": 4306759,
            "Nome": "Doutor Ricardo",
            "Uf": "RS"
        },
        {
            "Id": 4747,
            "Codigo": 4306767,
            "Nome": "Eldorado do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4748,
            "Codigo": 4306809,
            "Nome": "Encantado",
            "Uf": "RS"
        },
        {
            "Id": 4749,
            "Codigo": 4306908,
            "Nome": "Encruzilhada do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4750,
            "Codigo": 4306924,
            "Nome": "Engenho Velho",
            "Uf": "RS"
        },
        {
            "Id": 4751,
            "Codigo": 4306932,
            "Nome": "Entre-Ijuís",
            "Uf": "RS"
        },
        {
            "Id": 4752,
            "Codigo": 4306957,
            "Nome": "Entre Rios do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4753,
            "Codigo": 4306973,
            "Nome": "Erebango",
            "Uf": "RS"
        },
        {
            "Id": 4754,
            "Codigo": 4307005,
            "Nome": "Erechim",
            "Uf": "RS"
        },
        {
            "Id": 4755,
            "Codigo": 4307054,
            "Nome": "Ernestina",
            "Uf": "RS"
        },
        {
            "Id": 4756,
            "Codigo": 4307104,
            "Nome": "Herval",
            "Uf": "RS"
        },
        {
            "Id": 4757,
            "Codigo": 4307203,
            "Nome": "Erval Grande",
            "Uf": "RS"
        },
        {
            "Id": 4758,
            "Codigo": 4307302,
            "Nome": "Erval Seco",
            "Uf": "RS"
        },
        {
            "Id": 4759,
            "Codigo": 4307401,
            "Nome": "Esmeralda",
            "Uf": "RS"
        },
        {
            "Id": 4760,
            "Codigo": 4307450,
            "Nome": "Esperança do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4761,
            "Codigo": 4307500,
            "Nome": "Espumoso",
            "Uf": "RS"
        },
        {
            "Id": 4762,
            "Codigo": 4307559,
            "Nome": "Estação",
            "Uf": "RS"
        },
        {
            "Id": 4763,
            "Codigo": 4307609,
            "Nome": "Estância Velha",
            "Uf": "RS"
        },
        {
            "Id": 4764,
            "Codigo": 4307708,
            "Nome": "Esteio",
            "Uf": "RS"
        },
        {
            "Id": 4765,
            "Codigo": 4307807,
            "Nome": "Estrela",
            "Uf": "RS"
        },
        {
            "Id": 4766,
            "Codigo": 4307815,
            "Nome": "Estrela Velha",
            "Uf": "RS"
        },
        {
            "Id": 4767,
            "Codigo": 4307831,
            "Nome": "Eugênio de Castro",
            "Uf": "RS"
        },
        {
            "Id": 4768,
            "Codigo": 4307864,
            "Nome": "Fagundes Varela",
            "Uf": "RS"
        },
        {
            "Id": 4769,
            "Codigo": 4307906,
            "Nome": "Farroupilha",
            "Uf": "RS"
        },
        {
            "Id": 4770,
            "Codigo": 4308003,
            "Nome": "Faxinal do Soturno",
            "Uf": "RS"
        },
        {
            "Id": 4771,
            "Codigo": 4308052,
            "Nome": "Faxinalzinho",
            "Uf": "RS"
        },
        {
            "Id": 4772,
            "Codigo": 4308078,
            "Nome": "Fazenda Vilanova",
            "Uf": "RS"
        },
        {
            "Id": 4773,
            "Codigo": 4308102,
            "Nome": "Feliz",
            "Uf": "RS"
        },
        {
            "Id": 4774,
            "Codigo": 4308201,
            "Nome": "Flores da Cunha",
            "Uf": "RS"
        },
        {
            "Id": 4775,
            "Codigo": 4308250,
            "Nome": "Floriano Peixoto",
            "Uf": "RS"
        },
        {
            "Id": 4776,
            "Codigo": 4308300,
            "Nome": "Fontoura Xavier",
            "Uf": "RS"
        },
        {
            "Id": 4777,
            "Codigo": 4308409,
            "Nome": "Formigueiro",
            "Uf": "RS"
        },
        {
            "Id": 4778,
            "Codigo": 4308433,
            "Nome": "Forquetinha",
            "Uf": "RS"
        },
        {
            "Id": 4779,
            "Codigo": 4308458,
            "Nome": "Fortaleza dos Valos",
            "Uf": "RS"
        },
        {
            "Id": 4780,
            "Codigo": 4308508,
            "Nome": "Frederico Westphalen",
            "Uf": "RS"
        },
        {
            "Id": 4781,
            "Codigo": 4308607,
            "Nome": "Garibaldi",
            "Uf": "RS"
        },
        {
            "Id": 4782,
            "Codigo": 4308656,
            "Nome": "Garruchos",
            "Uf": "RS"
        },
        {
            "Id": 4783,
            "Codigo": 4308706,
            "Nome": "Gaurama",
            "Uf": "RS"
        },
        {
            "Id": 4784,
            "Codigo": 4308805,
            "Nome": "General Câmara",
            "Uf": "RS"
        },
        {
            "Id": 4785,
            "Codigo": 4308854,
            "Nome": "Gentil",
            "Uf": "RS"
        },
        {
            "Id": 4786,
            "Codigo": 4308904,
            "Nome": "Getúlio Vargas",
            "Uf": "RS"
        },
        {
            "Id": 4787,
            "Codigo": 4309001,
            "Nome": "Giruá",
            "Uf": "RS"
        },
        {
            "Id": 4788,
            "Codigo": 4309050,
            "Nome": "Glorinha",
            "Uf": "RS"
        },
        {
            "Id": 4789,
            "Codigo": 4309100,
            "Nome": "Gramado",
            "Uf": "RS"
        },
        {
            "Id": 4790,
            "Codigo": 4309126,
            "Nome": "Gramado dos Loureiros",
            "Uf": "RS"
        },
        {
            "Id": 4791,
            "Codigo": 4309159,
            "Nome": "Gramado Xavier",
            "Uf": "RS"
        },
        {
            "Id": 4792,
            "Codigo": 4309209,
            "Nome": "Gravataí",
            "Uf": "RS"
        },
        {
            "Id": 4793,
            "Codigo": 4309258,
            "Nome": "Guabiju",
            "Uf": "RS"
        },
        {
            "Id": 4794,
            "Codigo": 4309308,
            "Nome": "Guaíba",
            "Uf": "RS"
        },
        {
            "Id": 4795,
            "Codigo": 4309407,
            "Nome": "Guaporé",
            "Uf": "RS"
        },
        {
            "Id": 4796,
            "Codigo": 4309506,
            "Nome": "Guarani das Missões",
            "Uf": "RS"
        },
        {
            "Id": 4797,
            "Codigo": 4309555,
            "Nome": "Harmonia",
            "Uf": "RS"
        },
        {
            "Id": 4798,
            "Codigo": 4309571,
            "Nome": "Herveiras",
            "Uf": "RS"
        },
        {
            "Id": 4799,
            "Codigo": 4309605,
            "Nome": "Horizontina",
            "Uf": "RS"
        },
        {
            "Id": 4800,
            "Codigo": 4309654,
            "Nome": "Hulha Negra",
            "Uf": "RS"
        },
        {
            "Id": 4801,
            "Codigo": 4309704,
            "Nome": "Humaitá",
            "Uf": "RS"
        },
        {
            "Id": 4802,
            "Codigo": 4309753,
            "Nome": "Ibarama",
            "Uf": "RS"
        },
        {
            "Id": 4803,
            "Codigo": 4309803,
            "Nome": "Ibiaçá",
            "Uf": "RS"
        },
        {
            "Id": 4804,
            "Codigo": 4309902,
            "Nome": "Ibiraiaras",
            "Uf": "RS"
        },
        {
            "Id": 4805,
            "Codigo": 4309951,
            "Nome": "Ibirapuitã",
            "Uf": "RS"
        },
        {
            "Id": 4806,
            "Codigo": 4310009,
            "Nome": "Ibirubá",
            "Uf": "RS"
        },
        {
            "Id": 4807,
            "Codigo": 4310108,
            "Nome": "Igrejinha",
            "Uf": "RS"
        },
        {
            "Id": 4808,
            "Codigo": 4310207,
            "Nome": "Ijuí",
            "Uf": "RS"
        },
        {
            "Id": 4809,
            "Codigo": 4310306,
            "Nome": "Ilópolis",
            "Uf": "RS"
        },
        {
            "Id": 4810,
            "Codigo": 4310330,
            "Nome": "Imbé",
            "Uf": "RS"
        },
        {
            "Id": 4811,
            "Codigo": 4310363,
            "Nome": "Imigrante",
            "Uf": "RS"
        },
        {
            "Id": 4812,
            "Codigo": 4310405,
            "Nome": "Independência",
            "Uf": "RS"
        },
        {
            "Id": 4813,
            "Codigo": 4310413,
            "Nome": "Inhacorá",
            "Uf": "RS"
        },
        {
            "Id": 4814,
            "Codigo": 4310439,
            "Nome": "Ipê",
            "Uf": "RS"
        },
        {
            "Id": 4815,
            "Codigo": 4310462,
            "Nome": "Ipiranga do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4816,
            "Codigo": 4310504,
            "Nome": "Iraí",
            "Uf": "RS"
        },
        {
            "Id": 4817,
            "Codigo": 4310538,
            "Nome": "Itaara",
            "Uf": "RS"
        },
        {
            "Id": 4818,
            "Codigo": 4310553,
            "Nome": "Itacurubi",
            "Uf": "RS"
        },
        {
            "Id": 4819,
            "Codigo": 4310579,
            "Nome": "Itapuca",
            "Uf": "RS"
        },
        {
            "Id": 4820,
            "Codigo": 4310603,
            "Nome": "Itaqui",
            "Uf": "RS"
        },
        {
            "Id": 4821,
            "Codigo": 4310652,
            "Nome": "Itati",
            "Uf": "RS"
        },
        {
            "Id": 4822,
            "Codigo": 4310702,
            "Nome": "Itatiba do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4823,
            "Codigo": 4310751,
            "Nome": "Ivorá",
            "Uf": "RS"
        },
        {
            "Id": 4824,
            "Codigo": 4310801,
            "Nome": "Ivoti",
            "Uf": "RS"
        },
        {
            "Id": 4825,
            "Codigo": 4310850,
            "Nome": "Jaboticaba",
            "Uf": "RS"
        },
        {
            "Id": 4826,
            "Codigo": 4310876,
            "Nome": "Jacuizinho",
            "Uf": "RS"
        },
        {
            "Id": 4827,
            "Codigo": 4310900,
            "Nome": "Jacutinga",
            "Uf": "RS"
        },
        {
            "Id": 4828,
            "Codigo": 4311007,
            "Nome": "Jaguarão",
            "Uf": "RS"
        },
        {
            "Id": 4829,
            "Codigo": 4311106,
            "Nome": "Jaguari",
            "Uf": "RS"
        },
        {
            "Id": 4830,
            "Codigo": 4311122,
            "Nome": "Jaquirana",
            "Uf": "RS"
        },
        {
            "Id": 4831,
            "Codigo": 4311130,
            "Nome": "Jari",
            "Uf": "RS"
        },
        {
            "Id": 4832,
            "Codigo": 4311155,
            "Nome": "Jóia",
            "Uf": "RS"
        },
        {
            "Id": 4833,
            "Codigo": 4311205,
            "Nome": "Júlio de Castilhos",
            "Uf": "RS"
        },
        {
            "Id": 4834,
            "Codigo": 4311239,
            "Nome": "Lagoa Bonita do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4835,
            "Codigo": 4311254,
            "Nome": "Lagoão",
            "Uf": "RS"
        },
        {
            "Id": 4836,
            "Codigo": 4311270,
            "Nome": "Lagoa dos Três Cantos",
            "Uf": "RS"
        },
        {
            "Id": 4837,
            "Codigo": 4311304,
            "Nome": "Lagoa Vermelha",
            "Uf": "RS"
        },
        {
            "Id": 4838,
            "Codigo": 4311403,
            "Nome": "Lajeado",
            "Uf": "RS"
        },
        {
            "Id": 4839,
            "Codigo": 4311429,
            "Nome": "Lajeado do Bugre",
            "Uf": "RS"
        },
        {
            "Id": 4840,
            "Codigo": 4311502,
            "Nome": "Lavras do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4841,
            "Codigo": 4311601,
            "Nome": "Liberato Salzano",
            "Uf": "RS"
        },
        {
            "Id": 4842,
            "Codigo": 4311627,
            "Nome": "Lindolfo Collor",
            "Uf": "RS"
        },
        {
            "Id": 4843,
            "Codigo": 4311643,
            "Nome": "Linha Nova",
            "Uf": "RS"
        },
        {
            "Id": 4844,
            "Codigo": 4311700,
            "Nome": "Machadinho",
            "Uf": "RS"
        },
        {
            "Id": 4845,
            "Codigo": 4311718,
            "Nome": "Maçambará",
            "Uf": "RS"
        },
        {
            "Id": 4846,
            "Codigo": 4311734,
            "Nome": "Mampituba",
            "Uf": "RS"
        },
        {
            "Id": 4847,
            "Codigo": 4311759,
            "Nome": "Manoel Viana",
            "Uf": "RS"
        },
        {
            "Id": 4848,
            "Codigo": 4311775,
            "Nome": "Maquiné",
            "Uf": "RS"
        },
        {
            "Id": 4849,
            "Codigo": 4311791,
            "Nome": "Maratá",
            "Uf": "RS"
        },
        {
            "Id": 4850,
            "Codigo": 4311809,
            "Nome": "Marau",
            "Uf": "RS"
        },
        {
            "Id": 4851,
            "Codigo": 4311908,
            "Nome": "Marcelino Ramos",
            "Uf": "RS"
        },
        {
            "Id": 4852,
            "Codigo": 4311981,
            "Nome": "Mariana Pimentel",
            "Uf": "RS"
        },
        {
            "Id": 4853,
            "Codigo": 4312005,
            "Nome": "Mariano Moro",
            "Uf": "RS"
        },
        {
            "Id": 4854,
            "Codigo": 4312054,
            "Nome": "Marques de Souza",
            "Uf": "RS"
        },
        {
            "Id": 4855,
            "Codigo": 4312104,
            "Nome": "Mata",
            "Uf": "RS"
        },
        {
            "Id": 4856,
            "Codigo": 4312138,
            "Nome": "Mato Castelhano",
            "Uf": "RS"
        },
        {
            "Id": 4857,
            "Codigo": 4312153,
            "Nome": "Mato Leitão",
            "Uf": "RS"
        },
        {
            "Id": 4858,
            "Codigo": 4312179,
            "Nome": "Mato Queimado",
            "Uf": "RS"
        },
        {
            "Id": 4859,
            "Codigo": 4312203,
            "Nome": "Maximiliano de Almeida",
            "Uf": "RS"
        },
        {
            "Id": 4860,
            "Codigo": 4312252,
            "Nome": "Minas do Leão",
            "Uf": "RS"
        },
        {
            "Id": 4861,
            "Codigo": 4312302,
            "Nome": "Miraguaí",
            "Uf": "RS"
        },
        {
            "Id": 4862,
            "Codigo": 4312351,
            "Nome": "Montauri",
            "Uf": "RS"
        },
        {
            "Id": 4863,
            "Codigo": 4312377,
            "Nome": "Monte Alegre dos Campos",
            "Uf": "RS"
        },
        {
            "Id": 4864,
            "Codigo": 4312385,
            "Nome": "Monte Belo do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4865,
            "Codigo": 4312401,
            "Nome": "Montenegro",
            "Uf": "RS"
        },
        {
            "Id": 4866,
            "Codigo": 4312427,
            "Nome": "Mormaço",
            "Uf": "RS"
        },
        {
            "Id": 4867,
            "Codigo": 4312443,
            "Nome": "Morrinhos do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4868,
            "Codigo": 4312450,
            "Nome": "Morro Redondo",
            "Uf": "RS"
        },
        {
            "Id": 4869,
            "Codigo": 4312476,
            "Nome": "Morro Reuter",
            "Uf": "RS"
        },
        {
            "Id": 4870,
            "Codigo": 4312500,
            "Nome": "Mostardas",
            "Uf": "RS"
        },
        {
            "Id": 4871,
            "Codigo": 4312609,
            "Nome": "Muçum",
            "Uf": "RS"
        },
        {
            "Id": 4872,
            "Codigo": 4312617,
            "Nome": "Muitos Capões",
            "Uf": "RS"
        },
        {
            "Id": 4873,
            "Codigo": 4312625,
            "Nome": "Muliterno",
            "Uf": "RS"
        },
        {
            "Id": 4874,
            "Codigo": 4312658,
            "Nome": "Não-Me-Toque",
            "Uf": "RS"
        },
        {
            "Id": 4875,
            "Codigo": 4312674,
            "Nome": "Nicolau Vergueiro",
            "Uf": "RS"
        },
        {
            "Id": 4876,
            "Codigo": 4312708,
            "Nome": "Nonoai",
            "Uf": "RS"
        },
        {
            "Id": 4877,
            "Codigo": 4312757,
            "Nome": "Nova Alvorada",
            "Uf": "RS"
        },
        {
            "Id": 4878,
            "Codigo": 4312807,
            "Nome": "Nova Araçá",
            "Uf": "RS"
        },
        {
            "Id": 4879,
            "Codigo": 4312906,
            "Nome": "Nova Bassano",
            "Uf": "RS"
        },
        {
            "Id": 4880,
            "Codigo": 4312955,
            "Nome": "Nova Boa Vista",
            "Uf": "RS"
        },
        {
            "Id": 4881,
            "Codigo": 4313003,
            "Nome": "Nova Bréscia",
            "Uf": "RS"
        },
        {
            "Id": 4882,
            "Codigo": 4313011,
            "Nome": "Nova Candelária",
            "Uf": "RS"
        },
        {
            "Id": 4883,
            "Codigo": 4313037,
            "Nome": "Nova Esperança do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4884,
            "Codigo": 4313060,
            "Nome": "Nova Hartz",
            "Uf": "RS"
        },
        {
            "Id": 4885,
            "Codigo": 4313086,
            "Nome": "Nova Pádua",
            "Uf": "RS"
        },
        {
            "Id": 4886,
            "Codigo": 4313102,
            "Nome": "Nova Palma",
            "Uf": "RS"
        },
        {
            "Id": 4887,
            "Codigo": 4313201,
            "Nome": "Nova Petrópolis",
            "Uf": "RS"
        },
        {
            "Id": 4888,
            "Codigo": 4313300,
            "Nome": "Nova Prata",
            "Uf": "RS"
        },
        {
            "Id": 4889,
            "Codigo": 4313334,
            "Nome": "Nova Ramada",
            "Uf": "RS"
        },
        {
            "Id": 4890,
            "Codigo": 4313359,
            "Nome": "Nova Roma do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4891,
            "Codigo": 4313375,
            "Nome": "Nova Santa Rita",
            "Uf": "RS"
        },
        {
            "Id": 4892,
            "Codigo": 4313391,
            "Nome": "Novo Cabrais",
            "Uf": "RS"
        },
        {
            "Id": 4893,
            "Codigo": 4313409,
            "Nome": "Novo Hamburgo",
            "Uf": "RS"
        },
        {
            "Id": 4894,
            "Codigo": 4313425,
            "Nome": "Novo Machado",
            "Uf": "RS"
        },
        {
            "Id": 4895,
            "Codigo": 4313441,
            "Nome": "Novo Tiradentes",
            "Uf": "RS"
        },
        {
            "Id": 4896,
            "Codigo": 4313466,
            "Nome": "Novo Xingu",
            "Uf": "RS"
        },
        {
            "Id": 4897,
            "Codigo": 4313490,
            "Nome": "Novo Barreiro",
            "Uf": "RS"
        },
        {
            "Id": 4898,
            "Codigo": 4313508,
            "Nome": "Osório",
            "Uf": "RS"
        },
        {
            "Id": 4899,
            "Codigo": 4313607,
            "Nome": "Paim Filho",
            "Uf": "RS"
        },
        {
            "Id": 4900,
            "Codigo": 4313656,
            "Nome": "Palmares do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4901,
            "Codigo": 4313706,
            "Nome": "Palmeira das Missões",
            "Uf": "RS"
        },
        {
            "Id": 4902,
            "Codigo": 4313805,
            "Nome": "Palmitinho",
            "Uf": "RS"
        },
        {
            "Id": 4903,
            "Codigo": 4313904,
            "Nome": "Panambi",
            "Uf": "RS"
        },
        {
            "Id": 4904,
            "Codigo": 4313953,
            "Nome": "Pantano Grande",
            "Uf": "RS"
        },
        {
            "Id": 4905,
            "Codigo": 4314001,
            "Nome": "Paraí",
            "Uf": "RS"
        },
        {
            "Id": 4906,
            "Codigo": 4314027,
            "Nome": "Paraíso do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4907,
            "Codigo": 4314035,
            "Nome": "Pareci Novo",
            "Uf": "RS"
        },
        {
            "Id": 4908,
            "Codigo": 4314050,
            "Nome": "Parobé",
            "Uf": "RS"
        },
        {
            "Id": 4909,
            "Codigo": 4314068,
            "Nome": "Passa Sete",
            "Uf": "RS"
        },
        {
            "Id": 4910,
            "Codigo": 4314076,
            "Nome": "Passo do Sobrado",
            "Uf": "RS"
        },
        {
            "Id": 4911,
            "Codigo": 4314100,
            "Nome": "Passo Fundo",
            "Uf": "RS"
        },
        {
            "Id": 4912,
            "Codigo": 4314134,
            "Nome": "Paulo Bento",
            "Uf": "RS"
        },
        {
            "Id": 4913,
            "Codigo": 4314159,
            "Nome": "Paverama",
            "Uf": "RS"
        },
        {
            "Id": 4914,
            "Codigo": 4314175,
            "Nome": "Pedras Altas",
            "Uf": "RS"
        },
        {
            "Id": 4915,
            "Codigo": 4314209,
            "Nome": "Pedro Osório",
            "Uf": "RS"
        },
        {
            "Id": 4916,
            "Codigo": 4314308,
            "Nome": "Pejuçara",
            "Uf": "RS"
        },
        {
            "Id": 4917,
            "Codigo": 4314407,
            "Nome": "Pelotas",
            "Uf": "RS"
        },
        {
            "Id": 4918,
            "Codigo": 4314423,
            "Nome": "Picada Café",
            "Uf": "RS"
        },
        {
            "Id": 4919,
            "Codigo": 4314456,
            "Nome": "Pinhal",
            "Uf": "RS"
        },
        {
            "Id": 4920,
            "Codigo": 4314464,
            "Nome": "Pinhal da Serra",
            "Uf": "RS"
        },
        {
            "Id": 4921,
            "Codigo": 4314472,
            "Nome": "Pinhal Grande",
            "Uf": "RS"
        },
        {
            "Id": 4922,
            "Codigo": 4314498,
            "Nome": "Pinheirinho do Vale",
            "Uf": "RS"
        },
        {
            "Id": 4923,
            "Codigo": 4314506,
            "Nome": "Pinheiro Machado",
            "Uf": "RS"
        },
        {
            "Id": 4924,
            "Codigo": 4314548,
            "Nome": "Pinto Bandeira",
            "Uf": "RS"
        },
        {
            "Id": 4925,
            "Codigo": 4314555,
            "Nome": "Pirapó",
            "Uf": "RS"
        },
        {
            "Id": 4926,
            "Codigo": 4314605,
            "Nome": "Piratini",
            "Uf": "RS"
        },
        {
            "Id": 4927,
            "Codigo": 4314704,
            "Nome": "Planalto",
            "Uf": "RS"
        },
        {
            "Id": 4928,
            "Codigo": 4314753,
            "Nome": "Poço das Antas",
            "Uf": "RS"
        },
        {
            "Id": 4929,
            "Codigo": 4314779,
            "Nome": "Pontão",
            "Uf": "RS"
        },
        {
            "Id": 4930,
            "Codigo": 4314787,
            "Nome": "Ponte Preta",
            "Uf": "RS"
        },
        {
            "Id": 4931,
            "Codigo": 4314803,
            "Nome": "Portão",
            "Uf": "RS"
        },
        {
            "Id": 4932,
            "Codigo": 4314902,
            "Nome": "Porto Alegre",
            "Uf": "RS"
        },
        {
            "Id": 4933,
            "Codigo": 4315008,
            "Nome": "Porto Lucena",
            "Uf": "RS"
        },
        {
            "Id": 4934,
            "Codigo": 4315057,
            "Nome": "Porto Mauá",
            "Uf": "RS"
        },
        {
            "Id": 4935,
            "Codigo": 4315073,
            "Nome": "Porto Vera Cruz",
            "Uf": "RS"
        },
        {
            "Id": 4936,
            "Codigo": 4315107,
            "Nome": "Porto Xavier",
            "Uf": "RS"
        },
        {
            "Id": 4937,
            "Codigo": 4315131,
            "Nome": "Pouso Novo",
            "Uf": "RS"
        },
        {
            "Id": 4938,
            "Codigo": 4315149,
            "Nome": "Presidente Lucena",
            "Uf": "RS"
        },
        {
            "Id": 4939,
            "Codigo": 4315156,
            "Nome": "Progresso",
            "Uf": "RS"
        },
        {
            "Id": 4940,
            "Codigo": 4315172,
            "Nome": "Protásio Alves",
            "Uf": "RS"
        },
        {
            "Id": 4941,
            "Codigo": 4315206,
            "Nome": "Putinga",
            "Uf": "RS"
        },
        {
            "Id": 4942,
            "Codigo": 4315305,
            "Nome": "Quaraí",
            "Uf": "RS"
        },
        {
            "Id": 4943,
            "Codigo": 4315313,
            "Nome": "Quatro Irmãos",
            "Uf": "RS"
        },
        {
            "Id": 4944,
            "Codigo": 4315321,
            "Nome": "Quevedos",
            "Uf": "RS"
        },
        {
            "Id": 4945,
            "Codigo": 4315354,
            "Nome": "Quinze de Novembro",
            "Uf": "RS"
        },
        {
            "Id": 4946,
            "Codigo": 4315404,
            "Nome": "Redentora",
            "Uf": "RS"
        },
        {
            "Id": 4947,
            "Codigo": 4315453,
            "Nome": "Relvado",
            "Uf": "RS"
        },
        {
            "Id": 4948,
            "Codigo": 4315503,
            "Nome": "Restinga Seca",
            "Uf": "RS"
        },
        {
            "Id": 4949,
            "Codigo": 4315552,
            "Nome": "Rio dos Índios",
            "Uf": "RS"
        },
        {
            "Id": 4950,
            "Codigo": 4315602,
            "Nome": "Rio Grande",
            "Uf": "RS"
        },
        {
            "Id": 4951,
            "Codigo": 4315701,
            "Nome": "Rio Pardo",
            "Uf": "RS"
        },
        {
            "Id": 4952,
            "Codigo": 4315750,
            "Nome": "Riozinho",
            "Uf": "RS"
        },
        {
            "Id": 4953,
            "Codigo": 4315800,
            "Nome": "Roca Sales",
            "Uf": "RS"
        },
        {
            "Id": 4954,
            "Codigo": 4315909,
            "Nome": "Rodeio Bonito",
            "Uf": "RS"
        },
        {
            "Id": 4955,
            "Codigo": 4315958,
            "Nome": "Rolador",
            "Uf": "RS"
        },
        {
            "Id": 4956,
            "Codigo": 4316006,
            "Nome": "Rolante",
            "Uf": "RS"
        },
        {
            "Id": 4957,
            "Codigo": 4316105,
            "Nome": "Ronda Alta",
            "Uf": "RS"
        },
        {
            "Id": 4958,
            "Codigo": 4316204,
            "Nome": "Rondinha",
            "Uf": "RS"
        },
        {
            "Id": 4959,
            "Codigo": 4316303,
            "Nome": "Roque Gonzales",
            "Uf": "RS"
        },
        {
            "Id": 4960,
            "Codigo": 4316402,
            "Nome": "Rosário do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4961,
            "Codigo": 4316428,
            "Nome": "Sagrada Família",
            "Uf": "RS"
        },
        {
            "Id": 4962,
            "Codigo": 4316436,
            "Nome": "Saldanha Marinho",
            "Uf": "RS"
        },
        {
            "Id": 4963,
            "Codigo": 4316451,
            "Nome": "Salto do Jacuí",
            "Uf": "RS"
        },
        {
            "Id": 4964,
            "Codigo": 4316477,
            "Nome": "Salvador das Missões",
            "Uf": "RS"
        },
        {
            "Id": 4965,
            "Codigo": 4316501,
            "Nome": "Salvador do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4966,
            "Codigo": 4316600,
            "Nome": "Sananduva",
            "Uf": "RS"
        },
        {
            "Id": 4967,
            "Codigo": 4316709,
            "Nome": "Santa Bárbara do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4968,
            "Codigo": 4316733,
            "Nome": "Santa Cecília do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4969,
            "Codigo": 4316758,
            "Nome": "Santa Clara do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4970,
            "Codigo": 4316808,
            "Nome": "Santa Cruz do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4971,
            "Codigo": 4316907,
            "Nome": "Santa Maria",
            "Uf": "RS"
        },
        {
            "Id": 4972,
            "Codigo": 4316956,
            "Nome": "Santa Maria do Herval",
            "Uf": "RS"
        },
        {
            "Id": 4973,
            "Codigo": 4316972,
            "Nome": "Santa Margarida do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4974,
            "Codigo": 4317004,
            "Nome": "Santana da Boa Vista",
            "Uf": "RS"
        },
        {
            "Id": 4975,
            "Codigo": 4317103,
            "Nome": "Sant'Ana do Livramento",
            "Uf": "RS"
        },
        {
            "Id": 4976,
            "Codigo": 4317202,
            "Nome": "Santa Rosa",
            "Uf": "RS"
        },
        {
            "Id": 4977,
            "Codigo": 4317251,
            "Nome": "Santa Tereza",
            "Uf": "RS"
        },
        {
            "Id": 4978,
            "Codigo": 4317301,
            "Nome": "Santa Vitória do Palmar",
            "Uf": "RS"
        },
        {
            "Id": 4979,
            "Codigo": 4317400,
            "Nome": "Santiago",
            "Uf": "RS"
        },
        {
            "Id": 4980,
            "Codigo": 4317509,
            "Nome": "Santo Ângelo",
            "Uf": "RS"
        },
        {
            "Id": 4981,
            "Codigo": 4317558,
            "Nome": "Santo Antônio do Palma",
            "Uf": "RS"
        },
        {
            "Id": 4982,
            "Codigo": 4317608,
            "Nome": "Santo Antônio da Patrulha",
            "Uf": "RS"
        },
        {
            "Id": 4983,
            "Codigo": 4317707,
            "Nome": "Santo Antônio das Missões",
            "Uf": "RS"
        },
        {
            "Id": 4984,
            "Codigo": 4317756,
            "Nome": "Santo Antônio do Planalto",
            "Uf": "RS"
        },
        {
            "Id": 4985,
            "Codigo": 4317806,
            "Nome": "Santo Augusto",
            "Uf": "RS"
        },
        {
            "Id": 4986,
            "Codigo": 4317905,
            "Nome": "Santo Cristo",
            "Uf": "RS"
        },
        {
            "Id": 4987,
            "Codigo": 4317954,
            "Nome": "Santo Expedito do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4988,
            "Codigo": 4318002,
            "Nome": "São Borja",
            "Uf": "RS"
        },
        {
            "Id": 4989,
            "Codigo": 4318051,
            "Nome": "São Domingos do Sul",
            "Uf": "RS"
        },
        {
            "Id": 4990,
            "Codigo": 4318101,
            "Nome": "São Francisco de Assis",
            "Uf": "RS"
        },
        {
            "Id": 4991,
            "Codigo": 4318200,
            "Nome": "São Francisco de Paula",
            "Uf": "RS"
        },
        {
            "Id": 4992,
            "Codigo": 4318309,
            "Nome": "São Gabriel",
            "Uf": "RS"
        },
        {
            "Id": 4993,
            "Codigo": 4318408,
            "Nome": "São Jerônimo",
            "Uf": "RS"
        },
        {
            "Id": 4994,
            "Codigo": 4318424,
            "Nome": "São João da Urtiga",
            "Uf": "RS"
        },
        {
            "Id": 4995,
            "Codigo": 4318432,
            "Nome": "São João do Polêsine",
            "Uf": "RS"
        },
        {
            "Id": 4996,
            "Codigo": 4318440,
            "Nome": "São Jorge",
            "Uf": "RS"
        },
        {
            "Id": 4997,
            "Codigo": 4318457,
            "Nome": "São José das Missões",
            "Uf": "RS"
        },
        {
            "Id": 4998,
            "Codigo": 4318465,
            "Nome": "São José do Herval",
            "Uf": "RS"
        },
        {
            "Id": 4999,
            "Codigo": 4318481,
            "Nome": "São José do Hortêncio",
            "Uf": "RS"
        },
        {
            "Id": 5000,
            "Codigo": 4318499,
            "Nome": "São José do Inhacorá",
            "Uf": "RS"
        },
        {
            "Id": 5001,
            "Codigo": 4318507,
            "Nome": "São José do Norte",
            "Uf": "RS"
        },
        {
            "Id": 5002,
            "Codigo": 4318606,
            "Nome": "São José do Ouro",
            "Uf": "RS"
        },
        {
            "Id": 5003,
            "Codigo": 4318614,
            "Nome": "São José do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5004,
            "Codigo": 4318622,
            "Nome": "São José dos Ausentes",
            "Uf": "RS"
        },
        {
            "Id": 5005,
            "Codigo": 4318705,
            "Nome": "São Leopoldo",
            "Uf": "RS"
        },
        {
            "Id": 5006,
            "Codigo": 4318804,
            "Nome": "São Lourenço do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5007,
            "Codigo": 4318903,
            "Nome": "São Luiz Gonzaga",
            "Uf": "RS"
        },
        {
            "Id": 5008,
            "Codigo": 4319000,
            "Nome": "São Marcos",
            "Uf": "RS"
        },
        {
            "Id": 5009,
            "Codigo": 4319109,
            "Nome": "São Martinho",
            "Uf": "RS"
        },
        {
            "Id": 5010,
            "Codigo": 4319125,
            "Nome": "São Martinho da Serra",
            "Uf": "RS"
        },
        {
            "Id": 5011,
            "Codigo": 4319158,
            "Nome": "São Miguel das Missões",
            "Uf": "RS"
        },
        {
            "Id": 5012,
            "Codigo": 4319208,
            "Nome": "São Nicolau",
            "Uf": "RS"
        },
        {
            "Id": 5013,
            "Codigo": 4319307,
            "Nome": "São Paulo das Missões",
            "Uf": "RS"
        },
        {
            "Id": 5014,
            "Codigo": 4319356,
            "Nome": "São Pedro da Serra",
            "Uf": "RS"
        },
        {
            "Id": 5015,
            "Codigo": 4319364,
            "Nome": "São Pedro das Missões",
            "Uf": "RS"
        },
        {
            "Id": 5016,
            "Codigo": 4319372,
            "Nome": "São Pedro do Butiá",
            "Uf": "RS"
        },
        {
            "Id": 5017,
            "Codigo": 4319406,
            "Nome": "São Pedro do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5018,
            "Codigo": 4319505,
            "Nome": "São Sebastião do Caí",
            "Uf": "RS"
        },
        {
            "Id": 5019,
            "Codigo": 4319604,
            "Nome": "São Sepé",
            "Uf": "RS"
        },
        {
            "Id": 5020,
            "Codigo": 4319703,
            "Nome": "São Valentim",
            "Uf": "RS"
        },
        {
            "Id": 5021,
            "Codigo": 4319711,
            "Nome": "São Valentim do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5022,
            "Codigo": 4319737,
            "Nome": "São Valério do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5023,
            "Codigo": 4319752,
            "Nome": "São Vendelino",
            "Uf": "RS"
        },
        {
            "Id": 5024,
            "Codigo": 4319802,
            "Nome": "São Vicente do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5025,
            "Codigo": 4319901,
            "Nome": "Sapiranga",
            "Uf": "RS"
        },
        {
            "Id": 5026,
            "Codigo": 4320008,
            "Nome": "Sapucaia do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5027,
            "Codigo": 4320107,
            "Nome": "Sarandi",
            "Uf": "RS"
        },
        {
            "Id": 5028,
            "Codigo": 4320206,
            "Nome": "Seberi",
            "Uf": "RS"
        },
        {
            "Id": 5029,
            "Codigo": 4320230,
            "Nome": "Sede Nova",
            "Uf": "RS"
        },
        {
            "Id": 5030,
            "Codigo": 4320263,
            "Nome": "Segredo",
            "Uf": "RS"
        },
        {
            "Id": 5031,
            "Codigo": 4320305,
            "Nome": "Selbach",
            "Uf": "RS"
        },
        {
            "Id": 5032,
            "Codigo": 4320321,
            "Nome": "Senador Salgado Filho",
            "Uf": "RS"
        },
        {
            "Id": 5033,
            "Codigo": 4320354,
            "Nome": "Sentinela do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5034,
            "Codigo": 4320404,
            "Nome": "Serafina Corrêa",
            "Uf": "RS"
        },
        {
            "Id": 5035,
            "Codigo": 4320453,
            "Nome": "Sério",
            "Uf": "RS"
        },
        {
            "Id": 5036,
            "Codigo": 4320503,
            "Nome": "Sertão",
            "Uf": "RS"
        },
        {
            "Id": 5037,
            "Codigo": 4320552,
            "Nome": "Sertão Santana",
            "Uf": "RS"
        },
        {
            "Id": 5038,
            "Codigo": 4320578,
            "Nome": "Sete de Setembro",
            "Uf": "RS"
        },
        {
            "Id": 5039,
            "Codigo": 4320602,
            "Nome": "Severiano de Almeida",
            "Uf": "RS"
        },
        {
            "Id": 5040,
            "Codigo": 4320651,
            "Nome": "Silveira Martins",
            "Uf": "RS"
        },
        {
            "Id": 5041,
            "Codigo": 4320677,
            "Nome": "Sinimbu",
            "Uf": "RS"
        },
        {
            "Id": 5042,
            "Codigo": 4320701,
            "Nome": "Sobradinho",
            "Uf": "RS"
        },
        {
            "Id": 5043,
            "Codigo": 4320800,
            "Nome": "Soledade",
            "Uf": "RS"
        },
        {
            "Id": 5044,
            "Codigo": 4320859,
            "Nome": "Tabaí",
            "Uf": "RS"
        },
        {
            "Id": 5045,
            "Codigo": 4320909,
            "Nome": "Tapejara",
            "Uf": "RS"
        },
        {
            "Id": 5046,
            "Codigo": 4321006,
            "Nome": "Tapera",
            "Uf": "RS"
        },
        {
            "Id": 5047,
            "Codigo": 4321105,
            "Nome": "Tapes",
            "Uf": "RS"
        },
        {
            "Id": 5048,
            "Codigo": 4321204,
            "Nome": "Taquara",
            "Uf": "RS"
        },
        {
            "Id": 5049,
            "Codigo": 4321303,
            "Nome": "Taquari",
            "Uf": "RS"
        },
        {
            "Id": 5050,
            "Codigo": 4321329,
            "Nome": "Taquaruçu do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5051,
            "Codigo": 4321352,
            "Nome": "Tavares",
            "Uf": "RS"
        },
        {
            "Id": 5052,
            "Codigo": 4321402,
            "Nome": "Tenente Portela",
            "Uf": "RS"
        },
        {
            "Id": 5053,
            "Codigo": 4321436,
            "Nome": "Terra de Areia",
            "Uf": "RS"
        },
        {
            "Id": 5054,
            "Codigo": 4321451,
            "Nome": "Teutônia",
            "Uf": "RS"
        },
        {
            "Id": 5055,
            "Codigo": 4321469,
            "Nome": "Tio Hugo",
            "Uf": "RS"
        },
        {
            "Id": 5056,
            "Codigo": 4321477,
            "Nome": "Tiradentes do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5057,
            "Codigo": 4321493,
            "Nome": "Toropi",
            "Uf": "RS"
        },
        {
            "Id": 5058,
            "Codigo": 4321501,
            "Nome": "Torres",
            "Uf": "RS"
        },
        {
            "Id": 5059,
            "Codigo": 4321600,
            "Nome": "Tramandaí",
            "Uf": "RS"
        },
        {
            "Id": 5060,
            "Codigo": 4321626,
            "Nome": "Travesseiro",
            "Uf": "RS"
        },
        {
            "Id": 5061,
            "Codigo": 4321634,
            "Nome": "Três Arroios",
            "Uf": "RS"
        },
        {
            "Id": 5062,
            "Codigo": 4321667,
            "Nome": "Três Cachoeiras",
            "Uf": "RS"
        },
        {
            "Id": 5063,
            "Codigo": 4321709,
            "Nome": "Três Coroas",
            "Uf": "RS"
        },
        {
            "Id": 5064,
            "Codigo": 4321808,
            "Nome": "Três de Maio",
            "Uf": "RS"
        },
        {
            "Id": 5065,
            "Codigo": 4321832,
            "Nome": "Três Forquilhas",
            "Uf": "RS"
        },
        {
            "Id": 5066,
            "Codigo": 4321857,
            "Nome": "Três Palmeiras",
            "Uf": "RS"
        },
        {
            "Id": 5067,
            "Codigo": 4321907,
            "Nome": "Três Passos",
            "Uf": "RS"
        },
        {
            "Id": 5068,
            "Codigo": 4321956,
            "Nome": "Trindade do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5069,
            "Codigo": 4322004,
            "Nome": "Triunfo",
            "Uf": "RS"
        },
        {
            "Id": 5070,
            "Codigo": 4322103,
            "Nome": "Tucunduva",
            "Uf": "RS"
        },
        {
            "Id": 5071,
            "Codigo": 4322152,
            "Nome": "Tunas",
            "Uf": "RS"
        },
        {
            "Id": 5072,
            "Codigo": 4322186,
            "Nome": "Tupanci do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5073,
            "Codigo": 4322202,
            "Nome": "Tupanciretã",
            "Uf": "RS"
        },
        {
            "Id": 5074,
            "Codigo": 4322251,
            "Nome": "Tupandi",
            "Uf": "RS"
        },
        {
            "Id": 5075,
            "Codigo": 4322301,
            "Nome": "Tuparendi",
            "Uf": "RS"
        },
        {
            "Id": 5076,
            "Codigo": 4322327,
            "Nome": "Turuçu",
            "Uf": "RS"
        },
        {
            "Id": 5077,
            "Codigo": 4322343,
            "Nome": "Ubiretama",
            "Uf": "RS"
        },
        {
            "Id": 5078,
            "Codigo": 4322350,
            "Nome": "União da Serra",
            "Uf": "RS"
        },
        {
            "Id": 5079,
            "Codigo": 4322376,
            "Nome": "Unistalda",
            "Uf": "RS"
        },
        {
            "Id": 5080,
            "Codigo": 4322400,
            "Nome": "Uruguaiana",
            "Uf": "RS"
        },
        {
            "Id": 5081,
            "Codigo": 4322509,
            "Nome": "Vacaria",
            "Uf": "RS"
        },
        {
            "Id": 5082,
            "Codigo": 4322525,
            "Nome": "Vale Verde",
            "Uf": "RS"
        },
        {
            "Id": 5083,
            "Codigo": 4322533,
            "Nome": "Vale do Sol",
            "Uf": "RS"
        },
        {
            "Id": 5084,
            "Codigo": 4322541,
            "Nome": "Vale Real",
            "Uf": "RS"
        },
        {
            "Id": 5085,
            "Codigo": 4322558,
            "Nome": "Vanini",
            "Uf": "RS"
        },
        {
            "Id": 5086,
            "Codigo": 4322608,
            "Nome": "Venâncio Aires",
            "Uf": "RS"
        },
        {
            "Id": 5087,
            "Codigo": 4322707,
            "Nome": "Vera Cruz",
            "Uf": "RS"
        },
        {
            "Id": 5088,
            "Codigo": 4322806,
            "Nome": "Veranópolis",
            "Uf": "RS"
        },
        {
            "Id": 5089,
            "Codigo": 4322855,
            "Nome": "Vespasiano Correa",
            "Uf": "RS"
        },
        {
            "Id": 5090,
            "Codigo": 4322905,
            "Nome": "Viadutos",
            "Uf": "RS"
        },
        {
            "Id": 5091,
            "Codigo": 4323002,
            "Nome": "Viamão",
            "Uf": "RS"
        },
        {
            "Id": 5092,
            "Codigo": 4323101,
            "Nome": "Vicente Dutra",
            "Uf": "RS"
        },
        {
            "Id": 5093,
            "Codigo": 4323200,
            "Nome": "Victor Graeff",
            "Uf": "RS"
        },
        {
            "Id": 5094,
            "Codigo": 4323309,
            "Nome": "Vila Flores",
            "Uf": "RS"
        },
        {
            "Id": 5095,
            "Codigo": 4323358,
            "Nome": "Vila Lângaro",
            "Uf": "RS"
        },
        {
            "Id": 5096,
            "Codigo": 4323408,
            "Nome": "Vila Maria",
            "Uf": "RS"
        },
        {
            "Id": 5097,
            "Codigo": 4323457,
            "Nome": "Vila Nova do Sul",
            "Uf": "RS"
        },
        {
            "Id": 5098,
            "Codigo": 4323507,
            "Nome": "Vista Alegre",
            "Uf": "RS"
        },
        {
            "Id": 5099,
            "Codigo": 4323606,
            "Nome": "Vista Alegre do Prata",
            "Uf": "RS"
        },
        {
            "Id": 5100,
            "Codigo": 4323705,
            "Nome": "Vista Gaúcha",
            "Uf": "RS"
        },
        {
            "Id": 5101,
            "Codigo": 4323754,
            "Nome": "Vitória das Missões",
            "Uf": "RS"
        },
        {
            "Id": 5102,
            "Codigo": 4323770,
            "Nome": "Westfalia",
            "Uf": "RS"
        },
        {
            "Id": 5103,
            "Codigo": 4323804,
            "Nome": "Xangri-lá",
            "Uf": "RS"
        },
        {
            "Id": 5104,
            "Codigo": 5000203,
            "Nome": "Água Clara",
            "Uf": "MS"
        },
        {
            "Id": 5105,
            "Codigo": 5000252,
            "Nome": "Alcinópolis",
            "Uf": "MS"
        },
        {
            "Id": 5106,
            "Codigo": 5000609,
            "Nome": "Amambai",
            "Uf": "MS"
        },
        {
            "Id": 5107,
            "Codigo": 5000708,
            "Nome": "Anastácio",
            "Uf": "MS"
        },
        {
            "Id": 5108,
            "Codigo": 5000807,
            "Nome": "Anaurilândia",
            "Uf": "MS"
        },
        {
            "Id": 5109,
            "Codigo": 5000856,
            "Nome": "Angélica",
            "Uf": "MS"
        },
        {
            "Id": 5110,
            "Codigo": 5000906,
            "Nome": "Antônio João",
            "Uf": "MS"
        },
        {
            "Id": 5111,
            "Codigo": 5001003,
            "Nome": "Aparecida do Taboado",
            "Uf": "MS"
        },
        {
            "Id": 5112,
            "Codigo": 5001102,
            "Nome": "Aquidauana",
            "Uf": "MS"
        },
        {
            "Id": 5113,
            "Codigo": 5001243,
            "Nome": "Aral Moreira",
            "Uf": "MS"
        },
        {
            "Id": 5114,
            "Codigo": 5001508,
            "Nome": "Bandeirantes",
            "Uf": "MS"
        },
        {
            "Id": 5115,
            "Codigo": 5001904,
            "Nome": "Bataguassu",
            "Uf": "MS"
        },
        {
            "Id": 5116,
            "Codigo": 5002001,
            "Nome": "Batayporã",
            "Uf": "MS"
        },
        {
            "Id": 5117,
            "Codigo": 5002100,
            "Nome": "Bela Vista",
            "Uf": "MS"
        },
        {
            "Id": 5118,
            "Codigo": 5002159,
            "Nome": "Bodoquena",
            "Uf": "MS"
        },
        {
            "Id": 5119,
            "Codigo": 5002209,
            "Nome": "Bonito",
            "Uf": "MS"
        },
        {
            "Id": 5120,
            "Codigo": 5002308,
            "Nome": "Brasilândia",
            "Uf": "MS"
        },
        {
            "Id": 5121,
            "Codigo": 5002407,
            "Nome": "Caarapó",
            "Uf": "MS"
        },
        {
            "Id": 5122,
            "Codigo": 5002605,
            "Nome": "Camapuã",
            "Uf": "MS"
        },
        {
            "Id": 5123,
            "Codigo": 5002704,
            "Nome": "Campo Grande",
            "Uf": "MS"
        },
        {
            "Id": 5124,
            "Codigo": 5002803,
            "Nome": "Caracol",
            "Uf": "MS"
        },
        {
            "Id": 5125,
            "Codigo": 5002902,
            "Nome": "Cassilândia",
            "Uf": "MS"
        },
        {
            "Id": 5126,
            "Codigo": 5002951,
            "Nome": "Chapadão do Sul",
            "Uf": "MS"
        },
        {
            "Id": 5127,
            "Codigo": 5003108,
            "Nome": "Corguinho",
            "Uf": "MS"
        },
        {
            "Id": 5128,
            "Codigo": 5003157,
            "Nome": "Coronel Sapucaia",
            "Uf": "MS"
        },
        {
            "Id": 5129,
            "Codigo": 5003207,
            "Nome": "Corumbá",
            "Uf": "MS"
        },
        {
            "Id": 5130,
            "Codigo": 5003256,
            "Nome": "Costa Rica",
            "Uf": "MS"
        },
        {
            "Id": 5131,
            "Codigo": 5003306,
            "Nome": "Coxim",
            "Uf": "MS"
        },
        {
            "Id": 5132,
            "Codigo": 5003454,
            "Nome": "Deodápolis",
            "Uf": "MS"
        },
        {
            "Id": 5133,
            "Codigo": 5003488,
            "Nome": "Dois Irmãos do Buriti",
            "Uf": "MS"
        },
        {
            "Id": 5134,
            "Codigo": 5003504,
            "Nome": "Douradina",
            "Uf": "MS"
        },
        {
            "Id": 5135,
            "Codigo": 5003702,
            "Nome": "Dourados",
            "Uf": "MS"
        },
        {
            "Id": 5136,
            "Codigo": 5003751,
            "Nome": "Eldorado",
            "Uf": "MS"
        },
        {
            "Id": 5137,
            "Codigo": 5003801,
            "Nome": "Fátima do Sul",
            "Uf": "MS"
        },
        {
            "Id": 5138,
            "Codigo": 5003900,
            "Nome": "Figueirão",
            "Uf": "MS"
        },
        {
            "Id": 5139,
            "Codigo": 5004007,
            "Nome": "Glória de Dourados",
            "Uf": "MS"
        },
        {
            "Id": 5140,
            "Codigo": 5004106,
            "Nome": "Guia Lopes da Laguna",
            "Uf": "MS"
        },
        {
            "Id": 5141,
            "Codigo": 5004304,
            "Nome": "Iguatemi",
            "Uf": "MS"
        },
        {
            "Id": 5142,
            "Codigo": 5004403,
            "Nome": "Inocência",
            "Uf": "MS"
        },
        {
            "Id": 5143,
            "Codigo": 5004502,
            "Nome": "Itaporã",
            "Uf": "MS"
        },
        {
            "Id": 5144,
            "Codigo": 5004601,
            "Nome": "Itaquiraí",
            "Uf": "MS"
        },
        {
            "Id": 5145,
            "Codigo": 5004700,
            "Nome": "Ivinhema",
            "Uf": "MS"
        },
        {
            "Id": 5146,
            "Codigo": 5004809,
            "Nome": "Japorã",
            "Uf": "MS"
        },
        {
            "Id": 5147,
            "Codigo": 5004908,
            "Nome": "Jaraguari",
            "Uf": "MS"
        },
        {
            "Id": 5148,
            "Codigo": 5005004,
            "Nome": "Jardim",
            "Uf": "MS"
        },
        {
            "Id": 5149,
            "Codigo": 5005103,
            "Nome": "Jateí",
            "Uf": "MS"
        },
        {
            "Id": 5150,
            "Codigo": 5005152,
            "Nome": "Juti",
            "Uf": "MS"
        },
        {
            "Id": 5151,
            "Codigo": 5005202,
            "Nome": "Ladário",
            "Uf": "MS"
        },
        {
            "Id": 5152,
            "Codigo": 5005251,
            "Nome": "Laguna Carapã",
            "Uf": "MS"
        },
        {
            "Id": 5153,
            "Codigo": 5005400,
            "Nome": "Maracaju",
            "Uf": "MS"
        },
        {
            "Id": 5154,
            "Codigo": 5005608,
            "Nome": "Miranda",
            "Uf": "MS"
        },
        {
            "Id": 5155,
            "Codigo": 5005681,
            "Nome": "Mundo Novo",
            "Uf": "MS"
        },
        {
            "Id": 5156,
            "Codigo": 5005707,
            "Nome": "Naviraí",
            "Uf": "MS"
        },
        {
            "Id": 5157,
            "Codigo": 5005806,
            "Nome": "Nioaque",
            "Uf": "MS"
        },
        {
            "Id": 5158,
            "Codigo": 5006002,
            "Nome": "Nova Alvorada do Sul",
            "Uf": "MS"
        },
        {
            "Id": 5159,
            "Codigo": 5006200,
            "Nome": "Nova Andradina",
            "Uf": "MS"
        },
        {
            "Id": 5160,
            "Codigo": 5006259,
            "Nome": "Novo Horizonte do Sul",
            "Uf": "MS"
        },
        {
            "Id": 5161,
            "Codigo": 5006275,
            "Nome": "Paraíso das Águas",
            "Uf": "MS"
        },
        {
            "Id": 5162,
            "Codigo": 5006309,
            "Nome": "Paranaíba",
            "Uf": "MS"
        },
        {
            "Id": 5163,
            "Codigo": 5006358,
            "Nome": "Paranhos",
            "Uf": "MS"
        },
        {
            "Id": 5164,
            "Codigo": 5006408,
            "Nome": "Pedro Gomes",
            "Uf": "MS"
        },
        {
            "Id": 5165,
            "Codigo": 5006606,
            "Nome": "Ponta Porã",
            "Uf": "MS"
        },
        {
            "Id": 5166,
            "Codigo": 5006903,
            "Nome": "Porto Murtinho",
            "Uf": "MS"
        },
        {
            "Id": 5167,
            "Codigo": 5007109,
            "Nome": "Ribas do Rio Pardo",
            "Uf": "MS"
        },
        {
            "Id": 5168,
            "Codigo": 5007208,
            "Nome": "Rio Brilhante",
            "Uf": "MS"
        },
        {
            "Id": 5169,
            "Codigo": 5007307,
            "Nome": "Rio Negro",
            "Uf": "MS"
        },
        {
            "Id": 5170,
            "Codigo": 5007406,
            "Nome": "Rio Verde de Mato Grosso",
            "Uf": "MS"
        },
        {
            "Id": 5171,
            "Codigo": 5007505,
            "Nome": "Rochedo",
            "Uf": "MS"
        },
        {
            "Id": 5172,
            "Codigo": 5007554,
            "Nome": "Santa Rita do Pardo",
            "Uf": "MS"
        },
        {
            "Id": 5173,
            "Codigo": 5007695,
            "Nome": "São Gabriel do Oeste",
            "Uf": "MS"
        },
        {
            "Id": 5174,
            "Codigo": 5007703,
            "Nome": "Sete Quedas",
            "Uf": "MS"
        },
        {
            "Id": 5175,
            "Codigo": 5007802,
            "Nome": "Selvíria",
            "Uf": "MS"
        },
        {
            "Id": 5176,
            "Codigo": 5007901,
            "Nome": "Sidrolândia",
            "Uf": "MS"
        },
        {
            "Id": 5177,
            "Codigo": 5007935,
            "Nome": "Sonora",
            "Uf": "MS"
        },
        {
            "Id": 5178,
            "Codigo": 5007950,
            "Nome": "Tacuru",
            "Uf": "MS"
        },
        {
            "Id": 5179,
            "Codigo": 5007976,
            "Nome": "Taquarussu",
            "Uf": "MS"
        },
        {
            "Id": 5180,
            "Codigo": 5008008,
            "Nome": "Terenos",
            "Uf": "MS"
        },
        {
            "Id": 5181,
            "Codigo": 5008305,
            "Nome": "Três Lagoas",
            "Uf": "MS"
        },
        {
            "Id": 5182,
            "Codigo": 5008404,
            "Nome": "Vicentina",
            "Uf": "MS"
        },
        {
            "Id": 5183,
            "Codigo": 5100102,
            "Nome": "Acorizal",
            "Uf": "MT"
        },
        {
            "Id": 5184,
            "Codigo": 5100201,
            "Nome": "Água Boa",
            "Uf": "MT"
        },
        {
            "Id": 5185,
            "Codigo": 5100250,
            "Nome": "Alta Floresta",
            "Uf": "MT"
        },
        {
            "Id": 5186,
            "Codigo": 5100300,
            "Nome": "Alto Araguaia",
            "Uf": "MT"
        },
        {
            "Id": 5187,
            "Codigo": 5100359,
            "Nome": "Alto Boa Vista",
            "Uf": "MT"
        },
        {
            "Id": 5188,
            "Codigo": 5100409,
            "Nome": "Alto Garças",
            "Uf": "MT"
        },
        {
            "Id": 5189,
            "Codigo": 5100508,
            "Nome": "Alto Paraguai",
            "Uf": "MT"
        },
        {
            "Id": 5190,
            "Codigo": 5100607,
            "Nome": "Alto Taquari",
            "Uf": "MT"
        },
        {
            "Id": 5191,
            "Codigo": 5100805,
            "Nome": "Apiacás",
            "Uf": "MT"
        },
        {
            "Id": 5192,
            "Codigo": 5101001,
            "Nome": "Araguaiana",
            "Uf": "MT"
        },
        {
            "Id": 5193,
            "Codigo": 5101209,
            "Nome": "Araguainha",
            "Uf": "MT"
        },
        {
            "Id": 5194,
            "Codigo": 5101258,
            "Nome": "Araputanga",
            "Uf": "MT"
        },
        {
            "Id": 5195,
            "Codigo": 5101308,
            "Nome": "Arenápolis",
            "Uf": "MT"
        },
        {
            "Id": 5196,
            "Codigo": 5101407,
            "Nome": "Aripuanã",
            "Uf": "MT"
        },
        {
            "Id": 5197,
            "Codigo": 5101605,
            "Nome": "Barão de Melgaço",
            "Uf": "MT"
        },
        {
            "Id": 5198,
            "Codigo": 5101704,
            "Nome": "Barra do Bugres",
            "Uf": "MT"
        },
        {
            "Id": 5199,
            "Codigo": 5101803,
            "Nome": "Barra do Garças",
            "Uf": "MT"
        },
        {
            "Id": 5200,
            "Codigo": 5101852,
            "Nome": "Bom Jesus do Araguaia",
            "Uf": "MT"
        },
        {
            "Id": 5201,
            "Codigo": 5101902,
            "Nome": "Brasnorte",
            "Uf": "MT"
        },
        {
            "Id": 5202,
            "Codigo": 5102504,
            "Nome": "Cáceres",
            "Uf": "MT"
        },
        {
            "Id": 5203,
            "Codigo": 5102603,
            "Nome": "Campinápolis",
            "Uf": "MT"
        },
        {
            "Id": 5204,
            "Codigo": 5102637,
            "Nome": "Campo Novo do Parecis",
            "Uf": "MT"
        },
        {
            "Id": 5205,
            "Codigo": 5102678,
            "Nome": "Campo Verde",
            "Uf": "MT"
        },
        {
            "Id": 5206,
            "Codigo": 5102686,
            "Nome": "Campos de Júlio",
            "Uf": "MT"
        },
        {
            "Id": 5207,
            "Codigo": 5102694,
            "Nome": "Canabrava do Norte",
            "Uf": "MT"
        },
        {
            "Id": 5208,
            "Codigo": 5102702,
            "Nome": "Canarana",
            "Uf": "MT"
        },
        {
            "Id": 5209,
            "Codigo": 5102793,
            "Nome": "Carlinda",
            "Uf": "MT"
        },
        {
            "Id": 5210,
            "Codigo": 5102850,
            "Nome": "Castanheira",
            "Uf": "MT"
        },
        {
            "Id": 5211,
            "Codigo": 5103007,
            "Nome": "Chapada dos Guimarães",
            "Uf": "MT"
        },
        {
            "Id": 5212,
            "Codigo": 5103056,
            "Nome": "Cláudia",
            "Uf": "MT"
        },
        {
            "Id": 5213,
            "Codigo": 5103106,
            "Nome": "Cocalinho",
            "Uf": "MT"
        },
        {
            "Id": 5214,
            "Codigo": 5103205,
            "Nome": "Colíder",
            "Uf": "MT"
        },
        {
            "Id": 5215,
            "Codigo": 5103254,
            "Nome": "Colniza",
            "Uf": "MT"
        },
        {
            "Id": 5216,
            "Codigo": 5103304,
            "Nome": "Comodoro",
            "Uf": "MT"
        },
        {
            "Id": 5217,
            "Codigo": 5103353,
            "Nome": "Confresa",
            "Uf": "MT"
        },
        {
            "Id": 5218,
            "Codigo": 5103361,
            "Nome": "Conquista D'Oeste",
            "Uf": "MT"
        },
        {
            "Id": 5219,
            "Codigo": 5103379,
            "Nome": "Cotriguaçu",
            "Uf": "MT"
        },
        {
            "Id": 5220,
            "Codigo": 5103403,
            "Nome": "Cuiabá",
            "Uf": "MT"
        },
        {
            "Id": 5221,
            "Codigo": 5103437,
            "Nome": "Curvelândia",
            "Uf": "MT"
        },
        {
            "Id": 5222,
            "Codigo": 5103452,
            "Nome": "Denise",
            "Uf": "MT"
        },
        {
            "Id": 5223,
            "Codigo": 5103502,
            "Nome": "Diamantino",
            "Uf": "MT"
        },
        {
            "Id": 5224,
            "Codigo": 5103601,
            "Nome": "Dom Aquino",
            "Uf": "MT"
        },
        {
            "Id": 5225,
            "Codigo": 5103700,
            "Nome": "Feliz Natal",
            "Uf": "MT"
        },
        {
            "Id": 5226,
            "Codigo": 5103809,
            "Nome": "Figueirópolis D'Oeste",
            "Uf": "MT"
        },
        {
            "Id": 5227,
            "Codigo": 5103858,
            "Nome": "Gaúcha do Norte",
            "Uf": "MT"
        },
        {
            "Id": 5228,
            "Codigo": 5103908,
            "Nome": "General Carneiro",
            "Uf": "MT"
        },
        {
            "Id": 5229,
            "Codigo": 5103957,
            "Nome": "Glória D'Oeste",
            "Uf": "MT"
        },
        {
            "Id": 5230,
            "Codigo": 5104104,
            "Nome": "Guarantã do Norte",
            "Uf": "MT"
        },
        {
            "Id": 5231,
            "Codigo": 5104203,
            "Nome": "Guiratinga",
            "Uf": "MT"
        },
        {
            "Id": 5232,
            "Codigo": 5104500,
            "Nome": "Indiavaí",
            "Uf": "MT"
        },
        {
            "Id": 5233,
            "Codigo": 5104526,
            "Nome": "Ipiranga do Norte",
            "Uf": "MT"
        },
        {
            "Id": 5234,
            "Codigo": 5104542,
            "Nome": "Itanhangá",
            "Uf": "MT"
        },
        {
            "Id": 5235,
            "Codigo": 5104559,
            "Nome": "Itaúba",
            "Uf": "MT"
        },
        {
            "Id": 5236,
            "Codigo": 5104609,
            "Nome": "Itiquira",
            "Uf": "MT"
        },
        {
            "Id": 5237,
            "Codigo": 5104807,
            "Nome": "Jaciara",
            "Uf": "MT"
        },
        {
            "Id": 5238,
            "Codigo": 5104906,
            "Nome": "Jangada",
            "Uf": "MT"
        },
        {
            "Id": 5239,
            "Codigo": 5105002,
            "Nome": "Jauru",
            "Uf": "MT"
        },
        {
            "Id": 5240,
            "Codigo": 5105101,
            "Nome": "Juara",
            "Uf": "MT"
        },
        {
            "Id": 5241,
            "Codigo": 5105150,
            "Nome": "Juína",
            "Uf": "MT"
        },
        {
            "Id": 5242,
            "Codigo": 5105176,
            "Nome": "Juruena",
            "Uf": "MT"
        },
        {
            "Id": 5243,
            "Codigo": 5105200,
            "Nome": "Juscimeira",
            "Uf": "MT"
        },
        {
            "Id": 5244,
            "Codigo": 5105234,
            "Nome": "Lambari D'Oeste",
            "Uf": "MT"
        },
        {
            "Id": 5245,
            "Codigo": 5105259,
            "Nome": "Lucas do Rio Verde",
            "Uf": "MT"
        },
        {
            "Id": 5246,
            "Codigo": 5105309,
            "Nome": "Luciara",
            "Uf": "MT"
        },
        {
            "Id": 5247,
            "Codigo": 5105507,
            "Nome": "Vila Bela da Santíssima Trindade",
            "Uf": "MT"
        },
        {
            "Id": 5248,
            "Codigo": 5105580,
            "Nome": "Marcelândia",
            "Uf": "MT"
        },
        {
            "Id": 5249,
            "Codigo": 5105606,
            "Nome": "Matupá",
            "Uf": "MT"
        },
        {
            "Id": 5250,
            "Codigo": 5105622,
            "Nome": "Mirassol D'Oeste",
            "Uf": "MT"
        },
        {
            "Id": 5251,
            "Codigo": 5105903,
            "Nome": "Nobres",
            "Uf": "MT"
        },
        {
            "Id": 5252,
            "Codigo": 5106000,
            "Nome": "Nortelândia",
            "Uf": "MT"
        },
        {
            "Id": 5253,
            "Codigo": 5106109,
            "Nome": "Nossa Senhora do Livramento",
            "Uf": "MT"
        },
        {
            "Id": 5254,
            "Codigo": 5106158,
            "Nome": "Nova Bandeirantes",
            "Uf": "MT"
        },
        {
            "Id": 5255,
            "Codigo": 5106174,
            "Nome": "Nova Nazaré",
            "Uf": "MT"
        },
        {
            "Id": 5256,
            "Codigo": 5106182,
            "Nome": "Nova Lacerda",
            "Uf": "MT"
        },
        {
            "Id": 5257,
            "Codigo": 5106190,
            "Nome": "Nova Santa Helena",
            "Uf": "MT"
        },
        {
            "Id": 5258,
            "Codigo": 5106208,
            "Nome": "Nova Brasilândia",
            "Uf": "MT"
        },
        {
            "Id": 5259,
            "Codigo": 5106216,
            "Nome": "Nova Canaã do Norte",
            "Uf": "MT"
        },
        {
            "Id": 5260,
            "Codigo": 5106224,
            "Nome": "Nova Mutum",
            "Uf": "MT"
        },
        {
            "Id": 5261,
            "Codigo": 5106232,
            "Nome": "Nova Olímpia",
            "Uf": "MT"
        },
        {
            "Id": 5262,
            "Codigo": 5106240,
            "Nome": "Nova Ubiratã",
            "Uf": "MT"
        },
        {
            "Id": 5263,
            "Codigo": 5106257,
            "Nome": "Nova Xavantina",
            "Uf": "MT"
        },
        {
            "Id": 5264,
            "Codigo": 5106265,
            "Nome": "Novo Mundo",
            "Uf": "MT"
        },
        {
            "Id": 5265,
            "Codigo": 5106273,
            "Nome": "Novo Horizonte do Norte",
            "Uf": "MT"
        },
        {
            "Id": 5266,
            "Codigo": 5106281,
            "Nome": "Novo São Joaquim",
            "Uf": "MT"
        },
        {
            "Id": 5267,
            "Codigo": 5106299,
            "Nome": "Paranaíta",
            "Uf": "MT"
        },
        {
            "Id": 5268,
            "Codigo": 5106307,
            "Nome": "Paranatinga",
            "Uf": "MT"
        },
        {
            "Id": 5269,
            "Codigo": 5106315,
            "Nome": "Novo Santo Antônio",
            "Uf": "MT"
        },
        {
            "Id": 5270,
            "Codigo": 5106372,
            "Nome": "Pedra Preta",
            "Uf": "MT"
        },
        {
            "Id": 5271,
            "Codigo": 5106422,
            "Nome": "Peixoto de Azevedo",
            "Uf": "MT"
        },
        {
            "Id": 5272,
            "Codigo": 5106455,
            "Nome": "Planalto da Serra",
            "Uf": "MT"
        },
        {
            "Id": 5273,
            "Codigo": 5106505,
            "Nome": "Poconé",
            "Uf": "MT"
        },
        {
            "Id": 5274,
            "Codigo": 5106653,
            "Nome": "Pontal do Araguaia",
            "Uf": "MT"
        },
        {
            "Id": 5275,
            "Codigo": 5106703,
            "Nome": "Ponte Branca",
            "Uf": "MT"
        },
        {
            "Id": 5276,
            "Codigo": 5106752,
            "Nome": "Pontes e Lacerda",
            "Uf": "MT"
        },
        {
            "Id": 5277,
            "Codigo": 5106778,
            "Nome": "Porto Alegre do Norte",
            "Uf": "MT"
        },
        {
            "Id": 5278,
            "Codigo": 5106802,
            "Nome": "Porto dos Gaúchos",
            "Uf": "MT"
        },
        {
            "Id": 5279,
            "Codigo": 5106828,
            "Nome": "Porto Esperidião",
            "Uf": "MT"
        },
        {
            "Id": 5280,
            "Codigo": 5106851,
            "Nome": "Porto Estrela",
            "Uf": "MT"
        },
        {
            "Id": 5281,
            "Codigo": 5107008,
            "Nome": "Poxoréo",
            "Uf": "MT"
        },
        {
            "Id": 5282,
            "Codigo": 5107040,
            "Nome": "Primavera do Leste",
            "Uf": "MT"
        },
        {
            "Id": 5283,
            "Codigo": 5107065,
            "Nome": "Querência",
            "Uf": "MT"
        },
        {
            "Id": 5284,
            "Codigo": 5107107,
            "Nome": "São José dos Quatro Marcos",
            "Uf": "MT"
        },
        {
            "Id": 5285,
            "Codigo": 5107156,
            "Nome": "Reserva do Cabaçal",
            "Uf": "MT"
        },
        {
            "Id": 5286,
            "Codigo": 5107180,
            "Nome": "Ribeirão Cascalheira",
            "Uf": "MT"
        },
        {
            "Id": 5287,
            "Codigo": 5107198,
            "Nome": "Ribeirãozinho",
            "Uf": "MT"
        },
        {
            "Id": 5288,
            "Codigo": 5107206,
            "Nome": "Rio Branco",
            "Uf": "MT"
        },
        {
            "Id": 5289,
            "Codigo": 5107248,
            "Nome": "Santa Carmem",
            "Uf": "MT"
        },
        {
            "Id": 5290,
            "Codigo": 5107263,
            "Nome": "Santo Afonso",
            "Uf": "MT"
        },
        {
            "Id": 5291,
            "Codigo": 5107297,
            "Nome": "São José do Povo",
            "Uf": "MT"
        },
        {
            "Id": 5292,
            "Codigo": 5107305,
            "Nome": "São José do Rio Claro",
            "Uf": "MT"
        },
        {
            "Id": 5293,
            "Codigo": 5107354,
            "Nome": "São José do Xingu",
            "Uf": "MT"
        },
        {
            "Id": 5294,
            "Codigo": 5107404,
            "Nome": "São Pedro da Cipa",
            "Uf": "MT"
        },
        {
            "Id": 5295,
            "Codigo": 5107578,
            "Nome": "Rondolândia",
            "Uf": "MT"
        },
        {
            "Id": 5296,
            "Codigo": 5107602,
            "Nome": "Rondonópolis",
            "Uf": "MT"
        },
        {
            "Id": 5297,
            "Codigo": 5107701,
            "Nome": "Rosário Oeste",
            "Uf": "MT"
        },
        {
            "Id": 5298,
            "Codigo": 5107743,
            "Nome": "Santa Cruz do Xingu",
            "Uf": "MT"
        },
        {
            "Id": 5299,
            "Codigo": 5107750,
            "Nome": "Salto do Céu",
            "Uf": "MT"
        },
        {
            "Id": 5300,
            "Codigo": 5107768,
            "Nome": "Santa Rita do Trivelato",
            "Uf": "MT"
        },
        {
            "Id": 5301,
            "Codigo": 5107776,
            "Nome": "Santa Terezinha",
            "Uf": "MT"
        },
        {
            "Id": 5302,
            "Codigo": 5107792,
            "Nome": "Santo Antônio do Leste",
            "Uf": "MT"
        },
        {
            "Id": 5303,
            "Codigo": 5107800,
            "Nome": "Santo Antônio do Leverger",
            "Uf": "MT"
        },
        {
            "Id": 5304,
            "Codigo": 5107859,
            "Nome": "São Félix do Araguaia",
            "Uf": "MT"
        },
        {
            "Id": 5305,
            "Codigo": 5107875,
            "Nome": "Sapezal",
            "Uf": "MT"
        },
        {
            "Id": 5306,
            "Codigo": 5107883,
            "Nome": "Serra Nova Dourada",
            "Uf": "MT"
        },
        {
            "Id": 5307,
            "Codigo": 5107909,
            "Nome": "Sinop",
            "Uf": "MT"
        },
        {
            "Id": 5308,
            "Codigo": 5107925,
            "Nome": "Sorriso",
            "Uf": "MT"
        },
        {
            "Id": 5309,
            "Codigo": 5107941,
            "Nome": "Tabaporã",
            "Uf": "MT"
        },
        {
            "Id": 5310,
            "Codigo": 5107958,
            "Nome": "Tangará da Serra",
            "Uf": "MT"
        },
        {
            "Id": 5311,
            "Codigo": 5108006,
            "Nome": "Tapurah",
            "Uf": "MT"
        },
        {
            "Id": 5312,
            "Codigo": 5108055,
            "Nome": "Terra Nova do Norte",
            "Uf": "MT"
        },
        {
            "Id": 5313,
            "Codigo": 5108105,
            "Nome": "Tesouro",
            "Uf": "MT"
        },
        {
            "Id": 5314,
            "Codigo": 5108204,
            "Nome": "Torixoréu",
            "Uf": "MT"
        },
        {
            "Id": 5315,
            "Codigo": 5108303,
            "Nome": "União do Sul",
            "Uf": "MT"
        },
        {
            "Id": 5316,
            "Codigo": 5108352,
            "Nome": "Vale de São Domingos",
            "Uf": "MT"
        },
        {
            "Id": 5317,
            "Codigo": 5108402,
            "Nome": "Várzea Grande",
            "Uf": "MT"
        },
        {
            "Id": 5318,
            "Codigo": 5108501,
            "Nome": "Vera",
            "Uf": "MT"
        },
        {
            "Id": 5319,
            "Codigo": 5108600,
            "Nome": "Vila Rica",
            "Uf": "MT"
        },
        {
            "Id": 5320,
            "Codigo": 5108808,
            "Nome": "Nova Guarita",
            "Uf": "MT"
        },
        {
            "Id": 5321,
            "Codigo": 5108857,
            "Nome": "Nova Marilândia",
            "Uf": "MT"
        },
        {
            "Id": 5322,
            "Codigo": 5108907,
            "Nome": "Nova Maringá",
            "Uf": "MT"
        },
        {
            "Id": 5323,
            "Codigo": 5108956,
            "Nome": "Nova Monte Verde",
            "Uf": "MT"
        },
        {
            "Id": 5324,
            "Codigo": 5200050,
            "Nome": "Abadia de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5325,
            "Codigo": 5200100,
            "Nome": "Abadiânia",
            "Uf": "GO"
        },
        {
            "Id": 5326,
            "Codigo": 5200134,
            "Nome": "Acreúna",
            "Uf": "GO"
        },
        {
            "Id": 5327,
            "Codigo": 5200159,
            "Nome": "Adelândia",
            "Uf": "GO"
        },
        {
            "Id": 5328,
            "Codigo": 5200175,
            "Nome": "Água Fria de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5329,
            "Codigo": 5200209,
            "Nome": "Água Limpa",
            "Uf": "GO"
        },
        {
            "Id": 5330,
            "Codigo": 5200258,
            "Nome": "Águas Lindas de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5331,
            "Codigo": 5200308,
            "Nome": "Alexânia",
            "Uf": "GO"
        },
        {
            "Id": 5332,
            "Codigo": 5200506,
            "Nome": "Aloândia",
            "Uf": "GO"
        },
        {
            "Id": 5333,
            "Codigo": 5200555,
            "Nome": "Alto Horizonte",
            "Uf": "GO"
        },
        {
            "Id": 5334,
            "Codigo": 5200605,
            "Nome": "Alto Paraíso de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5335,
            "Codigo": 5200803,
            "Nome": "Alvorada do Norte",
            "Uf": "GO"
        },
        {
            "Id": 5336,
            "Codigo": 5200829,
            "Nome": "Amaralina",
            "Uf": "GO"
        },
        {
            "Id": 5337,
            "Codigo": 5200852,
            "Nome": "Americano do Brasil",
            "Uf": "GO"
        },
        {
            "Id": 5338,
            "Codigo": 5200902,
            "Nome": "Amorinópolis",
            "Uf": "GO"
        },
        {
            "Id": 5339,
            "Codigo": 5201108,
            "Nome": "Anápolis",
            "Uf": "GO"
        },
        {
            "Id": 5340,
            "Codigo": 5201207,
            "Nome": "Anhanguera",
            "Uf": "GO"
        },
        {
            "Id": 5341,
            "Codigo": 5201306,
            "Nome": "Anicuns",
            "Uf": "GO"
        },
        {
            "Id": 5342,
            "Codigo": 5201405,
            "Nome": "Aparecida de Goiânia",
            "Uf": "GO"
        },
        {
            "Id": 5343,
            "Codigo": 5201454,
            "Nome": "Aparecida do Rio Doce",
            "Uf": "GO"
        },
        {
            "Id": 5344,
            "Codigo": 5201504,
            "Nome": "Aporé",
            "Uf": "GO"
        },
        {
            "Id": 5345,
            "Codigo": 5201603,
            "Nome": "Araçu",
            "Uf": "GO"
        },
        {
            "Id": 5346,
            "Codigo": 5201702,
            "Nome": "Aragarças",
            "Uf": "GO"
        },
        {
            "Id": 5347,
            "Codigo": 5201801,
            "Nome": "Aragoiânia",
            "Uf": "GO"
        },
        {
            "Id": 5348,
            "Codigo": 5202155,
            "Nome": "Araguapaz",
            "Uf": "GO"
        },
        {
            "Id": 5349,
            "Codigo": 5202353,
            "Nome": "Arenópolis",
            "Uf": "GO"
        },
        {
            "Id": 5350,
            "Codigo": 5202502,
            "Nome": "Aruanã",
            "Uf": "GO"
        },
        {
            "Id": 5351,
            "Codigo": 5202601,
            "Nome": "Aurilândia",
            "Uf": "GO"
        },
        {
            "Id": 5352,
            "Codigo": 5202809,
            "Nome": "Avelinópolis",
            "Uf": "GO"
        },
        {
            "Id": 5353,
            "Codigo": 5203104,
            "Nome": "Baliza",
            "Uf": "GO"
        },
        {
            "Id": 5354,
            "Codigo": 5203203,
            "Nome": "Barro Alto",
            "Uf": "GO"
        },
        {
            "Id": 5355,
            "Codigo": 5203302,
            "Nome": "Bela Vista de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5356,
            "Codigo": 5203401,
            "Nome": "Bom Jardim de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5357,
            "Codigo": 5203500,
            "Nome": "Bom Jesus de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5358,
            "Codigo": 5203559,
            "Nome": "Bonfinópolis",
            "Uf": "GO"
        },
        {
            "Id": 5359,
            "Codigo": 5203575,
            "Nome": "Bonópolis",
            "Uf": "GO"
        },
        {
            "Id": 5360,
            "Codigo": 5203609,
            "Nome": "Brazabrantes",
            "Uf": "GO"
        },
        {
            "Id": 5361,
            "Codigo": 5203807,
            "Nome": "Britânia",
            "Uf": "GO"
        },
        {
            "Id": 5362,
            "Codigo": 5203906,
            "Nome": "Buriti Alegre",
            "Uf": "GO"
        },
        {
            "Id": 5363,
            "Codigo": 5203939,
            "Nome": "Buriti de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5364,
            "Codigo": 5203962,
            "Nome": "Buritinópolis",
            "Uf": "GO"
        },
        {
            "Id": 5365,
            "Codigo": 5204003,
            "Nome": "Cabeceiras",
            "Uf": "GO"
        },
        {
            "Id": 5366,
            "Codigo": 5204102,
            "Nome": "Cachoeira Alta",
            "Uf": "GO"
        },
        {
            "Id": 5367,
            "Codigo": 5204201,
            "Nome": "Cachoeira de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5368,
            "Codigo": 5204250,
            "Nome": "Cachoeira Dourada",
            "Uf": "GO"
        },
        {
            "Id": 5369,
            "Codigo": 5204300,
            "Nome": "Caçu",
            "Uf": "GO"
        },
        {
            "Id": 5370,
            "Codigo": 5204409,
            "Nome": "Caiapônia",
            "Uf": "GO"
        },
        {
            "Id": 5371,
            "Codigo": 5204508,
            "Nome": "Caldas Novas",
            "Uf": "GO"
        },
        {
            "Id": 5372,
            "Codigo": 5204557,
            "Nome": "Caldazinha",
            "Uf": "GO"
        },
        {
            "Id": 5373,
            "Codigo": 5204607,
            "Nome": "Campestre de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5374,
            "Codigo": 5204656,
            "Nome": "Campinaçu",
            "Uf": "GO"
        },
        {
            "Id": 5375,
            "Codigo": 5204706,
            "Nome": "Campinorte",
            "Uf": "GO"
        },
        {
            "Id": 5376,
            "Codigo": 5204805,
            "Nome": "Campo Alegre de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5377,
            "Codigo": 5204854,
            "Nome": "Campo Limpo de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5378,
            "Codigo": 5204904,
            "Nome": "Campos Belos",
            "Uf": "GO"
        },
        {
            "Id": 5379,
            "Codigo": 5204953,
            "Nome": "Campos Verdes",
            "Uf": "GO"
        },
        {
            "Id": 5380,
            "Codigo": 5205000,
            "Nome": "Carmo do Rio Verde",
            "Uf": "GO"
        },
        {
            "Id": 5381,
            "Codigo": 5205059,
            "Nome": "Castelândia",
            "Uf": "GO"
        },
        {
            "Id": 5382,
            "Codigo": 5205109,
            "Nome": "Catalão",
            "Uf": "GO"
        },
        {
            "Id": 5383,
            "Codigo": 5205208,
            "Nome": "Caturaí",
            "Uf": "GO"
        },
        {
            "Id": 5384,
            "Codigo": 5205307,
            "Nome": "Cavalcante",
            "Uf": "GO"
        },
        {
            "Id": 5385,
            "Codigo": 5205406,
            "Nome": "Ceres",
            "Uf": "GO"
        },
        {
            "Id": 5386,
            "Codigo": 5205455,
            "Nome": "Cezarina",
            "Uf": "GO"
        },
        {
            "Id": 5387,
            "Codigo": 5205471,
            "Nome": "Chapadão do Céu",
            "Uf": "GO"
        },
        {
            "Id": 5388,
            "Codigo": 5205497,
            "Nome": "Cidade Ocidental",
            "Uf": "GO"
        },
        {
            "Id": 5389,
            "Codigo": 5205513,
            "Nome": "Cocalzinho de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5390,
            "Codigo": 5205521,
            "Nome": "Colinas do Sul",
            "Uf": "GO"
        },
        {
            "Id": 5391,
            "Codigo": 5205703,
            "Nome": "Córrego do Ouro",
            "Uf": "GO"
        },
        {
            "Id": 5392,
            "Codigo": 5205802,
            "Nome": "Corumbá de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5393,
            "Codigo": 5205901,
            "Nome": "Corumbaíba",
            "Uf": "GO"
        },
        {
            "Id": 5394,
            "Codigo": 5206206,
            "Nome": "Cristalina",
            "Uf": "GO"
        },
        {
            "Id": 5395,
            "Codigo": 5206305,
            "Nome": "Cristianópolis",
            "Uf": "GO"
        },
        {
            "Id": 5396,
            "Codigo": 5206404,
            "Nome": "Crixás",
            "Uf": "GO"
        },
        {
            "Id": 5397,
            "Codigo": 5206503,
            "Nome": "Cromínia",
            "Uf": "GO"
        },
        {
            "Id": 5398,
            "Codigo": 5206602,
            "Nome": "Cumari",
            "Uf": "GO"
        },
        {
            "Id": 5399,
            "Codigo": 5206701,
            "Nome": "Damianópolis",
            "Uf": "GO"
        },
        {
            "Id": 5400,
            "Codigo": 5206800,
            "Nome": "Damolândia",
            "Uf": "GO"
        },
        {
            "Id": 5401,
            "Codigo": 5206909,
            "Nome": "Davinópolis",
            "Uf": "GO"
        },
        {
            "Id": 5402,
            "Codigo": 5207105,
            "Nome": "Diorama",
            "Uf": "GO"
        },
        {
            "Id": 5403,
            "Codigo": 5207253,
            "Nome": "Doverlândia",
            "Uf": "GO"
        },
        {
            "Id": 5404,
            "Codigo": 5207352,
            "Nome": "Edealina",
            "Uf": "GO"
        },
        {
            "Id": 5405,
            "Codigo": 5207402,
            "Nome": "Edéia",
            "Uf": "GO"
        },
        {
            "Id": 5406,
            "Codigo": 5207501,
            "Nome": "Estrela do Norte",
            "Uf": "GO"
        },
        {
            "Id": 5407,
            "Codigo": 5207535,
            "Nome": "Faina",
            "Uf": "GO"
        },
        {
            "Id": 5408,
            "Codigo": 5207600,
            "Nome": "Fazenda Nova",
            "Uf": "GO"
        },
        {
            "Id": 5409,
            "Codigo": 5207808,
            "Nome": "Firminópolis",
            "Uf": "GO"
        },
        {
            "Id": 5410,
            "Codigo": 5207907,
            "Nome": "Flores de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5411,
            "Codigo": 5208004,
            "Nome": "Formosa",
            "Uf": "GO"
        },
        {
            "Id": 5412,
            "Codigo": 5208103,
            "Nome": "Formoso",
            "Uf": "GO"
        },
        {
            "Id": 5413,
            "Codigo": 5208152,
            "Nome": "Gameleira de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5414,
            "Codigo": 5208301,
            "Nome": "Divinópolis de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5415,
            "Codigo": 5208400,
            "Nome": "Goianápolis",
            "Uf": "GO"
        },
        {
            "Id": 5416,
            "Codigo": 5208509,
            "Nome": "Goiandira",
            "Uf": "GO"
        },
        {
            "Id": 5417,
            "Codigo": 5208608,
            "Nome": "Goianésia",
            "Uf": "GO"
        },
        {
            "Id": 5418,
            "Codigo": 5208707,
            "Nome": "Goiânia",
            "Uf": "GO"
        },
        {
            "Id": 5419,
            "Codigo": 5208806,
            "Nome": "Goianira",
            "Uf": "GO"
        },
        {
            "Id": 5420,
            "Codigo": 5208905,
            "Nome": "Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5421,
            "Codigo": 5209101,
            "Nome": "Goiatuba",
            "Uf": "GO"
        },
        {
            "Id": 5422,
            "Codigo": 5209150,
            "Nome": "Gouvelândia",
            "Uf": "GO"
        },
        {
            "Id": 5423,
            "Codigo": 5209200,
            "Nome": "Guapó",
            "Uf": "GO"
        },
        {
            "Id": 5424,
            "Codigo": 5209291,
            "Nome": "Guaraíta",
            "Uf": "GO"
        },
        {
            "Id": 5425,
            "Codigo": 5209408,
            "Nome": "Guarani de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5426,
            "Codigo": 5209457,
            "Nome": "Guarinos",
            "Uf": "GO"
        },
        {
            "Id": 5427,
            "Codigo": 5209606,
            "Nome": "Heitoraí",
            "Uf": "GO"
        },
        {
            "Id": 5428,
            "Codigo": 5209705,
            "Nome": "Hidrolândia",
            "Uf": "GO"
        },
        {
            "Id": 5429,
            "Codigo": 5209804,
            "Nome": "Hidrolina",
            "Uf": "GO"
        },
        {
            "Id": 5430,
            "Codigo": 5209903,
            "Nome": "Iaciara",
            "Uf": "GO"
        },
        {
            "Id": 5431,
            "Codigo": 5209937,
            "Nome": "Inaciolândia",
            "Uf": "GO"
        },
        {
            "Id": 5432,
            "Codigo": 5209952,
            "Nome": "Indiara",
            "Uf": "GO"
        },
        {
            "Id": 5433,
            "Codigo": 5210000,
            "Nome": "Inhumas",
            "Uf": "GO"
        },
        {
            "Id": 5434,
            "Codigo": 5210109,
            "Nome": "Ipameri",
            "Uf": "GO"
        },
        {
            "Id": 5435,
            "Codigo": 5210158,
            "Nome": "Ipiranga de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5436,
            "Codigo": 5210208,
            "Nome": "Iporá",
            "Uf": "GO"
        },
        {
            "Id": 5437,
            "Codigo": 5210307,
            "Nome": "Israelândia",
            "Uf": "GO"
        },
        {
            "Id": 5438,
            "Codigo": 5210406,
            "Nome": "Itaberaí",
            "Uf": "GO"
        },
        {
            "Id": 5439,
            "Codigo": 5210562,
            "Nome": "Itaguari",
            "Uf": "GO"
        },
        {
            "Id": 5440,
            "Codigo": 5210604,
            "Nome": "Itaguaru",
            "Uf": "GO"
        },
        {
            "Id": 5441,
            "Codigo": 5210802,
            "Nome": "Itajá",
            "Uf": "GO"
        },
        {
            "Id": 5442,
            "Codigo": 5210901,
            "Nome": "Itapaci",
            "Uf": "GO"
        },
        {
            "Id": 5443,
            "Codigo": 5211008,
            "Nome": "Itapirapuã",
            "Uf": "GO"
        },
        {
            "Id": 5444,
            "Codigo": 5211206,
            "Nome": "Itapuranga",
            "Uf": "GO"
        },
        {
            "Id": 5445,
            "Codigo": 5211305,
            "Nome": "Itarumã",
            "Uf": "GO"
        },
        {
            "Id": 5446,
            "Codigo": 5211404,
            "Nome": "Itauçu",
            "Uf": "GO"
        },
        {
            "Id": 5447,
            "Codigo": 5211503,
            "Nome": "Itumbiara",
            "Uf": "GO"
        },
        {
            "Id": 5448,
            "Codigo": 5211602,
            "Nome": "Ivolândia",
            "Uf": "GO"
        },
        {
            "Id": 5449,
            "Codigo": 5211701,
            "Nome": "Jandaia",
            "Uf": "GO"
        },
        {
            "Id": 5450,
            "Codigo": 5211800,
            "Nome": "Jaraguá",
            "Uf": "GO"
        },
        {
            "Id": 5451,
            "Codigo": 5211909,
            "Nome": "Jataí",
            "Uf": "GO"
        },
        {
            "Id": 5452,
            "Codigo": 5212006,
            "Nome": "Jaupaci",
            "Uf": "GO"
        },
        {
            "Id": 5453,
            "Codigo": 5212055,
            "Nome": "Jesúpolis",
            "Uf": "GO"
        },
        {
            "Id": 5454,
            "Codigo": 5212105,
            "Nome": "Joviânia",
            "Uf": "GO"
        },
        {
            "Id": 5455,
            "Codigo": 5212204,
            "Nome": "Jussara",
            "Uf": "GO"
        },
        {
            "Id": 5456,
            "Codigo": 5212253,
            "Nome": "Lagoa Santa",
            "Uf": "GO"
        },
        {
            "Id": 5457,
            "Codigo": 5212303,
            "Nome": "Leopoldo de Bulhões",
            "Uf": "GO"
        },
        {
            "Id": 5458,
            "Codigo": 5212501,
            "Nome": "Luziânia",
            "Uf": "GO"
        },
        {
            "Id": 5459,
            "Codigo": 5212600,
            "Nome": "Mairipotaba",
            "Uf": "GO"
        },
        {
            "Id": 5460,
            "Codigo": 5212709,
            "Nome": "Mambaí",
            "Uf": "GO"
        },
        {
            "Id": 5461,
            "Codigo": 5212808,
            "Nome": "Mara Rosa",
            "Uf": "GO"
        },
        {
            "Id": 5462,
            "Codigo": 5212907,
            "Nome": "Marzagão",
            "Uf": "GO"
        },
        {
            "Id": 5463,
            "Codigo": 5212956,
            "Nome": "Matrinchã",
            "Uf": "GO"
        },
        {
            "Id": 5464,
            "Codigo": 5213004,
            "Nome": "Maurilândia",
            "Uf": "GO"
        },
        {
            "Id": 5465,
            "Codigo": 5213053,
            "Nome": "Mimoso de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5466,
            "Codigo": 5213087,
            "Nome": "Minaçu",
            "Uf": "GO"
        },
        {
            "Id": 5467,
            "Codigo": 5213103,
            "Nome": "Mineiros",
            "Uf": "GO"
        },
        {
            "Id": 5468,
            "Codigo": 5213400,
            "Nome": "Moiporá",
            "Uf": "GO"
        },
        {
            "Id": 5469,
            "Codigo": 5213509,
            "Nome": "Monte Alegre de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5470,
            "Codigo": 5213707,
            "Nome": "Montes Claros de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5471,
            "Codigo": 5213756,
            "Nome": "Montividiu",
            "Uf": "GO"
        },
        {
            "Id": 5472,
            "Codigo": 5213772,
            "Nome": "Montividiu do Norte",
            "Uf": "GO"
        },
        {
            "Id": 5473,
            "Codigo": 5213806,
            "Nome": "Morrinhos",
            "Uf": "GO"
        },
        {
            "Id": 5474,
            "Codigo": 5213855,
            "Nome": "Morro Agudo de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5475,
            "Codigo": 5213905,
            "Nome": "Mossâmedes",
            "Uf": "GO"
        },
        {
            "Id": 5476,
            "Codigo": 5214002,
            "Nome": "Mozarlândia",
            "Uf": "GO"
        },
        {
            "Id": 5477,
            "Codigo": 5214051,
            "Nome": "Mundo Novo",
            "Uf": "GO"
        },
        {
            "Id": 5478,
            "Codigo": 5214101,
            "Nome": "Mutunópolis",
            "Uf": "GO"
        },
        {
            "Id": 5479,
            "Codigo": 5214408,
            "Nome": "Nazário",
            "Uf": "GO"
        },
        {
            "Id": 5480,
            "Codigo": 5214507,
            "Nome": "Nerópolis",
            "Uf": "GO"
        },
        {
            "Id": 5481,
            "Codigo": 5214606,
            "Nome": "Niquelândia",
            "Uf": "GO"
        },
        {
            "Id": 5482,
            "Codigo": 5214705,
            "Nome": "Nova América",
            "Uf": "GO"
        },
        {
            "Id": 5483,
            "Codigo": 5214804,
            "Nome": "Nova Aurora",
            "Uf": "GO"
        },
        {
            "Id": 5484,
            "Codigo": 5214838,
            "Nome": "Nova Crixás",
            "Uf": "GO"
        },
        {
            "Id": 5485,
            "Codigo": 5214861,
            "Nome": "Nova Glória",
            "Uf": "GO"
        },
        {
            "Id": 5486,
            "Codigo": 5214879,
            "Nome": "Nova Iguaçu de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5487,
            "Codigo": 5214903,
            "Nome": "Nova Roma",
            "Uf": "GO"
        },
        {
            "Id": 5488,
            "Codigo": 5215009,
            "Nome": "Nova Veneza",
            "Uf": "GO"
        },
        {
            "Id": 5489,
            "Codigo": 5215207,
            "Nome": "Novo Brasil",
            "Uf": "GO"
        },
        {
            "Id": 5490,
            "Codigo": 5215231,
            "Nome": "Novo Gama",
            "Uf": "GO"
        },
        {
            "Id": 5491,
            "Codigo": 5215256,
            "Nome": "Novo Planalto",
            "Uf": "GO"
        },
        {
            "Id": 5492,
            "Codigo": 5215306,
            "Nome": "Orizona",
            "Uf": "GO"
        },
        {
            "Id": 5493,
            "Codigo": 5215405,
            "Nome": "Ouro Verde de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5494,
            "Codigo": 5215504,
            "Nome": "Ouvidor",
            "Uf": "GO"
        },
        {
            "Id": 5495,
            "Codigo": 5215603,
            "Nome": "Padre Bernardo",
            "Uf": "GO"
        },
        {
            "Id": 5496,
            "Codigo": 5215652,
            "Nome": "Palestina de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5497,
            "Codigo": 5215702,
            "Nome": "Palmeiras de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5498,
            "Codigo": 5215801,
            "Nome": "Palmelo",
            "Uf": "GO"
        },
        {
            "Id": 5499,
            "Codigo": 5215900,
            "Nome": "Palminópolis",
            "Uf": "GO"
        },
        {
            "Id": 5500,
            "Codigo": 5216007,
            "Nome": "Panamá",
            "Uf": "GO"
        },
        {
            "Id": 5501,
            "Codigo": 5216304,
            "Nome": "Paranaiguara",
            "Uf": "GO"
        },
        {
            "Id": 5502,
            "Codigo": 5216403,
            "Nome": "Paraúna",
            "Uf": "GO"
        },
        {
            "Id": 5503,
            "Codigo": 5216452,
            "Nome": "Perolândia",
            "Uf": "GO"
        },
        {
            "Id": 5504,
            "Codigo": 5216809,
            "Nome": "Petrolina de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5505,
            "Codigo": 5216908,
            "Nome": "Pilar de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5506,
            "Codigo": 5217104,
            "Nome": "Piracanjuba",
            "Uf": "GO"
        },
        {
            "Id": 5507,
            "Codigo": 5217203,
            "Nome": "Piranhas",
            "Uf": "GO"
        },
        {
            "Id": 5508,
            "Codigo": 5217302,
            "Nome": "Pirenópolis",
            "Uf": "GO"
        },
        {
            "Id": 5509,
            "Codigo": 5217401,
            "Nome": "Pires do Rio",
            "Uf": "GO"
        },
        {
            "Id": 5510,
            "Codigo": 5217609,
            "Nome": "Planaltina",
            "Uf": "GO"
        },
        {
            "Id": 5511,
            "Codigo": 5217708,
            "Nome": "Pontalina",
            "Uf": "GO"
        },
        {
            "Id": 5512,
            "Codigo": 5218003,
            "Nome": "Porangatu",
            "Uf": "GO"
        },
        {
            "Id": 5513,
            "Codigo": 5218052,
            "Nome": "Porteirão",
            "Uf": "GO"
        },
        {
            "Id": 5514,
            "Codigo": 5218102,
            "Nome": "Portelândia",
            "Uf": "GO"
        },
        {
            "Id": 5515,
            "Codigo": 5218300,
            "Nome": "Posse",
            "Uf": "GO"
        },
        {
            "Id": 5516,
            "Codigo": 5218391,
            "Nome": "Professor Jamil",
            "Uf": "GO"
        },
        {
            "Id": 5517,
            "Codigo": 5218508,
            "Nome": "Quirinópolis",
            "Uf": "GO"
        },
        {
            "Id": 5518,
            "Codigo": 5218607,
            "Nome": "Rialma",
            "Uf": "GO"
        },
        {
            "Id": 5519,
            "Codigo": 5218706,
            "Nome": "Rianápolis",
            "Uf": "GO"
        },
        {
            "Id": 5520,
            "Codigo": 5218789,
            "Nome": "Rio Quente",
            "Uf": "GO"
        },
        {
            "Id": 5521,
            "Codigo": 5218805,
            "Nome": "Rio Verde",
            "Uf": "GO"
        },
        {
            "Id": 5522,
            "Codigo": 5218904,
            "Nome": "Rubiataba",
            "Uf": "GO"
        },
        {
            "Id": 5523,
            "Codigo": 5219001,
            "Nome": "Sanclerlândia",
            "Uf": "GO"
        },
        {
            "Id": 5524,
            "Codigo": 5219100,
            "Nome": "Santa Bárbara de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5525,
            "Codigo": 5219209,
            "Nome": "Santa Cruz de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5526,
            "Codigo": 5219258,
            "Nome": "Santa Fé de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5527,
            "Codigo": 5219308,
            "Nome": "Santa Helena de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5528,
            "Codigo": 5219357,
            "Nome": "Santa Isabel",
            "Uf": "GO"
        },
        {
            "Id": 5529,
            "Codigo": 5219407,
            "Nome": "Santa Rita do Araguaia",
            "Uf": "GO"
        },
        {
            "Id": 5530,
            "Codigo": 5219456,
            "Nome": "Santa Rita do Novo Destino",
            "Uf": "GO"
        },
        {
            "Id": 5531,
            "Codigo": 5219506,
            "Nome": "Santa Rosa de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5532,
            "Codigo": 5219605,
            "Nome": "Santa Tereza de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5533,
            "Codigo": 5219704,
            "Nome": "Santa Terezinha de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5534,
            "Codigo": 5219712,
            "Nome": "Santo Antônio da Barra",
            "Uf": "GO"
        },
        {
            "Id": 5535,
            "Codigo": 5219738,
            "Nome": "Santo Antônio de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5536,
            "Codigo": 5219753,
            "Nome": "Santo Antônio do Descoberto",
            "Uf": "GO"
        },
        {
            "Id": 5537,
            "Codigo": 5219803,
            "Nome": "São Domingos",
            "Uf": "GO"
        },
        {
            "Id": 5538,
            "Codigo": 5219902,
            "Nome": "São Francisco de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5539,
            "Codigo": 5220009,
            "Nome": "São João D'Aliança",
            "Uf": "GO"
        },
        {
            "Id": 5540,
            "Codigo": 5220058,
            "Nome": "São João da Paraúna",
            "Uf": "GO"
        },
        {
            "Id": 5541,
            "Codigo": 5220108,
            "Nome": "São Luís de Montes Belos",
            "Uf": "GO"
        },
        {
            "Id": 5542,
            "Codigo": 5220157,
            "Nome": "São Luíz do Norte",
            "Uf": "GO"
        },
        {
            "Id": 5543,
            "Codigo": 5220207,
            "Nome": "São Miguel do Araguaia",
            "Uf": "GO"
        },
        {
            "Id": 5544,
            "Codigo": 5220264,
            "Nome": "São Miguel do Passa Quatro",
            "Uf": "GO"
        },
        {
            "Id": 5545,
            "Codigo": 5220280,
            "Nome": "São Patrício",
            "Uf": "GO"
        },
        {
            "Id": 5546,
            "Codigo": 5220405,
            "Nome": "São Simão",
            "Uf": "GO"
        },
        {
            "Id": 5547,
            "Codigo": 5220454,
            "Nome": "Senador Canedo",
            "Uf": "GO"
        },
        {
            "Id": 5548,
            "Codigo": 5220504,
            "Nome": "Serranópolis",
            "Uf": "GO"
        },
        {
            "Id": 5549,
            "Codigo": 5220603,
            "Nome": "Silvânia",
            "Uf": "GO"
        },
        {
            "Id": 5550,
            "Codigo": 5220686,
            "Nome": "Simolândia",
            "Uf": "GO"
        },
        {
            "Id": 5551,
            "Codigo": 5220702,
            "Nome": "Sítio D'Abadia",
            "Uf": "GO"
        },
        {
            "Id": 5552,
            "Codigo": 5221007,
            "Nome": "Taquaral de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5553,
            "Codigo": 5221080,
            "Nome": "Teresina de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5554,
            "Codigo": 5221197,
            "Nome": "Terezópolis de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5555,
            "Codigo": 5221304,
            "Nome": "Três Ranchos",
            "Uf": "GO"
        },
        {
            "Id": 5556,
            "Codigo": 5221403,
            "Nome": "Trindade",
            "Uf": "GO"
        },
        {
            "Id": 5557,
            "Codigo": 5221452,
            "Nome": "Trombas",
            "Uf": "GO"
        },
        {
            "Id": 5558,
            "Codigo": 5221502,
            "Nome": "Turvânia",
            "Uf": "GO"
        },
        {
            "Id": 5559,
            "Codigo": 5221551,
            "Nome": "Turvelândia",
            "Uf": "GO"
        },
        {
            "Id": 5560,
            "Codigo": 5221577,
            "Nome": "Uirapuru",
            "Uf": "GO"
        },
        {
            "Id": 5561,
            "Codigo": 5221601,
            "Nome": "Uruaçu",
            "Uf": "GO"
        },
        {
            "Id": 5562,
            "Codigo": 5221700,
            "Nome": "Uruana",
            "Uf": "GO"
        },
        {
            "Id": 5563,
            "Codigo": 5221809,
            "Nome": "Urutaí",
            "Uf": "GO"
        },
        {
            "Id": 5564,
            "Codigo": 5221858,
            "Nome": "Valparaíso de Goiás",
            "Uf": "GO"
        },
        {
            "Id": 5565,
            "Codigo": 5221908,
            "Nome": "Varjão",
            "Uf": "GO"
        },
        {
            "Id": 5566,
            "Codigo": 5222005,
            "Nome": "Vianópolis",
            "Uf": "GO"
        },
        {
            "Id": 5567,
            "Codigo": 5222054,
            "Nome": "Vicentinópolis",
            "Uf": "GO"
        },
        {
            "Id": 5568,
            "Codigo": 5222203,
            "Nome": "Vila Boa",
            "Uf": "GO"
        },
        {
            "Id": 5569,
            "Codigo": 5222302,
            "Nome": "Vila Propício",
            "Uf": "GO"
        },
        {
            "Id": 5570,
            "Codigo": 5300108,
            "Nome": "Brasília",
            "Uf": "DF"
        }
    ]

export const uf = [
    {"nome": "Acre", "sigla": "AC"},
    {"nome": "Alagoas", "sigla": "AL"},
    {"nome": "Amapá", "sigla": "AP"},
    {"nome": "Amazonas", "sigla": "AM"},
    {"nome": "Bahia", "sigla": "BA"},
    {"nome": "Ceará", "sigla": "CE"},
    {"nome": "Distrito Federal", "sigla": "DF"},
    {"nome": "Espírito Santo", "sigla": "ES"},
    {"nome": "Goiás", "sigla": "GO"},
    {"nome": "Maranhão", "sigla": "MA"},
    {"nome": "Mato Grosso", "sigla": "MT"},
    {"nome": "Mato Grosso do Sul", "sigla": "MS"},
    {"nome": "Minas Gerais", "sigla": "MG"},
    {"nome": "Pará", "sigla": "PA"},
    {"nome": "Paraíba", "sigla": "PB"},
    {"nome": "Paraná", "sigla": "PR"},
    {"nome": "Pernambuco", "sigla": "PE"},
    {"nome": "Piauí", "sigla": "PI"},
    {"nome": "Rio de Janeiro", "sigla": "RJ"},
    {"nome": "Rio Grande do Norte", "sigla": "RN"},
    {"nome": "Rio Grande do Sul", "sigla": "RS"},
    {"nome": "Rondônia", "sigla": "RO"},
    {"nome": "Roraima", "sigla": "RR"},
    {"nome": "Santa Catarina", "sigla": "SC"},
    {"nome": "São Paulo", "sigla": "SP"},
    {"nome": "Sergipe", "sigla": "SE"},
    {"nome": "Tocantins", "sigla": "TO"}

]