import arca_logo from '../images/arca_logo_branca.png'
import { Contato } from './Contato';
import { MainPage } from './Main';
import { Solucoes } from './Solucoes';
import { Clientes } from './Clientes';
import { useState, useEffect } from 'react';

export function Publicidade(props:any){

    const [page, setPage] = useState(0)

    const menu = [
        {texto: 'CLIENTES', state:3},
        {texto:'SOLUÇÕES', state:1},
        {texto:'CONTATO', state:2},
      ]

    return(
        <div>
            <div style={{top:'0', position:'fixed', padding:'2vh 4vw', zIndex:'100', width:'90vw'}}>
            <div style={{float:'left', cursor:'pointer'}} onClick={()=>{setPage(0)}}>
            <img src={arca_logo} width={props.mobile ? '70px' : '100px'}/>
            </div>
            <div style={{float:'right'}}>
            {menu.map((item:any,ind:any)=>{
                return(
                <div key={ind}
                    style={{fontFamily:'lemon', color:'#fff', float:'left', marginLeft:'2vw', marginTop:'10px', cursor:'pointer', fontSize:props.mobile ? '10px' : '16px'}}
                    onClick={()=>{setPage(item.state)}}>
                    {item.texto}
                </div>
                )
            })}
            <div style={{clear:'both'}}/>
            </div>
            <div style={{clear:'both'}}/>
        </div>
            {page==0&&(<MainPage height={props.sc_height} width={props.sc_width} mobile={props.mobile}/>)}
            {page==1&&(<Solucoes height={props.sc_height} width={props.sc_width} mobile={props.mobile}/>)}
            {page==2&&(<Contato height={props.sc_height} width={props.sc_width} mobile={props.mobile}/>)}
            {page==3&&(<Clientes height={props.sc_height} width={props.sc_width} mobile={props.mobile}/>)}
        </div>
    )
}