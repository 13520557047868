import { useEffect, useState } from "react"
import { produtos } from "./produtos"
import { useSpring, animated } from "react-spring"
import { ExpandableProduct } from "./ExpandableProduct"

export function MainPage(props:any){

    return(
        <div>
            {produtos.map((produto:any, index:any)=>{
                return(
                <div style={{display:'flex', width:'100vw', height:(produto.height/produto.width)*100+'vw', backgroundImage:'url('+produto.foto+')', backgroundSize:'100%'}} key={index}>
                    <div style={{backgroundColor:'#000', height:(produto.height/produto.width)*100+'vw', width:'100%', position:'absolute', opacity:'0.3'}}/>
                    <div style={{display:'flex', marginLeft:'10vw', marginTop:(produto.height/produto.width)*50+'vw', fontFamily:'lemon', fontSize: props.mobile ? '5vw' : '4vw', color:'#fff', transform:'translateY(-4vw)'}}>
                        <ExpandableProduct nome={produto.nome} mobile={props.mobile} link={produto.link}/>
                    </div>
                </div>
                )
            })}
        </div>
    )
}