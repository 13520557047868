export const  Termos = [
    'DISPOSIÇõES GERAIS',
    'O LABORATÓRIO CONSISTE EM 5 HORAS DE ACOMPANHAMENTO DE GRAVAÇÃO EM ESTÚDIO, SENDO PRESENCIAL OU ONLINE, DEPENDENDO DA MODALIDADE ESCOLHIDA NA HORA DA CONTRATAÇÃO.',
    'DAS INFORMAÇÕES',
    'AO ENVIAR AS INFORMAÇÕES, O PARTICIPANTE DECLARA QUE TODAS SÃO REAIS E ESTÃO CORRETAS, NÃO SENDO DE RESPONSABILIDADE DA CONTRATADA ERROS POR INCONSISTÊNCIAS NAS INFORMAÇÕES FORNECIDAS',
    'DO PRAZO',
    'O EVENTO OCORRERÁ EM DIA E HORA ESPECÍFICO INFORMADOS PREVIAMENTE AOS PARTICIPANTES. CASO HAJA IMPOSSIBILIDADE DE PRESENÇA OU ATRASO POR PARTE DO PARTICIPANTE, A CONTRATADA SE EXIME DE RESPONSABILIDADE.',
    'DO CANCELAMENTO OU DESISTÊNCIA',
    'O CANCELAMENTO DA COMPRA PODE SER FEITO EM ATÉ 7 DIAS APÓS A COMPRA, DE ACORDO COM O CÓDIGO DE DEFESA DO CONSUMIDOR. PARA EFETUAR O CANCELAMENTO, ENTRE EM CONTATO ATRAVÉS DO E-MAIL CONTATO@ARCAENTRETENIMENTO.COM.BR',
    'ALTERAÇÕES',
    'A CONTRATADA SE RESERVA NO DIREITO DE ALTERAR A DATA E HORÁRIO A QUALQUER MOMENTO PRÉVIO AO EVENTO, TENDO COMO RESPONSABILIDADE INFORMAR DEVIDAMENTE AOS PARTICIPAMENTES ATRAVÉS DO E-MAIL FORNECIDO NA CONTRATAÇÃO',
    'LGPD',
    'A Lei Geral de Proteção de Dados será obedecida, em todos os seus termos, pela CONTRATADA, obrigando-se ela a tratar os dados da CONTRATANTE que forem eventualmente coletados, conforme sua necessidade ou obrigatoriedade. (art. 7o, LGPD)'
]