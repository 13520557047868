import { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";

export function ExpandableProduct(props:any){

    const [open, setOpen] = useState(false)

    const expandableProduct = useSpring({
        from:{
            transform: 'translateY(0)',
            cursor:'pointer',
        },
        to:{
            transform: open ? 'translateY(-10vw)' : 'translateY(0%)'
        },
        config:{
            duration: 100
        }
    })
    const video = useSpring({
        from:{
            opacity:'0%',
        },
        to: {
            opacity: open ? '100%' : '0%',
        },
        config:{
            duration:100
        }
    })

    const link = 'https://player.vimeo.com/video/'+props.link

    return(
        <animated.div style={expandableProduct} onClick={()=>{setOpen(!open)}}>
            <div>
                {props.nome}
            </div>
            <animated.div style={video}>
                {props.mobile&&(
                    <iframe src={link} width='200' height='90' allow="autoplay; fullscreen" style={{border:'none', margin:'0', padding:'0'}}></iframe>
                )||(
                    <iframe src={link} width='640' height='350' allow="autoplay; fullscreen" style={{border:'none', margin:'0', padding:'0'}}></iframe>
                )}
                {/* <iframe width={frame.w} height={frame.h} src={link} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
            </animated.div>
        </animated.div>
    )
}