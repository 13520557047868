import { useState, useEffect } from "react"
import capa_ludmilla from '../images/capas/LUDMILLA.jpg'
import capa_giulia from '../images/capas/GIULIA.jpg'
import capa_jao from '../images/capas/JAO.jpg'
import capa_combatchy from '../images/capas/COMBATCHY.jpg'
import capa_2step from '../images/capas/2STEP.jpg'
import capa_hermeto from '../images/capas/HERMETO.jpeg'
import capa_anavitoria from '../images/capas/ANAVITORIA.jpg'
import capa_bossanegra from '../images/capas/BOSSA_NEGRA.jpg'
import capa_mundue from '../images/capas/MUNDUE.jpg'
import capa_tiagoiorc from '../images/capas/TIAGO IORC.jpg'
import capa_martinho from '../images/capas/MARTINHO.jpg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { animated, useSpring } from "react-spring";

export function ImageSlider(props:any){

    const allList = [
        {
            name:'LUDMILLA',
            image:capa_ludmilla,
        },
        {
            name:'GIULIA',
            image:capa_giulia
        },
        {
            name:'2STEP',
            image:capa_2step
        },
        {
            name:'COMBATCHY',
            image:capa_combatchy
        },
        
        {
            name:'HERMETO',
            image:capa_hermeto
        },
        {
            name:'ANAVITORIA',
            image:capa_anavitoria
        },
        {
            name:'BOSSA_NEGRA',
            image:capa_bossanegra
        },
        {
            name:'MUNDUE',
            image:capa_mundue
        },
        {
            name:'TIAGOIORC',
            image:capa_tiagoiorc
        },
        {
            name:'MARTINHO',
            image:capa_martinho
        },
        {
            name:'JAO',
            image:capa_jao
        },
    ]

    const randomDefault = allList.indexOf(allList[Math.floor(Math.random() * allList.length)])

    const [directionLeft, setDirectionLeft] = useState(true)
    const [mainImage, setMainImage] = useState(allList[randomDefault].name)
    const [carousel, setCarousel] = useState(setupCarousel(setCommandList(randomDefault)))

    const mainIndex = carousel.indexOf(carousel.find((o: { name: any })=>o.name==mainImage)!)
    const leftIndex = mainIndex==0?carousel.length-1:mainIndex-1
    const rightIndex = mainIndex==carousel.length-1?0:mainIndex+1
    const left_leftIndex = leftIndex==0?carousel.length-1:leftIndex-1
    const right_rightIndex = rightIndex==carousel.length-1?0:rightIndex+1

    function getIndex(orientation:string){
        let mIndex = allList.indexOf(allList.find(o=>o.name==mainImage)!)
        if(orientation=='left'){
            if(mIndex!=0){
                return(mIndex-1)
            } else {
                return(allList.length-1)
            }
        }
        if(orientation=='right'){
            if(mIndex!=allList.length-1){
                return(mIndex+1)
            } else {
                return(0)
            }
        }
        if(orientation=='leftleft'){
            if(mIndex>=2){
                return(mIndex-2)
            } else {
                return(allList.length-2+mIndex)
            }
        }
        if(orientation=='rightright'){
            if(mIndex<=allList.length-3){
                return(mIndex+2)
            } else {
                let math = allList.length-mIndex-2
                if(math<0){math=math*-1}
                return(math)
            }
        }
    }

    function setCommandList(masterIndex:number){
        let cmdList = []
        allList.forEach((key)=>{
            cmdList.push('none')
        })
        cmdList[masterIndex] = 'main'
        cmdList[getIndex('left')!] = 'left'
        cmdList[getIndex('right')!] = 'right'
        cmdList[getIndex('leftleft')!] = 'leftleft'
        cmdList[getIndex('rightright')!] = 'rightright'
        return(cmdList)
    }

    function setupCarousel(commandList:any){
        let newArr:any = []
        allList.forEach((key, index)=>{
            key = {...key, ...{position:commandList[index]}}
            newArr.push(key)
        })
        return(newArr)
    }

    const imageSize = {
        expanded: props.mobile?230:300,
        mini: props.mobile?200:250,
        minor: props.mobile?170:210,
    }
    const translate = {
        main:'translate(-'+imageSize.expanded/2+'px, 0px)',
        left:props.mobile?'translate(-'+imageSize.mini/1.2+'px, '+imageSize.expanded/20+'px)':'translate(-'+imageSize.mini+'px, '+imageSize.expanded/10+'px)',
        right:props.mobile?'translate(-'+imageSize.mini/7+'px, '+imageSize.expanded/20+'px)':'translate('+0+'px, '+imageSize.expanded/10+'px)',
        leftleft:props.mobile?'translate(-'+imageSize.minor*1.15+'px, '+10+'px)':'translate(-'+imageSize.minor*1.5+'px, '+10+'px)',
        rightright:props.mobile?'translate('+imageSize.minor/6.5+'px, '+10+'px)':'translate('+imageSize.minor/2+'px, '+10+'px)'
    }
    const opacity = {
        main:1,
        side:0.5
    }
    const arrowSize = 60

    const ZIndexStyle =(type:string)=>{
        const originalZindex =
            type=='main'?1
            :
            type=='left'?
                0
            :
            type=='right'?
                0
            :
                -1
        const destZindex =
            type=='main'?
                1
            :
            type=='left'?
                0
            :
            type=='right'?
                0
            :
                -1
        return(
        useSpring({
            config:{duration:0},
            from:{
                zIndex:originalZindex
            },
            to:{
                zIndex:destZindex
            }
        })
    ) 
    }

    const SpringStyle =(type:string)=>{
                
        const originalSize =
            type=='main'?
                imageSize.mini
            :
            type=='left'?
                directionLeft?
                    imageSize.minor
                :
                    imageSize.expanded
            :
            type=='right'?
                directionLeft?
                    imageSize.expanded
                    :
                    imageSize.minor
            :
                imageSize.minor

        const destSize =
            type=='main'?
                imageSize.expanded
            :
            type=='left'?
                    imageSize.mini
            :
            type=='right'?
                    imageSize.mini
            :
                    imageSize.minor

        const originalTransform =
            type=='main'?
                directionLeft?
                    translate.left
                    :
                    translate.right
            :
            type=='left'?
                directionLeft?
                    translate.leftleft
                :
                    translate.main
            :
            type=='right'?
                directionLeft?
                    translate.main
                :
                    translate.rightright
            :
            type=='leftleft'?
                directionLeft?
                    translate.left
                :
                    translate.rightright
            :
            type=='rightright'?
                directionLeft?
                    translate.leftleft
                :
                    translate.right
            :
                translate.main

        const destTransform =
            type=='main'?
                translate.main
            :
            type=='left'?
                translate.left
            :
            type=='right'?
                translate.right
            :
            type=='leftleft'?
                    translate.leftleft
            :
            type=='rightright'?
                    translate.rightright
            :
                translate.main
        
        return(
            useSpring({
        from:{
            width:originalSize+'px',
            height:originalSize+'px',
            backgroundColor:'#000',
            left:'50%',
            transform:originalTransform,
            boxShadow:'2px 2px 12px',
        },
        to:{
            width:destSize+'px',
            height:destSize+'px',
            backgroundColor:'#000',
            left:'50%',
            transform:destTransform,
            boxShadow:'2px 2px 12px',
        }
    })
    )
    }

    const SpringStyleContent=(type:string,image:any)=>{

        const originalSize =
        type=='main'?
            imageSize.mini
        :
        type=='left'?
            imageSize.mini
        :
        type=='right'?
            directionLeft?
                imageSize.expanded
            :
                imageSize.minor
        :
            imageSize.minor

            const destSize =
            type=='main'?
                imageSize.expanded
            :
            type=='left'?
                imageSize.mini
            :
            type=='right'?
                imageSize.mini
            :
            type=='leftleft'?
                    imageSize.minor
            :
                    imageSize.minor

        const originalOpacity =
            type=='none'?
                0
            :
            type=='main'?
                opacity.side
            :
            type=='right'?
                directionLeft?
                    opacity.main
                :
                    opacity.side
            :
            type=='left'?
                directionLeft?
                    opacity.side
                :
                    opacity.main
            :
                opacity.side

        const destOpacity =
            type=='none'?
                0
            :
            type=='main'?
                opacity.main
            :
            opacity.side

        return(
            useSpring({
                from:{
                    width:originalSize+'px',
                    height:originalSize+'px',
                    backgroundImage:'url("'+image+'")',
                    backgroundSize:'cover',
                    opacity:originalOpacity
                },
                to:{
                    width:destSize+'px',
                    height:destSize+'px',
                    backgroundImage:'url("'+image+'")',
                    backgroundSize:'cover',
                    opacity:destOpacity,
                }
            })
        )
    }

    async function handleArrows(left:boolean){
        setDirectionLeft(left)
        setMainImage(left?carousel[leftIndex].name:carousel[rightIndex].name)
    }

    useEffect(()=>{
        setCarousel(setupCarousel(setCommandList(carousel.indexOf(carousel.find((o: { name: any })=>o.name==mainImage)!))))
    },[mainImage])

    return(
        <div style={{height:imageSize.expanded+'px'}}>
            <div style={{
                color:'#fff',
                position:'absolute',
                marginTop:(imageSize.expanded/2)-(arrowSize/2)+'px',
                left:'50%',
                transform:props.mobile?'translate(-'+imageSize.expanded*0.75+'px)':'translate(-'+imageSize.expanded*1.1+'px)',
                cursor:'pointer',
                zIndex:'4'
                }} onClick={()=>{handleArrows(true)}}>
                <ArrowBackIosIcon style={{fontSize:arrowSize+'px'}}/>
            </div>
            <div style={{
                color:'#fff',
                position:'absolute',
                marginTop:(imageSize.expanded/2)-(arrowSize/2)+'px',
                transform:props.mobile?'translate('+imageSize.expanded*1.29+'px)':'translate('+imageSize.expanded*2.1+'px)',
                cursor:'pointer',
                zIndex:'4'
                }} onClick={()=>{handleArrows(false)}}>
                <ArrowForwardIosIcon style={{fontSize:arrowSize+'px'}}/>
            </div>
            {carousel.map((object:any, index:any)=>{
                return(
                    <animated.div key={index} style={{
                        ...SpringStyle(object.position),
                        ...ZIndexStyle(object.position),
                        ...{position:'absolute', opacity:object.position=='none'?0:1}
                    }}>
                        <animated.div style={SpringStyleContent(object.position,object.image)}/>
                    </animated.div>
                )
            })}
                {/* {carousel.map((object:any, index:any)=>{
                    if(index==left_leftIndex){
                        return(
                            <animated.div key={index} style={{
                                ...SpringStyle('leftleft'),
                                ...ZIndexStyle('leftleft'),
                                ...{position:'absolute'}
                            }}>
                                    <animated.div style={SpringStyleContent('leftleft',object.image)}></animated.div>
                            </animated.div>
                        )
                    }
                    if(index==leftIndex){
                        return(
                            <animated.div key={index} style={{
                                ...SpringStyle('left'),
                                ...ZIndexStyle('left'),
                                ...{position:'absolute'}
                            }}>
                                    <animated.div style={SpringStyleContent('left',object.image)}></animated.div>
                            </animated.div>
                        )
                    }
                    if(index==mainIndex){
                        return(
                            <animated.div key={index} style={{
                                ...SpringStyle('main'),
                                ...ZIndexStyle('main'),
                                ...{position:'absolute'}
                                }}>
                                <animated.div style={SpringStyleContent('main',object.image)}></animated.div>
                            </animated.div> 
                        )
                    }
                    if(index==rightIndex){
                        return(
                            <animated.div key={index} style={{
                                ...SpringStyle('right'),
                                ...ZIndexStyle('right'),
                                ...{position:'absolute'}
                            }}>
                                    <animated.div style={SpringStyleContent('right',object.image)}></animated.div>
                                </animated.div>
                        )
                    }
                    if(index==right_rightIndex){
                        return(
                            <animated.div key={index} style={{
                                ...SpringStyle('rightright'),
                                ...ZIndexStyle('rightright'),
                                ...{position:'absolute'}
                            }}>
                                    <animated.div style={SpringStyleContent('rightright',object.image)}></animated.div>
                            </animated.div>
                        )
                    }
                })} */}
        </div>
       
    )
}