import estudio from '../images/IMG_ESTUDIO.jpg'
import PlaceIcon from '@mui/icons-material/Place';
import SendIcon from '@mui/icons-material/Send';

export function Contato(props:any){

    return(
        <div style={{display:'flex', width:'100vw', height:'100vh', backgroundImage:'url('+estudio+')', backgroundSize: props.mobile ? '':'100%', backgroundPosition:props.mobile ? 'center' : ''}}>
            <div style={{backgroundColor:'#000', height:'100vh', width:'100%', position:'absolute', opacity:'0.6'}}/>
            <div style={{marginTop:props.mobile ? '35vh':'50vh', transform:'translateY(-80px)', width:'100vw'}}>
                <div style={{fontFamily:'lemon', color:'#fff', float:'left', width: props.mobile ? '100vw' : '50vw'}}>
                    <div>
                        <PlaceIcon sx={{fontSize:50}}/>
                    </div>
                    Avenida das Américas, 2000 - Barra da Tijuca<br/>Rio de Janeiro - RJ
                </div>
                <div style={{fontFamily:'lemon', color:'#fff', float:'left', width:props.mobile ? '100vw' : '50vw', marginTop:props.mobile ? '10vh' : '0'}}>
                <div>
                        <SendIcon sx={{fontSize:50}}/>
                    </div>
                    contato@arcaentretenimento.com.br<br/>(21) 99998-1909
                </div>
                <div style={{clear:'both'}}/>
            </div>
        </div>
        
    )
}