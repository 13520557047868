import './App.css';
import { Contato } from './components/Contato';
import { MainPage } from './components/Main';
import arca_logo from './images/arca_logo_branca.png'
import { useState, useEffect } from 'react';
import { Solucoes } from './components/Solucoes';
import { Clientes } from './components/Clientes';
import { Publicidade } from './components/publicidade';
import { Arcalab } from './components/Arcalab';
import {Route, Routes, useNavigate} from 'react-router-dom'

function App() {

  // const [page, setPage] = useState(0)
  const [mobile, setMobile] = useState(false)
  // const [part, setPart] = useState(0)
  const [cupom, setCupom] = useState('')
  const navigate = useNavigate()

  const isMobile = function() {
    return(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  };

  useEffect(()=>{
    setMobile(isMobile())
    if((new URLSearchParams(window.location.search)).get('arcalab')!=null){
      const cup = (new URLSearchParams(window.location.search)).get('cupom')
      if(cup!=null){
        setCupom(cup.toUpperCase())
      }
      navigate('/arcalab')
    }
  },[])

  const sc_width = window.innerWidth
  let sc_height = window.innerHeight
  if(sc_height>1200){
    sc_height=1200
  }

  const menu = [
    {texto: 'CLIENTES', state:3},
    {texto:'SOLUÇÕES', state:1},
    {texto:'CONTATO', state:2},
  ]

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Publicidade height={sc_height} width={sc_width} mobile={mobile}/>}/>
        <Route path='/arcalab' element={<Arcalab height={sc_height} width={sc_width} cupom={cupom} mobile={mobile}/>}/>
      </Routes>
      {/* {part==0&&(<Publicidade height={sc_height} width={sc_width} mobile={mobile}/>)}
      {part==1&&(<Arcalab height={sc_height} width={sc_width} mobile={mobile}/>)} */}
      {/* <div style={{top:'0', position:'fixed', padding:'2vh 4vw', zIndex:'100', width:'90vw'}}>
        <div style={{float:'left', cursor:'pointer'}} onClick={()=>{setPage(0)}}>
          <img src={arca_logo} width={mobile ? '70px' : '100px'}/>
        </div>
        <div style={{float:'right'}}>
          {menu.map((item:any,ind:any)=>{
            return(
              <div key={ind}
                style={{fontFamily:'lemon', color:'#fff', float:'left', marginLeft:'2vw', marginTop:'10px', cursor:'pointer', fontSize:mobile ? '10px' : '16px'}}
                onClick={()=>{setPage(item.state)}}>
                {item.texto}
              </div>
            )
          })}
          <div style={{clear:'both'}}/>
        </div>
        <div style={{clear:'both'}}/>
      </div>
          {page==0&&(<MainPage height={sc_height} width={sc_width} mobile={mobile}/>)}
          {page==1&&(<Solucoes height={sc_height} width={sc_width} mobile={mobile}/>)}
          {page==2&&(<Contato height={sc_height} width={sc_width} mobile={mobile}/>)}
          {page==3&&(<Clientes height={sc_height} width={sc_width} mobile={mobile}/>)}*/}
    </div> 
  );
}

export default App;
