import globo from '../images/LOGO_GLOBO.png'
import globoplay from '../images/LOGO_GLOBOPLAY.png'
import mccann from '../images/LOGO_MCCANN.png'
import globotracks from '../images/LOGO_GLOBOTRACKS.png'
import sbt from '../images/LOGO_SBT.png'
import somlivre from '../images/LOGO_SOMLIVRE.png'
import netflix from '../images/LOGO_NETFLIX.png'

export const clients = [
    {nome:'GLOBO', img: globo, width:852, height:293},
    {nome:'MCCANN', img: mccann, width:673, height: 107},
    {nome:'SOMLIVRE', img: somlivre, width: 831, height: 849},
    {nome:'GLOBOPLAY', img: globoplay, width: 2560, height:563},
    {nome:'SBT', img: sbt, width: 1024, height: 1024},
    {nome:'GLOBOTRACKS', img: globotracks, width: 1338, height: 237},
    {nome:'NETFLIX', img: netflix, width: 1024, height: 277}
]


export function Clientes(props:any){

    function fix_width(ratio:any){
        let height = 70
        if(height*ratio>window.innerWidth*0.8){
            height = window.innerWidth*0.8/ratio
        }
        return(height+'px')
    }

    return(
        <div style={{marginTop: props.mobile ? '15vh':'35vh', width:'90vw', marginLeft:'8vw', textAlign:'center'}}>
            {clients.map((logo:any, index:any)=>{
                return(
                <div key={index}style={{marginRight:'5vw', marginBottom: '5vw', display:'inline-block'}}>
                    <img src={logo.img} height={fix_width(logo.width/logo.height)}/>
                </div>
                )
            })}
            <div style={{clear:'both'}}/>
        </div>
    )
}